import React from "react";

/* Components */
import { Heading, RadioButton, Text } from "../../../../../../components";

/* Methods */
import API from "../../../../API";

export default function BaseLayers({ app }) {
  const setBaseLayer = (val) => {
    API.updateMapAttr("base_layer", val, app.state.mapID).then();
  };

  return (
    <>
      <Heading number={5} ko={true}>
        Base Layer
      </Heading>
      <Text number={2} ko={true}>
        The base layer sets the cartographic style for the underlying web map.
      </Text>
      <form className="u-flex u-flex-around u-flex-align-center u-mgn-top-sm u-mgn-btm-md">
        <div className="u-flex u-flex-align-center u-mgn-right-md">
          <RadioButton
            ko={true}
            name="Default"
            onClick={() => {
              app.setState({ baseLayer: "default" });
              setBaseLayer("default");
            }}
            defaultChecked={app.state.baseLayer === "default" ? true : false}
          />
          <div className="u-mgn-left-sm">
            <p className="paragraph__2 paragraph__2--ko">Default</p>
          </div>
        </div>
        <div className="u-flex u-flex-align-center">
          <RadioButton
            ko={true}
            name="Satellite"
            onClick={() => {
              app.setState({ baseLayer: "satellite" });
              setBaseLayer("satellite");
            }}
            defaultChecked={app.state.baseLayer !== "default" ? true : false}
          />
          <div className="u-mgn-left-sm">
            <p className="paragraph__2 paragraph__2--ko">Satellite</p>
          </div>
        </div>
      </form>
    </>
  );
}
