import React from 'react'

// Components
import { Heading, Text } from '../../../components'

function AttributesTable({ attrs, data, subTitle, title }) {
  const tableRows = attrs.map((attr) => {
    const label = data[attr].label ? data[attr].label : '',
      value = data[attr].textValueFn
        ? data[attr].textValueFn()
        : data[attr].value,
      units = data[attr].textUnitsFn
        ? data[attr].textUnitsFn()
        : data[attr].units
        ? data[attr].units
        : ''

    return (
      <div
        key={`${attr}`}
        className='u-full-width u-flex cvm__attributes-table__row'>
        <div className='cvm__attributes-table__col--left u-pad-sm'>
          <Text number={2}>{`${label}`}</Text>
        </div>
        <div className='cvm__attributes-table__col--mid u-pad-sm'>
          <Text number={2}>{`${value}`}</Text>
        </div>
        <div className='cvm__attributes-table__col--right u-pad-sm'>
          <Text number={2}>{units}</Text>
        </div>
      </div>
    )
  })

  return (
    <div className='u-pad-top-lg'>
      <div className='u-pad-btm-md'>
        <Heading number={4}>{title}</Heading>
        <Text number={1}>{subTitle}</Text>
      </div>
      <div className='u-full-width u-flex cvm__attributes-table__row'>
        <div className='cvm__attributes-table__col--left u-flex-all-center'>
          <Heading number={5}>Attribute</Heading>
        </div>
        <div className='cvm__attributes-table__col--mid u-flex-all-center'>
          <Heading number={5}>Value</Heading>
        </div>
        <div className='cvm__attributes-table__col--right u-flex-all-center'>
          <Heading number={5}>Units</Heading>
        </div>
      </div>
      {tableRows}
    </div>
  )
}

export default AttributesTable
