import React from 'react'

// Components
import { Icon, Tooltip } from '../../../../../../../components'

function WarningIcon({ dataLayers, i }) {
  return (
    <>
      {dataLayers.data[i].raster.data.isValidated &&
        !dataLayers.data[i].raster.data.isValidated.value && (
          <div className='u-flex u-flex-align-center cvm__data-layer__large-icon__container cvm__data-layer__large-icon__container--right-end'>
            <Tooltip
              body={`${dataLayers.data[i].label} has failed validation on raster upload.`}
              ko={true}>
              <Icon icon='warning' ko={true} size='md' />
            </Tooltip>
          </div>
        )}
    </>
  )
}

export default WarningIcon
