import { FormInput } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGFormInput() {
  const content = {
    name: "",
    variantName: "FormInput",
    description: `A composite component of <Label /> and <InputText /> that reoccurs often in UI form elements.`,
    implementation: "Instantiate as a closed component with props assigned.",
    propsTable: [
      [
        "errorMessages",
        "string or closed JSX element",
        `Log in information does not match our records.  Please try again.`,
        "no",
        "Add `errorMessages` argument when an error needs to be shown in the UI when `isErrorShowing` is set to `true`.",
      ],
      [
        "ko",
        "boolean",
        `true, false`,
        "no",
        "Set `ko` to `true` when placing element on a dark background.",
      ],
      [
        "inputClassName",
        "string",
        `u-mgn-sm`,
        "no",
        "Add `className` arguments for the <input> element.",
      ],
      [
        "inputProps",
        "hash",
        `{id: "firstName", placeholder: "John"}`,
        "no",
        `Add props as arguments for the <input> element.`,
      ],
      [
        "inputSmall",
        "boolean",
        "true, false",
        "no",
        "Reduces the dimensions of the <input> component when set to true.",
      ],
      [
        "isErrorShowing",
        "boolean",
        "true OR state.isErrorShowing",
        "no",
        "Add `className` arguments for the <input> element.",
      ],
      [
        "labelClassName",
        "string",
        `u-mgn-sm`,
        "no",
        "Add `className` arguments for the <label> element.",
      ],
      [
        "labelProps",
        "hash",
        `{htmlFor: "firstName"}`,
        "no",
        `Add props as arguments for the <label> element.`,
      ],
      [
        "labelText",
        "string",
        `Confirm Password`,
        "yes",
        "Add `labelText` argument to define the label text.",
      ],
      [
        "type",
        "string",
        `password`,
        "yes",
        "`type` argument is used to select the type of input element.  Options are 'password', 'text', and 'textarea'.",
      ],
    ],
    codeExample: `import { FormInput } from "..";
import React from "react";

export default function FormInputContainer(){
  return(
    <div className="u-flex u-flex-column u-full-width">
      <div className="u-pad-sm">
        <FormInput
          inputProps={{
            placeholder: "placeholder text",
            htmlFor: "email",
          }}
          labelProps={{ id: "email" }}
          labelText="Email"
          type="text"
        />
      </div>
      <div className="u-pad-sm">
        <FormInput
          inputProps={{
            placeholder: "placeholder text",
            htmlFor: "password",
          }}
          labelProps={{ id: "password" }}
          labelText="Password"
          type="password"
        />
      </div>
    </div>
  )
}`,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-pad-sm">
            <FormInput
              inputProps={{
                placeholder: "placeholder text",
                htmlFor: "email",
              }}
              labelProps={{ id: "email" }}
              labelText="Email"
              type="text"
            />
          </div>
          <div className="u-pad-sm">
            <FormInput
              inputProps={{
                placeholder: "placeholder text",
                htmlFor: "password",
              }}
              labelProps={{ id: "password" }}
              labelText="Password"
              type="password"
            />
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
