import React, { useEffect } from "react";

/* Components */
import { LinkTop } from "../../../../../components";

/* Methods */
import renderControls from "./renderControls";

export default function MapControls({ app }) {
  useEffect(() => {
    if (app.state.map) {
      if (app.state.isVectorDrawingControlsEnabled === false) {
        app.state.map.pm.addControls({
          drawMarker: false,
          drawPolygon: false,
          drawPolyline: false,
          drawRectangle: false,
          editMode: false,
          removalMode: false,
        });
      } else {
        app.state.map.pm.addControls({
          drawMarker: true,
          drawPolygon: true,
          drawPolyline: true,
          drawRectangle: true,
          editMode: true,
          removalMode: true,
        });
      }
      renderControls();
    }
  }, [app.state.isVectorDrawingControlsEnabled]);
  return (
    <>
      <div className="leaflet-buttons__exit-button">
        <LinkTop to="/apps/collaborativemapper/dashboard">
          <div className="rrm__exit--text">Exit</div>
        </LinkTop>
      </div>
    </>
  );
}
