import externalLinks from "../../utils/paths/externalLinks";
import routes from "../../utils/paths/routes";
import staticLink from "../../utils/static";
export default {
  applications: {
    text: {
      main: "Our Apps",
      sub: "The latest releases of our tools and services.",
    },
    cards: [
      {
        buttonPath: routes.apps.collabHome.path,
        imgPath: `${staticLink}logo/CM-Logo.png`,
        text: {
          main: routes.apps.collabHome.label,
          sub: "Edit maps as a team.",
        },
      },
      /* {
 buttonPath: routes.apps.cvm.path,
 imgPath: `${staticLink}logo/CVM-Logo.png`,
 text:
 {
 main: routes.apps.cvm.label,
 sub: "Model landscape conservation value."
 }
 }, */
      /* {
        buttonPath: routes.apps.earthenticateHome.path,
        imgPath: `${staticLink}logo/ETC-Logo.png`,
        text: {
          main: routes.apps.earthenticateHome.label,
          sub: "Improve platform AI models.",
        },
      }, */
      /* {
 buttonPath: routes.apps.esa.path,
 imgPath: `${staticLink}logo/EDS-Logo.png`,
 text:
 {
 main: routes.apps.esa.label,
 sub: "Find any document related to the ESA."
 }
 }, */
      {
        buttonPath: routes.apps.habPatHome.path,
        imgPath: `${staticLink}logo/HP-Logo.png`,
        text: {
          main: routes.apps.habPatHome.label,
          sub: "Detect landscape cover changes.",
        },
      },
    ],
  },
  banner: {
    text: {
      main: "Improving conservation together.",
      sub:
        "Conservationist.io is an apps platform created by conservationists to improve conservation outcomes. We design and develop a wide variety of tools that bridge existing technological gaps within the field to help solve some of conservation’s toughest problems.",
    },
  },
  technicalService: {
    text: {
      main: "Providing better ways to solve old problems.",
      sub:
        "Our goal is to design innovative apps that make it easier to achieve positive conservation outcomes.",
    },
    cards: [
      {
        main: "Creativity",
        sub:
          "Similar problems arise across many conservation contexts. Our tools provide you with innovative ways to solve these persistent challenges.",
      },
      {
        main: "Convenience",
        sub:
          "Our apps strive to merge and simplify complex analyses and processes. We hope that as the number of our apps grows, the services they provide will consolidate the functionality conservationists need in one place.",
      },
      {
        main: "Partnership",
        sub:
          "The apps on Conservationist.io are often inspired through working parnerships with conservation practitioners. These relationships ensure that the tools we build are solving real problems in the conservation community.",
      },
    ],
  },
  conservationService: {
    text: {
      main: "Provided By Defenders Of Wildlife",
      sub: "Giving wildlife a voice.",
    },
    catchPhrases: [
      {
        main: "Prevent",
        sub: "species and their habitat from becoming imperiled.",
      },
      {
        main: "Protect",
        sub: "endangered and threatened species and their habitats.",
      },
      {
        main: "Restore",
        sub: "the health of vulnerable species and their habitats.",
      },
    ],
    cards: [
      {
        main: "Defenders Of Wildlife",
        sub:
          "Defenders of Wildlife is a national nonprofit dedicated to the protection of threatened and endangered species in the United States. Using a range of tools and tactics, Defenders works on the ground, at the Hill, and in the courts to advocate for the rights of the animals we love.",
        externalLinkPathname: externalLinks.defenders.landing.path,
      },
      {
        main: "Center For Conservation Innovation",
        sub:
          "The Center for Conservation Innovation (CCI) at Defenders of Wildlife works at the intersection of science, technology, and policy to find creative, practical solutions for better conservation. Conservationist.io is one such product of this research and innovation.",
        externalLinkPathname: externalLinks.cci.landing.path,
      },
    ],
  },
};
