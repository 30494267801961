import { Icon } from "..";
import React, { useState } from "react";

export default function InputPassword({
  className,
  inputProps = {},
  ko,
  small,
}) {
  const inputPasswordStateInit = {
      isPasswordVisible: false,
    },
    [inputPasswordState, setInputPasswordState] = useState(
      inputPasswordStateInit,
    );

  const type = inputPasswordState.isPasswordVisible ? "text" : "password";

  let inputClassName = "input__text";
  if (ko) {
    inputClassName += " input__text--ko ";
  }
  if (small) {
    inputClassName += " input__text--sm ";
  }

  return (
    <div className={`input__password-container ${inputClassName}`}>
      <input
        autoComplete="off"
        className={`input__password ${className}`}
        size={56}
        spellCheck={false}
        type={type}
        {...inputProps}
      />
      <div
        onClick={() => {
          setInputPasswordState({
            ...inputPasswordState,
            isPasswordVisible: !inputPasswordState.isPasswordVisible,
          });
        }}>
        {!inputPasswordState.isPasswordVisible && (
          <Icon className="input__password-icon" icon="invisible" size="md" />
        )}
        {inputPasswordState.isPasswordVisible && (
          <Icon className="input__password-icon" icon="visible" size="md" />
        )}
      </div>
    </div>
  );
}
