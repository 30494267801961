const helpers = {
  updateGeojson: (feature, latLngs) => {
    if (feature.type === "Polygon" || feature.geometry.type === "Polygon") {
      latLngs = latLngs[0]
    } else if (feature.type === "Point" || feature.geometry.type === "Point") {
      latLngs = [latLngs]
    }
    const newLatLngs = []
    for (var i = 0; i < latLngs.length; i++) {
      var set = []
      set.push(latLngs[i]["lng"])
      set.push(latLngs[i]["lat"])
      newLatLngs.push(set)
    }
    if (feature.type === "Polygon" || feature.geometry.type === "Polygon") {
      feature.geometry.coordinates[0] = newLatLngs
    } else if (feature.type === "LineString" || feature.geometry.type === "LineString") {
      feature.geometry.coordinates = newLatLngs
    } else if (feature.type === "Point" || feature.geometry.type === "Point") {
      feature.geometry.coordinates = newLatLngs[0]
    }
    return feature
  }
}

export default helpers