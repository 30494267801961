import React, { useEffect, useState } from 'react'

// Components
import { FormInput, Text } from '..'

// Utilities
import API from '../../auth/API'
import InputsValidations from '../../utils/forms/InputsValidations'

export default function FormInputWValidations({
  inputClassName = '',
  inputSubType = '',
  inputType,
  ko = false,
  labelClassName = '',
  parentState,
  placeholder = '',
  validationKey,
  validationsContext = 'none',
  valueKey,
  setParentState,
  useValidations = false,
}) {
  /*  
  Initial component state Object set by `inputType` key.  
  
  The component state Object stores the input value, the validation state, 
  if the input has been `blurred`, and error messages. 
  */
  const inputStateInits = {
      contact: {
        email: {
          errorMessages: {
            isInputStringNotEmpty: '',
            isFormatValid: '',
          },
          isBlurred: false,
          validations: {
            isInputStringNotEmpty: false,
            isFormatValid: false,
          },
          value: '',
        },
        message: {
          errorMessages: { isInputStringNotEmpty: '' },
          isBlurred: false,
          validations: { isInputStringNotEmpty: false },
          value: '',
        },
        name: {
          errorMessages: {
            isInputStringNotEmpty: '',
          },
          isBlurred: false,
          validations: {
            isInputStringNotEmpty: false,
          },
          value: '',
        },
      },
      'sign up': {
        confirmpassword: {
          errorMessages: {
            isCharsMatching: '',
          },
          isBlurred: false,
          validations: {
            isCharsMatching: false,
          },
          value: '',
        },
        email: {
          errorMessages: {
            isInputStringNotEmpty: '',
            isFormatValid: '',
            isUnique: '',
          },
          isBlurred: false,
          validations: {
            isInputStringNotEmpty: false,
            isFormatValid: false,
            isUnique: false,
          },
          value: '',
        },
        name: {
          errorMessages: {
            isCharsTypesValid: '',
            isInputStringNotEmpty: '',
          },
          isBlurred: false,
          validations: {
            isCharsTypesValid: false,
            isInputStringNotEmpty: false,
          },
          value: '',
        },
        password: {
          errorMessages: {
            isCharsCountAtLeastMin: '',
            isCharsTypesValid: '',
            isInputStringNotEmpty: '',
          },
          isBlurred: false,
          validations: {
            isCharsCountAtLeastMin: false,
            isCharsTypesValid: false,
            isInputStringNotEmpty: false,
          },
          value: '',
        },
        username: {
          errorMessages: {
            isCharsTypesValid: '',
            isInputStringNotEmpty: '',
            isUnique: '',
          },
          isBlurred: false,
          validations: {
            isCharsTypesValid: false,
            isInputStringNotEmpty: false,
            isUnique: false,
          },
          value: '',
        },
      },
    },
    inputStateInit = inputStateInits[validationsContext][inputType],
    [inputState, setInputState] = useState(inputStateInit)

  /* 
  Validations functions Object set by `inputType` key.  
  
  These Objects define validation methods to be applied to the input value, 
  and `setState` functions to update the component state after validation is completed.
  */
  const validationFunctions = {
      contact: {
        email: function checkEmailValidity() {
          const [isInputStringNotEmpty, isEmptyErrorMessage] =
            InputsValidations.isInputStringNotEmpty(inputState.value)

          const [isFormatValid, formatInvalidErrorMessage] =
            InputsValidations.isEmailFormatValid(inputState.value)

          setInputState({
            ...inputState,
            isBlurred: true,
            errorMessages: {
              isInputStringNotEmpty: isEmptyErrorMessage,
              isFormatValid: formatInvalidErrorMessage,
            },
            validations: {
              isInputStringNotEmpty,
              isFormatValid,
            },
          })

          const isInputEmailValid =
            isInputStringNotEmpty === true && isFormatValid === true
              ? true
              : false

          setParentState({
            ...parentState,
            [validationKey]: isInputEmailValid,
          })
        },
        message: function checkMessageValidity() {
          const [isInputStringNotEmpty, isEmptyErrorMessage] =
            InputsValidations.isInputStringNotEmpty(inputState.value)

          setInputState({
            ...inputState,
            isBlurred: true,
            errorMessages: {
              isInputStringNotEmpty: isEmptyErrorMessage,
            },
            validations: {
              isInputStringNotEmpty,
            },
          })

          const isMessageValid = isInputStringNotEmpty === true ? true : false

          setParentState({
            ...parentState,
            [validationKey]: isMessageValid,
          })
        },
        name: function checkNameValidity() {
          const [isInputStringNotEmpty, isEmptyErrorMessage] =
            InputsValidations.isInputStringNotEmpty(inputState.value)

          setInputState({
            ...inputState,
            isBlurred: true,
            errorMessages: {
              isInputStringNotEmpty: isEmptyErrorMessage,
            },
            validations: {
              isInputStringNotEmpty,
            },
          })

          const isNameValid = isInputStringNotEmpty === true ? true : false

          setParentState({
            ...parentState,
            [validationKey]: isNameValid,
          })
        },
      },
      none: {},
      'sign up': {
        confirmpassword: function checkConfirmPasswordValidity() {
          const [isCharsMatching, charsNotMatchingErrorMessage] =
            InputsValidations.isConfirmPasswordCharsMatching(
              parentState.valuePassword,
              inputState.value,
            )

          setInputState({
            ...inputState,
            isBlurred: true,
            errorMessages: {
              isCharsMatching: charsNotMatchingErrorMessage,
            },
            validations: {
              isCharsMatching,
            },
          })

          const isConfirmPasswordValid = isCharsMatching === true ? true : false

          setParentState({
            ...parentState,
            [validationKey]: isConfirmPasswordValid,
          })
        },
        email: async function checkEmailValidity() {
          const [isInputStringNotEmpty, isEmptyErrorMessage] =
            InputsValidations.isInputStringNotEmpty(inputState.value)

          const [isFormatValid, formatInvalidErrorMessage] =
            InputsValidations.isEmailFormatValid(inputState.value)

          const data = await API.signUp
              .isAttributeUnique({
                reqType: 'email',
                input: inputState.value,
              })
              .then((res) => {
                return res.data
              }),
            [isUnique, isNotUniqueErrorMessage] =
              InputsValidations.isEmailUnique(data.is_unique)

          setInputState({
            ...inputState,
            isBlurred: true,
            errorMessages: {
              isUnique: isNotUniqueErrorMessage,
              isInputStringNotEmpty: isEmptyErrorMessage,
              isFormatValid: formatInvalidErrorMessage,
            },
            validations: {
              isUnique,
              isInputStringNotEmpty,
              isFormatValid,
            },
          })

          const isInputEmailValid =
            isInputStringNotEmpty === true &&
            isFormatValid === true &&
            isUnique === true
              ? true
              : false

          setParentState({
            ...parentState,
            [validationKey]: isInputEmailValid,
          })
        },
        name: function checkNameValidity() {
          const [isInputStringNotEmpty, isEmptyErrorMessage] =
            InputsValidations.isInputStringNotEmpty(inputState.value)

          const [isCharsTypesValid, formatInvalidErrorMessage] =
            InputsValidations.isNameCharsTypesValid(inputState.value)

          setInputState({
            ...inputState,
            isBlurred: true,
            errorMessages: {
              isInputStringNotEmpty: isEmptyErrorMessage,
              isCharsTypesValid: formatInvalidErrorMessage,
            },
            validations: {
              isInputStringNotEmpty,
              isCharsTypesValid,
            },
          })

          const isNameValid =
            isInputStringNotEmpty === true && isCharsTypesValid === true
              ? true
              : false

          setParentState({
            ...parentState,
            [validationKey]: isNameValid,
          })
        },
        password: function checkPasswordValidity() {
          const MINPASSWORDCHARSCOUNT = 8,
            [isCharsCountAtLeastMin, charsCountIsNotAtLeastMinErrorMessage] =
              InputsValidations.isCharsCountAtLeastMin(
                inputState.value,
                MINPASSWORDCHARSCOUNT,
              )

          const [isCharsTypesValid, charsTypesInvalidErrorMessage] =
            InputsValidations.isPasswordCharsTypesValid(inputState.value)

          const [isInputStringNotEmpty, isEmptyErrorMessage] =
            InputsValidations.isInputStringNotEmpty(inputState.value)

          setInputState({
            ...inputState,
            isBlurred: true,
            errorMessages: {
              isCharsCountAtLeastMin: charsCountIsNotAtLeastMinErrorMessage,
              isCharsTypesValid: charsTypesInvalidErrorMessage,
              isInputStringNotEmpty: isEmptyErrorMessage,
            },
            validations: {
              isCharsCountAtLeastMin,
              isCharsTypesValid,
              isInputStringNotEmpty,
            },
          })

          const isPasswordValid =
            isCharsCountAtLeastMin === true &&
            isCharsTypesValid === true &&
            isInputStringNotEmpty === true
              ? true
              : false

          setParentState({
            ...parentState,
            [validationKey]: isPasswordValid,
          })
        },
        username: async function checkUsernameValidity() {
          const [isCharsTypesValid, formatInvalidErrorMessage] =
            InputsValidations.isUsernameCharsTypesValid(inputState.value)

          const [isInputStringNotEmpty, isEmptyErrorMessage] =
            InputsValidations.isInputStringNotEmpty(inputState.value)

          const data = await API.signUp
              .isAttributeUnique({
                reqType: 'username',
                input: inputState.value,
              })
              .then((res) => {
                return res.data
              }),
            [isUnique, isNotUniqueErrorMessage] =
              InputsValidations.isUsernameUnique(data.is_unique)

          setInputState({
            ...inputState,
            isBlurred: true,
            errorMessages: {
              isCharsTypesValid: formatInvalidErrorMessage,
              isInputStringNotEmpty: isEmptyErrorMessage,
              isUnique: isNotUniqueErrorMessage,
            },
            validations: {
              isCharsTypesValid,
              isInputStringNotEmpty,
              isUnique,
            },
          })

          const isUsernameValid =
            isCharsTypesValid === true &&
            isInputStringNotEmpty === true &&
            isUnique === true
              ? true
              : false

          setParentState({
            ...parentState,
            [validationKey]: isUsernameValid,
          })
        },
      },
    },
    validate = validationFunctions[validationsContext][inputType]

  /* 
  Error message element.  

  Configured to show each triggered message in the `errorMessage` object.
  */

  const errorTextClassName = ko ? 'error-text error-text--ko' : 'error-text'
  let errorMessages
  if (inputState.isBlurred === true) {
    errorMessages = Object.keys(inputState.errorMessages).map(
      (errorMessagesKey, index) => {
        if (inputState.validations[errorMessagesKey] === false) {
          return (
            <div key={`${inputType}-${index}`} className='u-pad-vert-sm'>
              <Text number={3} className={errorTextClassName}>
                {inputState.errorMessages[errorMessagesKey]}
              </Text>
            </div>
          )
        } else return null
      },
    )
  }

  // Update input value methods.
  const updateValueWTrim = (e) => {
      setInputState({
        ...inputState,
        isBlurred: false,
        value: e.target.value.trim(),
      })
      setParentState({
        ...parentState,
        [valueKey]: e.target.value.trim(),
      })
    },
    updateValue = (e) => {
      setInputState({
        ...inputState,
        isBlurred: false,
        value: e.target.value,
      })
      setParentState({
        ...parentState,
        [valueKey]: e.target.value,
      })
    }

  // Form input configuration Object.  Set by `inputType`.
  const formInputProps = {
    confirmpassword: {
      id: 'confirm-password',
      maxLength: 32,
      name: 'confirmpassword',
      labelText: 'Confirm Password',
      formInputType: 'password',
      onChange: updateValue,
    },
    email: {
      id: 'email',
      maxLength: 50,
      name: 'email',
      labelText: 'Email',
      formInputType: 'text',
      onChange: updateValueWTrim,
    },
    firstname: {
      id: 'first-name',
      maxLength: 50,
      name: 'firstname',
      labelText: 'First Name',
      formInputType: 'text',
      onChange: updateValueWTrim,
    },
    lastname: {
      id: 'last-name',
      maxLength: 50,
      name: 'lastname',
      labelText: 'Last Name',
      formInputType: 'text',
      onChange: updateValueWTrim,
    },
    message: {
      id: 'message',
      maxLength: 3000,
      name: 'message',
      labelText: 'Message',
      formInputType: 'textarea',
      onChange: updateValue,
    },
    password: {
      id: 'password',
      maxLength: 32,
      name: 'password',
      labelText: 'Password',
      formInputType: 'password',
      onChange: updateValue,
    },
    username: {
      id: 'username',
      maxLength: 20,
      name: 'username',
      labelText: 'Username',
      formInputType: 'text',
      onChange: updateValueWTrim,
    },
  }

  // Pre-populates textarea with values.
  useEffect(() => {
    if (
      formInputProps[`${inputSubType}${inputType}`].formInputType === 'textarea'
    ) {
      setInputState({ ...inputState, value: parentState[valueKey] })
    }
    if (
      formInputProps[`${inputSubType}${inputType}`].formInputType ===
        'textarea' &&
      useValidations === true
    ) {
      setParentState({ ...parentState, [validationKey]: true }) // assumes that preloaded parent state textarea values are valid.
    }
  }, [parentState[valueKey]])

  // console.log(`${inputSubType}${inputType} inputState: `, inputState);

  return (
    <FormInput
      errorMessages={<>{errorMessages}</>}
      isErrorShowing={
        parentState[validationKey] === false && inputState.isBlurred === true
      }
      inputClassName={inputClassName}
      componentProps={{
        id: formInputProps[`${inputSubType}${inputType}`].id,
        maxLength: formInputProps[`${inputSubType}${inputType}`].maxLength,
        name: formInputProps[`${inputSubType}${inputType}`].name,
        onBlur: () => {
          if (inputState.value.length > 0 && useValidations === true) {
            validate()
          } else if (inputState.value.length === 0 && useValidations === true) {
            setParentState({
              ...parentState,
              [validationKey]: false,
              [valueKey]: '',
            })
          }
        },
        onChange: (e) => {
          formInputProps[`${inputSubType}${inputType}`].onChange(e)
        },
        placeholder: placeholder,
      }}
      ko={ko}
      labelClassName={labelClassName}
      labelProps={{ htmlFor: formInputProps[`${inputSubType}${inputType}`].id }}
      labelText={formInputProps[`${inputSubType}${inputType}`].labelText}
      type={formInputProps[`${inputSubType}${inputType}`].formInputType}
      value={inputState.value}
    />
  )
}
