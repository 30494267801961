import React, { useState } from 'react'

// Classes
import RasterDataset from '../../../../classes/rasterDataset'

// Components
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FileUpload } from '../../../../../../components'

// Utilities
import APIreqs, { headers, urls } from '../../../../helpers/API'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import utils from '../../../../../../utils/utils'

function UploadRasterModal({ dataLayers, pcm }) {
  const uploadPercentageInit = 0,
    [uploadPercentage, setUploadPercentage] = useState(uploadPercentageInit)
  // console.log("uploadPercentage: ", uploadPercentage);

  function delayedUploadPercentageReset() {
    setTimeout(() => {
      setUploadPercentage(uploadPercentageInit)
    }, 500) // This prevents render bug for upload percentage button.
  }

  function resetModal() {
    delayedUploadPercentageReset()
    pcm.setState({
      ...pcm.state,
      activeModal: '',
    })
  }

  function getProcessRasterModal() {
    delayedUploadPercentageReset()
    pcm.setState({
      ...pcm.state,
      activeModal: 'processRasterModal',
    })
  }

  const { projectID } = useParams()

  async function uploadRasterFile(data, fileName) {
    async function postDataLayer() {
      try {
        const dataLayerIDRes = await APIreqs.dataLayers.post(projectID)
        return dataLayerIDRes.data
      } catch {
        utils.sendAlert(
          "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'post CVM data layer instance' error.",
          'error',
        )
      }
    }

    async function getDataLayer(dataLayerID) {
      try {
        const dataLayerRes = await APIreqs.dataLayers.getByID(dataLayerID)
        return dataLayerRes.data
      } catch {
        utils.sendAlert(
          "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'get CVM data layer instance' error.",
          'error',
        )
      }
    }

    async function postRasterWithFile(dataLayerID, fileData, fileName) {
      try {
        const configPostRaster = headers.getHeadersConfig('fileUpload'),
          queryParamsPostRaster = new URLSearchParams({
            dataLayerID,
          }),
          postRasterRes = await axios.post(
            `${urls.rastersController}?${queryParamsPostRaster}`,
            fileData, // This var is passed the raster file appended to a `form` object.
            {
              configPostRaster,
              // Function that uses JS Progress Event (subclass of Event) to monitor file upload status
              onUploadProgress: (progressEvent) => {
                const uploadPercentage = (
                  (100 * progressEvent.loaded) /
                  progressEvent.total
                ).toFixed(0)
                setUploadPercentage(uploadPercentage)
              },
            },
          ),
          rasterID = postRasterRes.data
        utils.sendAlert(`File uploaded.`, 'success')
        return rasterID
      } catch {
        utils.sendAlert(
          "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'post CVM raster instance after post data layer' error.",
          'error',
        )
      }
    }

    async function getRaster(rasterID) {
      try {
        const rasterDataRes = await APIreqs.rasters.getByID(rasterID)
        return rasterDataRes.data
      } catch {
        utils.sendAlert(
          "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'get CVM raster instance' error.",
          'error',
        )
      }
    }

    // Post data layer with raster.
    try {
      const dataLayerID = await postDataLayer(),
        rasterID = await postRasterWithFile(dataLayerID, data, fileName)

      // Get data layer and raster data, set to state vars.
      const dataLayerData = await getDataLayer(dataLayerID),
        rasterData = await getRaster(rasterID),
        rasterDataset = new RasterDataset()
      rasterDataset.setResData(rasterData)
      dataLayerData.raster = rasterDataset
      dataLayers.setData([...dataLayers.data, dataLayerData])
    } catch {
      utils.sendAlert(
        "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'upload CVM data layer raster data file' error.",
        'error',
      )
    }
  }

  return (
    <Modal
      centered={true}
      size='lg'
      isOpen={pcm.state.activeModal === 'uploadRasterModal'}>
      <ModalHeader>Data Layers</ModalHeader>
      <ModalBody>
        <div className='u-pad-md'>
          <FileUpload
            continueCB={getProcessRasterModal}
            fileType='raster'
            heading='Upload Raster Data'
            maxFileSizeB={1e9}
            modalToggle={resetModal}
            uploadCB={uploadRasterFile}
            uploadPercentage={uploadPercentage}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default UploadRasterModal
