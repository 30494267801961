import axios from "axios";

let config;
if (localStorage.getItem('token')) {
  config = {
    headers: {
      Authorization: `JWT ${localStorage.getItem('token')}`
    }
  }
} else {
  config = {}
}

const uploadHeaders = {
  headers: {
    Authorization: `JWT ${localStorage.getItem('token')}`,
    'content-type': 'multipart/form-data'
  }
}

const API = {
  getAOI: (species, type) => axios.get(`/api/changedetection/aoi?species=${species}&type=${type}`, config),
  // getSpeciesThumb: () => axios.get(`https://ecos.fws.gov/ecp/pullreports/catalog/species/report/species/export?columns=%2Fspecies%2FspeciesImage`),
  getSpeciesThumb: (id) => axios.get(`https://ecos.fws.gov/ecp/pullreports/catalog/species/report/species/export?columns=%2Fspecies%2FspeciesImage&filter=%2Fspecies%2FspeciesImage%40species_id%20%3D%20%27${id}%27`),
  getSpeciesNames: () => axios.put(`/api/changedetection/aoi`, config),
  runAnalysis: data => axios.post(`/api/changedetection/analysis`, data, config),
  uploadRange: file => axios.post(`/api/changedetection/aoi`, file, uploadHeaders)
}

export default API