import React from "react";
import { Heading } from "../../../components";

export default function DashboardHeading() {
  return (
    <>
      <div className="u-mgn-btm-md">
        <Heading number={2}>Conservation Value Mapper Dashboard</Heading>
      </div>
      <div className="g-dashboard__heading">
        <div className="u-mgn-left-sm">
          <Heading ko={true} number={4}>
            My Projects
          </Heading>
        </div>
      </div>
    </>
  );
}
