import React, { useState } from "react";
import { Text } from "../components";
import { Tooltip as TooltipReactstrap } from "reactstrap";
import _uniqueId from "lodash/uniqueId";

export default function Tooltip({
  id = _uniqueId("tooltip-"),
  body,
  ko,
  ...props
}) {
  const tooltipStateInit = { id: id, isOpen: false },
    [tooltipState, setTooltipState] = useState(tooltipStateInit);

  const toggle = () => {
    setTooltipState({
      ...tooltipState,
      isOpen: !tooltipState.isOpen,
    });
  };

  const bodyElement =
    typeof body !== "string" ? (
      body
    ) : (
      <Text ko={true} number={3}>
        {body}
      </Text>
    );

  return (
    <>
      <span className="c-tooltip__children" id={tooltipState.id}>
        {props.children}
      </span>
      <TooltipReactstrap
        {...props}
        isOpen={tooltipState.isOpen}
        target={tooltipState.id}
        toggle={toggle}>
        <div className={`u-pad-sm ${ko ? "c-tooltip--ko" : "c-tooltip"}`}>
          {bodyElement}
        </div>
      </TooltipReactstrap>
    </>
  );
}
