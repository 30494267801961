import React, {
  useEffect,
  //useRef,
  useState,
} from "react";
import Icon from "./Icon";

export default function CollapsibleV({ children, parent = null, title }) {
  const collapsibleVStateInit = { active: false },
    [collapsibleVState, setCollapsibleVState] = useState(collapsibleVStateInit);

  /* const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        setCollapsibleVState({ ...collapsibleVState, active: false });
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]); */

  const toggle = () => {
    const active = !collapsibleVState.active;
    setCollapsibleVState({
      ...collapsibleVState,
      active,
    });
    if (active) {
      if (parent) {
        parent.setState({ ...parent.state, openSection: title });
      }
    }
  };

  useEffect(() => {
    if (parent) {
      if (parent.state.openSection !== title && collapsibleVState.active) {
        toggle();
      }
    }
  }, [parent ? parent.state.openSection : null]);

  return (
    <div className="collapsible" /* ref={ref} */>
      <button className="collapsible__btn" onClick={toggle}>
        <div className="collapsible__btn--title u-font-bold">{title}</div>
        <div className="collapsible__btn--icon">
          {collapsibleVState.active ? (
            // Won't work if this is an icon too
            <span className="icon--ko icon2-icon2__caret-up icon--sm"></span>
          ) : (
            <Icon icon="caret-down" size="sm" ko={true} />
          )}
        </div>
      </button>
      <div
        className={
          collapsibleVState.active
            ? "collapsible__content"
            : "collapsible__content--closed"
        }>
        {collapsibleVState.active && children}
      </div>
    </div>
  );
}
