import React, { useState } from 'react'
import {
  Button,
  Heading,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TH,
  TD,
} from '../components'
import { Navbar } from '../platform'
import {
  SGAutocomplete,
  SGButton,
  SGCard,
  SGCardLgProfile,
  SGCardMdProfile,
  SGCardMdContentStripe,
  SGCardMdProfileStripe,
  SGCardSmProfile,
  SGCollapsible,
  SGCollapsibleH,
  SGCollapsibleV,
  SGFileUpload,
  SGForm,
  SGFormInput,
  SGFormInputWValidations,
  SGHeading,
  SGIcon,
  SGInput,
  SGInputComment,
  SGInputEditField,
  SGInputPassword,
  SGInputSliderH,
  SGInputText,
  SGLabel,
  SGList,
  SGLoading,
  SGMenu,
  SGModal,
  SGPopover,
  SGRadioButton,
  SGSelect,
  SGSpan,
  SGTable,
  SGText,
  SGTextArea,
  SGTextEditField,
  SGTooltip,
  SGTypography,
} from '../components/SGcomponents'

import { CodeBlock, dracula } from 'react-code-blocks'

const StyleGuide = (props) => {
  const initState = {
    modalOpen: false,
    navShowing: false,
    editFieldName1: 'Default value',
    editFieldName2: 'Default value',
  }

  const [state, setState] = useState(initState)

  function toggleNav() {
    setState({ ...state, navShowing: !state.navShowing })
  }

  const dirInfo = [
      {
        name: 'Autocomplete',
        level: 0,
        href: '#autocomplete',
        SGComponent: <></>,
      },
      { name: 'Button', level: 0, href: '#button', SGComponent: <></> },
      { name: 'Card', level: 0, href: '#card', SGComponent: SGCard },
      {
        name: 'Card Large Profile',
        level: 1,
        href: '#cardlgprofile',
        SGComponent: SGCardLgProfile,
      },
      {
        name: 'Card Medium Content Stripe',
        level: 1,
        href: '#cardmdcontentstripe',
        SGComponent: SGCardMdContentStripe,
      },
      {
        name: 'Card Medium Profile',
        level: 1,
        href: '#cardmdprofile',
        SGComponent: SGCardMdProfile,
      },
      {
        name: 'Card Medium Profile Stripe',
        level: 1,
        href: '#cardmdprofilestripe',
        SGComponent: SGCardMdProfileStripe,
      },
      {
        name: 'Card Small Profile',
        level: 1,
        href: '#cardsmprofile',
        SGComponent: SGCardSmProfile,
      },
      {
        name: 'Collapsible',
        level: 0,
        href: '#collapsible',
        SGComponent: SGCollapsible,
      },
      {
        name: 'Collapsible Horizontal',
        level: 1,
        href: '#collapsibleh',
        SGComponent: <></>,
      },
      {
        name: 'Collapsible Vertical',
        level: 1,
        href: '#collapsiblev',
        SGComponent: <></>,
      },
      {
        name: 'File Upload',
        level: 0,
        href: '#fileupload',
        SGComponent: <></>,
      },
      {
        name: 'Form',
        level: 0,
        href: '#form',
        SGComponent: SGForm,
      },
      {
        name: 'Form Input',
        level: 1,
        href: '#forminput',
        SGComponent: SGFormInput,
      },
      {
        name: 'Form Input With Validations',
        level: 1,
        href: '#forminputwvalidations',
        SGComponent: SGFormInputWValidations,
      },
      { name: 'Icons', level: 0, href: '#icon', SGComponent: <></> },
      { name: 'Input', level: 0, href: '#input', SGComponent: SGInput },
      {
        name: 'Input Comment',
        level: 1,
        href: '#inputcomment',
        SGComponent: <></>,
      },
      {
        name: 'Input Edit Field',
        level: 1,
        href: '#inputeditfield',
        SGComponent: <></>,
      },
      {
        name: 'Input Password',
        level: 1,
        href: '#inputpassword',
        SGComponent: SGInputPassword,
      },
      {
        name: 'Input Slider H',
        level: 1,
        href: '#inputsliderh',
        SGComponent: SGInputSliderH,
      },
      { name: 'Input Text', level: 1, href: '#inputtext', SGComponent: <></> },
      {
        name: 'Label',
        level: 0,
        href: '#label',
        SGComponent: SGLabel,
      },
      { name: 'List', level: 0, href: '#list', SGComponent: <></> },
      {
        name: 'Loading',
        level: 0,
        href: '#loading',
        SGComponent: <></>,
      },
      { name: 'Menu', level: 0, href: '#menu', SGComponent: <></> },
      { name: 'Modal', level: 0, href: '#modal', SGComponent: <></> },
      { name: 'Navbar', level: 0, href: '#navbar', SGComponent: <></> },
      {
        name: 'Popover',
        level: 0,
        href: '#popover',
        SGComponent: SGPopover,
      },
      {
        name: 'Radio Button',
        level: 0,
        href: '#radiobutton',
        SGComponent: <></>,
      },
      {
        name: 'Select',
        level: 0,
        href: '#select',
        SGComponent: SGSelect,
      },
      { name: 'Table', level: 0, href: '#table', SGComponent: <></> },
      {
        name: 'Text Area',
        level: 0,
        href: '#textarea',
        SGComponent: <></>,
      },
      {
        name: 'Text Edit Field',
        level: 0,
        href: '#texteditfield',
        SGComponent: SGTextEditField,
      },
      {
        name: 'Tooltip',
        level: 0,
        href: '#tooltip',
        SGComponent: SGTooltip,
      },
      {
        name: 'Typography',
        level: 0,
        href: '#typography',
        SGComponent: <></>,
      },
      {
        name: 'Heading',
        level: 1,
        href: '#heading',
        SGComponent: SGHeading,
      },
      {
        name: 'Text',
        level: 1,
        href: '#text',
        SGComponent: SGText,
      },
      {
        name: 'Span',
        level: 1,
        href: '#span',
        SGComponent: SGSpan,
      },
      {
        name: 'Utility Classes',
        level: 0,
        href: '#utility',
        SGComponent: <></>,
      },
    ],
    dirButtons = dirInfo.map((button, index) => {
      let hierarchyClassName
      switch (button.level) {
        case 0:
          hierarchyClassName = '--level-0'
          break
        case 1:
          hierarchyClassName = '--level-1'
          break
        default:
          hierarchyClassName = ''
      }

      return (
        <div
          key={index}
          className={`styleguide__dir-button-container styleguide__dir-button-container${hierarchyClassName}`}>
          <Button buttontype='text' ko={true} href={button.href}>
            {button.name}
          </Button>
        </div>
      )
    })

  // TO BE ENABLED WHEN ALL SG COMPONENTS ARE COMPLETE
  /* const dirContentElements = dirInfo.map((element) => {
    return {element.SGComponent};
  }); */

  return (
    <div>
      <div className='styleguide__nav u-background-blue'>
        <div className='styleguide__nav__heading u-mgn-btm-sm'>
          <Heading number={3} ko={true} color='secondary'>
            Directory
          </Heading>
        </div>
        {dirButtons}
      </div>

      <div className='styleguide__components'>
        <h1 className='u-pad-sm u-mgn-btm-sm'>
          <Heading number={1}>Conservationist.io Style Guide</Heading>
        </h1>
        <SGAutocomplete />

        <SGButton />

        {/* CARD variants */}
        <SGCard />
        <SGCardLgProfile />
        <SGCardMdContentStripe />
        <SGCardMdProfile />
        <SGCardMdProfileStripe />
        <SGCardSmProfile />

        {/* COLLAPSIBLE variants */}
        <SGCollapsible />
        <SGCollapsibleH />
        <SGCollapsibleV />

        {/* FILE UPLOAD */}
        <SGFileUpload />

        {/* Icons */}
        <SGIcon />

        {/* INPUT CATEGORY */}
        <SGInput />

        <SGInputComment />

        {/* INPUT EDIT FIELD */}
        <SGInputEditField />

        {/* INPUT PASSWORD FIELD */}
        <SGInputPassword />

        {/* INPUT SLIDER H */}
        <SGInputSliderH />

        {/* INPUT TEXT */}
        <SGInputText />

        {/* FORM */}
        <SGForm />
        <SGFormInput />
        <SGFormInputWValidations />

        {/* LABEL */}
        <SGLabel />

        {/* LIST */}
        <SGList />

        {/* LOADING */}
        <SGLoading />

        {/* MENU */}
        <SGMenu />

        {/* MODAL */}
        <SGModal />

        {/* NAVBAR */}
        <div id='navbar' className=' u-pad-sm'>
          <h2 className='heading__2'>Navbar</h2>
          <h3 className='heading__3 u-mgn-vert-sm'>Description:</h3>
          <p className='paragraph__1'>
            A component that stays fixed to the top of the screen and allows
            users to navigate their way around the site.
          </p>
        </div>

        <div className='u-pad-sm'>
          {state.navShowing ? <Navbar /> : null}
          <Button buttontype='primary' onClick={toggleNav}>
            {' '}
            Toggle Navbar Display{' '}
          </Button>
        </div>

        {/* POPOVER */}
        <SGPopover />

        {/* RADIO */}
        <SGRadioButton />

        {/* SELECT */}
        <SGSelect />

        {/* TABLE */}
        <SGTable />

        {/* TEXTAREA */}
        <SGTextArea />

        {/* TEXT EDIT FIELD */}
        <SGTextEditField />

        {/* TOOLTIP */}
        <SGTooltip />

        {/* TYPOGRAPHY */}
        <SGTypography />
        <SGHeading />
        <SGText />
        <SGSpan />

        {/* UTILITY CLASSES */}
        <div id='utility' className='u-mgn-top-sm u-pad-sm'>
          <h2 className='heading__2'>Utility classes</h2>
          <p className='paragraph__1'>
            Utility classes are used to add single style values to a given
            element. For example, if all you need to do to an element was to
            give it margin above, rather than creating a brand new css class
            that would only add margin, you can use a utility class that
            accomplishes the same thing.
          </p>

          <p className='paragraph__1 u-mgn-top-sm'>
            When applied, all utility classes will begin with the `u-` prefix.
            For example: `u-mgn-btm-sm`
          </p>
        </div>
        <h3 className='heading__3 u-mgn-vert-sm'>Margin</h3>

        <Table>
          <TableHead>
            <TableRow>
              <TH>Category</TH>
              <TH>Prefix</TH>
              <TH>Extensions</TH>
              <TH>Modifiers</TH>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TD>Margin</TD>
              <TD>mgn-</TD>
              <TD>top-, left-, right-, bottom-</TD>
              <TD>sm, md, lg</TD>
            </TableRow>
          </TableBody>
        </Table>

        {/* Example */}
        <h5 className='heading__5 u-mgn-vert-sm'>Margin examples:</h5>
        <CodeBlock
          text={`
            <div className="u-mgn-btm-sm">
            </div>

            <div className="u-mgn-top-lg">
            </div>

          `}
          language={'jsx'}
          showLineNumbers={true}
          theme={dracula}
        />

        <h3 className='heading__3 u-mgn-vert-sm'>Padding</h3>
        <Table>
          <TableHead>
            <TableRow>
              <TH>Category</TH>
              <TH>Prefix</TH>
              <TH>Extensions</TH>
              <TH>Modifiers</TH>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TD>Padding</TD>
              <TD>pad-</TD>
              <TD>top-, left-, right-, bottom-</TD>
              <TD>sm, md, lg</TD>
            </TableRow>
          </TableBody>
        </Table>

        {/* Example */}
        <h5 className='heading__5 u-pad-vert-sm'>Padding examples:</h5>
        <CodeBlock
          text={`
            <div className="u-pad-btm-sm">
            </div>

            <div className="u-pad-top-lg">
            </div>

          `}
          language={'jsx'}
          showLineNumbers={true}
          theme={dracula}
        />

        <h3 className='heading__3 u-mgn-vert-sm'>Flexbox</h3>
        <Table>
          <TableHead>
            <TableRow>
              <TH>Category</TH>
              <TH>Prefix</TH>
              <TH>Extensions</TH>
              <TH>Modifiers</TH>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TD>Flexbox</TD>
              <TD>flex-</TD>
              <TD>justify-, align-, self-</TD>
              <TD>
                <div>
                  justify:
                  <p>start, end, center, between, evenly, around</p>
                </div>
                <div className='u-mgn-top-sm'>
                  align (items):
                  <p>start, end, center</p>
                </div>
                <div className='u-mgn-top-sm'>
                  Misc (no extensions. so u-[value listed below]):
                  <p>
                    all-center (display flex and justify + align center), row
                    (direction), column (direction), wrap (wrap)
                  </p>
                </div>
              </TD>
            </TableRow>
          </TableBody>
        </Table>

        {/* Example */}
        <h5 className='heading__5 u-pad-vert-sm'>Padding examples:</h5>
        <CodeBlock
          text={`
            <div className="u-pad-btm-sm">
            </div>

            <div className="u-pad-top-lg">
            </div>

          `}
          language={'jsx'}
          showLineNumbers={true}
          theme={dracula}
        />
      </div>
    </div>
  )
}

export default StyleGuide
