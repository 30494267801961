import { InputComment, Label } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGInputEditField() {
  const content = {
    name: "",
    variantName: "InputComment",
    description: `An input for users to submit a comment in a "comment section".`,
    implementation: "Used for comment threads.",
    propsTable: [
      [
        "onSubmit",
        "function",
        `a function designed to handle the submission of the comment input.`,
        "yes",
        "This function will be triggered when the user submits the form or clicks on the paper airplane button in the button.",
      ],
      [
        "onChange",
        "function",
        "a function that tracks the changing value in this input.",
        "no",
        "This function will take an event object as an argument. Use this and any other passed attributes to track the value input by the user.",
      ],
      [
        "ko",
        "Boolean",
        `true, false`,
        "no",
        `Set to true when the component is set over a dark background. Defaults to false.`,
      ],
      [
        "...props",
        "JSX button attributes",
        `Any valid attribute that is applicable to a default JSX "div" element `,
        "Provide elements as would normally be applied to a div element",
      ],
      [
        "children",
        "JSX input attributes",
        `Any valid JSX`,
        "yes",
        `InputComment is not a self-closing component. Wrap any desired
        content within the open and close tags. This could be used to
        pre-populate the field or to set a placeholder.`,
      ],
    ],
    codeExample: `
    import { InputComment } from "/components";

    state = {
      
    }

    function onChange(e) {
      // Update state or track input value in another way
    }

    handleCommentSubmit(e) {
      // Do stuff to habdle the form's submission
    }
     
    <InputComment onSubmit={handleCommentSubmit} ko={false} onChange={onChange} name="commentName" data-thing={someAttribute}>
      Some optional value
    </InputComment>
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-mgn-top-sm">
            <Label labelText="The component will grow with more text" />
            <InputComment placeholder="placeholder"></InputComment>
          </div>
          <div className="u-background-blue u-mgn-top-sm">
            <div className="u-pad-sm">
              <Label
                labelText="The component will grow with more text"
                ko={true}
              />
              <InputComment placeholder="placeholder" ko={true}></InputComment>
            </div>
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
