export default {
  "Cicek (minnow)":"Acanthorutilus handlirschi",
  "Christmas Island goshawk":"Accipiter fasciatus natalis",
  "Anjouan Island sparrowhawk":"Accipiter francesii pusillus",
  "Queen Charlotte goshawk":"Accipiter gentilis laingi",
  "Puerto Rican sharp-shinned hawk":"Accipiter striatus venator",
  "Oahu tree snails":"Achatinella spp.",
  "Cheetah":"Acinonyx jubatus",
  "Shortnose sturgeon":"Acipenser brevirostrum",
  "green sturgeon":"Acipenser medirostris",
  "Sakhalin sturgeon":"Acipenser mikadoi",
  "Adriatic sturgeon":"Acipenser naccarii",
  "Atlantic sturgeon (Gulf subspecies)":"Acipenser oxyrinchus (=oxyrhynchus) desotoi",
  "Atlantic sturgeon":"Acipenser oxyrinchus oxyrinchus",
  "Chinese sturgeon":"Acipenser sinensis",
  "European sturgeon":"Acipenser sturio",
  "White sturgeon":"Acipenser transmontanus",
  "Nihoa millerbird (old world warbler)":"Acrocephalus familiaris kingi",
  "Nightingale reed warbler (old world warbler)":"Acrocephalus luscinia",
  "Eiao Marquesas reed-Warbler":"Acrocephalus percernis aquilonis",
  "Staghorn coral":"Acropora cervicornis",
  "No common name":"Tubastraea floreana",
  "Elkhorn coral":"Acropora palmata",
  "Addax":"Addax nasomaculatus",
  "Kauai cave wolf or pe'e pe'e maka 'ole spider":"Adelocosa anops",
  "Black-faced impala":"Aepyceros melampus petersi",
  "Mariana gray swiftlet":"Aerodramus vanikorensis bartschi",
  "Yellow-shouldered blackbird":"Agelaius xanthomus",
  "White-breasted guineafowl":"Agelastes meleagrides",
  "Giant panda":"Ailuropoda melanoleuca",
  "Dusky sea snake":"Aipysurus fuscus",
  "Kauai akialoa (honeycreeper)":"Akialoa stejnegeri",
  "Cumberland elktoe":"Alasmidonta atropurpurea",
  "Dwarf wedgemussel":"Alasmidonta heterodon",
  "Appalachian elktoe":"Alasmidonta raveneliana",
  "Raso lark":"Alauda razae",
  "Swayne's hartebeest":"Alcelaphus buselaphus swaynei",
  "Tora hartebeest":"Alcelaphus buselaphus tora",
  "Thyolo alethe":"Alethe choloensis",
  "American alligator":"Alligator mississippiensis",
  "Chinese alligator":"Alligator sinensis",
  "Mantled howler monkey":"Alouatta palliata",
  "Black howler monkey":"Alouatta pigra",
  "Honduran emerald hummingbird":"Amazilia luciae",
  "Red-necked parrot":"Amazona arausiaca",
  "Red-tailed parrot":"Amazona brasiliensis",
  "Yellow-billed parrot":"Amazona collaria",
  "St Vincent parrot":"Amazona guildingii",
  "Imperial parrot":"Amazona imperialis",
  "Bahaman or Cuban parrot":"Amazona leucocephala",
  "Red-spectacled parrot":"Amazona pretrei pretrei",
  "Red-browed parrot":"Amazona rhodocorytha",
  "St. Lucia parrot":"Amazona versicolor",
  "Vinaceous-breasted parrot":"Amazona vinacea",
  "Puerto Rican parrot":"Amazona vittata",
  "Fat threeridge (mussel)":"Amblema neislerii",
  "Ozark cavefish":"Amblyopsis rosae",
  "Ash Meadows naucorid":"Ambrysus amargosus",
  "Reticulated flatwoods salamander":"Ambystoma bishopi",
  "California tiger Salamander":"Ambystoma californiense",
  "Frosted Flatwoods salamander":"Ambystoma cingulatum",
  "Santa Cruz long-toed salamander":"Ambystoma macrodactylum croceum",
  "Sonora tiger Salamander":"Ambystoma tigrinum stebbinsi",
  "St. Croix ground lizard":"Ameiva polops",
  "Clark's gazelle":"Ammodorcas clarkei",
  "Cape Sable seaside sparrow":"Ammodramus maritimus mirabilis",
  "Florida grasshopper sparrow":"Ammodramus savannarum floridanus",
  "San Clemente sage sparrow":"Amphispiza belli clementeae",
  "Eyrean grasswren (flycatcher)":"Amytornis goyderi",
  "Florida leafwing Butterfly":"Anaea troglodyta floridalis",
  "Ash-breasted tit-tyrant":"Anairetes alpinus",
  "Campbell Island flightless teal":"Anas aucklandica nesiotis",
  "Laysan duck":"Anas laysanensis",
  "Hawaiian (=koloa) Duck":"Anas wyvilliana",
  "Arroyo (=arroyo southwestern) toad":"Anaxyrus californicus",
  "Yosemite toad":"Anaxyrus canorus",
  "Chinese giant salamander":"Andrias davidianus (=davidianus d.)",
  "Japanese giant salamander":"Andrias japonicus (=davidianus j.)",
  "Painted snake coiled forest snail":"Anguispira picta",
  "Glaucous macaw":"Anodorhynchus glaucus",
  "Hyacinth macaw":"Anodorhynchus hyacinthinus",
  "Indigo macaw":"Anodorhynchus leari",
  "Culebra Island giant anole":"Anolis roosevelti",
  "Narrow sawfish":"Anoxypristis cuspidata",
  "Eastern jerboa marsupial":"Antechinomys laniger",
  "Dibbler":"Antechinus apicalis",
  "Peninsular pronghorn":"Antilocapra americana peninsularis",
  "Sonoran pronghorn":"Antilocapra americana sonoriensis",
  "Tumbling Creek cavesnail":"Antrobia culveri",
  "Madison Cave isopod":"Antrolana lira",
  "Cameroon clawless otter":"Aonyx congicus (=congica) microdon",
  "Florida scrub-jay":"Aphelocoma coerulescens",
  "Point Arena mountain beaver":"Aplodontia rufa nigra",
  "Ponape mountain starling":"Aplonis pelzelni",
  "Lange's metalmark butterfly":"Apodemia mormo langei",
  "Bulmer's fruit bat (=flying fox)":"Aproteles bulmerae",
  "Spanish imperial eagle":"Aquila heliaca adalberti",
  "Great green macaw":"Ara ambiguus",
  "Blue-throated macaw":"Ara glaucogularis",
  "Scarlet macaw":"Ara macao ssp. macao",
  "Military macaw":"Ara militaris",
  "Golden conure":"Aratinga guarouba",
  "Dappled mountain robin":"Arcanator orostruthus",
  "Guadalupe fur seal":"Arctocephalus townsendi",
  "Great Indian bustard":"Ardeotis (=Choriotis) nigriceps",
  "Ouachita rock pocketbook":"Arkansia wheeleri",
  "Pecos assiminea snail":"Assiminea pecos",
  "Spider monkey":"Ateles geoffroyl panamensis",
  "Panamanian golden frog":"Atelopus varius zeteki",
  "Anthony's riversnail":"Athearnia anthonyi",
  "Noisy scrub-bird":"Atrichornis clamosus",
  "Avahi":"Avahi laniger (entire genus)",
  "Indochina hog deer":"Axis porcinus annamiticus",
  "Calamianes (=Philippine) deer":"Axis porcinus calamianensis",
  "Kuhl's (=Bawean) deer":"Axis porcinus kuhli",
  "Madagascar pochard":"Aythya innotata",
  "Babirusa":"Babyrousa babyrussa",
  "Bowhead whale":"Balaena mysticetus",
  "Sei whale":"Balaenoptera borealis",
  "Blue whale":"Balaenoptera musculus",
  "Finback whale":"Balaenoptera physalus",
  "River terrapin":"Batagur baska",
  "Desert slender salamander":"Batrachoseps aridus",
  "Coffin Cave mold beetle":"Batrisodes texanus",
  "Helotes mold beetle":"Batrisodes venyivi",
  "Rodrigues warbler (old world warbler)":"Bebrornis rodericanus",
  "Seychelles warbler (old world warbler)":"Bebrornis sechellensis",
  "Gaimard's rat-kangaroo":"Bettongia gaimardi",
  "Lesueur's rat-kangaroo":"Bettongia lesueur",
  "Brush-tailed rat-kangaroo":"Bettongia penicillata",
  "Queensland rat-kangaroo":"Bettongia tropica",
  "Wood Bison":"Bison bison athabascae",
  "Marsh deer":"Blastocerus dichotomus",
  "Uncompahgre fritillary butterfly":"Boloria acrocnema",
  "Round Island bolyeria boa":"Bolyeria multocarinata",
  "Rusty patched bumble bee":"Bombus affinis",
  "Seledang":"Bos gaurus",
  "Banteng":"Bos javanicus",
  "Wild yak":"Bos mutus (=grunniens m.)",
  "Kouprey":"Bos sauveli",
  "Columbia Basin Pygmy Rabbit":"Brachylagus idahoensis",
  "Fiji banded iguana":"Brachylophus fasciatus",
  "Fiji crested iguana":"Brachylophus vitiensis",
  "Marbled murrelet":"Brachyramphus marmoratus",
  "Woolly spider monkey":"Brachyteles arachnoides",
  "Brazilian three-toed sloth":"Bradypus torquatus",
  "Conservancy fairy shrimp":"Branchinecta conservatio",
  "Longhorn fairy shrimp":"Branchinecta longiantenna",
  "Vernal pool fairy shrimp":"Branchinecta lynchi",
  "San Diego fairy shrimp":"Branchinecta sandiegonensis",
  "Hawaiian goose":"Branta (=Nesochen) sandvicensis",
  "Hungerford's crawling water Beetle":"Brychius hungerfordi",
  "Lowland anoa":"Bubalus depressicornis",
  "Tamaraw":"Bubalus mindorensis",
  "Mountain anoa":"Bubalus quarlesi",
  "Helmeted hornbill":"Buceros (=Rhinoplax) vigil",
  "Wyoming Toad":"Bufo hemiophrys baxteri",
  "Houston toad":"Bufo houstonensis",
  "Monte Verde golden toad":"Bufo periglenes",
  "Cameroon toad":"Bufo superciliaris",
  "Mountain pygmy possum":"Burramys parvus",
  "Galapagos hawk":"Buteo galapagoensis",
  "Puerto Rican broad-winged hawk":"Buteo platypterus brunnescens",
  "Uakari (all species)":"Cacajao spp.",
  "White cockatoo":"Cacatua alba",
  "Philippine cockatoo":"Cacatua haematuropygia",
  "Salmon-crested cockatoo":"Cacatua moluccensis",
  "Yellow-crested cockatoo":"Cacatua sulphurea",
  "Apaporis River caiman":"Caiman crocodilus apaporiensis",
  "Common caiman":"Caiman crocodilus crocodilus",
  "Brown Caiman":"Caiman crocodilus fuscus (includes Caiman crocodilus chiapasius)",
  "Broad-snouted caiman":"Caiman latirostris",
  "Yacare Caiman":"Caiman yacare",
  "White-winged wood duck":"Cairina scutulata",
  "Red knot":"Calidris canutus rufa",
  "Kokako (wattlebird)":"Callaeas cinerea",
  "Goeldi's marmoset":"Callimico goeldii",
  "White-eared (=buffy tufted-ear) marmoset":"Callithrix aurita (=jacchus a.)",
  "Buff-headed marmoset":"Callithrix flaviceps",
  "San Bruno elfin butterfly":"Callophrys mossii bayensis",
  "Desert (=plain) rat-kangaroo":"Caloprymnus campestris",
  "Floreana tree-Finch":"Camarhynchus pauper",
  "Benton County cave crayfish":"Cambarus aculabrum",
  "Big Sandy crayfish":"Cambarus callainus",
  "Guyandotte River crayfish":"Cambarus veteranus",
  "Hell Creek Cave crayfish":"Cambarus zophonastes",
  "Bactrian camel":"Camelus bactrianus",
  "Slender campeloma":"Campeloma decampi",
  "Imperial woodpecker":"Campephilus imperialis",
  "Ivory-billed woodpecker":"Campephilus principalis",
  "Gray wolf":"Canis lupus",
  "Mexican wolf":"Canis lupus baileyi",
  "Red wolf":"Canis rufus",
  "Simien fox":"Canis simensis",
  "Chiltan (=wild goat) markhor":"Capra falconeri (=aegragrus) chiltanensis",
  "Straight-horned markhor":"Capra falconeri megaceros",
  "Pyrenean ibex":"Capra pyrenaica pyrenaica",
  "Walia ibex":"Capra walie",
  "Puerto Rican nightjar":"Caprimulgus noctitherus",
  "Hispid hare":"Caprolagus hispidus",
  "Cabrera's hutia":"Capromys angelcabrerai",
  "Large-eared hutia":"Capromys auritus",
  "Dwarf hutia":"Capromys nana",
  "Little earth hutia":"Capromys sanfelipensis",
  "Red siskin":"Carduelis cucullata",
  "Loggerhead sea turtle":"Caretta caretta",
  "Round Island casarea boa":"Casarea dussumieri",
  "Beaver (Mongolian)":"Castor fiber birulai",
  "Asian golden (=Temmnick's) cat":"Catopuma (=Felis) temminckii",
  "Zuni bluehead Sucker":"Catostomus discobolus yarrowi",
  "Santa Ana sucker":"Catostomus santaanae",
  "Warner sucker":"Catostomus warnerensis",
  "Cheer pheasant":"Catreus wallichii",
  "Gunnison sage-grouse":"Centrocercus minimus",
  "Jentink's duiker":"Cephalophus jentinki",
  "South Island Hector's dolphin":"Cephalorhynchus hectori hectori",
  "Maui dolphin":"Cephalorhynchus hectori maui",
  "Northern white rhinoceros":"Ceratotherium simum cottoni",
  "Southern white rhinoceros":"Ceratotherium simum ssp. simum",
  "Tana River mangabey":"Cercocebus galeritus galeritus",
  "White-collared mangabey":"Cercocebus torquatus",
  "Diana monkey":"Cercopithecus diana",
  "Red-bellied monkey":"Cercopithecus erythrogaster",
  "Red-eared nose-spotted monkey":"Cercopithecus erythrotis",
  "L'hoest's monkey":"Cercopithecus lhoesti",
  "Visayan deer":"Cervus alfredi",
  "Swamp deer":"Cervus duvauceli",
  "Bactrian deer":"Cervus elaphus bactrianus",
  "Barbary stag":"Cervus elaphus barbarus",
  "Barbary deer":"Cervus elaphus barbarus",
  "Corsican red deer":"Cervus elaphus corsicanus",
  "Kashmir stag":"Cervus elaphus hanglu",
  "McNeill's deer":"Cervus elaphus macneilii",
  "Shou":"Cervus elaphus wallichi",
  "Yarkand deer":"Cervus elaphus yarkandensis",
  "Eld's brow-antlered Deer":"Cervus eldi",
  "Shansi sika deer":"Cervus nippon grassianus",
  "Ryukyu sika deer":"Cervus nippon keramae",
  "South China sika deer":"Cervus nippon kopschi",
  "North China sika deer":"Cervus nippon mandarinus",
  "Formosan sika deer":"Cervus nippon taiouanus",
  "Pig-footed bandicoot":"Chaeropus ecaudatus",
  "Esmeraldas woodstar":"Chaetocercus berlepschi",
  "Thin-spined porcupine":"Chaetomys subspinosus",
  "Piping Plover":"Charadrius melodus",
  "Western snowy plover":"Charadrius nivosus nivosus",
  "Oahu elepaio":"Chasiempis ibidis",
  "Shortnose Sucker":"Chasmistes brevirostris",
  "Cui-ui":"Chasmistes cujus",
  "June sucker":"Chasmistes liorus",
  "Green sea turtle":"Chelonia mydas",
  "Virgin Islands tree boa":"Chilabothrus granti",
  "Chinchilla":"Chinchilla brevicaudata boliviana",
  "White-nosed saki":"Chiropotes albinasus",
  "Southern bearded saki":"Chiropotes satanas satanas",
  "Pink fairy armadillo":"Chlamyphorus truncatus",
  "Grenada hook-billed kite":"Chondrohierax uncinatus mirus",
  "Cuba hook-billed kite":"Chondrohierax uncinatus wilsonii",
  "Laurel dace":"Chrosomus saylori",
  "Maned wolf":"Chrysocyon brachyurus",
  "St. Lucia Forest thrush":"Cichlherminia iherminieri santaeluciae",
  "Northeastern beach tiger beetle":"Cicindela dorsalis dorsalis",
  "Salt Creek Tiger beetle":"Cicindela nevadica lincolniana",
  "Ohlone tiger beetle":"Cicindela ohlone",
  "Puritan tiger beetle":"Cicindela puritana",
  "Miami tiger beetle":"Cicindelidia floridana",
  "Oriental white stork":"Ciconia boyciana (=ciconia b.)",
  "Robber Baron Cave Meshweaver":"Cicurina baronia",
  "Madla Cave Meshweaver":"Cicurina madla",
  "Braken Bat Cave Meshweaver":"Cicurina venii",
  "Government Canyon Bat Cave Meshweaver":"Cicurina vespera",
  "Martinique trembler (thrasher)":"Cinclocerthia ruficauda gutturalis",
  "Royal cinclodes":"Cinclodes aricomae",
  "bog turtle":"Clemmys muhlenbergii",
  "Maria Island ground lizard":"Cnemidophorus vanzoi",
  "Yellow-billed Cuckoo":"Coccyzus americanus",
  "Masked bobwhite (quail)":"Colinus virginianus ridgwayi",
  "Black colobus monkey":"Colobus satanas",
  "Puerto Rican plain Pigeon":"Columba inornata wetmorei",
  "White-tailed laurel Pigeon":"Columba junoniae",
  "Pink pigeon":"Columba mayeri",
  "Azores wood pigeon":"Columba palumbus azorica",
  "Barrington land iguana":"Conolophus pallidus",
  "Goliath Frog":"Conraua goliath",
  "Cebu black shama (thrush)":"Copsychus niger cebuensis",
  "Seychelles magpie-robin (thrush)":"Copsychus sechellarum",
  "Reunion cuckoo-shrike":"Coquus newtoni",
  "Mauritius cuckoo-shrike":"Coquus typicus",
  "Seychelles lesser vasa parrot":"Coracopsis nigra barklyi",
  "Nekogigi (catfish)":"Coreobagrus ichikawai",
  "Hawaiian (='alala) Crow":"Corvus hawaiiensis",
  "Mariana (=aga) Crow":"Corvus kubaryi",
  "White-necked crow":"Corvus leucognaphalus",
  "Ozark big-eared bat":"Corynorhinus (=Plecotus) townsendii ingens",
  "Virginia big-eared bat":"Corynorhinus (=Plecotus) townsendii virginianus",
  "Banded cotinga":"Cotinga maculata",
  "Pygmy Sculpin":"Cottus paulus (=pygmaeus)",
  "Grotto Sculpin":"Cottus specus",
  "Bumblebee bat":"Craseonycteris thonglongyai",
  "Blue-billed curassow":"Crax alberti",
  "Red-billed curassow":"Crax blumenbachii",
  "White River springfish":"Crenichthys baileyi baileyi",
  "Hiko White River springfish":"Crenichthys baileyi grandis",
  "Railroad Valley springfish":"Crenichthys nevadae",
  "American crocodile":"Crocodylus acutus",
  "African slender-snouted crocodile":"Crocodylus cataphractus",
  "Orinoco crocodile":"Crocodylus intermedius",
  "Nile crocodile":"Crocodylus niloticus",
  "Philippine crocodile":"Crocodylus novaeguineae mindorensis",
  "Ceylon mugger crocodile":"Crocodylus palustris kimbula",
  "Mugger crocodile":"Crocodylus palustris palustris",
  "Saltwater crocodile":"Crocodylus porosus",
  "Cuban crocodile":"Crocodylus rhombifer",
  "Siamese crocodile":"Crocodylus siamensis",
  "White eared pheasant":"Crossoptilon crossoptilon",
  "Brown eared pheasant":"Crossoptilon mantchuricum",
  "Aruba Island rattlesnake":"Crotalus unicolor",
  "New Mexican ridge-nosed rattlesnake":"Crotalus willardi obscurus",
  "Ozark Hellbender":"Cryptobranchus alleganiensis bishopi",
  "diamond Darter":"Crystallaria cincotta",
  "Spectaclecase (mussel)":"Cumberlandia monodonta",
  "Dhole":"Cuon alpinus",
  "Little blue macaw":"Cyanopsitta spixii",
  "Forbes' parakeet":"Cyanoramphus auriceps forbesi",
  "Norfolk Island parakeet":"Cyanoramphus cookii (=novaezelandiae c.)",
  "Nickerbean Blue Butterfly":"Cyclargus ammon",
  "Miami Blue Butterfly":"Cyclargus (=Hemiargus) thomasi bethunebakeri",
  "Mayaguana iguana":"Cyclura carinata bartschi",
  "Turks and Caicos iguana":"Cyclura carinata carinata",
  "Jamaican iguana":"Cyclura collei",
  "Andros Island ground iguana":"Cyclura cychlura cychlura",
  "Exuma Island iguana":"Cyclura cychlura figginsi",
  "Allen's Cay iguana":"Cyclura cychlura inornata",
  "Grand Cayman ground iguana":"Cyclura lewisi",
  "Cayman Brac ground iguana":"Cyclura nubila caymanensis",
  "Cuban ground iguana":"Cyclura nubila nubila",
  "Anegada ground iguana":"Cyclura pinguis",
  "White Cay ground iguana":"Cyclura rileyi cristata",
  "Acklins ground iguana":"Cyclura rileyi nuchalis",
  "Watling Island ground iguana":"Cyclura rileyi rileyi",
  "Mona ground Iguana":"Cyclura stejnegeri",
  "Mexican prairie dog":"Cynomys mexicanus",
  "Utah prairie dog":"Cynomys parvidens",
  "Totoaba (seatrout or weakfish)":"Cynoscion macdonaldi",
  "Blue shiner":"Cyprinella caerulea",
  "Beautiful shiner":"Cyprinella formosa",
  "Leon Springs pupfish":"Cyprinodon bovinus",
  "Devils Hole pupfish":"Cyprinodon diabolis",
  "Comanche Springs pupfish":"Cyprinodon elegans",
  "Desert pupfish":"Cyprinodon macularius",
  "Ash Meadows Amargosa pupfish":"Cyprinodon nevadensis mionectes",
  "Warm Springs pupfish":"Cyprinodon nevadensis pectoralis",
  "Owens pupfish":"Cyprinodon radiosus",
  "Fanshell":"Cyprogenia stegaria",
  "Serpent Island gecko":"Cyrtodactylus serpensinsula",
  "Tampico pearlymussel":"Cyrtonaias tampicoensis tecomatensis",
  "Merriam's Montezuma quail":"Cyrtonyx montezumae merriami",
  "Bontebok":"Damaliscus pygarus (=dorcas) dorcas",
  "Persian fallow deer":"Dama mesopotamica (=dama m.)",
  "Western rufous bristlebird":"Dasyornis broadbenti littoralis",
  "Western bristlebird":"Dasyornis longirostris (=brachypterus I.)",
  "Eastern native-cat":"Dasyurus viverrinus",
  "Aye-aye":"Daubentonia madagascariensis",
  "beluga whale":"Delphinapterus leucas",
  "Lost River sucker":"Deltistes luxatus",
  "Pillar Coral":"Dendrogyra cylindrus",
  "Golden-cheeked warbler (=wood)":"Dendroica chrysoparia",
  "Barbados yellow warbler (=wood)":"Dendroica petechia petechia",
  "Central American river turtle":"Dermatemys mawii",
  "Leatherback sea turtle":"Dermochelys coriacea",
  "Valley elderberry longhorn beetle":"Desmocerus californicus dimorphus",
  "Sumatran rhinoceros":"Dicerorhinus sumatrensis",
  "Black rhinoceros":"Diceros bicornis",
  "Casey's June Beetle":"Dinacoma caseyi",
  "Amsterdam Albatross":"Diomedia amsterdamensis",
  "Devils River minnow":"Dionda diaboli",
  "Morro Bay kangaroo rat":"Dipodomys heermanni morroensis",
  "Giant kangaroo rat":"Dipodomys ingens",
  "San Bernardino Merriam's kangaroo rat":"Dipodomys merriami parvus",
  "Fresno kangaroo rat":"Dipodomys nitratoides exilis",
  "Tipton kangaroo rat":"Dipodomys nitratoides nitratoides",
  "Stephens' kangaroo rat":"Dipodomys stephensi (incl. D. cascus)",
  "Israel painted frog":"Discoglossus nigriventer",
  "Iowa Pleistocene snail":"Discus macclintocki",
  "`I`iwi":"Drepanis coccinea",
  "Cloven-feathered dove":"Drepanoptila holosericea",
  "Dromedary pearlymussel":"Dromus dromas",
  "Hawaiian picture-wing fly":"Drosophila tarphytrichia",
  "Eastern indigo snake":"Drymarchon corais couperi",
  "Tristam's woodpecker":"Dryocopus javensis richardsi",
  "Marquesas pigeon":"Ducula galeata",
  "Mindoro imperial (=zone-tailed) pigeon":"Ducula mindorensis",
  "Dugong":"Dugong dugon",
  "Chinese egret":"Egretta eulophotes",
  "Delta green ground beetle":"Elaphrus viridis",
  "Spring pygmy sunfish":"Elassoma alabamae",
  "Asian elephant":"Elephas maximus",
  "Guajon":"Eleutherodactylus cooki",
  "Golden coqui":"Eleutherodactylus jasperi",
  "Llanero Coqui":"Eleutherodactylus juanariveroi",
  "Lacy elimia (snail)":"Elimia crenatella",
  "Chipola slabshell":"Elliptio chipolaensis",
  "Yellow lance":"Elliptio lanceolata",
  "Altamaha Spinymussel":"Elliptio spinosa",
  "Tar River spinymussel":"Elliptio steinstansana",
  "Purple bankclimber (mussel)":"Elliptoideus sloatianus",
  "Pacific sheath-tailed Bat":"Emballonura semicaudata semicaudata",
  "Slevin's skink":"Emoia slevini",
  "Pahrump poolfish":"Empetrichthys latos",
  "Euler's flycatcher":"Empidonax euleri johnstonei",
  "Southwestern willow flycatcher":"Empidonax traillii extimus",
  "Northern Sea Otter":"Enhydra lutris kenyoni",
  "Southern sea otter":"Enhydra lutris nereis",
  "Puerto Rican boa":"Epicrates inornatus",
  "Mona boa":"Epicrates monensis monensis",
  "Jamaican boa":"Epicrates subflavus",
  "Nassau grouper":"Epinephelus striatus",
  "Cumberlandian combshell":"Epioblasma brevidens",
  "Oyster mussel":"Epioblasma capsaeformis",
  "Curtis pearlymussel":"Epioblasma florentina curtisii",
  "Yellow blossom (pearlymussel)":"Epioblasma florentina florentina",
  "Tan riffleshell":"Epioblasma florentina walkeri (=E. walkeri)",
  "Upland combshell":"Epioblasma metastriata",
  "Purple Cat's paw (=Purple Cat's paw pearlymussel)":"Epioblasma obliquata obliquata",
  "White catspaw (pearlymussel)":"Epioblasma obliquata perobliqua",
  "Southern acornshell":"Epioblasma othcaloogensis",
  "Southern combshell":"Epioblasma penita",
  "Green blossom (pearlymussel)":"Epioblasma torulosa gubernaculum",
  "Northern riffleshell":"Epioblasma torulosa rangiana",
  "Tubercled blossom (pearlymussel)":"Epioblasma torulosa torulosa",
  "Snuffbox mussel":"Epioblasma triquetra",
  "Turgid blossom (pearlymussel)":"Epioblasma turgidula",
  "African wild ass":"Equus africanus",
  "Grevy's zebra":"Equus grevyi",
  "Asian wild ass":"Equus hemionus",
  "Przewalski's horse":"Equus przewalskii",
  "Hartmann's mountain zebra":"Equus zebra hartmannae",
  "Cape Mountain zebra":"Equus zebra ssp. zebra",
  "Mountain zebra":"Equus zebra zebra",
  "Desert dace":"Eremichthys acros",
  "Streaked Horned lark":"Eremophila alpestris strigata",
  "Hawksbill sea turtle":"Eretmochelys imbricata",
  "bearded Seal":"Erignathus barbatus nauticus",
  "Spotfin Chub":"Erimonax monachus",
  "Slender chub":"Erimystax cahni",
  "Newcomb's snail":"Erinna newcombi",
  "black-breasted puffleg":"Eriocnemis nigrivestis",
  "Gray whale":"Eschrichtius robustus",
  "bluemask darter":"Etheostoma akatulo",
  "Slackwater darter":"Etheostoma boschungi",
  "Vermilion darter":"Etheostoma chermocki",
  "Relict darter":"Etheostoma chienense",
  "Etowah darter":"Etheostoma etowahae",
  "Fountain darter":"Etheostoma fonticola",
  "Yellowcheek Darter":"Etheostoma moorei",
  "Niangua darter":"Etheostoma nianguae",
  "Watercress darter":"Etheostoma nuchale",
  "Okaloosa darter":"Etheostoma okaloosae",
  "Candy darter":"Etheostoma osburni",
  "Duskytail darter":"Etheostoma percnurum",
  "Rush Darter":"Etheostoma phytophilum",
  "Bayou darter":"Etheostoma rubrum",
  "Cherokee darter":"Etheostoma scotti",
  "Maryland darter":"Etheostoma sellare",
  "Kentucky arrow darter":"Etheostoma spilotum",
  "Cumberland darter":"Etheostoma susanae",
  "Trispot darter":"Etheostoma trisella",
  "Boulder darter":"Etheostoma wapiti",
  "Snail [no common name]":"Ostodes strigatus",
  "Southern Right Whale":"Eubalaena australis",
  "North Atlantic Right Whale":"Eubalaena glacialis",
  "North Pacific Right Whale":"Eubalaena japonica",
  "Island marble Butterfly":"Euchloe ausonides insulanus",
  "Tidewater goby":"Eucyclogobius newberryi",
  "southern rockhopper penguin":"Eudyptes chrysocome",
  "fiordland crested penguin":"Eudyptes pachyrhynchus",
  "erect-crested penguin":"Eudyptes sclateri",
  "white-flippered penguin":"Eudyptula albosignata",
  "Chilean woodstar":"Eulidia yarrellii",
  "Bluetail mole skink":"Eumeces egregius lividus",
  "Steller sea lion":"Eumetopias jubatus",
  "Florida bonneted bat":"Eumops floridanus",
  "El Segundo blue butterfly":"Euphilotes battoides allyni",
  "Smith's blue butterfly":"Euphilotes enoptes smithi",
  "Bay checkerspot butterfly":"Euphydryas editha bayensis",
  "Quino checkerspot butterfly":"Euphydryas editha quino (=E. e. wrighti)",
  "Taylor's (=whulge) Checkerspot":"Euphydryas editha taylori",
  "Kern primrose sphinx moth":"Euproserpinus euterpe",
  "Salado Salamander":"Eurycea chisholmensis",
  "San Marcos salamander":"Eurycea nana",
  "Georgetown Salamander":"Eurycea naufragia",
  "Barton Springs salamander":"Eurycea sosorum",
  "Jollyville Plateau Salamander":"Eurycea tonkawae",
  "Austin blind Salamander":"Eurycea waterlooensis",
  "Caerulean Paradise flycatcher":"Eutrichomyias rowleyi",
  "Madagascar serpent eagle":"Eutriorchis astur",
  "Seychelles kestrel":"Falco araea",
  "Northern Aplomado Falcon":"Falco femoralis septentrionalis",
  "Eurasian peregrine falcon":"Falco peregrinus peregrinus",
  "Mauritius kestrel":"Falco punctatus",
  "Andean cat":"Felis jacobita",
  "Pakistan sand cat":"Felis margarita scheffeli",
  "Black-footed cat":"Felis nigripes",
  "Spanish lynx":"Felis pardina",
  "Black hooded (black-headed) antwren":"Formicivora erythronotos",
  "Rodrigues fody":"Foudia flavicans",
  "Mauritius fody":"Foudia rubra",
  "Seychelles fody (weaver-finch)":"Foudia sechellarum",
  "Djibouti francolin":"Francolinus ochropectus",
  "Andrew's frigatebird":"Fregata andrewsi",
  "Hawaiian coot":"Fulica americana alai",
  "Barrens topminnow":"Fundulus julisia",
  "Tapered pigtoe":"Fusconaia burkei",
  "Shiny pigtoe":"Fusconaia cor",
  "Finerayed pigtoe":"Fusconaia cuneolus",
  "Narrow pigtoe":"Fusconaia escambia",
  "Round Ebonyshell":"Fusconaia rotulata",
  "Friendly Ground-Dove":"Gallicolumba stairi",
  "Mariana common moorhen":"Gallinula chloropus guami",
  "Hawaiian common gallinule":"Gallinula galeata sandvicensis",
  "Lord Howe wood rail":"Gallirallus (=Tricholimnas) sylvestris",
  "Hierro giant lizard":"Gallotia simonyi simonyi",
  "Blunt-nosed leopard lizard":"Gambelia silus",
  "Big Bend gambusia":"Gambusia gaigei",
  "San Marcos gambusia":"Gambusia georgei",
  "Clear Creek gambusia":"Gambusia heterochir",
  "Pecos gambusia":"Gambusia nobilis",
  "Illinois cave amphipod":"Gammarus acherondytes",
  "Noel's Amphipod":"Gammarus desperatus",
  "Diminutive Amphipod":"Gammarus hyalleloides",
  "Pecos amphipod":"Gammarus pecos",
  "Unarmored threespine stickleback":"Gasterosteus aculeatus williamsoni",
  "Gavial":"Gavialis gangeticus",
  "Mountain (=Cuvier's) gazelle":"Gazella cuvieri",
  "Dama gazelle":"Gazella dama",
  "Rio de Oro Dama gazelle":"Gazella dama lozanoi",
  "Mhorr gazelle":"Gazella dama mhorr",
  "Moroccan gazelle":"Gazella dorcas massaesyla",
  "Pelzeln's gazelle":"Gazella dorcas pelzelni",
  "Saudi Arabian gazelle":"Gazella dorcas saudiya",
  "Arabian gazelle":"Gazella gazella",
  "Slender-horned gazelle":"Gazella leptoceros",
  "Sand gazelle":"Gazella subgutturosa marica",
  "Galapagos tortoise":"Geochelone nigra (=elephantopus)",
  "Madagascar radiated tortoise":"Geochelone radiata",
  "Angulated tortoise":"Geochelone yniphora",
  "Spotted pond turtle":"Geoclemys hamiltonii",
  "Night (=Australian) parrot":"Geopsittacus occidentalis",
  "Northern bald ibis":"Geronticus eremita",
  "Hutton tui chub":"Gila bicolor ssp.",
  "Mohave tui chub":"Gila bicolor ssp. mohavensis",
  "Owens Tui Chub":"Gila bicolor ssp. snyderi",
  "Humpback chub":"Gila cypha",
  "Sonora chub":"Gila ditaenia",
  "Bonytail":"Gila elegans",
  "Gila chub":"Gila intermedia",
  "Chihuahua chub":"Gila nigrescens",
  "Yaqui chub":"Gila purpurea",
  "Pahranagat roundtail chub":"Gila robusta jordani",
  "Virgin River Chub":"Gila seminuda (=robusta)",
  "Carolina northern flying squirrel":"Glaucomys sabrinus coloratus",
  "Palos Verdes blue butterfly":"Glaucopsyche lygdamus palosverdesensis",
  "Desert tortoise":"Gopherus agassizii",
  "Bolson tortoise":"Gopherus flavomarginatus",
  "Gopher tortoise":"Gopherus polyphemus",
  "Gorilla":"Gorilla gorilla",
  "Brown-banded antpitta":"Grallaria milleri",
  "Yellow-blotched map turtle":"Graptemys flavimaculata",
  "Ringed map turtle":"Graptemys oculifera",
  "Whooping crane":"Grus americana",
  "Cuba sandhill crane":"Grus canadensis nesiotes",
  "Mississippi sandhill crane":"Grus canadensis pulla",
  "Japanese Crane":"Grus japonensis",
  "Siberian white Crane":"Grus leucogeranus",
  "Hooded Crane":"Grus monacha",
  "Black-necked Crane":"Grus nigricollis",
  "White-naped Crane":"Grus vipio",
  "Leadbeater's Possum":"Gymnobelideus leadbeateri",
  "California condor":"Gymnogyps californianus",
  "Mao (= maomao) (honeyeater)":"Gymnomyza samoensis",
  "Canarian black oystercatcher":"Haematopus meadewaldoi",
  "Guam Micronesian kingfisher":"Halcyon cinnamomina cinnamomina",
  "Greenland white-tailed eagle":"Haliaeetus albicilla groenlandicus",
  "Madagascar sea eagle":"Haliaeetus vociferoides",
  "Black Abalone":"Haliotis cracherodii",
  "White Abalone":"Haliotis sorenseni",
  "Southern sandshell":"Hamiota australis",
  "Harpy eagle":"Harpia harpyja",
  "Morro shoulderband (=Banded dune) snail":"Helminthoglypta walkeriana",
  "Ceraunus Blue Butterfly":"Hemiargus ceraunus antibubastus",
  "Maui nukupuu":"Hemignathus affinis",
  "Kauai nukupuu":"Hemignathus hanapepe",
  "akiapolaau":"Hemignathus wilsoni",
  "Chatham Island pigeon":"Hemiphaga novaeseelandiae chathamensis",
  "Cracking pearlymussel":"Hemistena lata",
  "Arabian tahr":"Hemitragus jayakari",
  "Kaempfer's tody-tyrant":"Hemitriccus kaempferi",
  "Schaus swallowtail butterfly":"Heraclides aristodemus ponceanus",
  "Gulf Coast jaguarundi":"Herpailurus (=Felis) yagouaroundi cacomitli",
  "Guatemalan jaguarundi":"Herpailurus (=Felis) yagouaroundi fossata",
  "Panamanian jaguarundi":"Herpailurus (=Felis) yagouaroundi panamensis",
  "Sinaloan Jaguarundi":"Herpailurus (=Felis) yagouaroundi tolteca",
  "Dakota Skipper":"Hesperia dacotae",
  "Pawnee montane skipper":"Hesperia leonardus montana",
  "Comal Springs riffle beetle":"Heterelmis comalensis",
  "Hawaiian stilt":"Himantopus mexicanus knudseni",
  "Black Stilt":"Himantopus novaezelandiae",
  "North Andean huemul":"Hippocamelus antisensis",
  "South Andean huemul":"Hippocamelus bisulcus",
  "Singapore roundleaf horseshoe bat":"Hipposideros ridleyi",
  "Giant sable antelope":"Hippotragus niger variani",
  "Kaluga sturgeon":"Huso dauricus",
  "Beluga sturgeon":"Huso huso",
  "Barbary hyena":"Hyaena hyaena barbara",
  "Rio Grande Silvery Minnow":"Hybognathus amarus",
  "Anthricinan yellow-faced bee":"Hylaeus anthracinus",
  "Assimulans yellow-faced bee":"Hylaeus assimulans",
  "Easy yellow-faced bee":"Hylaeus facilis",
  "Hilaris yellow-faced bee":"Hylaeus hilaris",
  "Hawaiian yellow-faced bee":"Hylaeus mana",
  "Gibbons":"Hylobates spp. (including Nomascus)",
  "Ayumodoki (loach)":"Hymenophysa curta",
  "Mariana eight-spot butterfly":"Hypolimnas octocula marianensis",
  "Delta smelt":"Hypomesus transpacificus",
  "Mauritius olivaceous Bulbul":"Hypsipetes borbonicus olivaceus",
  "Fender's blue butterfly":"Icaricia icarioides fenderi",
  "Mission blue butterfly":"Icaricia icarioides missionensis",
  "Mount Charleston blue butterfly":"Icaricia (Plebejus) shasta charlestonensis",
  "Yaqui catfish":"Ictalurus pricei",
  "Indri lemur":"Indri indri",
  "Rota blue damselfly":"Ischnura luta",
  "Koster's springsnail":"Juturnia kosteri",
  "Indian sawback turtle":"Kachuga tecta tecta",
  "Sonoyta mud turtle":"Kinosternon sonoriense longifemorale",
  "Red lechwe":"Kobus leche",
  "Western hare wallaby":"Lagorchestes hirsutus",
  "Banded hare wallaby":"Lagostrophus fasciatus",
  "Yellow-tailed woolly monkey":"Lagothrix flavicauda",
  "Pink mucket (pearlymussel)":"Lampsilis abrupta",
  "Finelined pocketbook":"Lampsilis altilis",
  "Higgins eye (pearlymussel)":"Lampsilis higginsii",
  "Orangenacre mucket":"Lampsilis perovalis",
  "Arkansas fatmucket":"Lampsilis powellii",
  "Neosho Mucket":"Lampsilis rafinesqueana",
  "Speckled pocketbook":"Lampsilis streckeri",
  "Shinyrayed pocketbook":"Lampsilis subangulata",
  "Alabama lampmussel":"Lampsilis virescens",
  "San Clemente loggerhead shrike":"Lanius ludovicianus mearnsi",
  "Banbury Springs limpet":"Lanx sp.",
  "Audouin's gull":"Larus audouinii",
  "Relict gull":"Larus relictus",
  "Queensland hairy-nosed wombat (incl. Barnard's)":"Lasiorhinus krefftii (formerly L. barnardi and L. gillespiei)",
  "Hawaiian hoary bat":"Lasiurus cinereus semotus",
  "Carolina heelsplitter":"Lasmigona decorata",
  "Junin rail":"Laterallus tuerosi",
  "African coelacanth":"Latimeria chalumnae",
  "Meltwater lednian stonefly":"Lednia tumana",
  "Round Island skink":"Leiolopisma telfairi",
  "Stephen Island frog":"Leiopelma hamiltoni",
  "Birdwing pearlymussel":"Lemiox rimosus",
  "Lemurs":"Lemuridae (incl. genera Lemur, Phaner, Hapalemur, Lepilemur, Microcebus, Allocebus, Cheirogaleus, Varecia)",
  "Golden-rumped tamarin":"Leontopithecus spp.",
  "Ocelot":"Leopardus (=Felis) pardalis",
  "Tiger cat":"Leopardus (=Felis) tigrinus",
  "Margay":"Leopardus (=Felis) wiedii",
  "Kemp's ridley sea turtle":"Lepidochelys kempii",
  "Olive ridley sea turtle":"Lepidochelys olivacea",
  "White River spinedace":"Lepidomeda albivallis",
  "Big Spring spinedace":"Lepidomeda mollispinis pratensis",
  "Little Colorado spinedace":"Lepidomeda vittata",
  "Vernal pool tadpole shrimp":"Lepidurus packardi",
  "Stick-nest rat":"Leporillus conditor",
  "Barbary serval":"Leptailurus (=Felis) serval constantina",
  "White-browed tit-spinetail":"Leptasthenura xenothorax",
  "Scaleshell mussel":"Leptodea leptodon",
  "Mexican long-nosed bat":"Leptonycteris nivalis",
  "Greater adjutant":"Leptoptilos dubius",
  "Cassius Blue Butterfly":"Leptotes cassius theonus",
  "Grenada gray-fronted dove":"Leptotila rufaxilla wellsi",
  "Round rocksnail":"Leptoxis ampla",
  "Interrupted (=Georgia) Rocksnail":"Leptoxis foremani",
  "Plicate rocksnail":"Leptoxis plicata",
  "Painted rocksnail":"Leptoxis taeniata",
  "Flat pebblesnail":"Lepyrium showalteri",
  "Semper's warbler (=wood)":"Leucopeza semperi",
  "Rothschild's starling (myna)":"Leucopsar rothschildi",
  "Helmeted honeyeater":"Lichenostomus melanops cassidix (=Meliphaga c.)",
  "Maria Island snake":"Liophus ornatus",
  "Cylindrical lioplax (snail)":"Lioplax cyclostomaformis",
  "Chinese River Dolphin":"Lipotes vexillifer",
  "Lee County cave isopod":"Lirceus usdagalun",
  "Marine otter":"Lontra (=Lutra) felina",
  "Long-tailed otter":"Lontra (=Lutra) longicaudis (incl. platensis)",
  "Southern river otter":"Lontra (=Lutra) provocax",
  "Chinese monal pheasant":"Lophophorus lhuysii",
  "Sclater's monal pheasant":"Lophophorus sclateri",
  "Edward's pheasant":"Lophura edwardsi",
  "Imperial pheasant":"Lophura imperialis",
  "Swinhoe's pheasant":"Lophura swinhoii",
  "Palila (honeycreeper)":"Loxioides bailleui",
  "African elephant":"Loxodonta africana",
  "Akekee":"Loxops caeruleirostris",
  "Hawaii akepa":"Loxops coccineus",
  "Maui akepa":"Loxops ochraceus",
  "Lotis blue butterfly":"Lycaeides argyrognomon lotis",
  "Karner blue butterfly":"Lycaeides melissa samuelis",
  "African wild dog":"Lycaon pictus",
  "Canada Lynx":"Lynx canadensis",
  "Mexican bobcat":"Lynx (=Felis) rufus escuinapae",
  "Stump-tailed macaque":"Macaca arctoides",
  "Formosan rock macaque":"Macaca cyclopis",
  "Japanese macaque":"Macaca fuscata",
  "Lion-tailed macaque":"Macaca silenus",
  "Toque macaque":"Macaca sinica",
  "Maleo megapode":"Macrocephalon maleo",
  "Tasmanian forester kangaroo":"Macropus giganteus tasmaniensis",
  "Parma wallaby":"Macropus parma",
  "Rabbit bandicoot":"Macrotis lagotis",
  "Lesser rabbit bandicoot":"Macrotis leucura",
  "Ulugura bush-shrike":"Malaconotus alius",
  "Ibadan malimbe":"Malimbus ibadanensis",
  "Drill":"Mandrillus (=Papio) leucophaeus",
  "Mandrill":"Mandrillus (=Papio) sphinx",
  "Blackburn's sphinx moth":"Manduca blackburni",
  "Temnick''s ground Pangolin":"Manis temminckii",
  "Louisiana pearlshell":"Margaritifera hembeli",
  "Alabama pearlshell":"Margaritifera marrianae",
  "Vancouver Island marmot":"Marmota vancouverensis",
  "Formosan yellow-throated marten":"Martes flavigula chrysospila",
  "Alameda whipsnake (=striped racer)":"Masticophis lateralis euryxanthus",
  "Spikedace":"Meda fulgida",
  "Alabama moccasinshell":"Medionidus acutissimus",
  "Coosa moccasinshell":"Medionidus parvulus",
  "Gulf moccasinshell":"Medionidus penicillatus",
  "Ochlockonee moccasinshell":"Medionidus simpsonianus",
  "Suwannee moccasinshell":"Medionidus walkeri",
  "yellow-eyed penguin":"Megadyptes antipodes",
  "Crimson Hawaiian damselfly":"Megalagrion leptodemas",
  "Flying earwig Hawaiian damselfly":"Megalagrion nesiotes",
  "Blackline Hawaiian damselfly":"Megalagrion nigrohamatum nigrolineatum",
  "Oceanic Hawaiian damselfly":"Megalagrion oceanicum",
  "Pacific Hawaiian damselfly":"Megalagrion pacificum",
  "Orangeblack Hawaiian damselfly":"Megalagrion xanthomelas",
  "Nicklin's pearlymussel":"Megalonaias nicklineana",
  "Micronesian megapode":"Megapodius laperouse",
  "Humpback whale":"Megaptera novaeangliae",
  "Po`ouli (honeycreeper)":"Melamprosops phaeosoma",
  "Three-keeled Asian turtle":"Melanochelys tricarinata",
  "Black caiman":"Melanosuchus niger",
  "Waccamaw silverside":"Menidia extensa",
  "Brazillian merganser":"Mergus octosetaceus",
  "noonday snail":"Mesodon clarki nantahala",
  "Spruce-fir moss spider":"Microhexura montivaga",
  "Amargosa vole":"Microtus californicus scirpensis",
  "Florida salt marsh vole":"Microtus pennsylvanicus dukecampbelli",
  "Giant scops owl":"Mimizuku (=Otus) gurneyi",
  "Socorro mockingbird":"Mimus graysoni",
  "Razor-billed curassow":"Mitu mitu mitu",
  "Moapa dace":"Moapa coriacea",
  "Kauai `o`o (honeyeater)":"Moho braccatus",
  "Mediterranean monk seal":"Monachus monachus",
  "Hawaiian monk seal":"Monachus schauinslandi",
  "Burmese peacock turtle":"Morenia ocellata",
  "Musk deer":"Moschus spp. (all species)",
  "Fea's muntjac":"Muntiacus feae",
  "Black-footed ferret":"Mustela nigripes",
  "Molokai thrush":"Myadestes lanaiensis rutha",
  "Large Kauai (=kamao) Thrush":"Myadestes myadestinus",
  "Small Kauai (=puaiohi) Thrush":"Myadestes palmeri",
  "Rough Cactus Coral":"Mycetophyllia ferox",
  "Wood stork":"Mycteria americana",
  "Gray bat":"Myotis grisescens",
  "Northern Long-Eared Bat":"Myotis septentrionalis",
  "Indiana bat":"Myotis sodalis",
  "Numbat":"Myrmecobius fasciatus",
  "Serow":"Naemorhedus (=Capricornis) sumatraensis",
  "Goral":"Naemorhedus goral",
  "Pagi Island langur":"Nasalis concolor",
  "Proboscis monkey":"Nasalis larvatus",
  "Marungu sunbird":"Nectarinia prigoginei",
  "African viviparous toads":"Nectophrynoides spp.",
  "Black warrior (=Sipsey Fork) Waterdog":"Necturus alabamensis",
  "Cherry-throated tanager":"Nemosia rourei",
  "Clouded leopard":"Neofelis nebulosa",
  "Government Canyon Bat Cave Spider":"Neoleptoneta microps",
  "Tooth Cave Spider":"Neoleptoneta myopica",
  "Southeastern rufous-vented ground cuckoo":"Neomorphus geoffroyi dulcis",
  "Saint Francis' satyr butterfly":"Neonympha mitchellii francisci",
  "Mitchell's satyr Butterfly":"Neonympha mitchellii mitchellii",
  "Orange-bellied parakeet":"Neophema chrysogaster",
  "Sand skink":"Neoseps reynoldsi",
  "Key Largo woodrat":"Neotoma floridana smalli",
  "Riparian woodrat (=San Joaquin Valley)":"Neotoma fuscipes riparia",
  "Zanzibar suni":"Neotragus moschatus moschatus",
  "Atlantic salt marsh snake":"Nerodia clarkii taeniata",
  "Copperbelly water snake":"Nerodia erythrogaster neglecta",
  "Aldabra Warbler (old world warbler)":"Nesillas aldabranus",
  "Newcomb's Tree snail":"Newcombia cumingi",
  "American burying beetle":"Nicrophorus americanus",
  "Japanese crested ibis":"Nipponia nippon",
  "Australian native mouse":"Zyzomys pedunculatus",
  "Palezone shiner":"Notropis albizonatus",
  "Smalleye Shiner":"Notropis buccula",
  "Cahaba shiner":"Notropis cahabae",
  "Arkansas River shiner":"Notropis girardi",
  "Cape Fear shiner":"Notropis mekistocholas",
  "Sharpnose Shiner":"Notropis oxyrhynchus",
  "Pecos bluntnose shiner":"Notropis simus pecosensis",
  "Topeka shiner":"Notropis topeka (=tristis)",
  "Smoky madtom":"Noturus baileyi",
  "Chucky Madtom":"Noturus crypticus",
  "Yellowfin madtom":"Noturus flavipinnis",
  "Neosho madtom":"Noturus placidus",
  "Pygmy madtom":"Noturus stanauli",
  "Scioto madtom":"Noturus trautmani",
  "Eskimo curlew":"Numenius borealis",
  "Slender-billed curlew":"Numenius tenuirostris",
  "Lesser slow loris":"Nycticebus pygmaeus",
  "Poweshiek skipperling":"Oarisma poweshiek",
  "Ring pink (mussel)":"Obovaria retusa",
  "Band-rumped storm-petrel":"Oceanodroma castro",
  "Cedros Island mule Deer":"Odocoileus hemionus cerrosensis",
  "Key deer":"Odocoileus virginianus clavium",
  "Columbian white-tailed deer":"Odocoileus virginianus leucurus",
  "Gorgeted wood-quail":"Odontophorus strophium",
  "Little Kern golden trout":"Oncorhynchus aguabonita whitei",
  "Apache trout":"Oncorhynchus apache",
  "Lahontan cutthroat trout":"Oncorhynchus clarkii henshawi",
  "Paiute cutthroat trout":"Oncorhynchus clarkii seleniris",
  "Greenback Cutthroat trout":"Oncorhynchus clarkii stomias",
  "Gila trout":"Oncorhynchus gilae",
  "Chum salmon":"Oncorhynchus keta",
  "Coho salmon":"Oncorhynchus (=Salmo) kisutch",
  "Steelhead":"Oncorhynchus (=Salmo) mykiss",
  "Sockeye salmon":"Oncorhynchus (=Salmo) nerka",
  "Chinook salmon":"Oncorhynchus (=Salmo) tshawytscha",
  "Brindled nail-tailed wallaby":"Onychogalea fraenata",
  "Crescent nail-tailed wallaby":"Onychogalea lunata",
  "Lobed Star Coral":"Orbicella annularis",
  "Mountainous Star Coral":"Orbicella faveolata",
  "Boulder star coral":"Orbicella franksi",
  "Killer whale":"Orcinus orca",
  "Nashville crayfish":"Orconectes shoupi",
  "Akikiki":"Oreomystis bairdi",
  "Hawaii creeper":"Oreomystis mana",
  "Horned guan":"Oreophasis derbianus",
  "Stock Island tree snail":"Orthalicus reses (not incl. nesodryas)",
  "Scimitar-horned oryx":"Oryx dammah",
  "Arabian oryx":"Oryx leucoryx",
  "Silver rice rat":"Oryzomys palustris natator",
  "Congo dwarf crocodile":"Osteolaemus tetraspis osborni",
  "African dwarf crocodile":"Osteolaemus tetraspis tetraspis",
  "Morden's owlet":"Otus ireneae",
  "Seychelles scops owl":"Otus magicus (=insularis) insularis",
  "Anjouan scops owl":"Otus rutilus capnodes",
  "Argali":"Ovis ammon",
  "Peninsular bighorn sheep":"Ovis canadensis nelsoni",
  "Sierra Nevada bighorn sheep":"Ovis canadensis sierrae",
  "Urial":"Ovis musimon ophion",
  "Shapo":"Ovis vignei vignei",
  "Kanab ambersnail":"Oxyloma haydeni kanabensis",
  "Pampas deer":"Ozotoceros bezoarticus",
  "Shasta crayfish":"Pacifastacus fortis",
  "Squirrel Chimney Cave shrimp":"Palaemonetes cummingi",
  "Alabama cave shrimp":"Palaemonias alabamae",
  "Kentucky cave shrimp":"Palaemonias ganteri",
  "crested honeycreeper (Akohekohe)":"Palmeria dolei",
  "Thailand giant catfish":"Pangasianodon gigas",
  "Catfish":"Pangasius sanitwongsei",
  "Pygmy chimpanzee":"Pan paniscus",
  "Tibetan antelope":"Panthalops hodgsonii",
  "Lion":"Panthera leo ssp. melanochaita",
  "Jaguar":"Panthera onca",
  "Leopard":"Panthera pardus",
  "Tiger":"Panthera tigris",
  "Chimpanzee":"Pan troglodytes",
  "Abbott's booby":"Papasula (=Sula) abbotti",
  "Luzon peacock swallowtail butterfly":"Papilio chikae",
  "Homerus swallowtail butterfly":"Papilio homerus",
  "Corsican swallowtail butterfly":"Papilio hospiton",
  "Manus Island tree snail":"Papustyla pulcherrima",
  "Brown Hyena":"Parahyaena (=Hyaena) brunnea",
  "Marbled cat":"Pardofelis (=Felis) marmorata",
  "Molokai creeper":"Paroreomyza flammea",
  "Oahu creeper":"Paroreomyza maculata",
  "Humped tree snail":"Partula gibba",
  "Langford's tree snail":"Partula langfordi",
  "Guam tree snail":"Partula radiolata",
  "Lanai tree snail":"Partulina variabilis",
  "Plains wanderer (=collared-hemipode)":"Pedionomus torquatus",
  "Littlewing pearlymussel":"Pegias fabula",
  "Fisher":"Pekania pennanti",
  "Puerto Rican crested toad":"Peltophryne lemur",
  "White-winged guan":"Penelope albipennis",
  "Cauca guan":"Penelope perspicax",
  "Ryukyu rabbit":"Pentalagus furnessi",
  "Barred bandicoot":"Perameles bougainville",
  "Desert bandicoot":"Perameles eremiana",
  "Amber darter":"Percina antesella",
  "Goldline darter":"Percina aurolineata",
  "Pearl darter":"Percina aurora",
  "Conasauga logperch":"Percina jenkinsi",
  "Leopard darter":"Percina pantherina",
  "Roanoke logperch":"Percina rex",
  "Snail darter":"Percina tanasi",
  "Pacific pocket mouse":"Perognathus longimembris pacificus",
  "Key Largo cotton mouse":"Peromyscus gossypinus allapaticola",
  "Choctawhatchee beach mouse":"Peromyscus polionotus allophrys",
  "Alabama beach mouse":"Peromyscus polionotus ammobates",
  "Southeastern beach mouse":"Peromyscus polionotus niveiventris",
  "St. Andrew beach mouse":"Peromyscus polionotus peninsularis",
  "Anastasia Island beach mouse":"Peromyscus polionotus phasma",
  "Perdido Key beach mouse":"Peromyscus polionotus trissyllepsis",
  "Yellow-footed rock wallaby":"Petrogale xanthopus",
  "Scarlet-breasted robin (flycatcher)":"Petroica multicolor multicolor",
  "Chatham Island robin":"Petroica traversi",
  "Ground parrot":"Pezoporus wallicus",
  "Red-faced malkoha (cuckoo)":"Phaenicophaeus pyrrhocephalus",
  "Red Hills salamander":"Phaeognathus hubrichti",
  "Margaretta's hermit":"Phaethornis malaris margarettae",
  "Resplendent quetzel":"Pharomachrus mocinno ssp. mocinno",
  "Koala":"Phascolarctos cinereus",
  "Day gecko":"Phelsuma edwardnewtoni",
  "Round Island day gecko":"Phelsuma guentheri",
  "Saimaa seal":"Phoca hispida saimensis",
  "Spotted Seal":"Phoca largha",
  "Ringed Seal":"Phoca (=Pusa) hispida ochotensis",
  "Ringed seal":"Phoca (=Pusa) hispida ladogensis",
  "Cochito":"Phocoena sinus",
  "Short-tailed albatross":"Phoebastria (=Diomedea) albatrus",
  "Andean flamingo":"Phoenicoparrus andinus",
  "Blackside dace":"Phoxinus cumberlandensis",
  "Brazilian sideneck turtle":"Phrynops hogei",
  "Snake River physa snail":"Physa natricina",
  "Sperm whale":"Physeter catodon (=macrocephalus)",
  "Peruvian plantcutter":"Phytotoma raimondii",
  "White-necked rockfowl":"Picathartes gymnocephalus",
  "Grey-necked rockfowl":"Picathartes oreas",
  "Red-cockaded woodpecker":"Picoides borealis",
  "Red-capped parrot":"Pionopsitta pileata",
  "Black-fronted piping-guan":"Pipile jacutinga",
  "Trinidad white-headed curassow":"Pipile pipile pipile",
  "Inyo California towhee":"Pipilo crissalis eremophilus",
  "Philippine eagle":"Pithecophaga jefferyi",
  "Gurney's pitta":"Pitta gurneyi",
  "Koch's pitta":"Pitta kochi",
  "Black pine snake":"Pituophis melanoleucus lodingi",
  "Louisiana pinesnake":"Pituophis ruthveni",
  "Woundfin":"Plagopterus argentissimus",
  "Little planigale":"Planigale ingrami subtilissima",
  "Southern planigale":"Planigale tenuirostris",
  "South Asian River dolphin":"Platanista gangetica minor",
  "Banded wattle-eye":"Platysteira laticincta",
  "White wartyback (pearlymussel)":"Plethobasus cicatricosus",
  "Orangefoot pimpleback (pearlymussel)":"Plethobasus cooperianus",
  "Sheepnose Mussel":"Plethobasus cyphyus",
  "Jemez Mountains salamander":"Plethodon neomexicanus",
  "Cheat Mountain salamander":"Plethodon nettingi",
  "Shenandoah salamander":"Plethodon shenandoah",
  "Clubshell":"Pleurobema clava",
  "James spinymussel":"Pleurobema collina",
  "Black clubshell":"Pleurobema curtum",
  "Southern clubshell":"Pleurobema decisum",
  "Dark pigtoe":"Pleurobema furvum",
  "Southern pigtoe":"Pleurobema georgianum",
  "Cumberland pigtoe":"Pleurobema gibberum",
  "Georgia pigtoe":"Pleurobema hanleyianum",
  "Flat pigtoe":"Pleurobema marshalli",
  "Ovate clubshell":"Pleurobema perovatum",
  "Rough pigtoe":"Pleurobema plenum",
  "Oval pigtoe":"Pleurobema pyriforme",
  "Fuzzy pigtoe":"Pleurobema strodeanum",
  "Heavy pigtoe":"Pleurobema taitianum",
  "Rough hornsnail":"Pleurocera foremani",
  "Slabside Pearlymussel":"Pleuronaia dolabelloides",
  "Clarke's weaver":"Ploceus golandi",
  "Ibiza wall lizard":"Podarcis pityusensis",
  "Junin grebe":"Podiceps taczanowskii",
  "Atitlan grebe":"Podilymbus gigas",
  "Tartaruga":"Podocnemis expansa",
  "Tracaja":"Podocnemis unifilis",
  "Gila topminnow (incl. Yaqui)":"Poeciliopsis occidentalis",
  "Sri Lankan Ornamental Tarantula":"Poecilotheria fasciata",
  "Fringed Ornamental Tarantula":"Poecilotheria ornata",
  "Kandy Parachute Spider":"Poecilotheria smithi",
  "Ivory Ornamental Tarantula":"Poecilotheria subfusca",
  "Pederseni''s Tiger Spider":"Poecilotheria vittata",
  "Coastal California gnatcatcher":"Polioptila californica californica",
  "Audubon's crested caracara":"Polyborus plancus audubonii",
  "Virginia fringed mountain snail":"Polygyriscus virginianus",
  "Mount Hermon June beetle":"Polyphylla barbata",
  "Palawan peacock pheasant":"Polyplectron emphanum",
  "Steller's Eider":"Polysticta stelleri",
  "Tahiti flycatcher":"Pomarea nigra",
  "Orangutan":"Pongo pygmaeus",
  "Texas Hornshell":"Popenaias popeii",
  "Fat pocketbook":"Potamilus capax",
  "Inflated heelsplitter":"Potamilus inflatus",
  "Long-tailed langur":"Presbytis potenziani",
  "Purple-faced langur":"Presbytis senex",
  "Mexican blindcat (catfish)":"Prietella phreatophila",
  "Giant armadillo":"Priodontes maximus",
  "Leopard cat":"Prionailurus (=Felis) bengalensis bengalensis",
  "Iriomote cat":"Prionailurus (=Felis) bengalensis iriomotensis",
  "Flat-headed cat":"Prionailurus (=Felis) planiceps",
  "Spotted linsang":"Prionodon pardicolor",
  "Dwarf sawfish":"Pristis clavata",
  "Smalltooth sawfish":"Pristis pectinata",
  "Largetooth Sawfish":"Pristis pristis",
  "Green sawfish":"Pristis zijsron",
  "Ikan temoleh (minnow)":"Probarbus jullieni",
  "Anchialine pool Shrimp":"Procaris hawaiana",
  "Zanzibar red colobus monkey":"Procolobus (=Colobus) pennantii (=kirki) kirki",
  "Preuss' red colobus monkey":"Procolobus (=Colobus) preussi (=badius p.)",
  "Tana River red colobus monkey":"Procolobus (=Colobus) rufomitratus (=badius r.)",
  "Sifakas":"Propithecus spp.",
  "Geometric turtle":"Psammobates geometricus",
  "Golden-shouldered parakeet":"Psephotus chrysopterygius",
  "Paradise parakeet":"Psephotus pulcherrimus",
  "Short-necked or western swamp turtle":"Pseudemydura umbrina",
  "Alabama red-bellied turtle":"Pseudemys alabamensis",
  "Plymouth Redbelly Turtle":"Pseudemys rubriventris bangsi",
  "Giant ibis":"Pseudibis gigantea",
  "Fiji petrel":"Pseudobulweria macgillivrayi",
  "Carson wandering skipper":"Pseudocopaeodes eunus obscurus",
  "Field's mouse":"Pseudomys fieldi",
  "Smoky mouse":"Pseudomys fumeus",
  "Gould's mouse":"Pseudomys gouldii",
  "New Holland mouse":"Pseudomys novaehollandiae",
  "Western mouse":"Pseudomys occidentalis",
  "Shark Bay mouse":"Pseudomys praeconis",
  "Shortridge's mouse":"Pseudomys shortridgei",
  "Maui parrotbill (Kiwikiu)":"Pseudonestor xanthophrys",
  "false killer whale":"Pseudorca crassidens",
  "Diamond Tryonia":"Pseudotryonia adamantina",
  "Mauritius parakeet":"Psittacula echo",
  "`O`u (honeycreeper)":"Psittirostra psittacea",
  "Western whipbird":"Psophodes nigrogularis",
  "Mascarene black petrel":"Pterodroma aterrima",
  "Chatham Island petrel":"Pterodroma axillaris",
  "Bermuda petrel":"Pterodroma cahow",
  "Freira":"Pterodroma madeira",
  "Magenta petrel":"Pterodroma magentae",
  "Galapagos petrel":"Pterodroma phaeopygia",
  "Hawaiian petrel":"Pterodroma sandwichensis",
  "Giant otter":"Pteronura brasiliensis",
  "Mariana fruit Bat (=Mariana flying fox)":"Pteropus mariannus mariannus",
  "Rodrigues fruit bat (=flying fox)":"Pteropus rodricensis",
  "Little Mariana fruit Bat":"Pteropus tokudae",
  "Triangular Kidneyshell":"Ptychobranchus greenii",
  "Southern kidneyshell":"Ptychobranchus jonesi",
  "Fluted kidneyshell":"Ptychobranchus subtentus",
  "Colorado pikeminnow (=squawfish)":"Ptychocheilus lucius",
  "Pudu":"Pudu pudu",
  "Newell's Townsend's shearwater":"Puffinus auricularis newelli",
  "Heinroth's shearwater":"Puffinus heinrothi",
  "Puma (=mountain lion)":"Puma (=Felis) concolor (all subsp. except coryi)",
  "Florida panther":"Puma (=Felis) concolor coryi",
  "Costa Rican puma":"Puma (=Felis) concolor costaricensis",
  "Douc langur":"Pygathrix nemaeus",
  "San Bernardino springsnail":"Pyrgulopsis bernardina",
  "Bruneau Hot springsnail":"Pyrgulopsis bruneauensis",
  "Chupadera springsnail":"Pyrgulopsis chupaderae",
  "Armored snail":"Pyrgulopsis (=Marstonia) pachyta",
  "Socorro springsnail":"Pyrgulopsis neomexicana",
  "Royal marstonia (snail)":"Pyrgulopsis ogmorhaphe",
  "Roswell springsnail":"Pyrgulopsis roswellensis",
  "Phantom Springsnail":"Pyrgulopsis texana",
  "Three Forks Springsnail":"Pyrgulopsis trivialis",
  "Laguna Mountains skipper":"Pyrgus ruralis lagunae",
  "Fringe-backed fire-eye":"Pyriglena atra",
  "Sao Miguel bullfinch (finch)":"Pyrrhula pyrrhula murina",
  "Blue-throated (=ochre-marked) parakeet":"Pyrrhura cruentata",
  "Indian python":"Python molurus molurus",
  "Rabbitsfoot":"Quadrula cylindrica cylindrica",
  "Rough rabbitsfoot":"Quadrula cylindrica strigillata",
  "Winged Mapleleaf":"Quadrula fragosa",
  "Cumberland monkeyface (pearlymussel)":"Quadrula intermedia",
  "Appalachian monkeyface (pearlymussel)":"Quadrula sparsa",
  "Stirrupshell":"Quadrula stapes",
  "Slender-billed Grackle":"Quisicalus palustris",
  "Light-footed clapper rail":"Rallus longirostris levipes",
  "California clapper rail":"Rallus longirostris obsoletus",
  "Yuma Ridgways (clapper) rail":"Rallus obsoletus [=longirostris] yumanensis",
  "Guam rail":"Rallus owstoni",
  "Aukland Island rail":"Rallus pectoralis muelleri",
  "White-breasted thrasher":"Ramphocinclus brachyurus",
  "Hook-billed hermit (hummingbird)":"Ramphodon (=Glaucis) dohrnii",
  "Chiricahua leopard frog":"Rana chiricahuensis",
  "California red-legged frog":"Rana draytonii",
  "Mountain yellow-legged frog":"Rana muscosa",
  "Oregon spotted frog":"Rana pretiosa",
  "dusky gopher frog":"Rana sevosa",
  "Sierra Nevada Yellow-legged Frog":"Rana sierrae",
  "Woodland Caribou":"Rangifer tarandus caribou",
  "Salt marsh harvest mouse":"Reithrodontomys raviventris",
  "[no common name] Beetle":"Rhadine infernalis",
  "Tooth Cave ground beetle":"Rhadine persephone",
  "Delhi Sands flower-loving fly":"Rhaphiomidas terminatus abdominalis",
  "Lesser rhea (incl. Darwin's)":"Rhea (=Pterocnemia) pennata",
  "Independence Valley speckled dace":"Rhinichthys osculus lethoporus",
  "Ash Meadows speckled dace":"Rhinichthys osculus nevadensis",
  "Clover Valley speckled dace":"Rhinichthys osculus oligoporus",
  "Kendall Warm Springs dace":"Rhinichthys osculus thermalis",
  "Javan rhinoceros":"Rhinoceros sondaicus",
  "Great Indian rhinoceros":"Rhinoceros unicornis",
  "Tonkin snub-nosed monkey":"Rhinopithecus avunculus",
  "Yunnan snub-nosed monkey":"Rhinopithecus bieti",
  "Guizhou snub-nosed monkey":"Rhinopithecus brelichi",
  "Sichuan snub-nosed monkey":"Rhinopithecus roxellana",
  "Jerdon's courser":"Rhinoptilus bitorquatus",
  "Pink-headed duck":"Rhodonessa caryophyllacea",
  "Thick-billed parrot":"Rhynchopsitta pachyrhyncha",
  "Kagu":"Rhynochetos jubatus",
  "Volcano rabbit":"Romerolagus diazi",
  "Everglade snail kite":"Rostrhamus sociabilis plumbeus",
  "Ponape greater white-eye":"Rukia longirostra",
  "Apennine chamois":"Rupicapra rupicapra ornata",
  "Pied tamarin":"Saguinus bicolor",
  "White-footed tamarin":"Saguinus leucopus",
  "Cotton-top tamarin":"Saguinus oedipus",
  "Mongolian saiga (antelope)":"Saiga tatarica mongolica",
  "Red-backed squirrel monkey":"Saimiri oerstedii",
  "Ala balik (trout)":"Salmo platycephalus",
  "Atlantic salmon":"Salmo salar",
  "Bull Trout":"Salvelinus confluentus",
  "Fragile tree snail":"Samoana fragilis",
  "San Esteban Island chuckwalla":"Sauromalus varius",
  "Pallid sturgeon":"Scaphirhynchus albus",
  "Shovelnose Sturgeon":"Scaphirhynchus platorynchus",
  "Alabama sturgeon":"Scaphirhynchus suttkusi",
  "Asian bonytongue":"Scleropages formosus",
  "Bocaccio":"Sebastes paucispinis",
  "canary rockfish":"Sebastes pinniger",
  "yelloweye rockfish":"Sebastes ruberrimus",
  "Gray (=entellus) langur":"Semnopithecus (=Presbytis) entellus",
  "Quokka":"Setonix brachyurus",
  "Elfin-woods warbler":"Setophaga angelae",
  "Eastern Massasauga (=rattlesnake)":"Sistrurus catenatus",
  "Algerian nuthatch":"Sitta ledanti",
  "Long-tailed marsupial-mouse":"Sminthopsis longicaudata",
  "Large desert marsupial-mouse":"Sminthopsis psammophila",
  "Cuban solenodon":"Solenodon cubanus",
  "Haitian solenodon":"Solenodon paradoxus",
  "Spectacled eider":"Somateria fischeri",
  "Hine's emerald dragonfly":"Somatochlora hineana",
  "Buena Vista Lake ornate Shrew":"Sorex ornatus relictus",
  "Kauai cave amphipod":"Spelaeorchestia koloana",
  "Alabama cavefish":"Speoplatyrhinus poulsoni",
  "Callippe silverspot butterfly":"Speyeria callippe callippe",
  "Behren's silverspot butterfly":"Speyeria zerene behrensii",
  "Oregon silverspot butterfly":"Speyeria zerene hippolyta",
  "Myrtle's silverspot butterfly":"Speyeria zerene myrtleae",
  "African penguin":"Spheniscus demersus",
  "Humboldt penguin":"Spheniscus humboldti",
  "Galapagos penguin":"Spheniscus mendiculus",
  "Brother's Island tuatara":"Sphenodon guntheri",
  "Tuatara":"Sphenodon punctatus",
  "Scalloped Hammerhead Shark":"Sphyrna lewini",
  "Angel shark":"Squatina squatina",
  "Least tern":"Sterna antillarum",
  "California least tern":"Sterna antillarum browni",
  "Roseate tern":"Sterna dougallii dougallii",
  "Flattened musk turtle":"Sternotherus depressus",
  "Riverside fairy shrimp":"Streptocephalus woottoni",
  "Seychelles turtle dove":"Streptopelia picturata rostrata",
  "Kakapo":"Strigops habroptilus",
  "Northern spotted owl":"Strix occidentalis caurina",
  "Mexican spotted owl":"Strix occidentalis lucida",
  "West African ostrich":"Struthio camelus spatzi",
  "Arabian ostrich":"Struthio camelus syriacus",
  "Bartram's hairstreak Butterfly":"Strymon acis bartrami",
  "Hay's Spring amphipod":"Stygobromus hayi",
  "Peck's cave amphipod":"Stygobromus (=Stygonectes) pecki",
  "Comal Springs dryopid beetle":"Stygoparnus comalensis",
  "Chittenango ovate amber snail":"Succinea chittenangoensis",
  "Pygmy hog":"Sus salvanius",
  "Riparian brush rabbit":"Sylvilagus bachmani riparius",
  "Lower Keys marsh rabbit":"Sylvilagus palustris hefneri",
  "Siamang":"Symphalangus syndactylus",
  "California freshwater shrimp":"Syncaris pacifica",
  "Elliot's pheasant":"Syrmaticus ellioti",
  "Bar-tailed pheasant":"Syrmaticus humaie",
  "Mikado pheasant":"Syrmaticus mikado",
  "Alaotra Grebe":"Tachybaptus rufolavatus",
  "Mount Graham red squirrel":"Tamiasciurus hudsonicus grahamensis",
  "Miyako tango (=Toyko bitterling)":"Tanakia tanago",
  "Central American tapir":"Tapirus bairdii",
  "Asian tapir":"Tapirus indicus",
  "Mountain tapir":"Tapirus pinchaque",
  "South American (=Brazilian) tapir":"Tapirus terrestris",
  "Philippine tarsier":"Tarsius syrichta",
  "Tooth Cave pseudoscorpion":"Tartarocreagris texana",
  "Bannerman's turaco":"Tauraco bannermani",
  "Western giant eland":"Taurotragus derbianus derbianus",
  "Bliss Rapids snail":"Taylorconcha serpenticola",
  "Laysan finch (honeycreeper)":"Telespyza cantans",
  "Nihoa finch (honeycreeper)":"Telespyza ultima",
  "Seychelles paradise flycatcher":"Terpsiphone corvina",
  "Aquatic box turtle":"Terrapene coahuila",
  "Cantabrian capercaillie":"Tetrao urogallus cantabricus",
  "Kretschmarr Cave mold beetle":"Texamaurops reddelli",
  "Cokendolpher Cave Harvestman":"Texella cokendolpheri",
  "Bee Creek Cave harvestman":"Texella reddelli",
  "Bone Cave harvestman":"Texella reyesi",
  "Eulachon":"Thaleichthys pacificus",
  "Northern Mexican gartersnake":"Thamnophis eques megalops",
  "Giant garter snake":"Thamnophis gigas",
  "Narrow-headed gartersnake":"Thamnophis rufipunctatus",
  "San Francisco garter snake":"Thamnophis sirtalis tetrataenia",
  "Socorro isopod":"Thermosphaeroma thermophilus",
  "Gelada baboon":"Theropithecus gelada",
  "New Zealand shore plover":"Thinornis novaeseelandiae",
  "Roy Prairie pocket gopher":"Thomomys mazama glacialis",
  "Olympia pocket gopher":"Thomomys mazama pugetensis",
  "Tenino pocket gopher":"Thomomys mazama tumuli",
  "Yelm pocket gopher":"Thomomys mazama yelmensis",
  "Tasmanian tiger":"Thylacinus cynocephalus",
  "Loach minnow":"Tiaroga cobitis",
  "Solitary tinamou":"Tinamus solitarius",
  "Tomistoma":"Tomistoma schlegelii",
  "Pale lilliput (pearlymussel)":"Toxolasma cylindrellus",
  "South American red-lined turtle":"Trachemys scripta callirostris",
  "Inagua Island turtle":"Trachemys stejnegeri malonei",
  "Cat Island turtle":"Trachemys terrapen",
  "Francois' langur":"Trachypithecus (=Presbytis) francoisi",
  "Golden langur":"Trachypithecus (=Presbytis) geei",
  "Capped Langur":"Trachypithecus (=Presbytis) pileatus",
  "Blyth's tragopan pheasant":"Tragopan blythii",
  "Cabot's tragopan pheasant":"Tragopan caboti",
  "Western tragopan pheasant":"Tragopan melanocephalus",
  "Amazonian manatee":"Trichechus inunguis",
  "West Indian Manatee":"Trichechus manatus",
  "West African manatee":"Trichechus senegalensis",
  "Long-legged warbler":"Trichocichla rufa",
  "Zayante band-winged grasshopper":"Trimerotropis infantilis",
  "Nordmann's greenshank":"Tringa guttifer",
  "Flat-spired three-toothed Snail":"Triodopsis platysayoides",
  "Cuatro Cienegas softshell turtle":"Trionyx ater",
  "Indian softshell turtle":"Trionyx gangeticus",
  "Peacock softshell turtle":"Trionyx hurum",
  "Black softshell turtle":"Trionyx nigricans",
  "Guadeloupe house wren":"Troglodytes aedon guadeloupensis",
  "St. Lucia house wren":"Troglodytes aedon mesoleucus",
  "Queen Alexandra's birdwing butterfly":"Troides alexandrae",
  "Alamosa springsnail":"Tryonia alamosae",
  "Phantom Tryonia":"Tryonia cheatumi",
  "Gonzales tryonia":"Tryonia circumstriata (=stocktonensis)",
  "Tulotoma snail":"Tulotoma magnifica",
  "Taita thrush":"Turdus olivaceus helleri",
  "New Zealand thrush (wattlebird)":"Turnagra capensis",
  "Attwater's greater prairie-chicken":"Tympanuchus cupido attwateri",
  "Texas blind salamander":"Typhlomolge rathbuni",
  "Madagascar red owl":"Tyto soumagnei",
  "Coachella Valley fringe-toed lizard":"Uma inornata",
  "Snow leopard":"Uncia (=Panthera) uncia",
  "Long-tailed ground roller":"Uratelornis chimaera",
  "Northern Idaho Ground Squirrel":"Urocitellus brunneus",
  "Santa Catalina Island Fox":"Urocyon littoralis catalinae",
  "Mexican grizzly bear":"Ursus arctos",
  "Brown bear":"Ursus arctos pruinosus",
  "Grizzly bear":"Ursus arctos horribilis",
  "Polar bear":"Ursus maritimus",
  "Baluchistan bear":"Ursus thibetanus gedrosianus",
  "Mariana wandering butterfly":"Vagrans egistina",
  "Indian (=Bengal) monitor":"Varanus bengalensis",
  "Yellow monitor":"Varanus flavescens",
  "Desert monitor":"Varanus griseus",
  "Komodo Island monitor":"Varanus komodoensis",
  "Bachman's warbler (=wood)":"Vermivora bachmanii",
  "Anchialine pool shrimp":"Vetericaris chaceorum",
  "Vicuna":"Vicugna vicugna",
  "Choctaw bean":"Villosa choctawensis",
  "Rayed Bean":"Villosa fabalis",
  "Purple bean":"Villosa perpurpurea",
  "Cumberland bean (pearlymussel)":"Villosa trabalis",
  "Lar Valley viper":"Vipera latifii",
  "Least Bell's vireo":"Vireo bellii pusillus",
  "Malabar large-spotted civet":"Viverra civettina (=megaspila c.)",
  "San Joaquin kit fox":"Vulpes macrotis mutica",
  "Northern swift fox":"Vulpes velox hebes",
  "Andean condor":"Vultur gryphus",
  "Scaly-tailed possum":"Wyulda squamicaudata",
  "New Zealand bushwren":"Xenicus longipes",
  "Van Dam's vanga":"Xenopirostris damii",
  "Pollen's vanga":"Xenopirostris polleni",
  "False water rat":"Xeromys myoides",
  "White-winged cotinga":"Xipholena atropurpurea",
  "Razorback sucker":"Xyrauchen texanus",
  "Western glacier stonefly":"Zapada glacier",
  "New Mexico meadow jumping mouse":"Zapus hudsonius luteus",
  "Preble's meadow jumping mouse":"Zapus hudsonius preblei",
  "Norfolk Island white-eye":"Zosterops albogularis",
  "Bridled white-eye":"Zosterops conspicillatus conspicillatus",
  "Seychelles white-eye":"Zosterops modesta",
  "Rota bridled White-eye":"Zosterops rotensis"}