import externalLinks from "../../utils/paths/externalLinks"
import routes from "../../utils/paths/routes"

/*
Technical value for a site map?
  Search Engine Optimization --> attaches a search term to each page.
  Screen readers --> enables navigation of the entire site using keyboard.
*/

export default {
  title: "Site Map",
  lastUpdated: "July 6, 2021",
  sections:
    [
      {
        heading: { main: "Applications" },
        subSections:
          [
            {
              subHeading: "App home pages",
              links:
                [
                  {
                    label: routes.apps.collabHome.label,
                    to: { "pathname": routes.apps.collabHome.path },
                    actionType: "internal link",
                  },
                  /* {
                    label: routes.apps.earthenticateHome.label,
                    to: { "pathname": routes.apps.earthenticateHome.path },
                    actionType: "internal link",
                  }, */
                  {
                    label: routes.apps.habPatHome.label,
                    to: { "pathname": routes.apps.habPatHome.path },
                    actionType: "internal link",
                  },
                ]
            },
            {
              subHeading: "App pages",
              links:
                [
                  {
                    label: routes.apps.collabDash.label,
                    to: { "pathname": routes.apps.collabDash.path },
                    actionType: "internal link",
                  },
                  /* {
                    label: routes.apps.earthenticateApp.label,
                    to: { "pathname": routes.apps.earthenticateApp.path },
                    actionType: "internal link",
                  }, */
                  {
                    label: routes.apps.habPatApp.label,
                    to: { "pathname": routes.apps.habPatApp.path },
                    actionType: "internal link",
                  },
                ]
            },
          ],
      },
      {
        heading: { main: "Discover" },
        subSections:
          [
            {
              subHeading: "Conservationist.io",
              links:
                [
                  {
                    label: routes.landing.label,
                    to: { "pathname": routes.landing.path },
                    actionType: "internal link",
                  },
                  /* {
                    label: "About us",
                    to: { "pathname": ""},
                    actionType: "internal link",
                  }, */
                  {
                    label: "Beta Testing",
                    to: { "pathname": routes.betaTesting.path.home },
                    actionType: "internal link",
                  },
                ]
            },
            {
              subHeading: "Defenders of Wildlife",
              links:
                [
                  {
                    label: externalLinks.defenders.landing.label,
                    linkPath: externalLinks.defenders.landing.path,
                    actionType: "external link",
                  },
                  {
                    label: externalLinks.defenders.about.label,
                    linkPath: externalLinks.defenders.about.path,
                    actionType: "external link",
                  },
                  {
                    label: externalLinks.defenders.jobs.label,
                    linkPath: externalLinks.defenders.jobs.path,
                    actionType: "external link",
                  },
                  {
                    label: externalLinks.defenders.donate.label,
                    linkPath: externalLinks.defenders.donate.path,
                    actionType: "external link",
                  },
                ]
            },
            {
              subHeading: "Center for Conservation Innovation",
              links:
                [
                  {
                    label: externalLinks.cci.landing.label,
                    linkPath: externalLinks.cci.landing.path,
                    actionType: "external link",
                  },
                  {
                    label: externalLinks.cci.about.label,
                    linkPath: externalLinks.cci.about.path,
                    actionType: "external link",
                  },
                  {
                    label: externalLinks.cci.projects.label,
                    linkPath: externalLinks.cci.projects.path,
                    actionType: "external link",
                  },
                  {
                    label: externalLinks.cci.publications.label,
                    linkPath: externalLinks.cci.publications.path,
                    actionType: "external link",
                  },
                ]
            },
          ],
      },
      {
        heading: { main: "Support" },
        subSections:
          [
            {
              subHeading: "Feedback",
              links:
                [
                  {
                    label: routes.support.contact.label,
                    to: { "pathname": routes.support.contact.path, "valueSubject": "Reaching out", },
                    actionType: "internal link",
                  },
                  {
                    label: "Feedback",
                    to: { "pathname": routes.support.contact.path, "valueSubject": "Providing feedback", },
                    actionType: "internal link",
                  },
                  {
                    label: "Bug report",
                    to: { "pathname": routes.support.contact.path, "valueSubject": "Reporting a bug", },
                    actionType: "internal link",
                  },
                ]
            },
          ],
      },
    ],
}



/*
Three headings: Apps, About, Support, (Discover coming soon)

conservationist.io technical, apps
  apps pages
  apps about pages

user interaction
  beta testing

about info
*/