import React from "react";

/* Components */
import Collaborators from "./Collaborators";
import DeleteMapButton from "./DeleteMapButton";
import MapSummary from "./MapSummary";
import MapSummaryEditable from "./MapSummaryEditable";
import MapTitle from "./MapTitle";
import MapTitleEditable from "./MapTitleEditable";
import MapPrivacy from "./MapPrivacy";
import Owner from "./Owner";
import { CollapsibleV, Heading } from "../../../../../../components";

export default function AboutSection({ app, parent }) {
  return (
    <CollapsibleV parent={parent} title="About">
      <div className="g__map__config-menu__section">
        {app.state.isCreator && (
          <>
            <MapTitleEditable app={app} />
            <MapSummaryEditable app={app} />
            <MapPrivacy app={app} />
            <div className="u-mgn-top-md">
              <Owner app={app} />
              <Collaborators app={app} />
            </div>
            <div className="u-mgn-top-md">
              <Heading className="u-pad-btm-sm" ko={true} number={5}>
                Actions
              </Heading>
              <DeleteMapButton app={app} />
              {/* Fork map action button */}
            </div>
          </>
        )}
        {!app.state.isCreator && (
          <>
            <MapTitle app={app} />
            <MapSummary app={app} />
            <div className="u-mgn-top-md">
              <Owner app={app} />
              <Collaborators app={app} />
            </div>
            {/* <div className="u-mgn-top-md">
              <Heading className="u-pad-btm-sm" ko={true} number={5}>
                Actions
              </Heading>
              // Fork map action button
            </div> */}
          </>
        )}
      </div>
    </CollapsibleV>
  );
}
