export default {
  apps: {
    collab: {
      label: "Collaborative Mapper",
      path: "/apps/collaborativemapper",
    },
    collabDash: {
      label: "Collaborative Mapper",
      path: "/apps/collaborativemapper/dashboard",
    },
    collabMap: {
      label: "Collaborative Mapper",
      path: "/apps/collaborativemapper/map",
    },
    collabHome: {
      label: "Collaborative Mapper",
      path: "/apps/collaborativemapper/home",
    },
    cvm: {
      label: "Conservation Value Mapper",
      path: "/apps/conservationvaluemapper",
    },
    cvmDash: {
      label: "Conservation Value Mapper",
      path: "/apps/conservationvaluemapper/dashboard",
    },
    cvmApp: {
      label: "Conservation Value Mapper",
      path: "/apps/conservationvaluemapper/projects/:projectID",
      subtitlePath: "/apps/conservationvaluemapper/projects",
    },
    habPatApp: {
      label: "Habitat Patrol",
      path: "/apps/habitatpatrol",
    },
    habPatHome: {
      label: "Habitat Patrol",
      path: "/apps/habitatpatrol/home",
    },
  },
  auth: {
    activate: {
      label: "Activate",
      path: "/accounts/activate"
    },
    passwordReset: {
      label: "Password Reset",
      path: "/accounts/passwordreset",
    },
  },
  betaTesting: {
    label: "Beta Testing",
    path: {
      home: "/betatesting",
      learnMore: "/betatesting#learnmore",
      signUp: "/betatesting#signup"
    },
  },
  landing: {
    label: "Landing Page",
    path: "/landing",
  },
  root: {
    path: "/"
  },
  profile: {
    path: "/profile/",
    username: {
      path: "/profile/:username",
    }
  },
  support: {
    contact: {
      label: "Contact",
      path: "/support/contact",
    },
    siteMap: {
      label: "Site Map",
      path: "/support/sitemap",
    },
    creativeCommons: {
      label: "Creative Commons",
      path: "/support/creativecommonslicense",
    },
  },
  style: {
    label: "Style Guide",
    path: "/styleguide"
  },
  utility: {
    "404": {
      path: "*",
    }
  },
};