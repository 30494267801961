import React from "react";
import { Select, Text } from "..";
import { SGTemplate } from ".";

export default function SGSelect() {
  const templateContent = {
    name: "Select",
    variantName: "",
    description:
      "A custom element that acts similarly to the <select> html element.  The Select component can have a default selected option defined, names for each option, and onClick actions to execute when an option is selected.",
    implementation:
      "Instantiate as a closed component.  The default option, option names, and onClick actions are defined as props.  The component width is determined by the parent <div>.",
    propsTable: [
      [
        "hashObjects",
        "array of hashes",
        "[{ 'Choice 0': () => {console.log('0');} }, { 'Choice 1': () => {console.log('1');} }]",
        "yes",
        "Each option is represented by the a hash {`optionName`: () => {function1(); function2();}}.  The arrangement of the array determines the order of appearance of the options in the dropdown menu.",
      ],
      [
        "defaultIndex",
        "integer",
        "1",
        "no",
        "The `defaultIndex` sets the default option that is shown in the component, with `0` being the 1st option, `1` being the 2nd, etc.  Default `defaultIndex` = 0.",
      ],
      [
        "ko",
        "boolean",
        "true",
        "no",
        "Setting `ko={true}` enables 'knock-out' style, useful for placement of the element on a dark background.",
      ],
    ],
    links: [
      {
        "Zeplin: form dropdown fields":
          "https://app.zeplin.io/project/5f45822488016a3407dea78c/screen/5faf432e3dccf367501e3040",
      },
    ],
    codeExample: `import { Select } from "frontend/src/components";

export default function SelectContainer(){
  const selectContent = {
    hashObjects: [
      {
        "Choice 0": () => {
          console.log("0");
        },
      },
      {
        "Choice 1": () => {
          console.log("1");
        },
      },
      {
        "Choice 2": () => {
          console.log("2");
        },
      },
    ],
    defaultIndex: 1,
  };

  return(
    <>
      // Example using new default index
      <div style={{ width: "200px" }} className="u-mgn-md">
        <Select
          hashObjects={selectContent.hashObjects}
          defaultIndex={selectContent.defaultIndex}
        />
      </div>

      // Example using KO style
      <div style={{ width: "200px" }} className="u-mgn-md">
        <Select ko={true} hashObjects={selectContent.hashObjects} />
      </div>
    </>
  )
}`,
  };

  const selectContent = {
    hashObjects: [
      {
        "Choice 0": () => {
          console.log("0");
        },
      },
      {
        "Choice 1": () => {
          console.log("1");
        },
      },
      {
        "Choice 2": () => {
          console.log("2");
        },
      },
    ],
    defaultIndex: 1,
  };

  return (
    <>
      <SGTemplate
        name={templateContent.name}
        variantName={templateContent.variantName}
        description={templateContent.description}
        implementation={templateContent.implementation}
        componentExamples={
          <div style={{ display: "flex" }}>
            <div className="u-pad-sm">
              <Text number={1}>
                Standard style with 'choice 1' set as default.
              </Text>
              <div style={{ width: "200px" }} className="u-mgn-md">
                <Select
                  hashObjects={selectContent.hashObjects}
                  defaultIndex={selectContent.defaultIndex}
                />
              </div>
            </div>
            <div className="u-background-darkblue u-pad-sm">
              <Text number={1} ko={true}>
                KO style with 'choice 0' set as default.
              </Text>
              <div style={{ width: "200px" }} className="u-mgn-md">
                <Select ko={true} hashObjects={selectContent.hashObjects} />
              </div>
            </div>
          </div>
        }
        propsTableContents={templateContent.propsTable}
        links={templateContent.links}
        codeExample={templateContent.codeExample}
      />
    </>
  );
}
