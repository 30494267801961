import React from "react";
import { Heading, TableHead, TableRow, TH } from "../../../../../components";

const MapsListColumnTitles = () => {
  return (
    <TableHead>
      <TableRow className="u-mgn-top-md">
        <TH className="u-align-center">
          <Heading number={4}>Map</Heading>
        </TH>
        <TH className="u-align-center">
          <Heading number={4}>Owner</Heading>
        </TH>
        {/* <TH className="u-align-center">
          <Heading number={4}>Editors</Heading>
        </TH> */}
        <TH className="u-align-center">
          <Heading number={4}>Date</Heading>
        </TH>
        <TH className="u-align-center">
          <Heading number={4}>Actions</Heading>
        </TH>
      </TableRow>
    </TableHead>
  );
};

export default MapsListColumnTitles;
