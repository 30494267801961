import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Heading, ModalClose, Text } from "../../components";
import React from "react";

export default function NavbarSignUpEmailMessageModal({ navbar }) {
  return (
    <Modal
      isOpen={navbar.state.isSignUpEmailMessageModalOpen}
      centered={true}
      backdrop="static"
      size="lg">
      <ModalHeader className="u-no-select">
        <ModalClose
          cb={() => {
            navbar.setState({
              ...navbar.state,
              isSignUpEmailMessageModalOpen: false,
            });
          }}
        />
        Email Verification
      </ModalHeader>
      <ModalBody>
        <Heading number={5} color={"secondary"} className="u-pad-sm">
          Thank you for registering for an account.
        </Heading>
        <Heading number={5} color={"secondary"} className="u-pad-sm">
          We have just sent you a verification email.
        </Heading>
        <div className="u-pad-sm">
          <Text number={2}>
            To ensure the security of you account, Conservationist.io follows an
            email verification process.
          </Text>
        </div>
        <div className="u-pad-sm">
          <Text number={2}>
            Before you continue and explore the site, please check the email
            inbox associated with your new account. All that needs to be done is
            open the email from us and click on the verification link!
          </Text>
        </div>
        <Heading
          number={5}
          className="u-pad-left-sm u-pad-right-sm u-pad-vert-md">
          Welcome to Conservationist.io!
        </Heading>
      </ModalBody>
    </Modal>
  );
}
