import { CollapsibleV, Heading, Text } from "../../components";
import { CodeBlock, nord } from "react-code-blocks";
import React from "react";
import { SGPropsTable } from ".";

export default function SGTemplate({
  name = "",
  variantName = "",
  description,
  implementation,
  componentExamples,
  propsTableContents,
  links = [],
  codeExample,
}) {
  const linksList = links.map((link, index) => {
    const linkText = Object.keys(links[index])[0];
    return (
      <Text number={1} key={index}>
        {index + 1}.{" "}
        <a
          key={index}
          className="hyperlink"
          href={link[linkText]}
          target="_blank"
          rel="noopener noreferrer">
          {linkText}
        </a>
      </Text>
    );
  });

  return (
    <div className="u-pad-sm u-mgn-btm-md">
      {name && (
        <div
          id={`${name.toLowerCase()}`}
          className="sg-components__template--id-div">
          <div className="u-mgn-btm-sm">
            <Heading number={2}>{name}</Heading>
          </div>
          <hr style={{ border: "1px solid #005596" }} />
        </div>
      )}
      {variantName && (
        <div
          id={`${variantName.toLowerCase()}`}
          className="sg-components__template--id-div">
          <div className="u-mgn-btm-sm">
            <Heading number={2}>{variantName}</Heading>
          </div>
          <hr style={{ border: "1px solid #c6c6c6" }} />
        </div>
      )}
      {description && (
        <>
          <div className="u-mgn-vert-sm">
            <Heading number={4} color="secondary">
              Description
            </Heading>
          </div>
          <p className="paragraph__1">{description}</p>
        </>
      )}
      {implementation && (
        <>
          <div className="u-mgn-vert-sm">
            <Heading number={4} color="secondary">
              Implementation
            </Heading>
          </div>
          <p className="paragraph__1 u-mgn-vert-sm">{implementation}</p>
        </>
      )}
      {componentExamples && (
        <>
          <div className="u-mgn-vert-sm">
            <Heading number={4} color="secondary">
              UI Examples
            </Heading>
          </div>
          <div className="u-mgn-vert-sm u-flex">{componentExamples}</div>
        </>
      )}
      {propsTableContents && (
        <>
          <div className="u-mgn-vert-sm">
            <Heading number={4} color="secondary">
              Props
            </Heading>
          </div>
          <SGPropsTable bodyContent={propsTableContents} />
        </>
      )}
      {links.length > 0 && (
        <div className="u-mgn-vert-sm">
          <div className="u-mgn-vert-sm">
            <Heading number={4} color="secondary">
              Links
            </Heading>
          </div>
          {linksList}
        </div>
      )}
      {codeExample && (
        <div className="u-mgn-top-sm">
          <CollapsibleV
            title={<h3 className="heading__3 u-mgn-vert-sm">Code examples</h3>}>
            <div
              style={{
                fontFamily: "Courier, monospace",
                fontSize: "16px",
              }}>
              <CodeBlock
                text={codeExample}
                language={"jsx"}
                showLineNumbers={true}
                theme={nord}
                wrapLines={true}
              />
            </div>
          </CollapsibleV>
        </div>
      )}
    </div>
  );
}
