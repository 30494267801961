import { Button } from "..";
import React from "react";

export default function CardMdProfileStripe({
  headerText,
  imgProps = {},
  leftFooterButtonText,
  leftFooterButtonLinkPath,
  leftFooterButtonOnClick = () => {},
  rightFooterIconsArray = [],
}) {
  const noImgClassName = imgProps.src ? "" : "card__md__img-cir--no-img";

  const rightFooterIconsStyled = rightFooterIconsArray.map((icon, index) => {
    return (
      <div key={index} className="card__md__icon-container">
        {icon}
      </div>
    );
  });

  return (
    <div className="card__md">
      <img
        className={`card__md__img-cir ${noImgClassName}`}
        src={imgProps.src}
        alt={imgProps.alt}
      />
      <div className="card__md__header-container heading__5">{headerText}</div>
      <div className="card__md__stripe-bottom">
        <div className="card__md__stripe-bottom--left">
          <Button buttontype="text" href={leftFooterButtonLinkPath} ko={true}>
            <span onClick={leftFooterButtonOnClick}>
              {leftFooterButtonText}
            </span>
          </Button>
        </div>
        {rightFooterIconsArray.length > 0 && (
          <div className="card__md__stripe-bottom--right">
            {rightFooterIconsStyled}
          </div>
        )}
      </div>
    </div>
  );
}
