import L from "leaflet";
import "leaflet-geometryutil";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import utils from "../../utils/utils";


const listeners = (map, app) => {
  // const state = app.state
  toast.configure({
    autoClose: 180000
  })

  map.on('pm:create', function (e) {
    app.setState({ coords: "", featureCollection: false, speciesImg: false, activeSpecies: false, speciesSciName: false, speciesComName: false })
    const latlngs = e.layer.getLatLngs()
    const area = L.GeometryUtil.length(latlngs[0]);
    if (app.state.layer) {
      map.removeLayer(app.state.layer)
    }
    if (area > 85420) {
      utils.sendAlert("Please draw a polygon that covers a smaller amount of land.", "warning")
      map.removeLayer(e.layer)
      app.setState({
        layer: false
      })
    } else {
      const geojson = e.layer.toGeoJSON()
      app.setState({
        userIsReady: true,
        coords: geojson,
        activeSpecies: false,
        layer: e.layer
      })
    }
  })



  let mouseLat, mouseLng
  map.on("keyup", function (e) {

    if (e.originalEvent.key === "Z" && e.originalEvent.shiftKey) {

      var latlng = L.latLng(mouseLat, mouseLng);
      app.setState({
        mapZoom: 12,
        mapCenter: latlng
      })
    }
  })

  map.addEventListener('mousemove', (event) => {
    mouseLat = Math.round(event.latlng.lat * 100000) / 100000;
    mouseLng = Math.round(event.latlng.lng * 100000) / 100000;
  })

  map.on('baselayerchange', function (e) {
    let { beforeImg, afterImg, changeImg } = app.state
    if (beforeImg || afterImg || changeImg) {

      map.removeLayer(beforeImg)
      map.removeLayer(afterImg)
      map.removeLayer(changeImg)
      setTimeout(() => {
        map.addLayer(beforeImg)
        map.addLayer(afterImg)
        map.addLayer(changeImg)
      }, 500)
    }
  });
}


export default listeners
