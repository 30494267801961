import { List, ListItem, Text } from "..";
import staticLink from "../../utils/static";
import React from "react";
import { SGTemplate } from ".";

export default function SGList() {
  const content = {
    name: "List",
    variantName: "",
    description:
      "Displays content in a vertical format with standardized spacing between elements.",
    implementation: `The List component is essentially optional. The ListItem will render content in a rectangular "card". If ListItems are wrapped within a List, they'll be rendered in a column with standardized spacing.`,
    propsTable: [
      [
        "children (List)",
        "JSX",
        "Any valid JSX",
        "yes",
        "List is not a self-closing component. Wrap any desired content within the open and close tags. All children will only be displayed when the dropdown is open.",
      ],
      [
        "thumbnail",
        "string",
        "a valid path to an image",
        "no",
        "Adds a thumbnail to the lefthand side of the ListItem component. "
      ],
      [
        "thumbnailRounded",
        "boolean",
        "true, false",
        "no",
        "Set to true if you want a ListItem's left-hand side thumbnailto be a circle",
      ],
      [
        "ko",
        "boolean",
        "true, false",
        "no",
        "Set to true when the component is used over a dark background.",
      ],
      [
        "interactive",
        "boolean",
        "true, false",
        "no",
        "Adds a hover effect to the ListItem.",
      ],
      [
        "children",
        "JSX",
        "Any valid JSX",
        "no",
        "ListItem is not a self-closing component. Wrap any desired content within the open and close tags. All children will only be displayed when the dropdown is open.",
      ],
    ],
    codeExample: `
    import { List, ListItem } from "/components";
             
    <List>
    <div className="u-mgn-btm-sm">

      <ListItem thumbnail="/species/canadaLynx1.jpg" thumbnailRounded={true}>
        <p className="paragraph__1 paragraph__1--ko">
           Some text
        </p>
      </ListItem>
    </div>
    <div className="u-mgn-btm-sm">

      <ListItem thumbnail="/species/canadaLynx1.jpg" thumbnailRounded={false}>
        <p className="paragraph__1 paragraph__1--ko">
           Some text
        </p>
      </ListItem>
    </div>
    <div className="u-mgn-btm-sm">

      <ListItem >
        <p className="paragraph__1 paragraph__1--ko">
           Some text
        </p>
      </ListItem>
    </div>
  </List>
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width u-around-flex u-flex-wrap">
          <div className="u-pad-sm">
            <List table={true}>
              <div className="u-mgn-btm-sm">
                <ListItem
                  ko={true}
                  thumbnail={`${staticLink}/species/canadaLynx1.jpg`}
                  interactive={true}>
                  <p className="paragraph__1">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Recusandae quidem perferendis culpa odio aliquam, dolorum
                    voluptates adipisci voluptatibus unde neque explicabo porro
                    repellendus dignissimos laudantium rem incidunt commodi,
                    maxime dolore?
                  </p>
                </ListItem>
              </div>
              <div className="u-mgn-btm-sm">
                <ListItem ko={true} thumbnail={`${staticLink}/species/canadaLynx1.jpg`}>
                  <p className="paragraph__1">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Recusandae quidem perferendis culpa odio aliquam, dolorum
                    voluptates adipisci voluptatibus unde neque explicabo porro
                    repellendus dignissimos laudantium rem incidunt commodi,
                    maxime dolore?
                  </p>
                </ListItem>
              </div>
              <div className="u-mgn-btm-sm">
                <ListItem ko={true} thumbnail={`${staticLink}/species/canadaLynx1.jpg`}>
                  <p className="paragraph__1">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Recusandae quidem perferendis culpa odio aliquam, dolorum
                    voluptates adipisci voluptatibus unde neque explicabo porro
                    repellendus dignissimos laudantium rem incidunt commodi,
                    maxime dolore?
                  </p>
                </ListItem>
              </div>
            </List>
          </div>

          <div className="u-pad-sm">
            <List>
              <div className="u-mgn-btm-sm">
                <ListItem
                  thumbnail={`${staticLink}/species/canadaLynx1.jpg`}
                  thumbnailRounded={true}
                  ko={true}>
                  <p className="paragraph__1">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Recusandae quidem perferendis culpa odio aliquam, dolorum
                    voluptates adipisci voluptatibus unde neque explicabo porro
                    repellendus dignissimos laudantium rem incidunt commodi,
                    maxime dolore?
                  </p>
                </ListItem>
              </div>
              <div className="u-mgn-btm-sm">
                <ListItem
                  thumbnail={`${staticLink}/species/canadaLynx1.jpg`}
                  thumbnailRounded={true}
                  ko={true}>
                  <p className="paragraph__1">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Recusandae quidem perferendis culpa odio aliquam, dolorum
                    voluptates adipisci voluptatibus unde neque explicabo porro
                    repellendus dignissimos laudantium rem incidunt commodi,
                    maxime dolore?
                  </p>
                </ListItem>
              </div>
              <div className="u-mgn-btm-sm">
                <ListItem
                  thumbnail={`${staticLink}/species/canadaLynx1.jpg`}
                  thumbnailRounded={true}
                  ko={true}>
                  <p className="paragraph__1">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Recusandae quidem perferendis culpa odio aliquam, dolorum
                    voluptates adipisci voluptatibus unde neque explicabo porro
                    repellendus dignissimos laudantium rem incidunt commodi,
                    maxime dolore?
                  </p>
                </ListItem>
              </div>
            </List>
          </div>

          <div className="u-pad-md">
            <List>
              <div className="u-mgn-btm-sm">
                <ListItem sm={true}>
                  <div className="u-flex u-flex-justify-evenly">
                    <Text number={2} ko={true}>
                      mm/dd/yyyy
                    </Text>
                    <Text number={2} ko={true}>
                      Some Text
                    </Text>
                    <Text number={2} ko={true}>
                      Some instructions
                    </Text>
                  </div>
                </ListItem>
              </div>
              <div className="u-mgn-btm-sm">
                <ListItem sm={true}>
                  <div className="u-flex u-flex-justify-evenly">
                    <Text number={2} ko={true}>
                      mm/dd/yyyy
                    </Text>
                    <Text number={2} ko={true}>
                      Some Text
                    </Text>
                    <Text number={2} ko={true}>
                      Some instructions
                    </Text>
                  </div>
                </ListItem>
              </div>
              <div className="u-mgn-btm-sm">
                <ListItem sm={true}>
                  <div className="u-flex u-flex-justify-evenly">
                    <Text number={2} ko={true}>
                      mm/dd/yyyy
                    </Text>
                    <Text number={2} ko={true}>
                      Some Text
                    </Text>
                    <Text number={2} ko={true}>
                      Some instructions
                    </Text>
                  </div>
                </ListItem>
              </div>
            </List>
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
