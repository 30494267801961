import { Heading, Text } from '../../components'
import React from 'react'

export default function CardTechnicalService({ textMain, textSub }) {
  return (
    <div>
      <Heading number={5}>{textMain}</Heading>
      <Text className='u-textbox-lg' number={2}>
        {textSub}
      </Text>
    </div>
  )
}
