import React from "react";
import { useParams } from "react-router-dom";

// Components
import { Heading, InputEditField } from "../../../../../components";

// Utilities
import APIreqs from "../../../helpers/API";
import utils from "../../../../../utils/utils";

export default function ProjectSummaryEditable({ projectHandler }) {
  const { projectID } = useParams();

  async function updateAttr(obj) {
    try {
      const { /* inputValue, */ updatedValue, elementName } = obj;
      await APIreqs.projects.put(projectID, [{ [elementName]: updatedValue }]);
      projectHandler.setData({
        ...projectHandler.data,
        [elementName]: updatedValue,
      });
    } catch {
      utils.sendAlert(
        "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'put CVM project title' error.",
        "error",
      );
    }
  }

  return (
    <div className="u-mgn-btm-md">
      <Heading className="u-pad-btm-sm" ko={true} number={5}>
        Summary
      </Heading>
      <InputEditField
        inputValue={projectHandler.data.summary}
        ko={true}
        maxLength="400"
        name="summary"
        updateValueCB={updateAttr}>
        <Heading bold={false} ko={true} number={5}>
          {projectHandler.data.summary}
        </Heading>
      </InputEditField>
    </div>
  );
}
