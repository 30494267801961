import Auth from "./API";
import { Button, FormInputWValidations, Heading } from "../components";
import React, { useEffect, useState } from "react";
import routes from "../utils/paths/routes";
import { useHistory } from "react-router-dom";
import staticLink from "../utils/static";
import utils from "../utils/utils";

export default function PasswordReset({ account, auth }) {
  const passwordResetStateInit = {
      isConfirmPasswordValid: false,
      isPasswordValid: false,
      token: "",
      username: "",
      valueConfirmPassword: "",
      valuePassword: "",
    },
    [passwordResetState, setPasswordResetState] = useState(
      passwordResetStateInit,
    );

  useEffect(() => {
    const url = window.location.search.substring(1),
      req = utils.queryParamsToObj(url),
      { token, username } = req;
    setPasswordResetState({ ...passwordResetState, username: username });
    Auth.checkResetToken(token)
      .then()
      .catch(() => {
        utils.sendAlert(
          `Something went wrong during the password reset process. You are be redirected to the home page.\n\nPlease let us know about this issue using the contact form with "Reporting a bug" in the subject line.`,
          "error",
        );
        setTimeout(() => {
          history.push(routes.root.path);
          utils.scrollToTop();
        }, 3000);
      });
  }, []);

  const history = useHistory(),
    resetPassword = () => {
      if (
        passwordResetState.isPasswordValid &&
        passwordResetState.isConfirmPasswordValid
      ) {
        const req = {
          password: passwordResetState.valuePassword,
          username: passwordResetState.username,
        };
        Auth.resetPassword(req).then(() => {
          if (auth.state.isAuthenticated) {
            utils.sendAlert("Password reset.", "success");
            setTimeout(() => {
              history.push(routes.root.path);
              utils.scrollToTop();
            }, 500);
          }

          if (!auth.state.isAuthenticated) {
            Auth.logIn(req).then((res) => {
              localStorage.setItem("token", res.data.token);
              auth.setState({ ...auth.state, isAuthenticated: true });
              Auth.getAuth().then((res2) => {
                account.setState(res2.data);
              });
              utils.sendAlert(
                "Password reset and logged in.",
                "success",
              );
              setTimeout(() => {
                history.push(routes.root.path);
                utils.scrollToTop();
              }, 500);
            });
          }
        });
      } else {
        utils.sendAlert(
          "Please complete the form to reset the password.",
          "error",
        );
      }
    };

  // console.log("passwordResetState:", passwordResetState);

  return (
    <div className="password-reset">
      <img
        src={`${staticLink}banner/Email-Banner.png`}
        alt="banner"
        className="password-reset__banner"
      />
      <div className="password-reset__content">
        <Heading className="u-pad-vert-sm" number={2}>
          Password reset
        </Heading>

        <Heading className="u-pad-vert-sm" number={4} color="secondary">
          Hello, {passwordResetState.username}.
        </Heading>
        <Heading className="u-pad-vert-sm" bold={false} number={5}>
          Set a new password for your account below.
        </Heading>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            resetPassword();
          }}>
          <div className="u-flex u-flex-column">
            <div className="u-pad-vert-sm">
              <FormInputWValidations
                parentState={passwordResetState}
                placeholder="Enter password"
                setParentState={setPasswordResetState}
                inputType="password"
                validationKey="isPasswordValid"
                validationsContext="sign up"
                valueKey="valuePassword"
                useValidations={true}
              />
            </div>
            <div className="u-pad-vert-sm">
              <FormInputWValidations
                parentState={passwordResetState}
                placeholder="Confirm password"
                setParentState={setPasswordResetState}
                inputType="confirmpassword"
                validationKey="isConfirmPasswordValid"
                validationsContext="sign up"
                valueKey="valueConfirmPassword"
                useValidations={true}
              />
            </div>
            <div className="u-pad-btm-md u-pad-top-md">
              <Button
                buttontype="primary"
                size="inherit"
                buttonProps={{ type: "submit" }}>
                Reset Password
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
