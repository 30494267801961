import React from "react";
import { Button } from "../../components";

export default function CardMdProfile({
  buttonText,
  headerText,
  imgProps = {},
  linkPath,
  onClick = () => {},
}) {
  const noImgClassName = imgProps.src ? "" : "card__md__img-cir--no-img";

  return (
    <div className="card__md">
      <img
        className={`card__md__img-cir ${noImgClassName}`}
        src={imgProps.src}
        alt={imgProps.alt}
      />
      <div className="card__md__header-container heading__5">{headerText}</div>
      <div className="card__md__button-container">
        <Button buttontype="text" href={linkPath}>
          <span onClick={onClick}>{buttonText}</span>
        </Button>
      </div>
    </div>
  );
}
