import { Heading, Icon, Menu, Span } from "../../components";
import React from "react";

export default function ProfileSideNav({ profile }) {
  return (
    <div className="profile__nav">
      <Menu
        options={[
          {
            jsx: (
              <>
                <div className="u-flex u-flex-align-center">
                  <Span
                    className={`${
                      profile.state.activeSection === "appsSection"
                        ? "u-color-yellow"
                        : ""
                    } u-mgn-right-sm u-flex u-flex-align-center`}>
                    <Icon inherit={true} icon="account" />
                  </Span>
                  <Heading number={5} ko={true}>
                    <Span
                      className={`${
                        profile.state.activeSection === "appsSection"
                          ? "u-color-yellow"
                          : ""
                      }`}>
                      Apps
                    </Span>
                  </Heading>
                </div>
              </>
            ),
            onClick: () =>
              profile.setState({
                ...profile.state,
                activeSection: "appsSection",
              }),
            active:
              profile.state.activeSection === "appsSection" ? true : false,
          },
          {
            jsx: (
              <>
                <div className="u-flex u-flex-align-center">
                  <Span
                    className={`${
                      profile.state.activeSection === "profileSection"
                        ? "u-color-yellow"
                        : ""
                    } u-mgn-right-sm u-flex u-flex-align-center`}>
                    <Icon inherit={true} icon="profile" />
                  </Span>
                  <Heading number={5} ko={true}>
                    <Span
                      className={`${
                        profile.state.activeSection === "profileSection"
                          ? "u-color-yellow"
                          : ""
                      }`}>
                      Profile
                    </Span>
                  </Heading>
                </div>
              </>
            ),
            onClick: () =>
              profile.setState({
                ...profile.state,
                activeSection: "profileSection",
              }),
            active:
              profile.state.activeSection === "profileSection" ? true : false,
          },
          {
            jsx: (
              <>
                <div className="u-flex u-flex-align-center">
                  <Span
                    className={`${
                      profile.state.activeSection === "accountSection"
                        ? "u-color-yellow"
                        : ""
                    } u-mgn-right-sm u-flex u-flex-align-center`}>
                    <Icon inherit={true} icon="personalinfo" />
                  </Span>
                  <Heading number={5} ko={true}>
                    <Span
                      className={`${
                        profile.state.activeSection === "accountSection"
                          ? "u-color-yellow"
                          : ""
                      }`}>
                      Account
                    </Span>
                  </Heading>
                </div>
              </>
            ),
            onClick: () =>
              profile.setState({
                ...profile.state,
                activeSection: "accountSection",
              }),
            active:
              profile.state.activeSection === "accountSection" ? true : false,
          },
        ]}
      />
    </div>
  );
}
