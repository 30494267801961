import API from "../../auth/API";
import {
  Button,
  Heading,
  Icon,
  InputEditField,
  ModalClose,
  RadioButton,
  Text,
  TextEditField,
} from "../../components";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import React, { useState } from "react";
import routes from "../../utils/paths/routes";
import utils from "../../utils/utils";
import { useHistory } from "react-router-dom";

export default function ProfilePageAccountSection({ profile }) {
  const initModalState = {
      confirmationModalOpen: false,
    },
    [modalState, setModalState] = useState(initModalState),
    modal = {
      initState: initModalState,
      setState: setModalState,
      state: modalState,
    };

  function toggleModal(modalType) {
    modal.setState({ ...modal.state, [modalType]: !modal.state[modalType] });
  }

  const history = useHistory();
  function changeUsername() {
    const { profileFields, username } = profile.state;
    profileFields.username = username;
    API.updateProfile(profileFields)
      .then(() => {
        toggleModal();
        utils.sendAlert(
          "Username changed.  Redirecting to the home page and where you can log in.",
          "success",
        );
        setTimeout(() => {
          localStorage.removeItem("token");
          history.push(routes.root.path);
        }, 3000);
      })
      .catch(() => {
        toggleModal();
        utils.sendAlert("Sorry, that username is already taken.", "error");
      });
  }

  const requestResetPasswordLinkEmail = () => {
    if (profile.state.profileFields.email) {
      const req = {
        email: profile.state.profileFields.email,
      };
      API.requestPassReset(req)
        .then(() => {
          utils.sendAlert(
            "A password reset email has been sent to your email address. Please check your inbox and follow the instructions there to change your password.",
            "success",
          );
        })
        .catch(() => {
          utils.sendAlert(
            "Something went wrong.  Please use the contact form to report a bug.",
            "error",
          );
        });
    } else {
      utils.sendAlert("Please submit an email with a valid format.", "error");
    }
  };

  function updateBio(e) {
    const { profileFields } = profile.state;
    profileFields["bio"] = e.value;
    profile.setState({ ...profile.state, profileFields });
    API.updateProfile(profileFields).then((res) => {});
  }

  function updateProf(e) {
    let formData = new FormData();
    try {
      formData.append("file", e.target.files[0], e.target.files[0].name);
    } catch {
      utils.sendAlert(`Please select a file before uploading.`, "error");
    }
    API.updateProfPic(formData, profile.state.profileFields.username).then(
      (res) => {
        const profileFields = res.data;
        profile.setState({ ...profile.state, profileFields });
      },
    );
  }

  function updateProfileInfo(e) {
    const { updatedValue, elementName } = e;
    const { profileFields } = profile.state;
    profileFields[elementName] = updatedValue;
    if (elementName === "username") {
      profile.setState({ ...profile.state, username: updatedValue });
      toggleModal("confirmationModalOpen");
      return;
    }
    API.updateProfile(profileFields).then((res) => {
      if (res.data.error) {
        utils.sendAlert(res.data.error, "error");
      } else {
        profile.setState({ ...profile.state, profileFields });
      }
    });
  }

  function toggleEmailSubscription(choice) {
    const { profileFields } = profile.state;
    profileFields["email_subscribed"] = choice;
    profile.setState({ ...profile.state, profileFields });
    API.updateProfile(profileFields).then(() => {
      if (choice) {
        utils.sendAlert("Subscribed to email notifications.", "success");
      } else {
        utils.sendAlert("Unsubscribed to email notifications.", "success");
      }
    });
  }

  function toggleTextFieldEditing() {
    let { textFieldEditing } = profile.state;
    textFieldEditing = !textFieldEditing;
    profile.setState({ ...profile.state, textFieldEditing });
  }

  return (
    <>
      <div className="profile_personalinfo">
        <div className="profile__header">
          <Heading number={4} ko={true}>
            Account
          </Heading>
        </div>
        <div className="u-mgn-top-sm">
          <div className="profile__box">
            <div className="u-flex u-flex-justify-between u-flex-align-center u-mgn-btm-md">
              <div className="u-mgn-right-sm">
                <Heading number={5}>Profile Picture</Heading>
              </div>
              <div className="u-pos-relative u-flex u-flex-align-end">
                <img
                  src={`/${profile.state.profileFields.photo}`}
                  alt="Profile picture"
                  className="profile__thumb--sm"
                />
                <input
                  className="profile__upload"
                  type="file"
                  onChange={updateProf}
                />
                <Icon icon="edit" />
              </div>
            </div>
            <div className="u-flex u-flex-justify-between u-flex-align-center u-mgn-btm-md">
              <div className="u-mgn-right-sm">
                <Heading number={5}>Username</Heading>
              </div>
              <div className="profile__field">
                <InputEditField
                  inputValue={profile.state.profileFields.username}
                  ko={false}
                  updateValueCB={updateProfileInfo}
                  name="username">
                  <Text number={1}>{profile.state.profileFields.username}</Text>
                </InputEditField>
              </div>
            </div>
            <div className="u-flex u-flex-justify-between u-flex-align-center u-mgn-btm-md">
              <div className="u-mgn-right-sm">
                <Heading number={5}>First Name</Heading>
              </div>
              <div className="profile__field">
                <InputEditField
                  inputValue={profile.state.profileFields.first_name}
                  ko={false}
                  updateValueCB={updateProfileInfo}
                  name="first_name">
                  <Text number={1}>
                    {profile.state.profileFields.first_name}
                  </Text>
                </InputEditField>
              </div>
            </div>
            <div className="u-flex u-flex-justify-between u-flex-align-center u-mgn-btm-md">
              <div className="u-mgn-right-sm">
                <Heading number={5}>Last Name</Heading>
              </div>
              <div className="profile__field">
                <InputEditField
                  inputValue={profile.state.profileFields.last_name}
                  ko={false}
                  updateValueCB={updateProfileInfo}
                  name="last_name">
                  <Text number={1}>
                    {profile.state.profileFields.last_name}
                  </Text>
                </InputEditField>
              </div>
            </div>
            <div className="u-flex u-flex-justify-between u-flex-align-center u-mgn-btm-md">
              <div className="u-mgn-right-sm">
                <Heading number={5}>Email</Heading>
              </div>
              <div className="profile__field">
                <InputEditField
                  inputValue={profile.state.profileFields.email}
                  ko={false}
                  updateValueCB={updateProfileInfo}
                  name="email">
                  <Text number={1}>{profile.state.profileFields.email}</Text>
                </InputEditField>
              </div>
            </div>
            <div className="u-flex u-flex-justify-between u-flex-align-center u-mgn-btm-md">
              <div className="u-mgn-right-sm">
                <Heading number={5}>Organization</Heading>
              </div>
              <div className="profile__field">
                <InputEditField
                  inputValue={profile.state.profileFields.organization}
                  ko={false}
                  updateValueCB={updateProfileInfo}
                  name="organization">
                  <Text number={1}>
                    {profile.state.profileFields.organization}
                  </Text>
                </InputEditField>
              </div>
            </div>
            <div className="u-mgn-btm-md">
              <div className="u-flex u-flex-justify-between u-flex-align-center u-mgn-btm-sm">
                <Heading number={5}>Bio</Heading>
                {profile.state.textFieldEditing ? (
                  <Button
                    buttontype="text"
                    buttonProps={{ onClick: toggleTextFieldEditing }}>
                    Done
                  </Button>
                ) : (
                  <div onClick={toggleTextFieldEditing}>
                    <Icon icon="edit" />
                  </div>
                )}
              </div>
              <TextEditField
                isEditing={profile.state.textFieldEditing}
                updateValueCB={updateBio}
                textValue={profile.state.profileFields.bio}>
                {profile.state.profileFields.bio ? (
                  <Text number={2}>{profile.state.profileFields.bio}</Text>
                ) : (
                  <Text number={2}>
                    Write a few sentences about yourself...
                  </Text>
                )}
              </TextEditField>
            </div>
            <div style={{ marginLeft: "-2rem" }}>
              <Button
                buttontype="text"
                buttonProps={{
                  onClick: requestResetPasswordLinkEmail,
                }}>
                Change Password
              </Button>
            </div>
          </div>
        </div>
        <div className="u-mgn-top-sm">
          <div className="profile__header">
            <Heading number={4} ko={true}>
              Settings
            </Heading>
          </div>
        </div>
        <div className="u-mgn-top-sm">
          <div className="profile__box u-flex u-flex-column">
            <Heading number={5}>
              Would you like to sign up to receive email notifications from us
              about app updates, news, and interactive opportunities?
            </Heading>
            <div className="u-mgn-top-sm">
              <div className="u-mgn-right-md u-flex u-flex-align-center">
                <RadioButton
                  name="yesEmail"
                  onClick={() => toggleEmailSubscription(true)}
                  defaultChecked={
                    profile.state.profileFields.email_subscribed ? true : false
                  }
                />
                <div className="u-mgn-left-sm">
                  <Text number={1}>
                    Yes, I would like to receive email notifications.
                  </Text>
                </div>
              </div>
              <div className="u-flex u-flex-align-center u-mgn-top-sm">
                <RadioButton
                  name="noEmail"
                  onClick={() => toggleEmailSubscription(false)}
                  defaultChecked={
                    profile.state.profileFields.email_subscribed ? false : true
                  }
                />
                <div className="u-mgn-left-sm">
                  <Text number={1}>
                    No, I would not like to receive email notifications.
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal to confirm the changing of username */}
      <Modal
        backdrop="static"
        centered={true}
        size="lg"
        isOpen={modal.state.confirmationModalOpen}
        toggle={() => toggleModal("confirmationModalOpen")}>
        <ModalHeader>
          <ModalClose cb={toggleModal} />
          Confirm Username Change
        </ModalHeader>
        <ModalBody>
          <div className="u-pad-md">
            <Heading number={3}>
              Are you sure you want to change your username? If so, you will be
              asked to log back in with your new username.
            </Heading>
            <div className="u-flex u-flex-justify-center u-mgn-top-md">
              <div className="u-mgn-right-sm">
                <Button
                  buttontype="primary"
                  buttonProps={{ onClick: changeUsername }}>
                  Confirm change
                </Button>
              </div>
              <Button
                buttontype="primary"
                buttonProps={{
                  onClick: () => {
                    toggleModal("confirmationModalOpen");
                  },
                }}>
                Go back
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
