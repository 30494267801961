import React, { useEffect, useState } from 'react'

// Components
import { Text } from '../../../../../../../components'
import ValRstBtn from './valBtn/ValRstBtn'

// Utilities
import Plot from 'react-plotly.js'
import {
  generateColorsArr,
  generatePlotRangeArr,
} from '../../../../../helpers/plot'

function ValuationElement({ dataLayers, i, pcm }) {
  const plotInit = {
      colorsArr: [],
      rangeArr: [],
      seriesArr: [],
      seriesMaxVal: undefined,
      seriesMinVal: undefined,
    },
    [plot, setPlot] = useState(plotInit)
  // console.log('plot: ', plot)

  // Generate plot series values.
  useEffect(() => {
    if (
      dataLayers.data[i].raster.data &&
      Object.keys(dataLayers.data[i].raster.data).length > 0
    ) {
      const seriesMaxVal = dataLayers.data[i].raster.data.datasetMaxVal.value
      const seriesMinVal = dataLayers.data[i].raster.data.datasetMinVal.value
      const seriesIntervalCount = 20
      const seriesInterval = (seriesMaxVal - seriesMinVal) / seriesIntervalCount
      const seriesArr = Array.from(
        { length: seriesIntervalCount + 1 },
        (_, index) => seriesMinVal + index * seriesInterval,
      )

      setPlot((plot) => ({ ...plot, seriesArr, seriesMaxVal, seriesMinVal }))
    }
  }, [dataLayers, i])

  // Updates plot arguments with props update.
  useEffect(() => {
    if (plot.seriesArr.length > 0 && dataLayers.data[i].valRstDataset) {
      // Valuation function coefficients.
      const A = Number(dataLayers.data[i].valRstDataset.data.valFnCoeffA.value)
      const B = Number(dataLayers.data[i].valRstDataset.data.valFnCoeffB.value)
      const C = Number(dataLayers.data[i].valRstDataset.data.valFnCoeffC.value)
      const fnType = dataLayers.data[i].valRstDataset.data.valFnType.value

      // Generate plot range values.
      const rangeArr = generatePlotRangeArr(
        fnType,
        plot.seriesArr,
        A,
        B,
        C,
        plot.seriesMinVal,
        plot.seriesMaxVal,
      )

      // Generate colors.
      const colorPalette = {
        min: dataLayers.data[i].valRstDataset.data.colorPaletteMin.value,
        zero: dataLayers.data[i].valRstDataset.data.colorPaletteZero.value,
        max: dataLayers.data[i].valRstDataset.data.colorPaletteMax.value,
      }
      const colorsArr = generateColorsArr(rangeArr, colorPalette)

      setPlot({ ...plot, colorsArr, rangeArr })
    }
  }, [plot.seriesArr, dataLayers, i])

  return (
    <div className='u-flex cvm__data-layer__val-fn__container cvm__data-layer__large-icon__container'>
      <div className='u-flex u-flex-align-center cvm__data-layer__large-icon__container cvm__data-layer__large-icon__container--end-end'>
        <ValRstBtn dataLayers={dataLayers} i={i} pcm={pcm} />
      </div>
      <div className='u-full-width u-flex u-flex-all-center'>
        <div className='cvm__data-layer__val-fn__status'>
          {dataLayers.data[i].valRstDataset ? (
            <div className='u-flex u-flex-all-center'>
              <Plot
                layout={{
                  font: { size: 12 },
                  height: 60,
                  margin: { t: 15, r: 30, b: 15, l: 30 },
                  width: 140,
                  xaxis: { showticklabels: false },
                  yaxis: {
                    range: [-1.25, 1.25],
                  },
                }}
                data={[
                  {
                    x: plot.seriesArr,
                    y: plot.rangeArr,
                    type: 'scatter',
                    mode: 'markers',
                    marker: {
                      color: plot.colorsArr,
                      line: {
                        color: 'black',
                        width: 1,
                      },
                      size: 6,
                    },
                  },
                ]}
              />
            </div>
          ) : (
            <Text ko={true} number={1}>
              No valuation function assigned.
            </Text>
          )}
        </div>
      </div>
    </div>
  )
}

export default ValuationElement
