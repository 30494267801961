import React from 'react'

// Components
import { Icon, Tooltip } from '../../../../../../../../components'
import ValRstPlotModal from './ValRstPlotModal'
import ValuationProcessingModal from './ProcessValRstModal'

function ValRstBtn({ dataLayers, i, pcm }) {
  return (
    <>
      <div
        onClick={() => {
          pcm.setState({
            ...pcm.state,
            activeModal: `valRstPlotModal${i}`,
            activeModalData: { i },
          })
        }}>
        <Tooltip body='Generate valued dataset from original dataset.'>
          <Icon icon='works' ko={true} size='md' />
        </Tooltip>
      </div>
      {pcm.state.activeModal === `valRstPlotModal${i}` && (
        <ValRstPlotModal dataLayers={dataLayers} pcm={pcm} />
      )}
      {pcm.state.activeModal === `processValRstModal${i}` && (
        <ValuationProcessingModal dataLayers={dataLayers} pcm={pcm} />
      )}
    </>
  )
}

export default ValRstBtn
