import axios from "axios";

let config;
if (localStorage.getItem('token')) {
  config = {
    headers: {
      Authorization: `JWT ${localStorage.getItem('token')}`
    }
  }
} else {
  config = {}
}


export default {
  getNotifications: acctID => axios.get(`/api/accounts/notifications?id=${acctID}`, config),
  readNotifications: acctID => axios.put(`/api/accounts/notifications?id=${acctID}`, config),
  clearNotifications: acctID => axios.delete(`/api/accounts/notifications?id=${acctID}`, config),
  submitBetaTestingSettings: (accountID, data) => axios.post(`/api/accounts/beta_testing?id=${accountID}`, data, config),
  submitContactForm: data => axios.post("/api/accounts/contact", data, config),
  submitFeedback: data => axios.post("/api/accounts/feedback", data, config),
}