import React from "react";

/* Components */
import {
  Autocomplete,
  Button,
  Heading,
  ModalClose,
  Text,
} from "../../../../../../components";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

/* Methods */
import API from "../../../../API";

export default function AddCollaboratorsModal({ app }) {
  const addCollaborator = (e) => {
    e.preventDefault();
    const username = document.querySelector("#userCollaborator").value,
      mapID = app.state.mapID,
      req = { username, mapID };
    API.addCollaborator(req).then((res) => {
      app.setState({ editors: res.data }, () => app.toggleModal("addEditors"));
    });
  };

  const getAvailableCollaborators = (e) => {
    e.preventDefault();
    const queryType = "available_collaborators";
    API.getMapUsers(app.state.mapID, queryType, e.target.value).then((res) => {
      if (res.data[queryType]) {
        const availableCollaborators = res.data[queryType].map((user) => {
          return user;
        });
        app.setState({ availableCollaborators });
      }
    });
  };

  return (
    <Modal
      centered={true}
      isOpen={app.state.modals.addEditors}
      toggle={() => app.toggleModal("addEditors")}>
      <ModalHeader>
        <ModalClose cb={() => app.toggleModal("addEditors")} />
        Collaborators
      </ModalHeader>
      <ModalBody>
        <div className="u-pad-md">
          <Heading className="" number={3} color="secondary">
            Add New Collaborator
          </Heading>
          <Heading className="u-pad-top-md u-pad-btm-sm" number={5}>
            Search for collaborators by username:
          </Heading>
          <Autocomplete
            data={app.state.availableCollaborators}
            onChange={getAvailableCollaborators}
            app={app}
            ko={true}
            htmlID="userCollaborator"
          />
          <div className="u-pad-top-md">
            <Text number={2}>
              Users who are added as Collaborators are able to propose updates
              to vector layer geometries.
            </Text>
          </div>
          <div className="u-pad-vert-sm">
            <Text number={2}>
              These updates can be accepted by the map Owner to become saved
              changes, or they can be rejected.
            </Text>
          </div>
          <div className="u-mgn-top-md u-flex u-flex-justify-end">
            <div className="u-mgn-right-sm map__modal-button-container">
              <Button
                buttontype="secondary"
                buttonProps={{
                  onClick: () => app.toggleModal("addEditors"),
                }}
                size="inherit">
                Cancel
              </Button>
            </div>
            <div className="map__modal-button-container">
              <Button
                buttontype="primary"
                buttonProps={{ onClick: addCollaborator }}
                size="inherit">
                Add
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
