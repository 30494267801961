import { Menu, Text } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGMenu() {
  const content = {
    name: "Menu",
    variantName: "",
    description:`A standardized "menu". Elements will be renderd together in a contiguous list. Devs can choose whether a given element contains a link or triggers a callback.`,
    implementation: `A contiguous list`,
    propsTable: [
      [
        "options",
        "array of objects",
        `[{name: "Option 1", link: "/link/to/place"},{name: "option 2",onClick: someFunction}]`,
        "yes",
        "Each object in the array must include a name field. This will be the text displayed for that option. `link` and `onClick` are both optional. Link will go to the provided web address when clicked. onClick will trigger the provided function when the menu option is clicked.",
      ],
      [
        "dark",
        "Boolean",
        "true, false",
        "no",
        "If true, the menu will have a dark background.",
      ],
    ],
    codeExample: `
    import { Menu } from "/components";

    <Menu
    options={[
      {
        jsx: (<Text number={1} ko={true}>Option1</Text>),
        link: "#menu",
      },
      {
        jsx: (<Text number={1} ko={true}>Option 2 -- I console.log when clicked!</Text>),
        onClick: () => console.log("Option onClick example"),
      },
      {
        jsx: (<Text number={1} ko={true}>Option3</Text>),
        link: "#menu",
      },
      {
        jsx: (<Text number={1} ko={true}>Option4</Text>),
        link: "#menu",
      },
    ]}
  />
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-pad-sm u-full-width">
            <Menu
              options={[
                {
                  jsx: (<Text number={1} ko={true}>Option1</Text>),
                  link: "#menu",
                },
                {
                  jsx: (<Text number={1} ko={true}>Option 2 -- I console.log when clicked!</Text>),
                  onClick: () => console.log("Option onClick example"),
                },
                {
                  jsx: (<Text number={1} ko={true}>Option3</Text>),
                  link: "#menu",
                },
                {
                  jsx: (<Text number={1} ko={true}>Option4</Text>),
                  link: "#menu",
                },
              ]}
            />
          </div>

          <div className="u-pad-sm">
            <Menu
              options={[
                {
                  jsx: (<Text number={1} ko={true}>Option1</Text>),
                  link: "#menu",
                },
                {
                  jsx: (<Text number={1} ko={true}>Option 2 -- I console.log when clicked!</Text>),
                  onClick: () => console.log("Option onClick example"),
                },
                {
                  jsx: (<Text number={1} ko={true}>Option3</Text>),
                  link: "#menu",
                },
                {
                  jsx: (<Text number={1} ko={true}>Option4</Text>),
                  link: "#menu",
                },
              ]}
              dark={true}
              sm={true}
            />
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
