import { TextArea, Label } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGTextArea() {
  const content = {
    name: "TextArea",
    variantName: "",
    description: `(needs description)`,
    implementation: `(needs implementation instructions.)`,
    propsTable: [
      [
        "ko",
        "Boolean",
        "true, false",
        "no",
        "Set to true if the component will be displayed over a dark background.",
      ],
      [
        "...props",
        "JSX textarea attributes",
        "Any valid textarea JSX attributes",
        "no",
        "Store/set any desired attributes in the TextArea component. Setting columns and rows will be very useful for sizing the TextArea.",
      ],
      [
        "value",
        "string",
        "This is a default message...",
        "no",
        "String content can be preloaded into the textarea component.",
      ],
    ],
    codeExample: `
    import { TextArea } from "/components";
            
    return (
      <TextArea placeholder="placeholder" cols="60" rows="10"></TextArea>
    )
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-mgn-top-sm">
            <Label labelText="The component will grow with more text" />
            <TextArea placeholder="placeholder" cols="60" rows="10"></TextArea>
          </div>
          <div className="u-background-blue u-mgn-top-sm">
            <div className="u-pad-sm">
              <Label
                labelText="The component will grow with more text"
                ko={true}
              />
              <TextArea
                placeholder="placeholder"
                cols="60"
                rows="10"
                ko={true}></TextArea>
            </div>
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
