import React from "react";
import { Heading, LinkTop, Text } from "../../components";
import { useHistory } from "react-router-dom";

export default function NavbarDropdownMenuSectionItemTemplate({
  navbar,
  sectionItemData,
}) {
  const itemOption =
      sectionItemData.actionType === "none"
        ? ""
        : " nav-dropdown-menu__section__item--active",
    superButtonContent = (
      <div className={`nav-dropdown-menu__section__item${itemOption}`}>
        <Heading number={5} color="primary">
          {sectionItemData.heading}
        </Heading>
        <Text number={2}>{sectionItemData.description}</Text>
      </div>
    );
  const history = useHistory();
  return (
    <>
      {sectionItemData.actionType === "external link" && (
        <a
          href={sectionItemData.url}
          onClick={() => {
            navbar.setState({ ...navbar.state, dropdownMenuSource: "" });
          }}
          rel="noopener noreferrer"
          target="_blank"
          style={{ textDecoration: "none" }}>
          {superButtonContent}
        </a>
      )}
      {sectionItemData.actionType === "internal link" && (
        <div
          onClick={() => {
            if (sectionItemData.to.pathname === history.location.pathname) {
              navbar.setState({ ...navbar.state, dropdownMenuSource: "" });
            }
          }}>
          <LinkTop to={sectionItemData.to} style={{ textDecoration: "none" }}>
            {superButtonContent}
          </LinkTop>
        </div>
      )}
      {sectionItemData.actionType === "none" && <div>{superButtonContent}</div>}
    </>
  );
}
