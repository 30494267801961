import API from "../../auth/API";
import { Loading } from "../../components";
import {
  Footer,
  ProfilePageAccountSection,
  ProfilePageAppsSection,
  ProfilePageProfileSection,
  ProfilePagePublicSection,
  ProfilePageSideNav,
} from "..";
import React, { useState, useEffect } from "react";
import routes from "../../utils/paths/routes";
import { Redirect } from "react-router-dom";

export default function Profile({ account, auth, location }) {
  const initProfileState = {
      activeSection: "appsSection",
      appsSection: false,
      finishedLoading: false,
      profileSection: true,
      isOwner: false,
      accountSection: false,
      profileFields: {},
      textFieldEditing: false,
      username: "",
      isEmailValid: false,
      noUser: false,
    },
    [profileState, setProfileState] = useState(initProfileState),
    profile = {
      initState: initProfileState,
      setState: setProfileState,
      state: profileState,
    };

  let username = location.pathname.split("/");
  username = username[username.length - 1];
  useEffect(() => {
    if (username === account.state.username) {
      profile.setState({
        ...profile.state,
        finishedLoading: true,
        isOwner: true,
        profileFields: account.state,
      });
    } else {
      API.getAccount(username)
        .then((res) => {
          profile.setState({
            ...profile.state,
            finishedLoading: true,
            isOwner: false,
            profileFields: res.data,
          });
        })
        .catch((err) => {
          if (err) {
            profile.setState({ ...profile.state, noUser: true });
          }
        });
    }
  }, [account.state]);

  if (profile.state.noUser) {
    return <Redirect to={routes.root.path} />;
  }

  return (
    <>
      {!profile.state.finishedLoading ? (
        <div className="profile__loading">
          <Loading size="lg" />
        </div>
      ) : auth.state.isAuthenticated && account.state.username === username ? (
        <div className="profile">
          <ProfilePageSideNav profile={profile} />
          <div className="profile__content">
            {profile.state.activeSection === "appsSection" && (
              <ProfilePageAppsSection auth={auth} />
            )}
            {profile.state.activeSection === "profileSection" && (
              <ProfilePageProfileSection profile={profile} />
            )}
            {profile.state.activeSection === "accountSection" && (
              <ProfilePageAccountSection profile={profile} />
            )}
          </div>
        </div>
      ) : (
        <ProfilePagePublicSection profile={profile} />
      )}
      <Footer />
    </>
  );
}
