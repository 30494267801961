import React from "react";

/* Components */
import { Button, Icon, Text, Tooltip } from "../../../../components";

/* Methods */
import API from "../../API";

export default function CreateMapButton({ account, auth }) {
  function createMap() {
    const req = { user: account.state.username };
    API.createMap("map", req).then((res) => {
      window.open(`/apps/collaborativemapper/map?id=${res.data.id}`); // `window.location.href` needed to trigger new map load.
    });
  }

  const buttonContent = (
    <div className="u-flex u-flex-column u-flex-justify-center u-flex-align-center cm-dash__create">
      <Icon icon="plus" ko={true} size="lg" />
      <Text className="u-pad-top-sm" ko={true} number={1}>
        Create Map
      </Text>
    </div>
  );

  return (
    <div className="u-mgn-vert-md">
      {auth.state.isAuthenticated ? (
        <Button
          buttontype="primary"
          buttonProps={{ onClick: createMap }}
          className={"u-hover-color-yellow"}>
          {buttonContent}
        </Button>
      ) : (
        <Tooltip
          body={
            <Text number={1} ko={true}>
              Please log in to create a map.
            </Text>
          }>
          <Button
            buttontype="primary"
            className="cm-dash__create-map-button-icon-disable"
            disabled={true}>
            {buttonContent}
          </Button>
        </Tooltip>
      )}
    </div>
  );
}
