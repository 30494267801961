import React from 'react'

// Components
import { Icon, Tooltip } from '../../../../../../../../components'
import MetadataModal from './MetadataModal'

function MetadataBtn({ dataLayers, i, pcm }) {
  return (
    <>
      <div
        onClick={() => {
          pcm.setState({
            ...pcm.state,
            activeModal: `metadataModal${i}`,
            activeModalData: { i },
          })
        }}>
        <Tooltip body={`View data layer files and datasets attributes.`}>
          <Icon icon='document' ko={true} size='md' />
        </Tooltip>
      </div>
      {pcm.state.activeModal === `metadataModal${i}` && (
        <MetadataModal dataLayers={dataLayers} i={i} pcm={pcm} />
      )}
    </>
  )
}

export default MetadataBtn
