import React from 'react'

// Components
import { InputPassword, InputText, Label, TextArea } from '..'

export default function FormInput({
  errorMessages,
  inputClassName = '',
  componentProps = {},
  inputSmall,
  isErrorShowing,
  ko,
  labelClassName = '',
  labelProps = {},
  labelText,
  type,
  value,
}) {
  const inputComponent = {
    password: (
      <InputPassword
        className={inputClassName}
        inputProps={componentProps}
        ko={ko}
        small={inputSmall}
      />
    ),
    text: (
      <InputText
        className={inputClassName}
        inputProps={componentProps}
        ko={ko}
        small={inputSmall}
      />
    ),
    textarea: <TextArea ko={ko} textAreaProps={componentProps} value={value} />,
  }

  return (
    <>
      <div className='form__form-input-text__label-container'>
        <Label
          className={`${labelClassName} u-no-select`}
          ko={ko}
          labelProps={labelProps}
          labelText={labelText}
        />
      </div>
      {inputComponent[type]}
      {isErrorShowing && <>{errorMessages}</>}
    </>
  )
}
