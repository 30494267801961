import externalLinks from "../../utils/paths/externalLinks"
import routes from "../../utils/paths/routes"

export default {
  "Applications": [
    {
      label: routes.apps.collabDash.label,
      to: { pathname: routes.apps.collabHome.path },
      actionType: "internal link",
    },
    /* {
      label: routes.apps.cvm.label,
      linkPath: routes.apps.cvm.path,
      actionType: "internal link",
    }, */
    /* {
      label: routes.apps.earthenticateApp.label,
      to: { pathname: routes.apps.earthenticateHome.path },
      actionType: "internal link",
    }, */
    /* {
      label: routes.apps.esa.label,
      linkPath: routes.apps.esa.path,
      actionType: "internal link",
    }, */
    {
      label: routes.apps.habPatApp.label,
      to: { pathname: routes.apps.habPatHome.path },
      actionType: "internal link",
    },
  ],
  "Discover": [
    {
      label: routes.betaTesting.label,
      to: { pathname: routes.betaTesting.path.home },
      actionType: "internal link",
    },
  ],
  "Support": [
    /* {
      label: "Help Center",
      linkPath: "",
      actionType: "internal link",
    }, */
    /* {
      label: "Tutorials",
      linkPath: "",
      actionType: "internal link",
    }, */
    {
      label: routes.support.contact.label,
      to: { pathname: routes.support.contact.path, "valueSubject": "Reaching out", },
      actionType: "internal link",
    },
    {
      label: "Feedback",
      to: { pathname: routes.support.contact.path, "valueSubject": "Providing feedback", },
      actionType: "internal link",
    },
    {
      label: "Bug Report",
      to: { pathname: routes.support.contact.path, "valueSubject": "Reporting a bug", },
      actionType: "internal link",
    },
  ],
  "CCI": [
    {
      label: externalLinks.cci.landing.label,
      pathname: externalLinks.cci.landing.path,
      actionType: "external link",
      newTab: "true",
    },
    {
      label: externalLinks.cci.projects.label,
      pathname: externalLinks.cci.projects.path,
      actionType: "external link",
      newTab: "true",
    },
    {
      label: externalLinks.cci.publications.label,
      pathname: externalLinks.cci.publications.path,
      actionType: "external link",
      newTab: "true",
    },
  ],
  "Defenders of Wildlife": [
    {
      label: externalLinks.defenders.landing.label,
      pathname: externalLinks.defenders.landing.path,
      actionType: "external link",
      newTab: "true",
    },
    {
      label: externalLinks.defenders.ourWork.label,
      pathname: externalLinks.defenders.ourWork.path,
      actionType: "external link",
      newTab: "true",
    },
  ],
}