import { Autocomplete, Label } from "..";
import React from "react";
import { SGTemplate } from ".";
import species from "../../utils/endangeredSpecies";
export default function SGAutocomplete() {
  const content = {
    name: "Autocomplete",
    variantName: "",
    description: `When passed an array of strings, the autcomplete will "suggest" options to the user based on what they type into the input.`,
    implementation:
      "Used when the user is selecting among a defined list of options.",
    propsTable: [
      [
        "data",
        "array",
        `["value1", "value2", etc...]`,
        "yes",
        "The data to be searched through user input.",
      ],
      [
        "placeholder",
        "string",
        "any string",
        "no",
        "Text to be rendered in input until it is being used.",
      ],
      [
        "onChange",
        "function",
        "() => {}",
        "no",
        "Event function triggered when text is typed into the input. Event object will be the autocomplete element including the current input value.",
      ],
      [
        "...props",
        "JSX div attributes",
        `Any valid attribute that is applicable to a default JSX "div"
        element`,
        "no",
        "Provide elements as would normally be applied to a div element.",
      ],
    ],
    codeExample: `
    import { Autocomplete } from "/components";

    const species = ["Acanthorutilus handlirschi", "Accipiter fasciatus natalis", "Accipiter francesii pusillus"] 
    
    function autoCompleteEvent(e) {
      // Will print the autocomplete element including the current input value
      console.log("event", e)
    }
    
    <Autocomplete data={species} onChange={autoCompleteEvent} placeholder="Placeholder Text" htmlID="uniqueID" />
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-mgn-btm-sm">
            <Label labelText="Type a species name below to test the component!" />
          </div>
          <Autocomplete
            data={species}
            placeholder="Placeholder Text"
            htmlID="uniqueID"
          />
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
