import { Table, TableBody, TableHead, TableRow, TH, TD } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGTable() {

  const content = {
    name: "Table",
    variantName: "",
    description:`A customized version of default jsx table elements.`,
    implementation: `A convenient way to store data in a tabular format.`,
    propsTable: [
      [
        "children (Table)",
        "JSX",
        "Any valid JSX",
        "yes",
        `Table is not a self-closing component. Wrap any desired content within the open and close tags. Place other "Table-based" components here.`,
      ],
      [
        "children (TableHead)",
        "JSX",
        "Any valid JSX",
        "yes",
        "TableHead is not a self-closing component. Wrap any desired content within the open and close tags. Place other TH components here.",
      ],
      [
        "children (TableBody)",
        "JSX",
        "Any valid JSX",
        "yes",
        "TableBody is not a self-closing component. Wrap any desired content within the open and close tags.",
      ],
      [
        "children (TableRow)",
        "JSX",
        "Any valid JSX",
        "yes",
        "TableRow is not a self-closing component. Wrap any desired content within the open and close tags. Place other TD components here.",
      ],
      [
        "children (TH)",
        "JSX",
        "Any valid JSX",
        "yes",
        "TH is not a self-closing component. Wrap any desired content within the open and close tags.",
      ],
      [
        "children (TD)",
        "JSX",
        "Any valid JSX",
        "yes",
        "TD is not a self-closing component. Wrap any desired content within the open and close tags.",
      ],

    ],
    codeExample: `
    import { Table, TableBody TableHead, TH, TableRow, TD } from "/components";

    <Table>
      <TableHead>
        <TableRow>
          <TH>Column 1</TH>
          <TH>Column 2</TH>
          <TH>Column 3</TH>
          <TH>Column 4</TH>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TD>Data Value 1</TD>
          <TD>Data Value 2</TD>
          <TD>Data Value 3</TD>
          <TD>Data Value 4</TD>
        </TableRow>
      </TableBody>
    </Table>
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-pad-sm">
            <Table>
              <TableHead>
                <TableRow>
                  <TH>Column 1</TH>
                  <TH>Column 2</TH>
                  <TH>Column 3</TH>
                  <TH>Column 4</TH>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TD>Data Value 1</TD>
                  <TD>Data Value 2</TD>
                  <TD>Data Value 3</TD>
                  <TD>Data Value 4</TD>
                </TableRow>

                <TableRow>
                  <TD>Other Value 1</TD>
                  <TD>Other Value 2</TD>
                  <TD>Other Value 3</TD>
                  <TD>Other Value 4</TD>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
