import React from "react";

/* Components */
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  Button,
  Heading,
  ModalClose,
  Text,
} from "../../../../../../components";

/* Methods */
import API from "../../../../API";
import utils from "../../../../../../utils/utils";

export default function DeleteVectorLayerModal({ app, dataset }) {
  const deleteVectorLayer = () => {
    const { mapID } = app.state;
    const req = { dataset, mapID };
    const query = utils.objToQueryParams(req);
    API.deleteFeature(query).then(() => {
      app.renderFeatures();
      app.toggleModal(`deleteVectorLayer${dataset}`);
      utils.sendAlert(
        `Vector layer deleted.\n\nDepending on the amount of data in the layer, it may take a few moments before it is deleted from the map.`,
        "success",
      );
    });
  };

  return (
    <Modal
      centered={true}
      isOpen={app.state.modals[`deleteVectorLayer${dataset}`]}
      toggle={() => {
        app.toggleModal(`deleteVectorLayer${dataset}`);
      }}>
      <ModalHeader>
        <ModalClose
          cb={() => {
            app.toggleModal(`deleteVectorLayer${dataset}`);
          }}
        />
        Vector Layers
      </ModalHeader>
      <ModalBody>
        <div className="u-pad-md">
          <Heading className="u-pad-btm-md" number={3} color="secondary">
            Delete Vector Layer
          </Heading>
          <Heading number={5}>Deleting a vector layer is permanent.</Heading>
          <div className="u-pad-vert-sm">
            <Text number={1}>
              This action will delete vector layer "{`${dataset}`}" and
              associated files and data.
            </Text>
          </div>
          <div className="u-mgn-top-md u-flex u-flex-justify-end">
            <div className="u-mgn-right-sm map__modal-button-container">
              <Button
                buttontype="secondary"
                buttonProps={{
                  onClick: () => {
                    app.toggleModal(`deleteVectorLayer${dataset}`);
                  },
                }}
                size="inherit">
                Cancel
              </Button>
            </div>
            <div className="map__modal-button-container">
              <Button
                buttontype="primary"
                buttonProps={{
                  onClick: () => {
                    deleteVectorLayer();
                  },
                }}
                size="inherit">
                Delete
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
