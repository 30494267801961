import React from "react";

/* Components */
import { Text, Heading } from "../components";
import Footer from "../platform/footer/Footer";

export default function CreativeCommons() {
  return (
    <>
      <div className="u-mgn-vert-lg">
        <div className="u-layout-body-lg">
          <Heading number={2} className="u-align-center u-mgn-btm-md">
            Creative Commons Attribution-NonCommercial-ShareAlike 4.0
            International Public License
          </Heading>
          <Text number={1} className="u-mgn-btm-sm">
            By exercising the Licensed Rights (defined below), You accept and
            agree to be bound by the terms and conditions of this Creative
            Commons Attribution-NonCommercial-ShareAlike 4.0 International
            Public License ("Public License"). To the extent this Public License
            may be interpreted as a contract, You are granted the Licensed
            Rights in consideration of Your acceptance of these terms and
            conditions, and the Licensor grants You such rights in consideration
            of benefits the Licensor receives from making the Licensed Material
            available under these terms and conditions.
          </Text>
          <Heading number={4} className="u-mgn-top-md u-mgn-btm-sm">
            Section 1 – Definitions.
          </Heading>
          <div className="u-mgn-left-md">
            <div className="u-flex u-mgn-btm-sm">
              <Text number={1} className="u-mgn-right-sm">
                a.
              </Text>
              <Text number={1}>
                <span className="u-text-bold">Adapted Material</span> means
                material subject to Copyright and Similar Rights that is derived
                from or based upon the Licensed Material and in which the
                Licensed Material is translated, altered, arranged, transformed,
                or otherwise modified in a manner requiring permission under the
                Copyright and Similar Rights held by the Licensor. For purposes
                of this Public License, where the Licensed Material is a musical
                work, performance, or sound recording, Adapted Material is
                always produced where the Licensed Material is synched in timed
                relation with a moving image.
              </Text>
            </div>
            <div className="u-flex u-mgn-btm-sm">
              <Text number={1} className="u-mgn-right-sm">
                b.
              </Text>
              <Text number={1}>
                <span className="u-text-bold">Adapter's License</span> means the
                license You apply to Your Copyright and Similar Rights in Your
                contributions to Adapted Material in accordance with the terms
                and conditions of this Public License.
              </Text>
            </div>
            <div className="u-flex u-mgn-btm-sm">
              <Text number={1} className="u-mgn-right-sm">
                c.
              </Text>
              <Text number={1}>
                <span className="u-text-bold">BY-NC-SA Compatible License</span>{" "}
                means a license listed at
                creativecommons.org/compatiblelicenses, approved by Creative
                Commons as essentially the equivalent of this Public License.
              </Text>
            </div>
            <div className="u-flex u-mgn-btm-sm">
              <Text number={1} className="u-mgn-right-sm">
                d.
              </Text>
              <Text number={1}>
                <span className="u-text-bold">
                  Copyright and Similar Rights
                </span>{" "}
                means copyright and/or similar rights closely related to
                copyright including, without limitation, performance, broadcast,
                sound recording, and Sui Generis Database Rights, without regard
                to how the rights are labeled or categorized. For purposes of
                this Public License, the rights specified in Section 2(b)(1)-(2)
                are not Copyright and Similar Rights.
              </Text>
            </div>
            <div className="u-flex u-mgn-btm-sm">
              <Text number={1} className="u-mgn-right-sm">
                e.
              </Text>
              <Text number={1}>
                <span className="u-text-bold">
                  Effective Technological Measures
                </span>{" "}
                means those measures that, in the absence of proper authority,
                may not be circumvented under laws fulfilling obligations under
                Article 11 of the WIPO Copyright Treaty adopted on December 20,
                1996, and/or similar international agreements.
              </Text>
            </div>
            <div className="u-flex u-mgn-btm-sm">
              <Text number={1} className="u-mgn-right-sm">
                f.
              </Text>
              <Text number={1}>
                <span className="u-text-bold">Exceptions and Limitations</span>{" "}
                means fair use, fair dealing, and/or any other exception or
                limitation to Copyright and Similar Rights that applies to Your
                use of the Licensed Material.
              </Text>
            </div>
            <div className="u-flex u-mgn-btm-sm">
              <Text number={1} className="u-mgn-right-sm">
                g.
              </Text>
              <Text number={1}>
                <span className="u-text-bold">License Elements</span> means the
                license attributes listed in the name of a Creative Commons
                Public License. The License Elements of this Public License are
                Attribution, NonCommercial, and ShareAlike.
              </Text>
            </div>
            <div className="u-flex u-mgn-btm-sm">
              <Text number={1} className="u-mgn-right-sm">
                h.
              </Text>
              <Text number={1}>
                <span className="u-text-bold">Licensed Material</span> means the
                artistic or literary work, database, or other material to which
                the Licensor applied this Public License.
              </Text>
            </div>
            <div className="u-flex u-mgn-btm-sm">
              <Text number={1} className="u-mgn-right-sm">
                i.
              </Text>
              <Text number={1}>
                <span className="u-text-bold">Licensed Rights</span> means the
                rights granted to You subject to the terms and conditions of
                this Public License, which are limited to all Copyright and
                Similar Rights that apply to Your use of the Licensed Material
                and that the Licensor has authority to license.
              </Text>
            </div>
            <div className="u-flex u-mgn-btm-sm">
              <Text number={1} className="u-mgn-right-sm">
                j.
              </Text>
              <Text number={1}>
                <span className="u-text-bold">Licensor</span> means the
                individual(s) or entity(ies) granting rights under this Public
                License.
              </Text>
            </div>
            <div className="u-flex u-mgn-btm-sm">
              <Text number={1} className="u-mgn-right-sm">
                k.
              </Text>
              <Text number={1}>
                <span className="u-text-bold">NonCommercial</span> means not
                primarily intended for or directed towards commercial advantage
                or monetary compensation. For purposes of this Public License,
                the exchange of the Licensed Material for other material subject
                to Copyright and Similar Rights by digital file-sharing or
                similar means is NonCommercial provided there is no payment of
                monetary compensation in connection with the exchange.
              </Text>
            </div>
            <div className="u-flex u-mgn-btm-sm">
              <Text number={1} className="u-mgn-right-sm">
                l.
              </Text>
              <Text number={1}>
                <span className="u-text-bold">Share</span> means to provide
                material to the public by any means or process that requires
                permission under the Licensed Rights, such as reproduction,
                public display, public performance, distribution, dissemination,
                communication, or importation, and to make material available to
                the public including in ways that members of the public may
                access the material from a place and at a time individually
                chosen by them.means not primarily intended for or directed
                towards commercial advantage or monetary compensation. For
                purposes of this Public License, the exchange of the Licensed
                Material for other material subject to Copyright and Similar
                Rights by digital file-sharing or similar means is NonCommercial
                provided there is no payment of monetary compensation in
                connection with the exchange.
              </Text>
            </div>
            <div className="u-flex u-mgn-btm-sm">
              <Text number={1} className="u-mgn-right-sm">
                m.
              </Text>
              <Text number={1}>
                <span className="u-text-bold">Sui Generis Database Rights</span>{" "}
                means rights other than copyright resulting from Directive
                96/9/EC of the European Parliament and of the Council of 11
                March 1996 on the legal protection of databases, as amended
                and/or succeeded, as well as other essentially equivalent rights
                anywhere in the world.
              </Text>
            </div>
            <div className="u-flex u-mgn-btm-sm">
              <Text number={1} className="u-mgn-right-sm">
                n.
              </Text>
              <Text number={1}>
                <span className="u-text-bold">You</span> means the individual or
                entity exercising the Licensed Rights under this Public License.
                Your has a corresponding meaning.
              </Text>
            </div>
          </div>
          <Heading number={4} className="u-mgn-top-md">
            Section 2 – Scope.
          </Heading>
          <div className="u-mgn-left-md">
            <div className="u-mgn-top-md u-mgn-btm-sm">
              <Text number={1} className="u-mgn-right-sm">
                a. <span className="u-text-bold">License grant.</span>
              </Text>
            </div>
            <div className="u-flex u-mgn-left-md">
              <Text number={1}>1.</Text>
              <Text number={1}>
                &nbsp; Subject to the terms and conditions of this Public
                License, the Licensor hereby grants You a worldwide,
                royalty-free, non-sublicensable, non-exclusive, irrevocable
                license to exercise the Licensed Rights in the Licensed Material
                to:
              </Text>
            </div>
            <div className="u-mgn-left-md">
              <div className="u-flex u-mgn-left-md">
                <Text number={1}>A.</Text>
                <Text number={1}>
                  &nbsp; reproduce and Share the Licensed Material, in whole or
                  in part, for NonCommercial purposes only; and
                </Text>
              </div>
              <div className="u-flex u-mgn-left-md">
                <Text number={1}>B.</Text>
                <Text number={1}>
                  &nbsp; produce, reproduce, and Share Adapted Material for
                  NonCommercial purposes only.
                </Text>
              </div>
            </div>
            <div className="u-flex u-mgn-left-md u-mgn-top-sm">
              <Text number={1}>2.</Text>
              <Text number={1}>
                &nbsp;{" "}
                <span className="u-text-underline">
                  Exceptions and Limitations.
                </span>{" "}
                For the avoidance of doubt, where Exceptions and Limitations
                apply to Your use, this Public License does not apply, and You
                do not need to comply with its terms and conditions.
              </Text>
            </div>
            <div className="u-flex u-mgn-left-md u-mgn-top-sm">
              <Text number={1}>3.</Text>
              <Text number={1}>
                &nbsp; <span className="u-text-underline">Term.</span> The term
                of this Public License is specified in Section 6(a).
              </Text>
            </div>
            <div className="u-flex u-mgn-left-md u-mgn-top-sm">
              <Text number={1}>4.</Text>
              <Text number={1}>
                &nbsp;{" "}
                <span className="u-text-underline">
                  Media and formats; technical modifications allowed.
                </span>{" "}
                The Licensor authorizes You to exercise the Licensed Rights in
                all media and formats whether now known or hereafter created,
                and to make technical modifications necessary to do so. The
                Licensor waives and/or agrees not to assert any right or
                authority to forbid You from making technical modifications
                necessary to exercise the Licensed Rights, including technical
                modifications necessary to circumvent Effective Technological
                Measures. For purposes of this Public License, simply making
                modifications authorized by this Section 2(a)(4) never produces
                Adapted Material.
              </Text>
            </div>
            <div className="u-flex u-mgn-left-md u-mgn-vert-sm">
              <Text number={1}>5.</Text>
              <Text number={1}>
                &nbsp;{" "}
                <span className="u-text-underline">Downstream recipients.</span>
              </Text>
            </div>
            <div className="u-mgn-left-md">
              <div className="u-flex u-mgn-left-md">
                <Text number={1}>A.</Text>
                <Text number={1}>
                  &nbsp;{" "}
                  <span className="u-text-underline">
                    Offer from the Licensor – Licensed Material.
                  </span>{" "}
                  Every recipient of the Licensed Material automatically
                  receives an offer from the Licensor to exercise the Licensed
                  Rights under the terms and conditions of this Public License.
                </Text>
              </div>
              <div className="u-flex u-mgn-left-md">
                <Text number={1}>B.</Text>
                <Text number={1}>
                  &nbsp;{" "}
                  <span className="u-text-underline">
                    Additional offer from the Licensor – Adapted Material.
                  </span>{" "}
                  Every recipient of Adapted Material from You automatically
                  receives an offer from the Licensor to exercise the Licensed
                  Rights in the Adapted Material under the conditions of the
                  Adapter’s License You apply.
                </Text>
              </div>
              <div className="u-flex u-mgn-left-md">
                <Text number={1}>C.</Text>
                <Text number={1}>
                  &nbsp;{" "}
                  <span className="u-text-underline">
                    No downstream restrictions.
                  </span>{" "}
                  You may not offer or impose any additional or different terms
                  or conditions on, or apply any Effective Technological
                  Measures to, the Licensed Material if doing so restricts
                  exercise of the Licensed Rights by any recipient of the
                  Licensed Material.
                </Text>
              </div>
            </div>
            <div className="u-flex u-mgn-left-md u-mgn-top-sm">
              <Text number={1}>6.</Text>
              <Text number={1}>
                &nbsp; <span className="u-text-underline">No endorsement.</span>{" "}
                Nothing in this Public License constitutes or may be construed
                as permission to assert or imply that You are, or that Your use
                of the Licensed Material is, connected with, or sponsored,
                endorsed, or granted official status by, the Licensor or others
                designated to receive attribution as provided in Section
                3(a)(1)(A)(i).
              </Text>
            </div>
            <div className="u-mgn-top-md">
              <Text number={1} className="u-mgn-right-sm">
                b. <span className="u-text-bold">Other rights.</span>
              </Text>
            </div>
            <div className="u-flex u-mgn-left-md u-mgn-top-sm">
              <Text number={1}>1.</Text>
              <Text number={1}>
                Moral rights, such as the right of integrity, are not licensed
                under this Public License, nor are publicity, privacy, and/or
                other similar personality rights; however, to the extent
                possible, the Licensor waives and/or agrees not to assert any
                such rights held by the Licensor to the limited extent necessary
                to allow You to exercise the Licensed Rights, but not otherwise.
              </Text>
            </div>
            <div className="u-flex u-mgn-left-md u-mgn-top-sm">
              <Text number={1}>2.</Text>
              <Text number={1}>
                Patent and trademark rights are not licensed under this Public
                License.
              </Text>
            </div>
            <div className="u-flex u-mgn-left-md u-mgn-top-sm">
              <Text number={1}>3.</Text>
              <Text number={1}>
                To the extent possible, the Licensor waives any right to collect
                royalties from You for the exercise of the Licensed Rights,
                whether directly or through a collecting society under any
                voluntary or waivable statutory or compulsory licensing scheme.
                In all other cases the Licensor expressly reserves any right to
                collect such royalties, including when the Licensed Material is
                used other than for NonCommercial purposes.
              </Text>
            </div>
          </div>
          <Heading number={4} className="u-mgn-top-md u-mgn-btm-sm">
            Section 3 – License Conditions.
          </Heading>
          <Text number={1}>
            Your exercise of the Licensed Rights is expressly made subject to
            the following conditions.
          </Text>
          <div className="u-mgn-left-md">
            <div className="u-mgn-top-md u-mgn-btm-sm">
              <Text number={1} className="u-mgn-right-sm">
                a. <span className="u-text-bold">Attribution.</span>
              </Text>
            </div>
            <div className="u-mgn-left-md">
              <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
                <Text number={1}>1.</Text>
                <Text number={1}>
                  If You Share the Licensed Material (including in modified
                  form), You must:
                </Text>
              </div>
              <div className="u-mgn-left-md">
                <div className="u-mgn-left-md">
                  <div className="u-flex">
                    <Text number={1}>A.&nbsp;</Text>
                    <Text number={1}>
                      retain the following if it is supplied by the Licensor
                      with the Licensed Material:
                    </Text>
                  </div>
                  <div className="u-mgn-left-md">
                    <div className="u-flex u-mgn-btm-sm">
                      <Text number={1}>i.&nbsp;</Text>
                      <Text number={1}>
                        identification of the creator(s) of the Licensed
                        Material and any others designated to receive
                        attribution, in any reasonable manner requested by the
                        Licensor (including by pseudonym if designated);
                      </Text>
                    </div>
                    <div className="u-flex u-mgn-btm-sm">
                      <Text number={1}>ii.&nbsp;</Text>
                      <Text number={1}>a copyright notice;</Text>
                    </div>
                    <div className="u-flex u-mgn-btm-sm">
                      <Text number={1}>iii.&nbsp;</Text>
                      <Text number={1}>
                        a notice that refers to this Public License;
                      </Text>
                    </div>
                    <div className="u-flex u-mgn-btm-sm">
                      <Text number={1}>iv.&nbsp;</Text>
                      <Text number={1}>
                        a notice that refers to the disclaimer of warranties;
                      </Text>
                    </div>
                    <div className="u-flex u-mgn-btm-sm">
                      <Text number={1}>v.&nbsp;</Text>
                      <Text number={1}>
                        a URI or hyperlink to the Licensed Material to the
                        extent reasonably practicable;
                      </Text>
                    </div>
                  </div>
                </div>

                <div className="u-flex u-mgn-left-md">
                  <Text number={1}>B.&nbsp;</Text>
                  <Text number={1}>
                    indicate if You modified the Licensed Material and retain an
                    indication of any previous modifications; and
                  </Text>
                </div>
                <div className="u-flex u-mgn-left-md">
                  <Text number={1}>C.&nbsp;</Text>
                  <Text number={1}>
                    indicate the Licensed Material is licensed under this Public
                    License, and include the text of, or the URI or hyperlink
                    to, this Public License.
                  </Text>
                </div>
              </div>
              <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
                <Text number={1}>2.&nbsp;</Text>
                <Text number={1}>
                  You may satisfy the conditions in Section 3(a)(1) in any
                  reasonable manner based on the medium, means, and context in
                  which You Share the Licensed Material. For example, it may be
                  reasonable to satisfy the conditions by providing a URI or
                  hyperlink to a resource that includes the required
                  information.
                </Text>
              </div>
              <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
                <Text number={1}>3.&nbsp;</Text>
                <Text number={1}>
                  If requested by the Licensor, You must remove any of the
                  information required by Section 3(a)(1)(A) to the extent
                  reasonably practicable.
                </Text>
              </div>
            </div>
            <div className="u-mgn-top-md">
              <Text number={1} className="u-mgn-right-sm">
                b. <span className="u-text-bold">ShareAlike.</span>
              </Text>
            </div>
            <Text
              number={1}
              className="u-mgn-btm-md u-mgn-top-sm u-mgn-left-sm">
              In addition to the conditions in Section 3(a), if You Share
              Adapted Material You produce, the following conditions also apply.
            </Text>
            <div className="u-mgn-left-md">
              <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
                <Text number={1}>1.&nbsp;</Text>
                <Text number={1}>
                  The Adapter’s License You apply must be a Creative Commons
                  license with the same License Elements, this version or later,
                  or a BY-NC-SA Compatible License.
                </Text>
              </div>
              <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
                <Text number={1}>2.&nbsp;</Text>
                <Text number={1}>
                  You must include the text of, or the URI or hyperlink to, the
                  Adapter's License You apply. You may satisfy this condition in
                  any reasonable manner based on the medium, means, and context
                  in which You Share Adapted Material.
                </Text>
              </div>
              <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
                <Text number={1}>3.&nbsp;</Text>
                <Text number={1}>
                  You may not offer or impose any additional or different terms
                  or conditions on, or apply any Effective Technological
                  Measures to, Adapted Material that restrict exercise of the
                  rights granted under the Adapter's License You apply.
                </Text>
              </div>
            </div>
          </div>
          <Heading number={4} className="u-mgn-top-md u-mgn-btm-sm">
            Section 4 – Sui Generis Database Rights.
          </Heading>
          <Text number={1} className="u-mgn-vert-md u-mgn-left-sm">
            Where the Licensed Rights include Sui Generis Database Rights that
            apply to Your use of the Licensed Material:
          </Text>
          <div className="u-mgn-left-md">
            <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
              <Text number={1}>a.&nbsp;</Text>
              <Text number={1}>
                for the avoidance of doubt, Section 2(a)(1) grants You the right
                to extract, reuse, reproduce, and Share all or a substantial
                portion of the contents of the database for NonCommercial
                purposes only;
              </Text>
            </div>
            <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
              <Text number={1}>b.&nbsp;</Text>
              <Text number={1}>
                if You include all or a substantial portion of the database
                contents in a database in which You have Sui Generis Database
                Rights, then the database in which You have Sui Generis Database
                Rights (but not its individual contents) is Adapted Material,
                including for purposes of Section 3(b); and
              </Text>
            </div>
            <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
              <Text number={1}>c.&nbsp;</Text>
              <Text number={1}>
                You must comply with the conditions in Section 3(a) if You Share
                all or a substantial portion of the contents of the database.
              </Text>
            </div>
          </div>
          <Text number={1} className="u-mgn-vert-md">
            For the avoidance of doubt, this Section 4 supplements and does not
            replace Your obligations under this Public License where the
            Licensed Rights include other Copyright and Similar Rights.
          </Text>
          <Heading number={4} className="u-mgn-vert-md">
            Section 5 – Disclaimer of Warranties and Limitation of Liability.
          </Heading>
          <div className="u-mgn-left-md">
            <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
              <Text number={1} className="u-text-bold">
                a.&nbsp;
              </Text>
              <Text number={1} className="u-text-bold">
                Unless otherwise separately undertaken by the Licensor, to the
                extent possible, the Licensor offers the Licensed Material as-is
                and as-available, and makes no representations or warranties of
                any kind concerning the Licensed Material, whether express,
                implied, statutory, or other. This includes, without limitation,
                warranties of title, merchantability, fitness for a particular
                purpose, non-infringement, absence of latent or other defects,
                accuracy, or the presence or absence of errors, whether or not
                known or discoverable. Where disclaimers of warranties are not
                allowed in full or in part, this disclaimer may not apply to
                You.
              </Text>
            </div>
            <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
              <Text number={1} className="u-text-bold">
                b.&nbsp;
              </Text>
              <Text number={1} className="u-text-bold">
                To the extent possible, in no event will the Licensor be liable
                to You on any legal theory (including, without limitation,
                negligence) or otherwise for any direct, special, indirect,
                incidental, consequential, punitive, exemplary, or other losses,
                costs, expenses, or damages arising out of this Public License
                or use of the Licensed Material, even if the Licensor has been
                advised of the possibility of such losses, costs, expenses, or
                damages. Where a limitation of liability is not allowed in full
                or in part, this limitation may not apply to You.
              </Text>
            </div>
            <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
              <Text number={1}>c.&nbsp;</Text>
              <Text number={1}>
                The disclaimer of warranties and limitation of liability
                provided above shall be interpreted in a manner that, to the
                extent possible, most closely approximates an absolute
                disclaimer and waiver of all liability.
              </Text>
            </div>
          </div>
          <Heading number={4} className="u-mgn-vert-md">
            Section 6 – Term and Termination.
          </Heading>
          <div className="u-mgn-left-md">
            <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
              <Text number={1}>a.&nbsp;</Text>
              <Text number={1}>
                This Public License applies for the term of the Copyright and
                Similar Rights licensed here. However, if You fail to comply
                with this Public License, then Your rights under this Public
                License terminate automatically.
              </Text>
            </div>
            <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
              <Text number={1}>b.&nbsp;</Text>
              <Text number={1}>
                Where Your right to use the Licensed Material has terminated
                under Section 6(a), it reinstates:
              </Text>
            </div>
            <div className="u-mgn-left-md">
              <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
                <Text number={1}>1.&nbsp;</Text>
                <Text number={1}>
                  automatically as of the date the violation is cured, provided
                  it is cured within 30 days of Your discovery of the violation;
                  or
                </Text>
              </div>
              <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
                <Text number={1}>2.&nbsp;</Text>
                <Text number={1}>
                  upon express reinstatement by the Licensor.
                </Text>
              </div>
            </div>
            <Text number={1} className="u-mgn-left-sm">
              For the avoidance of doubt, this Section 6(b) does not affect any
              right the Licensor may have to seek remedies for Your violations
              of this Public License.
            </Text>
            <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
              <Text number={1}>c.&nbsp;</Text>
              <Text number={1}>
                For the avoidance of doubt, the Licensor may also offer the
                Licensed Material under separate terms or conditions or stop
                distributing the Licensed Material at any time; however, doing
                so will not terminate this Public License.
              </Text>
            </div>
            <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
              <Text number={1}>d.&nbsp;</Text>
              <Text number={1}>
                Sections 1, 5, 6, 7, and 8 survive termination of this Public
                License.
              </Text>
            </div>
          </div>
          <Heading number={4} className="u-mgn-vert-md">
            Section 7 – Other Terms and Conditions.
          </Heading>
          <div className="u-mgn-left-md">
            <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
              <Text number={1}>a.&nbsp;</Text>
              <Text number={1}>
                The Licensor shall not be bound by any additional or different
                terms or conditions communicated by You unless expressly agreed.
              </Text>
            </div>
            <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
              <Text number={1}>b.&nbsp;</Text>
              <Text number={1}>
                Any arrangements, understandings, or agreements regarding the
                Licensed Material not stated herein are separate from and
                independent of the terms and conditions of this Public License.
              </Text>
            </div>
          </div>
          <Heading number={4} className="u-mgn-vert-md">
            Section 8 – Interpretation.
          </Heading>
          <div className="u-mgn-left-md">
            <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
              <Text number={1}>a.&nbsp;</Text>
              <Text number={1}>
                For the avoidance of doubt, this Public License does not, and
                shall not be interpreted to, reduce, limit, restrict, or impose
                conditions on any use of the Licensed Material that could
                lawfully be made without permission under this Public License.
              </Text>
            </div>
            <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
              <Text number={1}>b.&nbsp;</Text>
              <Text number={1}>
                To the extent possible, if any provision of this Public License
                is deemed unenforceable, it shall be automatically reformed to
                the minimum extent necessary to make it enforceable. If the
                provision cannot be reformed, it shall be severed from this
                Public License without affecting the enforceability of the
                remaining terms and conditions.
              </Text>
            </div>
            <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
              <Text number={1}>c.&nbsp;</Text>
              <Text number={1}>
                No term or condition of this Public License will be waived and
                no failure to comply consented to unless expressly agreed to by
                the Licensor.
              </Text>
            </div>
            <div className="u-flex u-mgn-top-sm u-mgn-btm-sm">
              <Text number={1}>d.&nbsp;</Text>
              <Text number={1}>
                Nothing in this Public License constitutes or may be interpreted
                as a limitation upon, or waiver of, any privileges and
                immunities that apply to the Licensor or You, including from the
                legal processes of any jurisdiction or authority.
              </Text>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
