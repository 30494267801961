import React from "react";

export default function TextArea({ ko, textAreaProps = {}, height, value }) {
  let className = "textarea";
  if (ko) {
    className += " textarea--ko ";
  }
  return (
    <>
      <textarea
        className={`${className} u-pre-wrap`}
        {...textAreaProps}
        spellCheck={false}
        id=""
        style={height ? { height } : { height: "20rem" }}>
        {value}
      </textarea>
    </>
  );
}
