import React from 'react'

// Components
import { Icon, Tooltip } from '../../../../../../../components'

// Utilities
import APIreqs from '../../../../../helpers/API'
import utils from '../../../../../../../utils/utils'

function CycleVisibilityBtn({ dataLayers, i }) {
  async function cycleDataLayerVisibility() {
    function setDataLayerVisibility() {
      dataLayers.setData(
        dataLayers.data.map((dataLayer) => {
          if (dataLayer.id === dataLayers.data[i].id) {
            dataLayer.is_visible = !dataLayer.is_visible
          }
          return dataLayer
        }),
      )
    }

    async function putDataLayerVisibility() {
      try {
        await APIreqs.dataLayers.putAttrs(dataLayers.data[i].id, [
          { is_visible: !dataLayers.data[i].is_visible },
        ])
      } catch {
        utils.sendAlert(
          "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'put CVM data layer is_visible' error.",
          'error',
        )
      }
    }

    try {
      await putDataLayerVisibility()
      setDataLayerVisibility()
    } catch {
      utils.sendAlert(
        "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'set CVM data layer is_visible' error.",
        'error',
      )
    }
  }

  return (
    <div
      onClick={() => {
        cycleDataLayerVisibility()
      }}>
      {dataLayers.data[i].is_visible ? (
        <Tooltip body='Hide image on map.'>
          <Icon icon='visible' key='visibleIcon' ko={true} size='md' />
        </Tooltip>
      ) : (
        <Tooltip body='Show image on map.'>
          <Icon icon='invisible' key='invisibleIcon' ko={true} size='md' />
        </Tooltip>
      )}
    </div>
  )
}

export default CycleVisibilityBtn
