import { ModalClose, Button } from "..";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React, { useState } from "react";
import { SGTemplate } from ".";

export default function SGModal() {
  const initState = {
    modalOpen: false,
  };

  const [state, setState] = useState(initState);

  function toggleModal() {
    setState({ ...state, modalOpen: !state.modalOpen });
  }

  const content = {
    name: "Modal",
    variantName: "",
    description: `A popup that dominates the UI of a page when triggered, rendering
    above all other content and darkening the interface below it when
    active.`,
    implementation: `Open state is toggled via true/false. The best implementation is to track this value with state.`,
    propsTable: [
      [
        "isOpen (Modal)",
        "boolean",
        "true, false",
        "yes",
        "Determines whether or not the modal is active/open.",
      ],
      [
        "toggle (Modal)",
        "function",
        "modal toggling function",
        "yes",
        "The function passed as a prop here should set the modal's openstate to its opposite. In other words, it should toggle the modal's open state.",
      ],
      [
        "children (Modal)",
        "JSX",
        "Any valid JSX",
        "yes",
        "Modal is not a self-closing component. Wrap any desired content within the open and close tags. All children will only be displayed when the modal is open/active.",
      ],
      [
        "children (ModalHeader)",
        "JSX",
        "Any valid JSX",
        "yes",
        "Modal Header is not a self-closing component. Wrap any desired content within the open and close tags. Generally a heading will go here. In most cases, it is best to just write the desired heading directly in between the ModalHeader tags.",
      ],
      [
        "cb (ModalClose)",
        "function",
        "modal toggling function",
        "yes",
        "Text to be displayed on the dropdown button (the button that, when clicked, opens the dropdown. The function passed as a prop here should set the modal's open state to its opposite. In other words, it should toggle the modal's open state.",
      ],
      [
        "children (ModalBody)",
        "JSX",
        "Any valid JSX",
        "yes",
        "ModalBody is not a self-closing component. Wrap any desired content within the open and close tags. All children will only be displayed when the modal is open/active.",
      ],
      [
        "children (ModalFooter)",
        "JSX",
        "Any valid JSX",
        "yes",
        "ModalFooter is not a self-closing component. Wrap any desired content within the open and close tags. All children will only be displayed when the modal is open/active.",
      ],
    ],
    codeExample: `
    import { ModalClose } from "/components";
    import {
      Modal,
      ModalHeader,
      ModalBody,
      ModalFooter
    } from "reactstrap";
    
    const initState = {
      modalOpen: false
    }
    
    const [state, setState] = useState(initState)
    
    
    <Modal isOpen={state.modalOpen} toggle={() => setState({ ...state, modalOpen: !state.modalOpen })}>
    <ModalHeader>
        <ModalClose cb={() => setState({ ...state, modalOpen: !state.modalOpen })}/>
        Modal Header
    </ModalHeader>
      <ModalBody>
        <div className="u-pad-sm">
          <p className="paragraph__1">Modal Content</p>
        </div>
        <ModalFooter>
        <div className="u-flex u-flex-justify-center">
          <Button
            buttontype="primary"
            buttonProps={{ onClick: toggleModal }}>
            Close Modal
          </Button>
        </div>
      </ModalFooter>
      </ModalBody>
    </Modal>
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-pad-sm">
            <Button buttontype="primary" buttonProps={{ onClick: toggleModal }}>
              Open Modal
            </Button>

            <Modal isOpen={state.modalOpen} toggle={toggleModal}>
              <ModalHeader>
                <ModalClose cb={toggleModal} />
                Modal Header
              </ModalHeader>
              <ModalBody>
                <div className="u-pad-sm">
                  <p className="paragraph__1">Modal Content</p>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="u-flex u-flex-justify-center">
                  <Button
                    buttontype="primary"
                    buttonProps={{ onClick: toggleModal }}>
                    Close Modal
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
