export default {
  cci: {
    about: { label: "About", path: "https://defenders-cci.org/about/" },
    contact: { label: "Contact", path: "https://defenders-cci.org/contact/" },
    landing: { label: "Landing Page", path: "https://defenders-cci.org/" },
    projects: { label: "Projects", path: "https://defenders-cci.org/project/" },
    publications: { label: "Publications & Products", path: "https://defenders-cci.org/publication/" },
  },
  defenders: {
    about: { label: "About", path: "https://defenders.org/about" },
    cookiePolicy: { label: "Cookie Policy", path: "https://defenders.org/defenders-of-wildlife-cookie-policy" },
    donate: { label: "Donate", path: "https://defenders.org/support-our-work" },
    ourWork: { label: "Our Work", path: "https://defenders.org/our-work" },
    privacyPolicy: { label: "Privacy Policy", path: "https://defenders.org/defenders-wildlife-privacy-policy" },
    jobs: { label: "Jobs", path: "https://defenders.org/jobs" },
    landing: { label: "Landing Page", path: "https://defenders.org/" },
  },
}