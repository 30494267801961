import React from "react";
import { InputSliderH, Text } from "..";
import { SGTemplate } from ".";

export default function SGInputSliderH() {
  const content = {
    name: "",
    variantName: "InputSliderH",
    description:
      "An element that enables the setting of a numerical value, between a minimum value and maximum value, using a horizontally oriented, slider UI input.",
    implementation:
      "Instantiate as a self-closing component.  Control width of InputSliderH using the css width attribute of the element's parent <div>.",
    propsTable: [
      [
        "...props",
        "hash",
        "disabled={true}",
        "no",
        "Add html attributes to `...props` that would be appropriate for an html `<input type='range' {...props}>` element.",
      ],
      [
        "ko",
        "boolean",
        "true",
        "no",
        "Setting `ko={true}` enables 'knock-out' style, useful for placement of the element on a dark background.",
      ],
    ],
    links: [
      {
        "MDN: <Input type='range'>":
          "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/range",
      },
      {
        "Zeplin: form elements - slider":
          "https://app.zeplin.io/project/5f45822488016a3407dea78c/screen/5feb52e5953d6455bee2d23a",
      },
    ],
    codeExample: `import {InputSliderH} from "frontend/src/components";

export default function InputSliderHContainer(){
  return(
    <>
      <InputSliderH
        disabled={state.isDisabled}
        ko={true}
      />
    </>
  )
}`,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <>
          <div>
            <Text number={1}>Active elements</Text>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100px" }} className="u-pad-sm">
                <InputSliderH />
              </div>
              <div
                style={{ width: "100px" }}
                className="u-pad-sm u-background-darkblue">
                <InputSliderH ko={true} />
              </div>
            </div>
          </div>
          <div>
            <Text number={1}>Disabled elements</Text>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100px" }} className="u-pad-sm">
                <InputSliderH disabled={true} />
              </div>
              <div
                style={{ width: "100px" }}
                className="u-pad-sm u-background-darkblue">
                <InputSliderH ko={true} disabled={true} />
              </div>
            </div>
          </div>
        </>
      }
      propsTableContents={content.propsTable}
      links={content.links}
      codeExample={content.codeExample}
    />
  );
}
