import { Button } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGButton() {
  const content = {
    name: "Button",
    variantName: "",
    description:
      "Acts very similarly to a default JSX button element. Use props to determine the type of button to be used.",
    implementation: "To be used as a defualt JSX button.",
    propsTable: [
      [
        "buttonProps",
        "object",
        "Define key values pairs in the object that correspond to attributes that would be passed to a standard button.",
        "no",
        "Example:\nbuttonProps={{onClick:doStuff}}",
      ],
      [
        "buttontype",
        "string",
        `"primary", "secondary", or "text"`,
        "yes",
        "The prop passed here will determine the type of button that is rendered.",
      ],
      [
        "children",
        "JSX",
        "Any valid JSX",
        "no",
        "Button is not a self-closing component. Wrap any desired content within the open and close tags.",
      ],
      [
        "className",
        "string",
        "button__style__extra",
        "no",
        "Add styles to overwrite default style.",
      ],
      [
        "hashLinkTo",
        "string",
        "{routes.apps.habPatHome.path}",
        "no",
        "Set internal route path to add <HashLink /> functionality onClick.",
      ],
      [
        "href",
        "string",
        "a valid URL",
        "no",
        "Will redirect to the provided URL.",
      ],
      [
        "ko",
        "boolean",
        "true, false",
        "no",
        "Set to true when the element is rendered over a dark background. Defaults to false",
      ],
      [
        "linkTopTo",
        "string",
        "{routes.apps.habPatHome.path}",
        "no",
        "Set internal route path to add <LinkTop /> functionality onClick.",
      ],
      [
        "newTab",
        "boolean",
        "true",
        "no",
        "If using `href` for anchor functionality, set `newTab` to true to open external link in new browser tab.",
      ],
      [
        "size",
        "string",
        `sm", "md", "lg", "inherit"`,
        "no",
        "Determine the size of the button. Defaults to medium (md)",
      ],
    ],
    codeExample: `
    import { Button } from "/components";
    
    function doStuff() {
      // This function will be invoked when the button is clicked
    }
    
    <Button buttontype="primary" ko={true} href="/some/link" buttonProps={{onClick:doStuff}}> Button Text <Button/> 
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-pad-sm u-flex u-flex-justify-between">
            <div className="u-mgn-vert-sm">
              <Button buttontype="primary" ko={false}>
                {" "}
                Primary Button{" "}
              </Button>
            </div>
            <div className="u-mgn-vert-sm">
              <Button buttontype="secondary" ko={false}>
                {" "}
                Secondary Button{" "}
              </Button>
            </div>
            <div className="u-mgn-vert-sm">
              <Button buttontype="text" ko={false}>
                {" "}
                Text Button{" "}
              </Button>
            </div>
          </div>

          <div className="u-background-blue u-pad-sm u-flex u-flex-justify-between">
            <div className="u-mgn-vert-sm">
              <Button buttontype="primary" ko={true}>
                {" "}
                Primary Button KO{" "}
              </Button>
            </div>
            <div className="u-mgn-vert-sm">
              <Button buttontype="secondary" ko={true}>
                {" "}
                Secondary Button KO{" "}
              </Button>
            </div>
            <div className="u-mgn-vert-sm">
              <Button buttontype="text" ko={true}>
                {" "}
                Text Button KO{" "}
              </Button>
            </div>
          </div>

          <div className="u-pad-sm u-flex u-flex-justify-between">
            <div className="u-mgn-vert-sm">
              <Button buttontype="primary" size="sm">
                Small
              </Button>
            </div>
            <div className="u-mgn-vert-sm">
              <Button buttontype="primary" size="md">
                Medium
              </Button>
            </div>
            <div className="u-mgn-vert-sm">
              <Button buttontype="primary" size="lg">
                Large
              </Button>
            </div>
            <div className="u-mgn-vert-sm">
              <Button buttontype="primary" size="inherit">
                Inherit
              </Button>
            </div>
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
