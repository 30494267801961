import React, { useState } from "react";

/* Components */
import { Button, Heading, Text } from "../../components";
import { Footer } from "..";

/* Methods */
import API from "../../utils/API";
import staticLink from "../../utils/static";
import utils from "../../utils/utils";

export default function BetaTestingPage({ account, auth, navbar }) {
  const betaTestingStateInit = {
      programOptions: {
        "Online Surveys & Questionnaires": false,
        "Remote Interviews": false,
        "Remote Apps Testing": false,
      },
      apps: {
        "Collaborative Mapper": false,
        "Habitat Patrol": false,
      },
    },
    [betaTestingState, setBetaTestingState] = useState(betaTestingStateInit);

  function updateBetaTestingSettings() {
    let programOptionsError = true;
    for (let option of Object.keys(betaTestingState.programOptions)) {
      if (betaTestingState.programOptions[option]) {
        programOptionsError = false;
        break;
      }
    }

    let appsError = true;
    for (let option of Object.keys(betaTestingState.apps)) {
      if (betaTestingState.apps[option]) {
        appsError = false;
        break;
      }
    }

    if (programOptionsError || appsError) {
      utils.sendAlert(
        "You must select at least one Program Option and one App to sign up for beta testing.\n\nBeta testing preferences not updated.",
        "warning",
      );
    } else {
      API.submitBetaTestingSettings(account.state.id, betaTestingState)
        .then(() => {
          utils.sendAlert(
            "Beta testing preferences updated and saved.",
            "success",
          );
          setBetaTestingState(betaTestingStateInit);
        })
        .catch(() => {
          utils.sendAlert(
            "Something went wrong.\n\nPlease use the contact form to report a bug.",
            "error",
          );
        });
    }
  }

  // console.log("Beta Testing State: ", betaTestingState);

  return (
    <div className="page__container">
      <div
        className="landing__banner"
        style={{
          backgroundImage: `linear-gradient(to right, rgba(1, 0, 39, 0.651), rgba(255, 255, 255, 0)), url("${staticLink}hero/Cio-Hero.png")`,
        }}>
        <div className="beta-testing-page__banner__text__container">
          <div className="landing__banner__text--main ">
            <Heading ko={true} number={2}>
              Help us improve,
              <br /> become a beta tester.
            </Heading>
          </div>
          <div className="landing__banner__text--sub">
            <Heading ko={true} number={5} bold={false}>
              Influence the course of our apps through surveys, interviews, and
              live testing of upcoming apps and features.
            </Heading>
          </div>
        </div>
      </div>
      <div
        className="u-flex beta-testing-page__description-section__container"
        id="learnmore">
        <div className="u-mgn-right-lg u-flex u-flex-column u-flex-justify-center">
          <div className="u-textbox-lg">
            <Heading number={4}>What is beta testing?</Heading>
            <Text number={2} className="u-pad-btm-sm">
              Software products (like conservation apps) can go through stages
              of testing with users to ensure quality, usability, and
              effectiveness. Often, early versions of apps are considered to be
              in the "alpha" (or <i>first</i>) stage, or the "beta" (or{" "}
              <i>second</i>) stage, depending on how far along they are in
              development. Defining these stages helps determine the most useful
              testing that users can participate in to further improve the user
              interface and user experience. Accordingly, <i>beta testing</i>,
              is the process of testing apps to see if they meet users' needs
              and perceptions during the beta stage of app development.
            </Text>
          </div>
          <div className="u-textbox-lg">
            <div className="u-mgn-top-md">
              <Heading number={4}>How does it help us?</Heading>
              <Text number={2} className="u-pad-btm-sm">
                We perform beta testing for a number of reasons. Firstly, it is
                an invaluable opportunity to receive feedback from practicing
                conservationists across the entire community to ensure our apps
                are useful and effective. Secondly, we strive to deliver the
                most advanced versions of our apps to provide their value as
                soon as possible. Through alpha and beta launches of our
                software that accompanies these user testing initiatives, we are
                able to publicly deliver conservation apps faster while
                consistently meeting user expectation.
              </Text>
            </div>
          </div>
        </div>
        <img
          src={`${staticLink}graphics/Beta-Graphic.png`}
          alt="beta-testing-graphic"
          className="beta-testing-page__description-section__graphic"
        />
      </div>
      <div className="beta-testing-page__branding-section__container">
        <Heading number={2} ko={true} className="u-pad-btm-sm">
          Benefits
        </Heading>
        <Heading number={4} bold={false} ko={true} className="u-pad-btm-sm">
          Previews of updates.
        </Heading>
        <Heading number={4} bold={false} ko={true} className="u-pad-btm-sm">
          Inform decision making.
        </Heading>
        <Heading number={4} bold={false} ko={true}>
          Shape our apps.
        </Heading>
      </div>
      <div className="beta-testing-page__signup-section" id="signup">
        <div className="beta-testing-page__signup-section__title-container">
          <Heading number={3}>Convenient for you.</Heading>
        </div>
        <div className="beta-testing-page__signup-section__sub-title-container">
          <Heading number={5} bold={false}>
            You choose your level of involvement based on your schedule and
            interests.
          </Heading>
        </div>
        <form
          className="beta-testing-page__signup-section__form-container"
          onSubmit={(e) => {
            e.preventDefault();
            updateBetaTestingSettings();
          }}>
          <div className="beta-testing-page__signup-section__form">
            <div className="beta-testing-page__signup-section__form__header">
              <Heading number={3} ko={true}>
                Beta Testing Sign Up
              </Heading>
            </div>
            {auth.state.isAuthenticated ? (
              <div className="beta-testing-page__signup-section__form__body-auth">
                <div className="beta-testing-page__signup-section__form__body-auth__text-container">
                  <div>
                    <Heading number={5}>Program Options</Heading>
                    <Text number={1}>
                      Please select which kind of testing you would like to
                      participate in.
                    </Text>
                    <div className="u-pad-vert-sm">
                      {Object.keys(betaTestingState.programOptions).map(
                        (option, index) => {
                          return (
                            <div key={index}>
                              <Text
                                key={option}
                                className="u-pad-vert-sm"
                                number={2}>
                                <input
                                  type="checkbox"
                                  checked={
                                    !!betaTestingState.programOptions[option]
                                  }
                                  onChange={() => {
                                    setBetaTestingState({
                                      ...betaTestingState,
                                      programOptions: {
                                        ...betaTestingState.programOptions,
                                        [option]:
                                          !betaTestingState.programOptions[
                                            option
                                          ],
                                      },
                                    });
                                  }}
                                />
                                &nbsp;&nbsp;{option}
                              </Text>
                            </div>
                          );
                        },
                      )}
                    </div>
                  </div>
                  <div className="u-mgn-top-md">
                    <Heading number={5}>Apps</Heading>
                    <Text number={2}>
                      Please select which apps you are interested in testing.
                    </Text>
                    <div className="u-pad-vert-sm">
                      {Object.keys(betaTestingState.apps).map(
                        (option, index) => {
                          return (
                            <div key={index}>
                              <Text
                                className="u-pad-vert-sm"
                                key={option}
                                number={2}>
                                <input
                                  type="checkbox"
                                  checked={!!betaTestingState.apps[option]}
                                  onChange={() => {
                                    setBetaTestingState({
                                      ...betaTestingState,
                                      apps: {
                                        ...betaTestingState.apps,
                                        [option]:
                                          !betaTestingState.apps[option],
                                      },
                                    });
                                  }}
                                />
                                &nbsp;&nbsp;{option}
                              </Text>
                            </div>
                          );
                        },
                      )}
                    </div>
                  </div>
                </div>
                <div className="beta-testing-page__signup-section__form__body-auth__button-container">
                  <Button
                    buttonProps={{
                      type: "submit",
                    }}
                    buttontype="primary"
                    className="beta-testing-page__signup-section__form__body-auth__button"
                    size="inherit">
                    Sign Up
                  </Button>
                </div>
              </div>
            ) : (
              <div className="beta-testing-page__signup-section__form__body-not-auth">
                <div className="beta-testing-page__signup-section__form__body-not-auth__text-container">
                  <Heading number={5}>
                    Please log in to your account, or sign up for one, to access
                    beta testing sign up.
                  </Heading>
                </div>
                <div className="beta-testing-page__signup-section__form__body-not-auth__button-container">
                  <Button
                    buttonProps={{
                      onClick: () => {
                        navbar.setState({
                          ...navbar.state,
                          isLogInModalOpen: true,
                        });
                      },
                      type: "button",
                    }}
                    buttontype="primary"
                    className="beta-testing-page__signup-section__form__body-not-auth__button"
                    size="inherit">
                    Log In
                  </Button>
                  <Button
                    buttonProps={{
                      onClick: () => {
                        navbar.setState({
                          ...navbar.state,
                          isSignUpModalOpen: true,
                        });
                      },
                      type: "button",
                    }}
                    buttontype="primary"
                    className="beta-testing-page__signup-section__form__body-not-auth__button"
                    size="inherit">
                    Account Sign Up
                  </Button>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
}
