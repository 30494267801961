import React from "react";

/* Components */
import AddCollaboratorsModal from "./AddCollaboratorsModal";
import UserDataListItem from "./UserDataListItem";
import {
  Heading,
  Icon,
  List,
  Text,
  Tooltip,
} from "../../../../../../components";

/* Methods */
import API from "../../../../API";

export default function Collaborators({ app }) {
  const deleteCollaborator = (id) => {
    API.deleteCollaborator({
      editorID: id,
      mapID: app.state.mapID,
    }).then(() => {
      API.getMapUsers(app.state.mapID, "collaborators").then((res) => {
        app.setState({ editors: res.data.collaborators });
      });
    });
  };

  return (
    <>
      <div className="u-flex u-flex-justify-between u-mgn-btm-sm u-mgn-top-md">
        <Heading number={5} ko={true}>
          Collaborators
        </Heading>
        {app.state.isCreator && (
          <div onClick={() => app.toggleModal("addEditors")}>
            <Tooltip body="Add Collaborator" ko="true">
              <Icon icon="adduser" ko={true} />
            </Tooltip>
          </div>
        )}
      </div>
      <div className="u-mgn-btm-sm">
        <List>
          {app.state.editors.length > 0 &&
            app.state.editors.map((editor, index) => (
              <UserDataListItem
                key={index}
                user={editor}
                buttonsElement={
                  app.state.isCreator && (
                    <Text className="u-mgn-sm" ko={true} number={2}>
                      <span
                        className="icon--ko icon--sm icon2-icon2__close"
                        onClick={() => deleteCollaborator(editor.id)}></span>
                    </Text>
                  )
                }
              />
            ))}
        </List>
      </div>
      <AddCollaboratorsModal app={app} />
    </>
  );
}
