import { Footer } from "..";
import { Button, Heading, Text } from "../../components";
import React from "react";
import routes from "../../utils/paths/routes";
import staticLink from "../../utils/static";

export default function UtilityPage404({ account, auth }) {
  return (
    <>
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0)), url(${staticLink}banner/Error-Banner.png)`,
          backgroundPosition: "center",
          minHeight: "230px",
        }}></div>
      <div className="utility__generic__page-container">
        <div className="utility__generic__content-container">
          <Heading number={2} color="secondary" className="u-pad-vert-sm">
            Error
          </Heading>
          <Heading number={4} className="u-pad-vert-sm">
            Error Code: 404
          </Heading>
          <Text number={1} className="u-pad-vert-sm">
            The requested resource specified by the current URL is not found.
          </Text>
          <div className="utility__generic__button-container">
            {auth.state.isAuthenticated ? (
              <Button
                buttontype="text"
                linkTopTo={`/profile/${account.state.username}`}>
                Return To Profile
              </Button>
            ) : (
              <Button buttontype="text" linkTopTo={routes.landing.path}>
                Return To Landing
              </Button>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
