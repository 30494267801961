import API from "../../auth/API";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  Button,
  FormInputWValidations,
  ModalClose,
  Text,
} from "../../components";
import React, { useState } from "react";
import utils from "../../utils/utils";

export default function NavbarResetPasswordModal({ navbar }) {
  const navbarResetPasswordModalStateInit = {
      isEmailValid: false,
      valueEmail: "",
    },
    [
      navbarResetPasswordModalState,
      setNavbarResetPasswordModalState,
    ] = useState(navbarResetPasswordModalStateInit);

  const requestResetPasswordLinkEmail = () => {
    if (navbarResetPasswordModalState.isEmailValid) {
      const req = {
        email: navbarResetPasswordModalState.valueEmail,
      };
      API.requestPassReset(req)
        .then(() => {
          utils.sendAlert(
            "A password reset email has been sent to the given address.  Please check your inbox.",
            "success",
          );
          navbar.setState({
            ...navbar.state,
            isResetPasswordModalOpen: false,
          });
        })
        .catch(() => {
          utils.sendAlert(
            "Something went wrong.  Please use the contact form to report a bug.",
            "error",
          );
        });
    } else {
      utils.sendAlert("Please submit an email with a valid format.", "error");
    }
  };

  // console.log("navbarResetPasswordModalState: ", navbarResetPasswordModalState);

  return (
    <Modal
      isOpen={navbar.state.isResetPasswordModalOpen}
      centered={true}
      backdrop="static"
      size="lg">
      <ModalHeader>
        <ModalClose
          cb={() => {
            setNavbarResetPasswordModalState(navbarResetPasswordModalStateInit);
            navbar.setState({
              ...navbar.state,
              isResetPasswordModalOpen: false,
            });
          }}
        />
        Reset Password
      </ModalHeader>
      <ModalBody>
        <div className="u-pad-sm">
          <Text number={1}>
            Please enter the email address associated with your account and
            instructions for resetting the account password will be sent to your
            inbox.
          </Text>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            requestResetPasswordLinkEmail();
          }}>
          <div className="u-pad-sm u-pad-top-sm">
            <FormInputWValidations
              parentState={navbarResetPasswordModalState}
              placeholder="Enter email"
              setParentState={setNavbarResetPasswordModalState}
              inputType="email"
              validationsContext="contact"
              validationKey="isEmailValid"
              valueKey="valueEmail"
              useValidations={true}
            />
          </div>
          <div className="u-pad-btm-md u-pad-top-md u-pad-left-sm u-pad-right-sm">
            <Button
              buttonProps={{ type: "submit" }}
              buttontype="primary"
              size="inherit">
              Submit
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}
