import { Heading, LinkTop } from "../../components";
import React from "react";

export default function CardFooter({ heading, data }) {
  const linkClassName = "hyperlink u-hover-underline";

  const footerLinks = data.map((footerLink, index) => {
    let actionElement;
    switch (footerLink.actionType) {
      case "external link":
        actionElement = (
          <a
            className={linkClassName}
            href={footerLink.pathname}
            rel="noopener noreferrer"
            target="_blank">
            {footerLink.label}
          </a>
        );
        break;
      case "internal link":
        actionElement = (
          <LinkTop
            className={linkClassName}
            to={footerLink.to}
            state={footerLink.state}>
            {footerLink.label}
          </LinkTop>
        );
        break;
      case "none":
        actionElement = <div>{footerLink.label}</div>;
        break;
      default:
        actionElement = <></>;
    }
    return (
      <div className="footer__card--item" key={index}>
        {actionElement}
      </div>
    );
  });

  return (
    <div className="footer__card u-pad-left-sm">
      <div className="footer__card--heading">
        <Heading number={5}>{heading}</Heading>
      </div>
      <div>{footerLinks}</div>
    </div>
  );
}
