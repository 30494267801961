import React from "react";
import { SGTemplate } from ".";

export default function SGCollapsible() {
  const content = {
    name: "Collapsible",
    description:
      "Collapsible components enable the showing and hiding of large portions of content in the UI.",
  };

  return <SGTemplate name={content.name} description={content.description} />;
}
