import React from "react";
import { FeatureGroup, GeoJSON, Popup } from "react-leaflet";
import { Button, ListItem, Heading, Text } from "../../components";

const proposals = app => {
  const proposals = []
  const { datasets } = app.state
  app.state.proposals.forEach(edit => {
    const geojson = JSON.parse(edit.layer)
    geojson["id"] = edit.id
    const expandStyle = { "color": "green" }
    const refineStyle = { "color": "red" }
    const editGroup = (
      <FeatureGroup key={edit.id}>
        <Popup>
          <div>
            <div className="u-background-blue u-align-center u-pad-sm u-mgn-btm-sm">
              <Heading number={5} ko={true}>Accept/Reject Proposed Edit</Heading>
            </div>
            <div className="u-pad-sm">
              <ListItem>
                <div className="u-flex u-flex-column u-flex-justify-between">
                  <Text number={2} ko={true}>{edit.note}</Text>
                  <div className="u-flex-all-center u-mgn-top-sm">
                    {
                      (() => {
                        if (app.state.isCreator) {
                          return (
                            <div className="u-full-width u-flex u-flex-justify-evenly">
                              <Button small={true} buttontype="text" ko={true} buttonProps={{ onClick: () => app.decideOnEdit(edit.id, "accept") }}>
                                Accept
                              </Button>
                              <Button small={true} buttontype="text" ko={true} buttonProps={{ onClick: () => app.decideOnEdit(edit.id, "reject") }}>
                                Reject
                              </Button>
                            </div>
                          )
                        } else if (app.state.account.username === edit.editor.username) {
                          return (
                            <div>
                              <Button small={true} buttontype="text" ko={true}
                                buttonProps={{ onClick: () => app.deleteProposal(edit.id, "edit") }}
                              >
                                Withdraw Edit
                              </Button>
                            </div>
                          )
                        } else {
                          return (
                            ""
                          )
                        }
                      })()
                    }
                  </div>
                </div>
              </ListItem>
            </div>
          </div>
        </Popup>
        {
          edit.edit_type === "edit_add" ?
            <GeoJSON
              data={geojson}
              key={edit.id}
              style={expandStyle}
              onEachFeature={app.onEachProposal}
            /> :
            <GeoJSON
              data={geojson}
              key={edit.id}
              style={refineStyle}
              onEachFeature={app.onEachProposal}
            />
        }
      </FeatureGroup>
    )
    proposals.push(editGroup)
    // return editGroup
  })
  // )
  if (proposals.length > 0) {
    datasets["Proposed Edits"] = {
      dataset: "Proposed Edits",
      features: proposals
    }
    app.setState({ datasets })
  }
}

export default proposals