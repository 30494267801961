import { Heading, Text } from "../../components";
import React from "react";

export default function ProfilePageProfileSection({ profile }) {
  return (
    <div className="profile__profile-section">
      <div className="profile__header u-mgn-btm-md">
        <Heading number={4} ko={true}>
          Profile
        </Heading>
      </div>
      <img
        src={`/${profile.state.profileFields.photo}`}
        alt="Profile picture"
        className="profile__thumb"
      />
      <div className="u-mgn-top-sm">
        <Heading number={4}>{profile.state.profileFields.username}</Heading>
      </div>
      <div className="u-mgn-top-sm">
        <Text number={3}> {profile.state.profileFields.organization} </Text>
      </div>
      <div className="u-mgn-top-md">
        {profile.state.profileFields.bio ? (
          <Text number={2}>{profile.state.profileFields.bio}</Text>
        ) : (
          <Text number={2}>No description added yet</Text>
        )}
      </div>
    </div>
  );
}
