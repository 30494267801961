import { InputText, Label } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGInputText() {
  const content = {
    name: "",
    variantName: "InputText",
    description: `A customized version of the default JSX Input`,
    implementation: "The same as an input element",
    propsTable: [
      [
        "className",
        "string",
        `u-mgn-sm`,
        "no",
        "Add utility, custom, etc. class name.",
      ],
      [
        "ko",
        "Boolean",
        `true, false`,
        "no",
        "Pass in any of the values displayed below the desired icon above.",
      ],
      [
        "small",
        "Boolean",
        "true, false",
        "no",
        "Reduces the size of the component when set to true.",
      ],
      [
        "inputProps",
        "JSX input attributes",
        `Any valid attribute that is applicable to a default JSX "input" element`,
        "no",
        `Provide elements as would normally be applied to a button element. For example: type="password"`,
      ],
    ],
    links: [
      {
        "MDN Input type='text'":
          "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/text",
      },
    ],
    codeExample: `
    import { Input, Label } from "/components";

    function handleChange(e) {
      setState({...state, [e.target.name]: e.target.value})
    }

    <Label labelText="label" ko={true} />
    <InputText inputProps={{onChange:handleChange placeholder:"placeholder text" type:"email"}}/>
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-mgn-btm-sm">
            <Label labelText="label" />
            <InputText inputProps={{ placeholder: "placeholder text" }} />
          </div>
          <div className="u-background-blue u-pad-sm">
            <Label labelText="label" ko={true} />
            <InputText
              small={true}
              inputProps={{ placeholder: "placeholder text" }}
              ko={true}
            />
          </div>
        </div>
      }
      links={content.links}
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
