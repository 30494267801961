import React from "react";
import { Route, Switch } from "react-router-dom";

// Components
import Dashboard from "./dashboard/Dashboard";
import PageHandler from "./app/PageHandler";
import { UtilityPage404 } from "../../platform";

// Utilities
import routes from "../../utils/paths/routes";

export default function CVM({ account, auth }) {
  return (
    <>
      <Switch>
        {/* Add route for CVM home page here. */}
        <Route exact path={routes.apps.cvmDash.path}>
          <Dashboard account={account} auth={auth} />
        </Route>
        <Route path={routes.apps.cvmApp.path}>
          <PageHandler account={account} auth={auth} />
        </Route>

        {/* 404 route added here for no-match urls of `/apps/conservationvaluemapper/*` */}
        <Route path={routes.utility[404].path}>
          <UtilityPage404 account={account} auth={auth} />
        </Route>
      </Switch>
    </>
  );
}
