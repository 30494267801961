import React from "react";

// Components
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button, Heading, ModalClose, Text } from "../../../components";

// Utilities
import APIreqs from "../helpers/API";
import utils from "../../../utils/utils";

function DeleteProjectModal({ dashboard }) {
  function resetModal() {
    dashboard.setState({
      ...dashboard.state,
      activeModal: "",
      activeModalData: null,
    });
  }

  async function deleteProject() {
    try {
      const projectID = dashboard.state.activeModalData.id;
      await APIreqs.projects.delete(projectID);
      dashboard.methods.getAndSetCvmProjectsData();
      utils.sendAlert(`Project deleted.`, "success");
    } catch {
      utils.sendAlert(
        "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'delete CVM project' error.",
        "error",
      );
    }
  }

  return (
    <Modal
      centered={true}
      size="lg"
      isOpen={dashboard.state.activeModal === "deleteProjectModal"}
      toggle={() => {}}>
      <ModalHeader>
        <ModalClose cb={resetModal} />
        Projects
      </ModalHeader>
      <ModalBody>
        <div className="u-pad-md">
          <Heading className="" number={3} color="secondary">
            Delete Project
          </Heading>
          <Heading className="u-pad-top-md u-pad-btm-sm" number={5}>
            This action is permanent.
          </Heading>
          <div className="u-pad-top-md">
            <Text number={1}>
              Delete Conservation Value Mapper project{" "}
              <b>
                {dashboard.state.activeModalData &&
                  dashboard.state.activeModalData.title}
              </b>
              ?
            </Text>
          </div>
          <div className="u-pad-vert-md">
            <Text number={1}>
              This action will delete all maps, data, and files saved within
              this project.
            </Text>
          </div>
          <div className="u-mgn-top-md u-flex u-flex-justify-end">
            <div className="u-mgn-right-sm map__modal-button-container">
              <Button
                buttontype="secondary"
                buttonProps={{
                  onClick: resetModal,
                }}
                size="inherit">
                Cancel
              </Button>
            </div>
            <div className="map__modal-button-container">
              <Button
                buttontype="primary"
                buttonProps={{
                  onClick: () => {
                    deleteProject();
                  },
                }}
                size="inherit">
                Delete
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default DeleteProjectModal;
