import React from "react";
import { Heading, Menu } from "../../../../components";

export default function MapListSelector({ cm }) {
  /* Defines lables and onClick action for side nav that lets
  users select which maps they want to browse in the MapsListTable. */
  const mapsListSelectionOptions = [
      { title: "Public Maps", dataKey: "publicMapsDataArray" },
      { title: "My Maps", dataKey: "myMapsDataArray" },
    ],
    mapsListSelectionMenuOptions = mapsListSelectionOptions.map((option) => {
      return {
        onClick: () =>
          cm.setState({
            ...cm.state,
            mapsListSelection: option.title,
            selectedDataArrayLength: cm.state[option.dataKey].length,
          }),
        jsx: (
          <Heading
            number={5}
            ko={true}
            className={
              cm.state.mapsListSelection === option.title
                ? "u-color-yellow"
                : ""
            }>
            {option.title}
          </Heading>
        ),
        active: cm.state.mapsListSelection === option.title ? true : false,
      };
    });

  return (
    <div className="g-dashboard__nav">
      <Menu options={mapsListSelectionMenuOptions} />
    </div>
  );
}
