import $ from "jquery";

const renderControls = () => {
  const controlsContainer = $(".leaflet-top.leaflet-right") // Top level controls buttons container.

  const exit = $(".leaflet-buttons__exit-button") // Exit map button.
  controlsContainer.append(exit);

  const zoomContainer = $(".leaflet-control-zoom"); // Map zoom buttons container.
  controlsContainer.append(zoomContainer);
  zoomContainer.addClass("leaflet-buttons__zoom-container");

  const drawPolygonContainer = $(".leaflet-pm-draw"); // `drawPolygonContainer` is the container that stores all the add marker and polygons buttons
  controlsContainer.append(drawPolygonContainer);

  const editPolygonContainer = $(".leaflet-pm-edit"); // `editPolygonContainer` is the container that stores all the edit and delete buttons
  controlsContainer.append(editPolygonContainer);

  /* Leaflet default buttons style update. */
  $(".leaflet-buttons-control-button").addClass("leaflet-buttons__base-button-style")

  /* Leaflet default buttons containers style update. */
  drawPolygonContainer.addClass("leaflet-buttons__draw-polygon-container")
  editPolygonContainer.addClass("leaflet-buttons__edit-polygon-container")
  if (drawPolygonContainer.length === 0) { $(".leaflet-control-zoom").addClass("leaflet-buttons__draw-edit-container-spacer") }
  else { $(".leaflet-control-zoom").removeClass("leaflet-buttons__draw-edit-container-spacer") }

  /* Remove default leaflet controls border. */
  $(".leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar").css({
    "border": "none",
  })

  $(".leaflet-buttons-control-button div").each(function () {

    /* ADD MARKER BUTTON STYLE */
    if ($(this).hasClass("leaflet-pm-icon-marker")) {
      $(this).parent().append("<div class='tooltip__marker'><span class='icon--ko icon--md icon-icon__marker leaflet-pm--icon'></span></div>")
      $(this).css({
        "background-image": "none"
      })
      const button = document.getElementsByClassName("control-icon leaflet-pm-icon-marker")
      button.onClick = "preventEventDefault(); return false";
    }

    /* DRAW LINE BUTTON STYLE */
    else if ($(this).hasClass("leaflet-pm-icon-polyline")) {
      $(this).parent().append("<div class='tooltip__line'><span class='icon--ko icon--md icon-icon__line leaflet-pm--icon'></span></div>")
      $(this).css({
        "background-image": "none"
      })
    }

    /* DRAW RECTANGLE BUTTON STYLE */
    else if ($(this).hasClass("leaflet-pm-icon-rectangle")) {
      $(this).parent().append("<div class='tooltip__rectangle'><span class='icon--ko icon--md icon-icon__rectangle leaflet-pm--icon'></span></div>")
      $(this).css({
        "background-image": "none",
      })
    }

    /* DRAW POLYGON BUTTON STYLE */
    else if ($(this).hasClass("leaflet-pm-icon-polygon")) {
      $(this).parent().append("<div class='tooltip__polygon'><span class='icon--ko icon--md icon-icon__polygon leaflet-pm--icon'></span></div>")
      $(this).css({
        "background-image": "none"
      })
    }

    /* EDIT POLYGON BUTTON STYLE */
    else if ($(this).hasClass("leaflet-pm-icon-edit")) {
      $(this).parent().append("<div class='tooltip__edit'><span class='icon--ko icon--md icon-icon__edit leaflet-pm--icon'></span></div>")
      $(this).css({
        "background-image": "none"
      })
    }

    /* DELETE BUTTON STYLE */
    else if ($(this).hasClass("leaflet-pm-icon-delete")) {
      $(this).parent().append("<div class='tooltip__delete'><span class='icon--ko icon--md icon-icon__trash leaflet-pm--icon'></span></div>")
      $(this).css({
        "background-image": "none"
      })
    }
  })
}

export default renderControls