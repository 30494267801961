import { CollapsibleV, Menu, List, Text } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGCollapsibleV() {
  /* const initState = {
    sidenavOpen: true,
  };

  const [state, setState] = useState(initState);

  function toggleNav() {
    setState({ ...state, navShowing: !state.navShowing });
  } */

  const content = {
    name: "CollapsibleV",
    variantName: "",
    description: `A component that expands downwards. When closed, all that is
    displayed is a "button" that will reveal more content when clicked.
    When open, this component displays all the elements passed to it as
    children.`,
    implementation: "Allows users to manually hide and reveal content.",
    propsTable: [
      [
        "children",
        "JSX",
        "Any valid JSX",
        "no",
        "CollapsibleV is not a self-closing component. Wrap any desired content within the open and close tags. All children will only be displayed when the collapisble menu is open.",
      ],
      [
        "parent",
        "JS object",
        "hash element including parent state",
        "no",
        "The `parent` object must look something like `{state: parentStateVar, setState: parentSetStateMethod}` with the attribute `active`.  This connects open/close logic between multiple <CollapsibleV /> components in the same menu.",
      ],
      [
        "title",
        "string",
        `any string`,
        "no",
        "Text to be displayed on the collapisble menu button (the button that, when clicked, opens the collapisble menu.",
      ],
    ],
    codeExample: `
    import { CollapsibleV } from "/components";
             
    <CollapsibleV 
      title="CollapsibleV"
      parent={parent}>
        <div>
          Some content
        </div>
    </CollapsibleV>
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-pad-sm">
            <CollapsibleV title="Collapsible Vertical">
              <Menu
                options={[
                  {
                    jsx: (
                      <Text number={1} ko={true}>
                        Option1
                      </Text>
                    ),
                    link: "#menu",
                  },
                  {
                    jsx: (
                      <Text number={1} ko={true}>
                        Option 2 -- I console.log when clicked!
                      </Text>
                    ),
                    onClick: () => console.log("Option onClick example"),
                  },
                  {
                    jsx: (
                      <Text number={1} ko={true}>
                        Option3
                      </Text>
                    ),
                    link: "#menu",
                  },
                  {
                    jsx: (
                      <Text number={1} ko={true}>
                        Option4
                      </Text>
                    ),
                    link: "#menu",
                  },
                ]}
              />
            </CollapsibleV>
          </div>

          <div className="u-pad-sm">
            <List>
              <CollapsibleV title="Collapsible Vertical">
                <Menu
                  options={[
                    {
                      jsx: (
                        <Text number={1} ko={true}>
                          Option1
                        </Text>
                      ),
                      link: "#menu",
                    },
                    {
                      jsx: (
                        <Text number={1} ko={true}>
                          Option 2 -- I console.log when clicked!
                        </Text>
                      ),
                      onClick: () => console.log("Option onClick example"),
                    },
                    {
                      jsx: (
                        <Text number={1} ko={true}>
                          Option3
                        </Text>
                      ),
                      link: "#menu",
                    },
                    {
                      jsx: (
                        <Text number={1} ko={true}>
                          Option4
                        </Text>
                      ),
                      link: "#menu",
                    },
                  ]}
                />
              </CollapsibleV>

              <CollapsibleV title="Collapsible Vertical">
                <Menu
                  options={[
                    {
                      jsx: (
                        <Text number={1} ko={true}>
                          Option1
                        </Text>
                      ),
                      link: "#menu",
                    },
                    {
                      jsx: (
                        <Text number={1} ko={true}>
                          Option 2 -- I console.log when clicked!
                        </Text>
                      ),
                      onClick: () => console.log("Option onClick example"),
                    },
                    {
                      jsx: (
                        <Text number={1} ko={true}>
                          Option3
                        </Text>
                      ),
                      link: "#menu",
                    },
                    {
                      jsx: (
                        <Text number={1} ko={true}>
                          Option4
                        </Text>
                      ),
                      link: "#menu",
                    },
                  ]}
                />
              </CollapsibleV>

              <CollapsibleV title="Collapsible Vertical">
                <Menu
                  options={[
                    {
                      jsx: (
                        <Text number={1} ko={true}>
                          Option1
                        </Text>
                      ),
                      link: "#menu",
                    },
                    {
                      jsx: (
                        <Text number={1} ko={true}>
                          Option 2 -- I console.log when clicked!
                        </Text>
                      ),
                      onClick: () => console.log("Option onClick example"),
                    },
                    {
                      jsx: (
                        <Text number={1} ko={true}>
                          Option3
                        </Text>
                      ),
                      link: "#menu",
                    },
                    {
                      jsx: (
                        <Text number={1} ko={true}>
                          Option4
                        </Text>
                      ),
                      link: "#menu",
                    },
                  ]}
                />
              </CollapsibleV>

              <CollapsibleV title="Collapsible Vertical">
                <Menu
                  options={[
                    {
                      jsx: (
                        <Text number={1} ko={true}>
                          Option1
                        </Text>
                      ),
                      link: "#menu",
                    },
                    {
                      jsx: (
                        <Text number={1} ko={true}>
                          Option 2 -- I console.log when clicked!
                        </Text>
                      ),
                      onClick: () => console.log("Option onClick example"),
                    },
                    {
                      jsx: (
                        <Text number={1} ko={true}>
                          Option3
                        </Text>
                      ),
                      link: "#menu",
                    },
                    {
                      jsx: (
                        <Text number={1} ko={true}>
                          Option4
                        </Text>
                      ),
                      link: "#menu",
                    },
                  ]}
                />
              </CollapsibleV>
            </List>
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
