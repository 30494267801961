import React from "react";
import { Heading, Menu } from "../../../components";

export default function ProjectsListSelector() {
  const projectsListSelectionOptions = [
      { title: "My Projects", dataKey: "cvmProjectsDataArray" },
    ],
    projectsListSelectionMenuOptions = projectsListSelectionOptions.map(
      (option) => {
        return {
          onClick: () => {},
          jsx: (
            <Heading number={5} ko={true} className={"u-color-yellow"}>
              {option.title}
            </Heading>
          ),
          active: true,
        };
      },
    );

  return (
    <div className="g-dashboard__nav">
      <Menu options={projectsListSelectionMenuOptions} />
    </div>
  );
}
