export default {
   "Aboriginal Prickly-apple": "Harrisia (=Cereus) aboriginum (=gracilis)",
   "Acuna Cactus": "Echinomastus erectocentrus var. acunensis",
   "A`e": "Zanthoxylum oahuense",
   "`Ahinahina": "Argyroxiphium sandwicense ssp. macrocephalum",
   "`Aiakeakua, popolo": "Solanum sandwicense",
   "`Aiea": "Nothocestrum peltatum",
   "Akekee": "Loxops caeruleirostris",
   "Akikiki": "Oreomystis bairdi",
   "`Akoko": "Euphorbia rockii",
   "`aku`aku": "Cyanea platyphylla",
   "`Ala `ala wai nui": "Peperomia subpetiolata",
   "Alabama beach mouse": "Peromyscus polionotus ammobates",
   "Alabama cavefish": "Speoplatyrhinus poulsoni",
   "Alabama moccasinshell": "Medionidus acutissimus",
   "Alabama pearlshell": "Margaritifera marrianae",
   "Alabama sturgeon": "Scaphirhynchus suttkusi",
   "Alameda whipsnake (=striped racer)": "Masticophis lateralis euryxanthus",
   "Alani": "Melicope zahlbruckneri",
   "Altamaha Spinymussel": "Elliptio spinosa",
   "Amargosa niterwort": "Nitrophila mohavensis",
   "Amargosa vole": "Microtus californicus scirpensis",
   "Amber darter": "Percina antesella",
   "American crocodile": "Crocodylus acutus",
   "`Anaunau": "Lepidium arbuscula",
   "Antioch Dunes evening-primrose": "Oenothera deltoides ssp. howellii",
   "`Anunu": "Sicyos albus",
   "Appalachian elktoe": "Alasmidonta raveneliana",
   "Arkansas River shiner": "Notropis girardi",
   "Arroyo (=arroyo southwestern) toad": "Anaxyrus californicus",
   "Ash-grey paintbrush": "Castilleja cinerea",
   "Ash Meadows Amargosa pupfish": "Cyprinodon nevadensis mionectes",
   "Ash Meadows blazingstar": "Mentzelia leucophylla",
   "Ash Meadows gumplant": "Grindelia fraxinipratensis",
   "Ash Meadows ivesia": "Ivesia kingii var. eremica",
   "Ash meadows milk-vetch": "Astragalus phoenix",
   "Ash Meadows naucorid": "Ambrysus amargosus",
   "Ash Meadows speckled dace": "Rhinichthys osculus nevadensis",
   "Ash Meadows sunray": "Enceliopsis nudicaulis var. corrugata",
   "Asplenium-leaved diellia": "Asplenium dielerectum",
   "Atlantic salmon": "Salmo salar",
   "Atlantic sturgeon (Gulf subspecies)": "Acipenser oxyrinchus (=oxyrhynchus) desotoi",
   "Aupaka": "Isodendrion longifolium",
   "Austin blind Salamander": "Eurycea waterlooensis",
   "`Awikiwiki": "Canavalia pubescens",
   "Awiwi": "Schenkia sebaeoides",
   "'Awiwi": "Kadua cookiana",
   "Baker's larkspur": "Delphinium bakeri",
   "Bartram's hairstreak Butterfly": "Strymon acis bartrami",
   "Bay checkerspot butterfly": "Euphydryas editha bayensis",
   "Bear Valley sandwort": "Arenaria ursina",
   "Beautiful shiner": "Cyprinella formosa",
   "Big Spring spinedace": "Lepidomeda mollispinis pratensis",
   "Blackburn's sphinx moth": "Manduca blackburni",
   "Blackline Hawaiian damselfly": "Megalagrion nigrohamatum nigrolineatum",
   "Black pine snake": "Pituophis melanoleucus lodingi",
   "Black warrior (=Sipsey Fork) Waterdog": "Necturus alabamensis",
   "Bonytail": "Gila elegans",
   "Braken Bat Cave Meshweaver": "Cicurina venii",
   "Braun's rock-cress": "Arabis perstellata",
   "Braunton's milk-vetch": "Astragalus brauntonii",
   "Buena Vista Lake ornate Shrew": "Sorex ornatus relictus",
   "Bull Trout": "Salvelinus confluentus",
   "Butte County meadowfoam": "Limnanthes floccosa ssp. californica",
   "California condor": "Gymnogyps californianus",
   "California red-legged frog": "Rana draytonii",
   "California taraxacum": "Taraxacum californicum",
   "California tiger Salamander": "Ambystoma californiense",
   "Canada Lynx": "Lynx canadensis",
   "canary rockfish": "Sebastes pinniger",
   "Cape Fear shiner": "Notropis mekistocholas",
   "Cape Sable seaside sparrow": "Ammodramus maritimus mirabilis",
   "Cape Sable Thoroughwort": "Chromolaena frustrata",
   "Carolina heelsplitter": "Lasmigona decorata",
   "Carter's panicgrass": "Panicum fauriei var. carteri",
   "Carter's small-flowered flax": "Linum carteri carteri",
   "Casey's June Beetle": "Dinacoma caseyi",
   "Chinook salmon": "Oncorhynchus (=Salmo) tshawytscha",
   "Chipola slabshell": "Elliptio chipolaensis",
   "Chiricahua leopard frog": "Rana chiricahuensis",
   "Choctaw bean": "Villosa choctawensis",
   "Choctawhatchee beach mouse": "Peromyscus polionotus allophrys",
   "Chucky Madtom": "Noturus crypticus",
   "Chupadera springsnail": "Pyrgulopsis chupaderae",
   "Clay-Loving wild buckwheat": "Eriogonum pelinophilum",
   "Coachella Valley fringe-toed lizard": "Uma inornata",
   "Coachella Valley milk-vetch": "Astragalus lentiginosus var. coachellae",
   "Coastal California gnatcatcher": "Polioptila californica californica",
   "Coho salmon": "Oncorhynchus (=Salmo) kisutch",
   "Cokendolpher Cave Harvestman": "Texella cokendolpheri",
   "Colorado pikeminnow (=squawfish)": "Ptychocheilus lucius",
   "Colusa grass": "Neostapfia colusana",
   "Comal Springs dryopid beetle": "Stygoparnus comalensis",
   "Comal Springs riffle beetle": "Heterelmis comalensis",
   "Conasauga logperch": "Percina jenkinsi",
   "Conservancy fairy shrimp": "Branchinecta conservatio",
   "Contra Costa goldfields": "Lasthenia conjugens",
   "Contra Costa wallflower": "Erysimum capitatum var. angustatum",
   "Cooke's koki`o": "Kokia cookei",
   "Cook's lomatium": "Lomatium cookii",
   "Coosa moccasinshell": "Medionidus parvulus",
   "crested honeycreeper (Akohekohe)": "Palmeria dolei",
   "Crimson Hawaiian damselfly": "Megalagrion leptodemas",
   "Culebra Island giant anole": "Anolis roosevelti",
   "Cumberland darter": "Etheostoma susanae",
   "Cumberland elktoe": "Alasmidonta atropurpurea",
   "Cumberlandian combshell": "Epioblasma brevidens",
   "Cushenbury buckwheat": "Eriogonum ovalifolium var. vineum",
   "Cushenbury milk-vetch": "Astragalus albens",
   "Cushenbury oxytheca": "Oxytheca parishii var. goodmaniana",
   "Dakota Skipper": "Hesperia dacotae",
   "Dark pigtoe": "Pleurobema furvum",
   "DeBeque phacelia": "Phacelia submutica",
   "Delta green ground beetle": "Elaphrus viridis",
   "Delta smelt": "Hypomesus transpacificus",
   "Desert dace": "Eremichthys acros",
   "Desert pupfish": "Cyprinodon macularius",
   "Desert tortoise": "Gopherus agassizii",
   "Desert yellowhead": "Yermo xanthocephalus",
   "Devils River minnow": "Dionda diaboli",
   "diamond Darter": "Crystallaria cincotta",
   "Diamond Tryonia": "Pseudotryonia adamantina",
   "Diminutive Amphipod": "Gammarus hyalleloides",
   "dusky gopher frog": "Rana sevosa",
   "Elfin-woods warbler": "Setophaga angelae",
   "Elkhorn coral": "Acropora palmata",
   "Everglade snail kite": "Rostrhamus sociabilis plumbeus",
   "Ewa Plains `akoko": "Euphorbia skottsbergii var. skottsbergii",
   "Fat threeridge (mussel)": "Amblema neislerii",
   "Fender's blue butterfly": "Icaricia icarioides fenderi",
   "Fickeisen plains cactus": "Pediocactus peeblesianus fickeiseniae",
   "Finelined pocketbook": "Lampsilis altilis",
   "Fish Slough milk-vetch": "Astragalus lentiginosus var. piscinensis",
   "Fleshy-fruit gladecress": "Leavenworthia crassa",
   "Fleshy owl's-clover": "Castilleja campestris ssp. succulenta",
   "Florida brickell-bush": "Brickellia mosieri",
   "Florida leafwing Butterfly": "Anaea troglodyta floridalis",
   "Florida semaphore Cactus": "Consolea corallicola",
   "Fluted kidneyshell": "Ptychobranchus subtentus",
   "Fosberg's love grass": "Eragrostis fosbergii",
   "Fountain darter": "Etheostoma fonticola",
   "Franciscan manzanita": "Arctostaphylos franciscana",
   "Fresno kangaroo rat": "Dipodomys nitratoides exilis",
   "Frosted Flatwoods salamander": "Ambystoma cingulatum",
   "Fuzzy pigtoe": "Pleurobema strodeanum",
   "Gaviota Tarplant": "Deinandra increscens ssp. villosa",
   "Georgia pigtoe": "Pleurobema hanleyianum",
   "Georgia rockcress": "Arabis georgiana",
   "Gierisch mallow": "Sphaeralcea gierischii",
   "Gila chub": "Gila intermedia",
   "Golden coqui": "Eleutherodactylus jasperi",
   "Golden sedge": "Carex lutea",
   "Gonzales tryonia": "Tryonia circumstriata (=stocktonensis)",
   "Government Canyon Bat Cave Meshweaver": "Cicurina vespera",
   "Government Canyon Bat Cave Spider": "Neoleptoneta microps",
   "Gray wolf": "Canis lupus",
   "Greene's tuctoria": "Tuctoria greenei",
   "Green sea turtle": "Chelonia mydas",
   "Grotto Sculpin": "Cottus specus",
   "Guadalupe fescue": "Festuca ligulata",
   "Guajon": "Eleutherodactylus cooki",
   "Guam Micronesian kingfisher": "Halcyon cinnamomina cinnamomina",
   "Gulf moccasinshell": "Medionidus penicillatus",
   "Gunnison sage-grouse": "Centrocercus minimus",
   "Gypsum wild-buckwheat": "Eriogonum gypsophilum",
   "haha": "Cyanea munroi",
   "Haha": "Cyanea undulata",
   "haha nui": "Cyanea horrida",
   "Hairy Orcutt grass": "Orcuttia pilosa",
   "haiwale": "Cyrtandra ferripilosa",
   "Haiwale": "Cyrtandra waiolani",
   "Ha`iwale": "Cyrtandra viridiflora",
   "Hala pepe": "Pleomele hawaiiensis",
   "Hau kuahiwi": "Hibiscadelphus woodii",
   "Hawaiian bluegrass": "Poa sandvicensis",
   "Hawaiian monk seal": "Monachus schauinslandi",
   "Hawaiian picture-wing fly": "Drosophila tarphytrichia",
   "Hawksbill sea turtle": "Eretmochelys imbricata",
   "Heau": "Exocarpos luteolus",
   "Heliotrope milk-vetch": "Astragalus montii",
   "Helotes mold beetle": "Batrisodes venyivi",
   "Hiko White River springfish": "Crenichthys baileyi grandis",
   "Hillebrand's reedgrass": "Calamagrostis hillebrandii",
   "Hilo ischaemum": "Ischaemum byrone",
   "Hine's emerald dragonfly": "Somatochlora hineana",
   "Hoawa": "Pittosporum halophilum",
   "Ho`awa": "Pittosporum napaliense",
   "Holmgren milk-vetch": "Astragalus holmgreniorum",
   "Hoover's spurge": "Chamaesyce hooveri",
   "Houston toad": "Bufo houstonensis",
   "Huachuca water-umbel": "Lilaeopsis schaffneriana var. recurva",
   "Hulumoa": "Korthalsella degeneri",
   "Humpback chub": "Gila cypha",
   "Ihi`ihi": "Marsilea villosa",
   "Indiana bat": "Myotis sodalis",
   "Interrupted (=Georgia) Rocksnail": "Leptoxis foremani",
   "Inyo California towhee": "Pipilo crissalis eremophilus",
   "Island marble Butterfly": "Euchloe ausonides insulanus",
   "Jaguar": "Panthera onca",
   "Jemez Mountains salamander": "Plethodon neomexicanus",
   "Johnson's seagrass": "Halophila johnsonii",
   "Jollyville Plateau Salamander": "Eurycea tonkawae",
   "June sucker": "Chasmistes liorus",
   "Kamakahala": "Labordia triflora",
   "Kamanomano": "Cenchrus agrimonioides",
   "Kauai cave amphipod": "Spelaeorchestia koloana",
   "Kauai cave wolf or pe'e pe'e maka 'ole spider": "Adelocosa anops",
   "Kauila": "Colubrina oppositifolia",
   "Kaulu": "Pteralyxia macrocarpa",
   "Keck's Checker-mallow": "Sidalcea keckii",
   "Kentucky arrow darter": "Etheostoma spilotum",
   "Kentucky cave shrimp": "Palaemonias ganteri",
   "Kentucky glade cress": "Leavenworthia exigua laciniata",
   "Killer whale": "Orcinus orca",
   "Kincaid's Lupine": "Lupinus sulphureus ssp. kincaidii",
   "Kio`ele": "Kadua coriacea",
   "Kiponapona": "Phyllostegia racemosa",
   "Kneeland Prairie penny-cress": "Thlaspi californicum",
   "Kohe malama malama o kanaloa": "Kanaloa kahoolawensis",
   "Koholapehu": "Dubautia latifolia",
   "Koki`o": "Kokia drynarioides",
   "Koki`o ke`oke`o": "Hibiscus arnottianus ssp. immaculatus",
   "Kolea": "Myrsine vaccinioides",
   "Ko`oko`olau": "Bidens wiebkei",
   "kopa": "Kadua cordata remyi",
   "Kopiko": "Psychotria hobdyi",
   "Koster's springsnail": "Juturnia kosteri",
   "Kuahiwi laukahi": "Plantago princeps",
   "Kula wahine noho": "Isodendrion pyrifolium",
   "Kulu`i": "Nototrichium humile",
   "La Graciosa thistle": "Cirsium loncholepis",
   "Laguna Mountains skipper": "Pyrgus ruralis lagunae",
   "Lanai sandalwood (=`iliahi)": "Santalum haleakalae var. lanaiense",
   "Lanai tree snail": "Partulina variabilis",
   "Lane Mountain milk-vetch": "Astragalus jaegerianus",
   "Large-flowered fiddleneck": "Amsinckia grandiflora",
   "Large-flowered woolly meadowfoam": "Limnanthes pumila ssp. grandiflora",
   "Lau `ehu": "Panicum niihauense",
   "Laulihilihi": "Schiedea stellarioides",
   "Laurel dace": "Chrosomus saylori",
   "Least Bell's vireo": "Vireo bellii pusillus",
   "Leatherback sea turtle": "Dermochelys coriacea",
   "lehua makanoe": "Lysimachia daphnoides",
   "Leon Springs pupfish": "Cyprinodon bovinus",
   "Leopard darter": "Percina pantherina",
   "Liliwai": "Acaena exigua",
   "Little Colorado spinedace": "Lepidomeda vittata",
   "Little Kern golden trout": "Oncorhynchus aguabonita whitei",
   "Llanero Coqui": "Eleutherodactylus juanariveroi",
   "Loach minnow": "Tiaroga cobitis",
   "Loggerhead sea turtle": "Caretta caretta",
   "Lompoc yerba santa": "Eriodictyon capitatum",
   "Longhorn fairy shrimp": "Branchinecta longiantenna",
   "Lost River sucker": "Deltistes luxatus",
   "Lo`ulu": "Pritchardia viscosa",
   "Lyon's pentachaeta": "Pentachaeta lyonii",
   "Madla Cave Meshweaver": "Cicurina madla",
   "Mahoe": "Alectryon macrococcus",
   "Makou": "Peucedanum sandwicense",
   "Malheur wire-lettuce": "Stephanomeria malheurensis",
   "Ma`oli`oli": "Schiedea kealiae",
   "Mapele": "Cyrtandra cyaneoides",
   "Marbled murrelet": "Brachyramphus marmoratus",
   "Mariana (=aga) Crow": "Corvus kubaryi",
   "Mariana fruit Bat (=Mariana flying fox)": "Pteropus mariannus mariannus",
   "Maryland darter": "Etheostoma sellare",
   "Maui parrotbill (Kiwikiu)": "Pseudonestor xanthophrys",
   "Maui remya": "Remya mauiensis",
   "Mauna Loa (=Ka'u) silversword": "Argyroxiphium kauense",
   "Mehamehame": "Flueggea neowawraea",
   "Mexican flannelbush": "Fremontodendron mexicanum",
   "Mexican spotted owl": "Strix occidentalis lucida",
   "Mississippi sandhill crane": "Grus canadensis pulla",
   "Mona boa": "Epicrates monensis monensis",
   "Mona ground Iguana": "Cyclura stejnegeri",
   "Monterey spineflower": "Chorizanthe pungens var. pungens",
   "Morro Bay kangaroo rat": "Dipodomys heermanni morroensis",
   "Morro shoulderband (=Banded dune) snail": "Helminthoglypta walkeriana",
   "Mountain golden heather": "Hudsonia montana",
   "Mountain yellow-legged frog": "Rana muscosa",
   "Mount Charleston blue butterfly": "Icaricia (Plebejus) shasta charlestonensis",
   "Mount Graham red squirrel": "Tamiasciurus hudsonicus grahamensis",
   "Munz's onion": "Allium munzii",
   "Naenae": "Dubautia kenwoodii",
   "Na`ena`e": "Dubautia waialealae",
   "Nanu": "Gardenia mannii",
   "Narrow pigtoe": "Fusconaia escambia",
   "(=Native yellow hibiscus) ma`o hau hele": "Hibiscus brackenridgei",
   "Navajo sedge": "Carex specuicola",
   "Neches River rose-mallow": "Hibiscus dasycalyx",
   "nehe": "Melanthera kamolensis",
   "Nehe": "Melanthera tenuifolia",
   "Neosho Mucket": "Lampsilis rafinesqueana",
   "Nevin's barberry": "Berberis nevinii",
   "Newcomb's snail": "Erinna newcombi",
   "Newcomb's Tree snail": "Newcombia cumingi",
   "New Mexican ridge-nosed rattlesnake": "Crotalus willardi obscurus",
   "New Mexico meadow jumping mouse": "Zapus hudsonius luteus",
   "Niangua darter": "Etheostoma nianguae",
   "Nioi": "Eugenia koolauensis",
   "No common name": "Wikstroemia villosa",
   "[no common name] Beetle": "Rhadine infernalis",
   "Noel's Amphipod": "Gammarus desperatus",
   "Nohoanu": "Geranium multiflorum",
   "North Atlantic Right Whale": "Eubalaena glacialis",
   "Northern Sea Otter": "Enhydra lutris kenyoni",
   "Northern spotted owl": "Strix occidentalis caurina",
   "Oahu elepaio": "Chasiempis ibidis",
   "Oceanic Hawaiian damselfly": "Megalagrion oceanicum",
   "Ochlockonee moccasinshell": "Medionidus simpsonianus",
   "Oha": "Delissea subcordata",
   "Ohai": "Sesbania tomentosa",
   "`Oha wai": "Clermontia samuelii",
   "`Ohe`ohe": "Polyscias gymnocarpa",
   "Olulu": "Brighamia insignis",
   "Olympia pocket gopher": "Thomomys mazama pugetensis",
   "Opuhe": "Urera kaalae",
   "Orangenacre mucket": "Lampsilis perovalis",
   "Oregon silverspot butterfly": "Speyeria zerene hippolyta",
   "Oregon spotted frog": "Rana pretiosa",
   "Otay tarplant": "Deinandra (=Hemizonia) conjugens",
   "Oval pigtoe": "Pleurobema pyriforme",
   "Ovate clubshell": "Pleurobema perovatum",
   "Owens Tui Chub": "Gila bicolor ssp. snyderi",
   "Oyster mussel": "Epioblasma capsaeformis",
   "Pagosa skyrocket": "Ipomopsis polyantha",
   "Pa`iniu": "Astelia waialealae",
   "Palapalai aumakua": "Dryopteris crinalis var. podosorus",
   "Palila (honeycreeper)": "Loxioides bailleui",
   "Palos Verdes blue butterfly": "Glaucopsyche lygdamus palosverdesensis",
   "Pamakani": "Viola chamissoniana ssp. chamissoniana",
   "Papala": "Charpentiera densiflora",
   "Parachute beardtongue": "Penstemon debilis",
   "Parish's daisy": "Erigeron parishii",
   "Pauoa": "Ctenitis squamigera",
   "Peck's cave amphipod": "Stygobromus (=Stygonectes) pecki",
   "Pecos amphipod": "Gammarus pecos",
   "Pecos assiminea snail": "Assiminea pecos",
   "Pecos bluntnose shiner": "Notropis simus pecosensis",
   "Pecos (=puzzle, =paradox) sunflower": "Helianthus paradoxus",
   "Peirson's milk-vetch": "Astragalus magdalenae var. peirsonii",
   "Pendant kihi fern": "Adenophorus periens",
   "Peninsular bighorn sheep": "Ovis canadensis nelsoni",
   "Perdido Key beach mouse": "Peromyscus polionotus trissyllepsis",
   "Phantom Springsnail": "Pyrgulopsis texana",
   "Phantom Tryonia": "Tryonia cheatumi",
   "pilo": "Kadua laxiflora",
   "Pilo kea lau li`i": "Platydesma rostrata",
   "Piping Plover": "Charadrius melodus",
   "Plymouth Redbelly Turtle": "Pseudemys rubriventris bangsi",
   "Po`e": "Portulaca sclerocarpa",
   "Polar bear": "Ursus maritimus",
   "Popolo": "Cyanea solanacea",
   "Popolo ku mai": "Solanum incompletum",
   "Poweshiek skipperling": "Oarisma poweshiek",
   "Preble's meadow jumping mouse": "Zapus hudsonius preblei",
   "Pua `ala": "Brighamia rockii",
   "Purple amole": "Chlorogalum purpureum",
   "Purple bankclimber (mussel)": "Elliptoideus sloatianus",
   "Purple bean": "Villosa perpurpurea",
   "Pu`uka`a": "Cyperus trachysanthos",
   "Quino checkerspot butterfly": "Euphydryas editha quino (=E. e. wrighti)",
   "Rabbitsfoot": "Quadrula cylindrica cylindrica",
   "Railroad Valley springfish": "Crenichthys nevadae",
   "Razorback sucker": "Xyrauchen texanus",
   "Reticulated flatwoods salamander": "Ambystoma bishopi",
   "Rio Grande Silvery Minnow": "Hybognathus amarus",
   "Riverside fairy shrimp": "Streptocephalus woottoni",
   "Robber Baron Cave Meshweaver": "Cicurina baronia",
   "Robust spineflower": "Chorizanthe robusta var. robusta",
   "Roswell springsnail": "Pyrgulopsis roswellensis",
   "Rota bridled White-eye": "Zosterops rotensis",
   "Rough hornsnail": "Pleurocera foremani",
   "Rough rabbitsfoot": "Quadrula cylindrica strigillata",
   "Round Ebonyshell": "Fusconaia rotulata",
   "Round-leaved chaff-flower": "Achyranthes splendens var. rotundata",
   "Roy Prairie pocket gopher": "Thomomys mazama glacialis",
   "Rush Darter": "Etheostoma phytophilum",
   "Sacramento Orcutt grass": "Orcuttia viscida",
   "Salt Creek Tiger beetle": "Cicindela nevadica lincolniana",
   "San Bernardino bluegrass": "Poa atropurpurea",
   "San Bernardino Merriam's kangaroo rat": "Dipodomys merriami parvus",
   "San Bernardino Mountains bladderpod": "Lesquerella kingii ssp. bernardina",
   "San Bernardino springsnail": "Pyrgulopsis bernardina",
   "San Diego ambrosia": "Ambrosia pumila",
   "San Diego fairy shrimp": "Branchinecta sandiegonensis",
   "San Diego thornmint": "Acanthomintha ilicifolia",
   "San Francisco Peaks ragwort": "Packera franciscana",
   "San Jacinto Valley crownscale": "Atriplex coronata var. notatior",
   "San Joaquin Orcutt grass": "Orcuttia inaequalis",
   "San Marcos gambusia": "Gambusia georgei",
   "San Marcos salamander": "Eurycea nana",
   "Santa Ana sucker": "Catostomus santaanae",
   "Santa Catalina Island Fox": "Urocyon littoralis catalinae",
   "Santa Cruz tarplant": "Holocarpha macradenia",
   "Scotts Valley Polygonum": "Polygonum hickmanii",
   "Scotts Valley spineflower": "Chorizanthe robusta var. hartwegii",
   "sea bean": "Mucuna sloanei var. persericea",
   "Sharpnose Shiner": "Notropis oxyrhynchus",
   "Shinyrayed pocketbook": "Lampsilis subangulata",
   "Shivwits milk-vetch": "Astragalus ampullarioides",
   "Shortnose Sucker": "Chasmistes brevirostris",
   "Short's bladderpod": "Physaria globosa",
   "Sierra Nevada bighorn sheep": "Ovis canadensis sierrae",
   "Sierra Nevada Yellow-legged Frog": "Rana sierrae",
   "Silver rice rat": "Oryzomys palustris natator",
   "Slabside Pearlymussel": "Pleuronaia dolabelloides",
   "Slackwater darter": "Etheostoma boschungi",
   "Slender chub": "Erimystax cahni",
   "Slender Orcutt grass": "Orcuttia tenuis",
   "Smalleye Shiner": "Notropis buccula",
   "Smoky madtom": "Noturus baileyi",
   "Snail darter": "Percina tanasi",
   "Sockeye salmon": "Oncorhynchus (=Salmo) nerka",
   "Soft bird's-beak": "Cordylanthus mollis ssp. mollis",
   "Solano grass": "Tuctoria mucronata",
   "Sonora chub": "Gila ditaenia",
   "Sonoyta mud turtle": "Kinosternon sonoriense longifemorale",
   "Southern acornshell": "Epioblasma othcaloogensis",
   "Southern clubshell": "Pleurobema decisum",
   "Southern kidneyshell": "Ptychobranchus jonesi",
   "Southern mountain wild-buckwheat": "Eriogonum kennedyi var. austromontanum",
   "Southern pigtoe": "Pleurobema georgianum",
   "Southern sandshell": "Hamiota australis",
   "Southwestern willow flycatcher": "Empidonax traillii extimus",
   "Spectacled eider": "Somateria fischeri",
   "Spikedace": "Meda fulgida",
   "Spotfin Chub": "Erimonax monachus",
   "Spreading navarretia": "Navarretia fossalis",
   "Spring-loving centaury": "Centaurium namophilum",
   "Spring pygmy sunfish": "Elassoma alabamae",
   "Spruce-fir moss spider": "Microhexura montivaga",
   "Staghorn coral": "Acropora cervicornis",
   "St. Andrew beach mouse": "Peromyscus polionotus peninsularis",
   "St. Croix ground lizard": "Ameiva polops",
   "Steelhead": "Oncorhynchus (=Salmo) mykiss",
   "Steller sea lion": "Eumetopias jubatus",
   "Steller's Eider": "Polysticta stelleri",
   "Streaked Horned lark": "Eremophila alpestris strigata",
   "Suisun thistle": "Cirsium hydrophilum var. hydrophilum",
   "Tapered pigtoe": "Fusconaia burkei",
   "Taylor's (=whulge) Checkerspot": "Euphydryas editha taylori",
   "Tenino pocket gopher": "Thomomys mazama tumuli",
   "Texas golden Gladecress": "Leavenworthia texana",
   "Texas wild-rice": "Zizania texana",
   "Thread-leaved brodiaea": "Brodiaea filifolia",
   "Three Forks Springsnail": "Pyrgulopsis trivialis",
   "Tidewater goby": "Eucyclogobius newberryi",
   "Todsen's pennyroyal": "Hedeoma todsenii",
   "Topeka shiner": "Notropis topeka (=tristis)",
   "Triangular Kidneyshell": "Ptychobranchus greenii",
   "Tumbling Creek cavesnail": "Antrobia culveri",
   "Uhi uhi": "Mezoneuron kavaiense",
   "Umtanum Desert buckwheat": "Eriogonum codium",
   "Upland combshell": "Epioblasma metastriata",
   "Vail Lake ceanothus": "Ceanothus ophiochilus",
   "Valley elderberry longhorn beetle": "Desmocerus californicus dimorphus",
   "Vandenberg monkeyflower": "Diplacus vandenbergensis",
   "Ventura Marsh Milk-vetch": "Astragalus pycnostachyus var. lanosissimus",
   "Vermilion darter": "Etheostoma chermocki",
   "Vernal pool fairy shrimp": "Branchinecta lynchi",
   "Vernal pool tadpole shrimp": "Lepidurus packardi",
   "Virginia big-eared bat": "Corynorhinus (=Plecotus) townsendii virginianus",
   "Virgin River Chub": "Gila seminuda (=robusta)",
   "Waccamaw silverside": "Menidia extensa",
   "Warner sucker": "Catostomus warnerensis",
   "Wawae`iole": "Huperzia nutans",
   "Webber's ivesia": "Ivesia webberi",
   "Welsh's milkweed": "Asclepias welshii",
   "Wenatchee Mountains checkermallow": "Sidalcea oregana var. calva",
   "Western snowy plover": "Charadrius nivosus nivosus",
   "West Indian Manatee": "Trichechus manatus",
   "White Bluffs bladderpod": "Physaria douglasii ssp. tuplashensis",
   "White River spinedace": "Lepidomeda albivallis",
   "White River springfish": "Crenichthys baileyi baileyi",
   "White sturgeon": "Acipenser transmontanus",
   "Whooping crane": "Grus americana",
   "Whorled Sunflower": "Helianthus verticillatus",
   "Willamette daisy": "Erigeron decumbens",
   "Willowy monardella": "Monardella viminea",
   "Woodland Caribou": "Rangifer tarandus caribou",
   "Woundfin": "Plagopterus argentissimus",
   "Yadon's piperia": "Piperia yadonii",
   "Yaqui catfish": "Ictalurus pricei",
   "Yaqui chub": "Gila purpurea",
   "Yellowcheek Darter": "Etheostoma moorei",
   "yelloweye rockfish": "Sebastes ruberrimus",
   "Yellowfin madtom": "Noturus flavipinnis",
   "Yellow larkspur": "Delphinium luteum",
   "Yellow-shouldered blackbird": "Agelaius xanthomus",
   "Yelm pocket gopher": "Thomomys mazama yelmensis",
   "Yosemite toad": "Anaxyrus canorus",
   "Zapata bladderpod": "Lesquerella thamnophila",
   "Zayante band-winged grasshopper": "Trimerotropis infantilis",
   "Zuni bluehead Sucker": "Catostomus discobolus yarrowi"
}