import React, { Component } from "react";
import Icon from "./Icon";

class Dropdown extends Component {
  // Dismisses the Inlinedropdown when another element is clicked
  container = React.createRef();
  state = {
    active: false,
    jutified: "left",
    contentClass: "dropdown__content",
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    if (this.props.justified === "right") {
      // console.log("this.props", this.props);
      let { contentClass } = this.state;
      contentClass += " dropdown__content--right";
      this.setState({ contentClass });
    }
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  open = (e) => {
    this.setState({
      active: !this.state.active,
    });
    
  };

  handleClickOutside = (event) => {
    if (
      this.container.current &&
      !this.container.current.contains(event.target)
    ) {
      this.setState({
        active: false,
      });
    }
  };

  render() {
    return (
      <div className="dropdown" ref={this.container}>
        <div
          className={
            this.state.active
              ? "dropdown__btn dropdown__btn--active"
              : "dropdown__btn"
          }
          onClick={this.open}>
          <div
            className={this.state.active ? "" : ""}>
            {this.props.title}
          </div>
          {this.props.arrow ? (
            <div>
              {this.state.active ? (
                // Won't work if this is an icon too
                <span className="icon--ko icon2-icon2__caret-up icon--sm"></span>
              ) : (
                <Icon icon="caret-down" size="sm" ko={true} />
              )}
            </div>
          ) : null}
        </div>
        <div className={this.state.contentClass}>
          {this.state.active ? this.props.children : null}
        </div>
      </div>
    );
  }
}

export default Dropdown;
