import React from "react";
import { Button, List, Text, InputComment } from "../../components";
import utils from "../../utils/utils";

export default function ChatBox(props) {
  return (
    <div className="chatbox">
      <div className="chatbox__comments">
        <List>
          {props.comments.map((c) => {
            return (
              <div className="chatbox__comment u-mgn-btm-sm" key={c.id}>
                <div className="chatbox__comment">
                  <div className="u-flex u-flex-column">
                    <div className="u-flex u-flex-justify-between u-mgn-btm-sm">
                      <Text number={2} ko={true}>
                        {c.account.first_name} {c.account.last_name}
                      </Text>
                      <Text number={2} ko={true}>
                        {utils.formatDateTime(c.date_created)}
                      </Text>
                    </div>
                    <div>
                      <Text number={2} ko={true}>
                        {c.comment}
                      </Text>
                    </div>
                    {props.account.id === c.account.id || props.isCreator ? (
                      <div className="u-mgn-top-sm">
                        <div className="chatbox__buttons">
                          {(c.propose_add || c.propose_delete) &&
                          props.isCreator ? (
                            <div
                              className="u-mgn-right-sm"
                              style={{ width: "100%" }}>
                              {c.propose_add ? (
                                <div className="u-flex">
                                  <div className="u-mgn-right-sm">
                                    <Button
                                      buttontype="secondary"
                                      size="sm"
                                      ko={true}
                                      buttonProps={{
                                        onClick: () =>
                                          props.acceptCB(
                                            c.feature,
                                            c.id,
                                            "accept",
                                          ),
                                      }}>
                                      Accept
                                    </Button>
                                  </div>
                                  <Button
                                    buttontype="secondary"
                                    size="sm"
                                    ko={true}
                                    buttonProps={{
                                      onClick: () =>
                                        props.acceptCB(
                                          c.feature,
                                          c.id,
                                          "reject",
                                        ),
                                    }}>
                                    Reject
                                  </Button>
                                </div>
                              ) : (
                                <div className="u-flex">
                                  <div className="u-mgn-right-sm">
                                    <Button
                                      buttontype="secondary"
                                      size="sm"
                                      ko={true}
                                      buttonProps={{
                                        onClick: () =>
                                          props.deleteCB(
                                            c.feature,
                                            c.id,
                                            "accept",
                                          ),
                                      }}>
                                      Accept
                                    </Button>
                                  </div>
                                  <Button
                                    buttontype="secondary"
                                    size="sm"
                                    ko={true}
                                    buttonProps={{
                                      onClick: () =>
                                        props.deleteCB(
                                          c.feature,
                                          c.id,
                                          "reject",
                                        ),
                                    }}>
                                    Reject
                                  </Button>
                                </div>
                              )}
                            </div>
                          ) : null}
                          {!(c.propose_add || c.propose_delete) &&
                          props.isCreator ? (
                            <div className="u-mgn-right-sm">
                              <Button
                                buttontype="text"
                                size="sm"
                                ko={true}
                                buttonProps={{
                                  onClick: () =>
                                    props.deleteCommentCB(c.id, c.feature),
                                }}>
                                Dismiss
                              </Button>
                            </div>
                          ) : null}
                          {!(c.propose_add || c.propose_delete) ? (
                            <span
                              className="icon--ko icon--sm icon-icon__trash"
                              onClick={() =>
                                props.deleteCommentCB(c.id, c.feature)
                              }></span>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              // </ListItem>
            );
          })}
        </List>
      </div>

      <div className="chatbox__form">
        <InputComment
          onSubmit={props.onSubmit}
          onChange={props.onChange}
          commentProps={{ name: props.name }}></InputComment>
      </div>
    </div>
  );
}
