import React, { useEffect, useRef } from "react";

export default function NavbarDropdownMenu({ navbar, menuContent }) {
  const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        navbar.setState({ ...navbar.state, dropdownMenuSource: "" });
      }
    }
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      {navbar.state.dropdownMenuSource && (
        <>
          <div className="nav-dropdown-menu" ref={ref}>
            {menuContent[navbar.state.dropdownMenuSource]}
          </div>
          <div className="nav-dropdown-menu__background"></div>
        </>
      )}
    </>
  );
}
