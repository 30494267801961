import React, { useState } from "react";

/* Components */
import DashboardHeading from "./DashboardHeading";
import { Footer } from "../../../../platform";
import MapsListTable from "./mapsList/MapsListTable";
import MapsListSelector from "./MapsListSelector";
import CreateMapButton from "./CreateMapButton";

/* Methods */
import staticLink from "../../../../utils/static";
import { Heading } from "../../../../components";

export default function CMDashboard({ account, auth }) {
  /* Determine maps list type, My Maps or Public Maps, to show when 
  component first loads based on current isAuthenticated state. */

  const initCmState = {
      /* Maps */
      isMyMapsDataChecked: false,
      isPublicMapsDataChecked: false,
      myMapsDataArray: [],
      publicMapsDataArray: [],
      mapsListSelection: "Public Maps",
      selectedDataArrayLength: 0,

      /* Search */
      // isMyMapsActive: false,
      // searchVal: "",
    },
    [cmState, setCmState] = useState(initCmState),
    cm = { initState: initCmState, setState: setCmState, state: cmState };

  // console.log("CM state: ", cm.state);

  return (
    <>
      <div className="g-dashboard__container">
        <img
          src={`${staticLink}banner/CM-Banner.png`}
          alt="Collaborative Mapper Banner"
          className="u-full-width"
        />
        <div className="g-dashboard">
          {/* LEFT MENU */}
          <MapsListSelector auth={auth} cm={cm} />

          {/* RIGHT CM DASHBOARD */}
          <div className="u-full-width">
            <DashboardHeading cm={cm} />
            <CreateMapButton account={account} auth={auth} />
            {cm.state.mapsListSelection === "Public Maps" && (
              <MapsListTable account={account} cm={cm} />
            )}
            {cm.state.mapsListSelection === "My Maps" &&
              (auth.state.isAuthenticated ? (
                <MapsListTable account={account} cm={cm} />
              ) : (
                <Heading className="u-pad-vert-lg" number={5}>
                  Please log in to view my maps.
                </Heading>
              ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
