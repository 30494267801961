const inputsValidations = {
  isCharsCountAtMostMax: () => { },
  isCharsCountAtLeastMin: (inputString, minCharsCount) => {
    const validationArray = inputString.length >= minCharsCount
      ? [true, ""]
      : [false, `Requires at least ${minCharsCount} characters.`]
    return validationArray
  },
  isConfirmPasswordCharsMatching: (password, confirmPassword) => {
    const errorMessage = "Confirm Password must match the Password entered above.",
      validationArray = (password === confirmPassword) ? [true, ""] : [false, errorMessage]
    return validationArray
  },
  isEmailFormatValid: (inputString) => {
    const validEmailFormatRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      errorMessage = "Email requires the standard email format.",
      validationArray = (validEmailFormatRegex.test(inputString) || inputString.length === 0)
        ? [true, ""]
        : [false, errorMessage]
    return validationArray
  },
  isEmailUnique: (boolean) => {
    const errorMessage = boolean ? "" : "This Email is taken.  Please use another.";
    return [boolean, errorMessage]
  },
  isInputStringNotEmpty: (inputString) => {
    const errorMessage = "This is required.",
      validationArray = (inputString.length > 0)
        ? [true, ""]
        : [false, errorMessage]
    return validationArray
  },
  isNameCharsTypesValid: (inputString) => {
    const validNameCharsRegex = /^[a-zA-Z]+[a-zA-Z ,.'-]+$/,
      errorMessage = "Name must start with one letter, followed by letters, spaces, periods, commas, apostrophes, or dashes.",
      validationArray = (validNameCharsRegex.test(inputString) || inputString.length === 0)
        ? [true, ""]
        : [false, errorMessage]
    return validationArray
  },
  isPasswordCharsTypesValid: (inputString) => {
    const passwordRegexs = {
      uppercase: /[A-Z]+/,
      lowercase: /[a-z]+/,
      special: /\W+/,
      number: /\d+/
    }
    let isValid = true
    Object.keys(passwordRegexs).forEach(
      (regexType) => {
        if (!passwordRegexs[regexType].test(inputString)) {
          isValid = false
        }
      }
    )
    const errorMessage = isValid
      ? ""
      : "Password must include at least one of each: lowercase letters, uppercase letters, numbers, and special characters."
    return [isValid, errorMessage]
  },
  isUsernameCharsTypesValid: (inputString) => {
    const validUsernameCharsRegex = /^[a-zA-Z]+[a-zA-Z0-9-_]+$/,
      errorMessage = "Username must start with one letter followed by letters, spaces, periods, commas, apostrophes, or dashes.  Must contain at least two characters.",
      validationArray = (validUsernameCharsRegex.test(inputString) || inputString.length === 0)
        ? [true, ""]
        : [false, errorMessage]
    return validationArray
  },
  isUsernameUnique: (boolean) => {
    const errorMessage = boolean ? "" : "This username is taken.  Please use another.";
    return [boolean, errorMessage]
  },
}

export default inputsValidations