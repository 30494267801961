import API from "./API";
import utils from "../../utils/utils";
import Timer from "../../utils/timer";
import L from "leaflet";

const timer = new Timer()

const helpers = {
  getLargestGeometry: fc => {
    let largestArea = 0
    let largestPolygon = fc.features[0]
    for (let i = 0; i < fc.features.length ; i++) {
      const coords = fc.features[i].coordinates || fc.features[i].geometry.coordinates
      const poly = L.polygon(coords)
      const latlngs = poly.getLatLngs()
      const area = L.GeometryUtil.length(latlngs[0]);
      if (area > largestArea) {
        largestArea = area
        largestPolygon = fc.features[i]

      }
    }
    return largestPolygon
  },
  renderTiles: function(before, after, change, map, app) {
    if (app.state.layer) {
      map.removeLayer(app.state.layer)
    }
    if (app.state.featureCollection) {
      app.setState({featureCollection: []})
    }

    app.setState({
      beforeImg: L.tileLayer(before, {opacity: 1}),
      afterImg: L.tileLayer(after, {opacity: 1}),
      changeImg: L.tileLayer(change, {opacity: 1}),
      layer: false,
      coords: "",
      loading: false,
      featureCollection: false,
      queryMade: true,
      mapZoom: 12,
    }, function() {
      app.state.beforeImg.setZIndex(1000)
      app.state.afterImg.setZIndex(1000)
      app.state.changeImg.setZIndex(1001)

      try {
        app.state.beforeImg.addTo(map)

      } catch (err) {
      }

      try {
        app.state.afterImg.addTo(map)

      } catch (err) {
        
      }

      try {
        app.state.changeImg.addTo(map)

      } catch (err) {
        
      }
  
      timer.stop()
      timer.reset()
    })
    utils.sendAlert("We've successfully retrieved all the necessary satellite data. Hang tight, tiles are currently in the process of rendering to your screen. Please stay at this zoom level for the best results.", "success")
    
    // setTimeout(() => {
    //   this.notifyUser('Change Detection Ready', 'Head over to the Change Detection app. Your query is finished and your tiles are loading.')
    // }, 100);
  },
  runAnalysis: function(req, map, app) {
    const helpers = this
    document.querySelector(".leaflet-buttons-control-button").classList.add("u-inactive")
    timer.start()
    const handleError = () => {
      req.tries += 1
      if (req.tries === 11) {

        utils.sendAlert("There were too many unsuccessful attempts to render the tiles from Google Earth Engine. Please try again.", "error")
        app.setState({coords: "", featureCollection: false, speciesImg: false, activeSpecies: false, speciesSciName: false, speciesComName: false})
        app.setState({
          loading: false,
          userIsReady: false
        })
        if (app.state.layer) {
          map.removeLayer(app.state.layer)
        }
        // helpers.notifyUser('Change Detection Failed', 'We have tried too many times to contact the Google servers with your request. A polygon covering a smaller amount of land will be more likely to succeed.')
        // $(".leaflet-buttons-control-button").removeClass("u-inactive")
        document.querySelector(".leaflet-buttons-control-button").classList.remove("u-inactive")
        timer.stop()
        timer.reset()
        return
      } else {
        utils.sendAlert("There was an issue communicating with Google's servers. We are trying again.", "error")
        helpers.runAnalysis(req, map, app)
        // helpers.notifyUser('Change Detection Retrying', "There was an issue communicating with Google's servers. We are trying again.")
      }
    }
    API.runAnalysis(req)
      .then(res => {
        app.setState({featureCollection: false}, () => {

            app.setState({featureCollection: [res.data.poly], loading: false})

        })
        // const latlngs =  L.polygon(res.data.poly.coordinates).getLatLngs()
        // const area = L.GeometryUtil.length(latlngs[0]);

        // return 
        if (res.data.beforeImg === undefined || res.data.afterImg === undefined) {
          handleError()
        } else {
          const changeOccured = res.data.change_occured
          if (!changeOccured) {
            utils.sendAlert("No change was detected using the specified parameters.", "warning")
          }
          helpers.renderTiles(res.data.beforeImg, res.data.afterImg, res.data.changeImg, map, app)
        }

      })
      .catch(err => {
        handleError()
      })
  }
}

export default helpers
