import React from "react";

/* Components */
import { Button, Heading, Text } from "../../components";
import { Footer } from "..";

/* Methods */
import routes from "../../utils/paths/routes";
import staticLink from "../../utils/static";

export default function Home() {
  return (
    <div className="home">
      {/* HERO IMAGE */}
      <section
        className="home__hero"
        style={{
          backgroundImage: `linear-gradient(to right , rgba(58,3,0,.75), rgba(0,0,0,0)), url(${staticLink}hero/HP-Hero.png)`,
        }}>
        <Heading number={2} ko={true} className="u-mgn-btm-md">
          Habitat Patrol
        </Heading>
        <Heading number={4} bold={false} ko={true} className="u-mgn-btm-lg">
          Retrospective land cover change detection.
        </Heading>
        <Button
          buttontype="secondary"
          ko={true}
          linkTopTo={routes.apps.habPatApp.path}>
          Open App
        </Button>
      </section>

      {/* APP DESCRIPTIONS */}
      <section className="home__content">
        <div className="u-align-center u-mgn-btm-lg u-textbox-lg u-mgn-auto">
          <Heading number={3}>Monitor Land Cover Change</Heading>
          <div className="u-mgn-top-sm">
            <Heading number={5} bold={false} className="u-color-black">
              Habitat Patrol puts the power of satellite imagery in your hands
              to detect habitat loss in the places you care about.
            </Heading>
          </div>
        </div>
        <div>
          <div className="u-flex u-mgn-btm-md">
            <img
              src={`${staticLink}demo-shots/habpat-demo1.png`}
              alt="Habitat Patrol Screenshot"
              className="home__demoimg"
            />
            <div className="u-mgn-left-lg u-flex u-flex-column u-flex-justify-center">
              <div className="u-textbox-lg">
                <Heading number={4}>Latest imagery</Heading>
                <Text number={2}>
                  Habitat Patrol uses the most recently available data from the
                  European Space Agency’s Sentinel-2 satellite system, provided
                  through the Google Earth Engine. With 10 meter pixel
                  resolution and updates every 5 days, our apps allow you to
                  accurately monitor landscape change for your projects.
                </Text>
              </div>
              <div className="u-mgn-top-md u-textbox-lg">
                <Heading number={4}>Leveraging AI</Heading>
                <Text number={2}>
                  Automated change detection is powered by machine learning
                  algorithms trained on millions of data points. These models
                  can detect and delineate land clearing in a wide range of
                  habitat types, including desert, forest, grassland, shrubland,
                  and wetland areas. For more information on the change
                  detection algorithms, see:{" "}
                  <a
                    href="https://conbio.onlinelibrary.wiley.com/doi/abs/10.1111/cobi.13680"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="u-color-blue u-text-underline u-hover-underline">
                    Supporting Habitat Conservation With Automated Change
                    Detection In Google Earth Engine
                  </a>{" "}
                  .
                </Text>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* BETA TESTING */}
      <section className="home__testing">
        <div className="u-flex u-flex-column u-mgn-right-md">
          <Heading number={3}>
            Interested in participating in beta testing?
          </Heading>
          <Heading number={5} bold={false}>
            Help us improve Habitat Patrol so it can be more helpful to you!
          </Heading>
          <div className="u-mgn-top-md">
            <Text number={1}>
              As a beta tester, you will be able to help us shape the future of
              our apps. Sign up and choose your level of participation to start
              helping us push the platform forward. You will also have the
              option to receive regular email updates about this app and other
              new features we plan to add in the future.
            </Text>
          </div>
          <div className="u-flex u-flex-justify-end u-mgn-top-md">
            <div className="u-mgn-right-sm">
              <Button
                buttontype="primary"
                hashLinkTo={routes.betaTesting.path.learnMore}
                size="sm">
                Learn More
              </Button>
            </div>
            <Button
              buttontype="primary"
              size="sm"
              hashLinkTo={routes.betaTesting.path.signUp}>
              Sign Up
            </Button>
          </div>
        </div>
        <img
          src={`${staticLink}graphics/HP-Graphic.png`}
          alt=""
          className="home__demoimg"
        />
      </section>

      {/* COMING SOON */}
      <section
        className="home__soon"
        style={{
          backgroundImage: `linear-gradient(#3a030088, #3a030088), url("${staticLink}section/HP-Section.png")`,
        }}>
        <div className="home__soon--left u-mgn-right-lg">
          <Heading number={4} ko={true}>
            Coming Soon
          </Heading>
          <div className="u-pad-vert-sm">
            <img
              src={`${staticLink}demo-shots/hp-ui-update.png`}
              alt="HP ecos search"
              className="home__soon--img"
            />
          </div>
          <div className="u-mgn-top-sm u-mgn-btm-md">
            <Heading number={5} ko={true}>
              Habitat Patrol Alpha-stage development and optimization is
              underway.
            </Heading>
            <Text number={2} ko={true}>
              March 30th, 2021
            </Text>
          </div>

          <div className="u-mgn-top-md">
            <Text number={2} ko={true} className="u-pad-btm-sm">
              Habitat Patrol is in the Alpha-stage of development, which means
              it currently provides a rudimentary user flow that enables the
              viewing of imagery generated by the change detection algorithm.
              The imagery is generated after choosing two dates in the
              landscape's history that are of interest, and selecting the area
              on the landscape to investigate.
            </Text>
            <Text number={2} ko={true} className="u-pad-btm-sm">
              During the computational portion of the user flow, the app may
              require one to three minutes to process the imagery data. Given
              the app's unoptimized state, we ask app users to have patience
              while computations are being performed.
            </Text>
            <Text number={2} ko={true}>
              If you are having trouble receiving or understanding the output
              from the app, please contact us using the contact form and someone
              from the development team will get back to you to help.
            </Text>
          </div>
        </div>

        <div>
          <div className="home__timeline--mgn-btm">
            <Heading number={5} ko={true}>
              Timeline
            </Heading>
          </div>
          <div className="home__box u-mgn-btm-sm">
            <div className="u-pad-btm-sm">
              <Heading number={5}>Deployment of a change detection app</Heading>
              <Text number={1}>2021</Text>
            </div>
            <Text number={2}>
              We are be performing under-the-hood improvements of HP in order to
              improve the change-detection processing time and accuracy. This
              will enable landscape conservationists to produce the analyses
              they need more quickly, and over larger areas of interest.
            </Text>
            <br />
            <Text number={2}>
              The planned technology improvements include updating the
              statistical models, currently used in the algorithm, to AI-based
              neural network models.
            </Text>
          </div>
          <div className="home__box u-mgn-btm-sm">
            <div className="u-pad-btm-sm">
              <Heading number={5}>Deployment of a change detection app</Heading>
              <Text number={1}>January, 2020</Text>
            </div>
            <Text number={2}>
              The CCI Tech Team has implemented this change-detection technology
              in the form of a web app to make the analysis process publicly
              available. The app, called Habitat Patrol or HP, uses enabling
              technologies such as Leaflet and Google Earth Engine to transform
              the algorithm into a user application.
            </Text>
          </div>
          <div className="home__box u-mgn-btm-sm">
            <div className="u-pad-btm-sm">
              <Heading number={5}>Evaluating how landscapes change</Heading>
              <Text number={1}>2018</Text>
            </div>
            <Text number={2}>
              Dr. Michael Evans has developed a "change-detection" algorithm to
              supply the USFWS with crucial data to inform the Section 4 ESA
              Listing Process for the Lesser Prairie Chicken. This algorithm is
              used to analyze satellite data taken on different dates, and
              compares the imagery data to detect habitat loss over time.
            </Text>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
