class RasterDataset {
  constructor() {
    // Private properties.
    this._attrsMap = {
      bounding_box: 'boundingBox',
      bounds: 'bounds',
      center_coords: 'centerCoords',
      data_crs: 'dataCRS',
      data_file: 'dataFilePath',
      data_file_name: 'dataFileName',
      data_file_size: 'dataFileSize',
      data_layer: 'dataLayer',
      data_type: 'dataType',
      dataset_max_val: 'datasetMaxVal',
      dataset_min_val: 'datasetMinVal',
      driver: 'driver',
      geospatial_crs: 'geospatialCRS',
      height: 'height',
      id: 'ID',
      img_file: 'imgFilePath',
      img_file_name: 'imgFileName',
      img_file_size: 'imgFileSize',
      is_img_loaded: 'isImgLoaded',
      is_validated: 'isValidated',
      no_data_val: 'noDataVal',
      num_of_bands: 'numOfBands',
      original_data_crs: 'originalDataCRS',
      pixel_count: 'pixelCount',
      pixel_resolution_epsg_3857: 'pixelResolutionEPSG3857',
      pixel_resolution_epsg_4326: 'pixelResolutionEPSG4326',
      width: 'width',
    }
    this._resData = {}

    // Public properties.
    this.data = {}
  }

  /**
   * @param {{ }} resDataObj - Object containing response data from a CVMOrigRstDatasetsCtrl GET req.
   */

  setResData(resDataObj) {
    for (const key in resDataObj) {
      const newKey = this._attrsMap[key] ? this._attrsMap[key] : key
      this._resData[newKey] = resDataObj[key]
    }
  }

  setData() {
    for (const key1 in this._resData) {
      this.data[key1] = {}
      const value1 = this._resData[key1]
      this.data[key1]['value'] = value1

      const decoratorObj = {
        boundingBox: {
          label: 'Bounding Box',
          textValueFn: () => `( ${value1.map(
            (subValue) => ` ${subValue.toFixed(3)}`,
          )} )`,
          units: '[W deg. lng., S deg. lat., E deg. lng., N deg. lat.]',
        },
        bounds: {
          label: 'Bounds',
          textValueFn: () => `${value1.map(
            (subValue) =>
              ` ( ${subValue.map(
                (subSubValue) => ` ${subSubValue.toFixed(3)}`,
              )} )`,
          )}`,
          units: '( [S deg. lat.], [W deg. lng.] ), ( [N. deg. lat.], [E deg. lng.] )',
        },
        centerCoords: {
          label: 'Center Coordinates',
          textValueFn: () => `( ${value1.map(
            (subValue) => ` ${subValue.toFixed(3)}`,
          )} )`,
          units: '( [deg. lat.], [deg. lng.] )',
        },
        dataCRS: {
          label: 'Data Coordinate Reference System',
          textValueFn: () => `${value1}, "Pseudo-Mercator"`,
          units: '[m]',
        },
        dataFileName: { label: 'Data File Name' },
        dataFilePath: {},
        dataFileSize: {
          label: 'Data File Size',
          textValueFn: () => `${(value1 / 1e6).toFixed(1)}`,
          units: '[MB]'
        },
        dataLayer: {},
        datasetMaxVal: {},
        datasetMinVal: {},
        dataType: { label: 'Data Type' },
        driver: { label: 'Data File Type' },
        geospatialCRS: {
          label: 'Coordinate Reference System',
          units: '[deg.]',
        },
        height: {
          label: 'Height',
          textValueFn: () => `${value1.toExponential(3)}`,
          units: '[px]'
        },
        ID: {},
        imgFileName: { label: 'Image File Name' },
        imgFilePath: {},
        imgFileSize: {
          label: 'Image File Size',
          textValueFn: () => `${(value1 / 1e6).toFixed(1)}`,
          units: '[MB]',
        },
        isValidated: {},
        isImgLoaded: {},
        noDataVal: { label: "'No-Data' Value" },
        numOfBands: { label: 'Number Of Bands' },
        originalDataCRS: {
          label: 'Original Data File Coordinate Reference System',
          textUnitsFn: () => {
            if (value1 === 'EPSG:3857') {
              return `[m]`
            } else if (value1 === 'EPSG:4326') {
              return `[deg.]`
            }
          },
          textValueFn: () => {
            if (value1 === 'EPSG:3857') {
              return `${value1}, "Pseudo-Mercator"`
            } else if (value1 === 'EPSG:4326') {
              return `${value1}, "World Geodetic System"`
            } else {
              return `${value1}`
            }
          },
        },
        pixelCount: {
          label: 'Pixel Count',
          textValueFn: () => `${value1.toExponential(3)}`,
          units: '[px]',
        },
        pixelResolutionEPSG3857: {
          label: 'Pixel Resolution In Meters',
          textValueFn: () => `( ${value1.map(
            (subValue) => ` ${subValue.toFixed(1)}`,
          )} )`,
          units: '( [vert. m / px], [horiz. m / px] )',
        },
        pixelResolutionEPSG4326: {
          label: 'Pixel Resolution In Degrees',
          textValueFn: () => `( ${value1.map(
            (subValue) => ` ${subValue.toExponential(3)}`,
          )} )`,
          units: '( [deg. lat. / px], [deg. lng. / px] )',
        },
        width: {
          label: 'Width',
          textValueFn: () => `${value1.toExponential(3)}`,
          units: '[px]',
        },
      }

      if (Object.keys(decoratorObj[key1]).length > 0) {
        for (const [key2, value2] of Object.entries(decoratorObj[key1])) {
          this.data[key1][key2] = value2
        }
      }
    }
  }
}

export default RasterDataset