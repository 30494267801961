import React from "react";

/* Components */
import DeleteMapModal from "./DeleteMapModal";
import { Button } from "../../../../../../components";

export default function DeleteMapButton({ app }) {
  return (
    <>
      <Button
        buttonProps={{
          onClick: () => app.toggleModal("deleteMap"),
        }}
        buttontype="primary"
        ko={true}>
        Delete Map
      </Button>
      <DeleteMapModal app={app} />
    </>
  );
}
