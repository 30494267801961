import React, { useEffect, useState } from 'react'

// Components
import AppDataLoadingModal from './AppDataLoadingModal'
import MapContainer from './map/MapContainer'
import ProjectConfigMenu from './menu/ProjectConfigMenu'

function AppUI() {
  // Project data handler.
  const projectDataInit = {},
    [projectData, setProjectData] = useState(projectDataInit),
    projectHandler = {
      dataInit: projectDataInit,
      data: projectData,
      setData: setProjectData,
    }
  // console.log('projectHandler.data: ', projectHandler.data)

  // Map data handlers.
  const mapDataInit = {},
    [mapData, setMapData] = useState(mapDataInit),
    mapHandler = { dataInit: mapDataInit, data: mapData, setData: setMapData }
  // console.log('mapHandler.data: ', mapHandler.data)

  // DataLayer data handlers.
  const dataLayersDataInit = [],
    [dataLayersData, setDataLayersData] = useState(dataLayersDataInit),
    dataLayers = {
      dataInit: dataLayersDataInit,
      data: dataLayersData,
      setData: setDataLayersData,
    }
  // console.log('dataLayers.data: ', dataLayers.data)

  // Modal handlers.
  const modalInit = { activeModal: '' },
    [modalState, setModalState] = useState(modalInit),
    modalHandler = {
      stateInit: modalInit,
      state: modalState,
      setState: setModalState,
    }

  // Load AppDataLoadingModal on AppUI load to prevent user from interacting
  // with app until until all app data has loaded.
  useEffect(() => {
    modalHandler.setState({
      ...modalHandler.state,
      activeModal: 'AppDataLoadingModal',
    })
  }, [])

  return (
    <div className='cvm__app-ui-container'>
      <ProjectConfigMenu
        dataLayers={dataLayers}
        mapHandler={mapHandler}
        projectHandler={projectHandler}
      />
      <MapContainer dataLayersHandler={dataLayers} mapHandler={mapHandler} />
      <AppDataLoadingModal
        dataLayers={dataLayers}
        mapHandler={mapHandler}
        modalHandler={modalHandler}
        projectHandler={projectHandler}
      />
    </div>
  )
}

export default AppUI
