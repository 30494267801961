import { Span, Text } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGSpan() {
  const content = {
    name: "",
    variantName: "Span",
    description: `A pre-styled p tag.`,
    implementation: `To be used whenever rendering "regular" text. If no additional styling is specified, the Span will adopt the styling of its parent element.`,
    propsTable: [ 
      [
        "className",
        "string",
        "array of className strings",
        "no",
        "Add desired classes to the array to customize the styling of the Span component.",
      ],
    ],
    codeExample: `
    import { Span } from "/components";
            
    return (

      <Text number={1}>This is the parent <Span className="u-color-blue u-text-bold"> this is a Span </Span> ...And the parent again </Text>

      <Text number={2}>This is the parent <Span className="u-text-italic"> this is a Span KO </Span> ...And the parent again </Text>

    
    )
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-pad-sm">
            <div className="u-mgn-vert-sm">
            <Text number={1}>This is the parent <Span className="u-color-blue u-text-bold"> Span 2 KO </Span> ...And the parent again </Text>
            </div>
            <div className="u-mgn-vert-sm">
            <Text number={2}>This is the parent <Span className="u-text-italic"> Span 2 KO </Span> ...And the parent again </Text>
            </div>
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
