import axios from "axios"

// Request headers.
function getHeadersConfig(type) {
  let headersConfig = { headers: {} }
  const token = localStorage.getItem('token'), formattedToken = `JWT ${token}`;

  if (token) {
    headersConfig = { headers: { Authorization: formattedToken } }
    if (type === "fileUpload") {
      headersConfig.headers['content-type'] = 'multipart/form-data'
    }
  }
  return headersConfig
}

export const headers = {
  getHeadersConfig,
}

// Controller URLs.
export const urls = {
  dataLayersController: "/api/conservation_value_mapper/data_layers_ctrl",
  mapsController: "/api/conservation_value_mapper/maps_ctrl",
  rastersController: "/api/conservation_value_mapper/orig_rst_datasets_ctrl",
  projectsController: "/api/conservation_value_mapper/projects_ctrl",
  valRastersController: '/api/conservation_value_mapper/val_rst_datasets_ctrl',
}

// Request methods.
const reqs = {
  dataLayers: {
    delete: async function (dataLayerID) {
      const queryParams = new URLSearchParams({ dataLayerID }),
        headersConfig = getHeadersConfig(),
        res = await axios.delete(`${urls.dataLayersController}?${queryParams}`, headersConfig)
      return res
    },
    getAll: async function (projectID) {
      const queryParams = new URLSearchParams({ projectID, type: "all" }),
        headersConfig = getHeadersConfig(),
        res = await axios.get(`${urls.dataLayersController}?${queryParams}`, headersConfig)
      return res
    },
    getByID: async function (dataLayerID) {
      const queryParams = new URLSearchParams({ dataLayerID, type: "byID" }),
        headersConfig = getHeadersConfig(),
        res = await axios.get(`${urls.dataLayersController}?${queryParams}`, headersConfig)
      return res
    },
    post: async function (projectID) {
      const queryParams = new URLSearchParams({ projectID }),
        headersConfig = getHeadersConfig(),
        res = await axios.post(`${urls.dataLayersController}?${queryParams}`, headersConfig)
      return res
    },
    putAttrs: async function (dataLayerID, keyValUpdateArray) {
      const queryParams = new URLSearchParams({
        dataLayerID,
        keyValUpdateArray: JSON.stringify(keyValUpdateArray),
        type: "attrs",
      }),
        headersConfig = getHeadersConfig(),
        res = await axios.put(`${urls.dataLayersController}?${queryParams}`, headersConfig)
      return res
    },
    putColor: async function (dataLayerID) {
      const queryParams = new URLSearchParams({
        dataLayerID,
        type: "color",
      }),
        headersConfig = getHeadersConfig(),
        res = await axios.put(`${urls.dataLayersController}?${queryParams}`, headersConfig)
      return res
    },
  },
  maps: {
    getByID: async function (projectID) {
      const queryParams = new URLSearchParams({ projectID }),
        headersConfig = getHeadersConfig(),
        res = await axios.get(`${urls.mapsController}?${queryParams}`, headersConfig)
      return res
    },
    post: async function (projectID) {
      const queryParams = new URLSearchParams({ projectID }),
        headersConfig = getHeadersConfig(),
        res = await axios.post(`${urls.mapsController}?${queryParams}`, headersConfig)
      return res
    },
    put: async function (mapID, keyValUpdateArray) {
      const queryParams = new URLSearchParams({
        mapID,
        keyValUpdateArray: JSON.stringify(keyValUpdateArray)
      }),
        headersConfig = getHeadersConfig(),
        res = await axios.put(`${urls.mapsController}?${queryParams}`, headersConfig)
      return res
    }
  },
  projects: {
    delete: async function (projectID) {
      const queryParams = new URLSearchParams({ projectID }),
        headersConfig = getHeadersConfig(),
        res = await axios.delete(`${urls.projectsController}?${queryParams}`, headersConfig)
      return res
    },
    getAll: async function (accountID) {
      const queryParams = new URLSearchParams({ accountID, type: "all" }),
        headersConfig = getHeadersConfig(),
        res = await axios.get(`${urls.projectsController}?${queryParams}`, headersConfig)
      return res
    },
    getByID: async function (projectID) {
      const queryParams = new URLSearchParams({ projectID, type: "byID" }),
        headersConfig = getHeadersConfig(),
        res = await axios.get(`${urls.projectsController}?${queryParams}`, headersConfig)
      return res
    },
    post: async function (accountID) {
      const queryParams = new URLSearchParams({ accountID }),
        headersConfig = getHeadersConfig(),
        res = await axios.post(`${urls.projectsController}?${queryParams}`, headersConfig)
      return res
    },
    put: async function (projectID, keyValUpdateArray) {
      const queryParams = new URLSearchParams({
        projectID,
        keyValUpdateArray: JSON.stringify(keyValUpdateArray)
      }),
        headersConfig = getHeadersConfig(),
        res = await axios.put(`${urls.projectsController}?${queryParams}`, headersConfig)
      return res
    }
  },
  rasters: {
    getAll: async function (projectID) {
      const queryParams = new URLSearchParams({ projectID, type: "all" }),
        headersConfig = getHeadersConfig(),
        res = await axios.get(`${urls.rastersController}?${queryParams}`, headersConfig)
      return res
    },
    getByID: async function (rasterID) {
      const queryParams = new URLSearchParams({ rasterID, type: "byID" }),
        headersConfig = getHeadersConfig(),
        res = await axios.get(`${urls.rastersController}?${queryParams}`, headersConfig)
      return res
    },
    put: async function (rasterID, type) {
      const queryParams = new URLSearchParams({ rasterID, type }),
        headersConfig = getHeadersConfig(),
        res = await axios.put(`${urls.rastersController}?${queryParams}`, headersConfig)
      return res
    }
  },
  valRasters: {
    delete: async function (valRstDatasetID) {
      const queryParams = new URLSearchParams({ valRstDatasetID }),
        headersConfig = getHeadersConfig(),
        res = await axios.delete(`${urls.valRastersController}?${queryParams}`, headersConfig)
      return res
    },
    getAll: async function (projectID) {
      const queryParams = new URLSearchParams({ projectID, type: 'all' }),
        headersConfig = getHeadersConfig(),
        res = await axios.get(`${urls.valRastersController}?${queryParams}`, headersConfig)
      return res
    },
    getByID: async function (valRstDatasetID) {
      const queryParams = new URLSearchParams({ valRstDatasetID, type: 'byID' }),
        headersConfig = getHeadersConfig(),
        res = await axios.get(`${urls.valRastersController}?${queryParams}`, headersConfig)
      return res
    },
    post: async function (colorPalette, dataLayerID, fnCoeffs, fnType) {
      const queryParams = new URLSearchParams({ colorPalette, dataLayerID, fnCoeffs, fnType }),
        headersConfig = getHeadersConfig(),
        res = await axios.post(`${urls.valRastersController}?${queryParams}`, headersConfig)
      return res
    },
    put: async function (valRstDatasetID, type) {
      const queryParams = new URLSearchParams({ valRstDatasetID, type }),
        headersConfig = getHeadersConfig(),
        res = await axios.put(`${urls.valRastersController}?${queryParams}`, headersConfig)
      return res
    }
  },
}

export default reqs