import React from "react";

/* Components */
import { Heading, Icon, Text, Tooltip } from "../../../../../../components";
import ImportGBIFDataModal from "./ImportGBIFDataModal";

export default function ReferenceLayers({ app }) {
  const deleteGBIFLayer = () => {
    let { gbifLayer, map } = app.state;
    map.removeLayer(gbifLayer);
    app.setState({ gbifLayer: false, gbifName: false });
  };

  return (
    <>
      <div className="u-mgn-top-md u-mgn-btm-sm u-flex u-flex-justify-between">
        <Heading number={5} ko={true}>
          Reference Layers
        </Heading>
        <div
          onClick={() => {
            app.toggleModal(`addReferenceLayerGBIF`);
          }}>
          <Tooltip body="Import Species Occurrence Reference Data" ko={true}>
            <Icon icon="search" ko={true} size="md" />
          </Tooltip>
        </div>
      </div>
      {app.state.gbifLayer && (
        <div className="rrm__layer-group__container u-pad-sm">
          <Text className="rrm__layer-group-name" ko={true} number={2}>
            {app.state.gbifName} (GBIF)
          </Text>
          <div onClick={deleteGBIFLayer}>
            <Tooltip body="Delete Layer" ko={true}>
              <Icon icon="trash" ko={true} size="md" />
            </Tooltip>
          </div>
        </div>
      )}
      <ImportGBIFDataModal app={app} />
    </>
  );
}
