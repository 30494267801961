import React from "react";

export default function Span({className, children}) {
  if (!className) {
    className=""
  }

  return (
    <>
      <span className={className}>{children}</span>        
    </>
  );
}