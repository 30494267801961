import React from "react";

/* Components */
import { Heading } from "../../../../../../components";

const MapTitle = ({ app }) => {
  return (
    <div className="u-mgn-btm-md">
      <Heading className="u-pad-btm-sm" ko={true} number={5}>
        Title
      </Heading>
      <Heading number={5} ko={true} bold={false}>
        {app.state.name}
      </Heading>
    </div>
  );
};

export default MapTitle;
