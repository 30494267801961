import React from "react";

export default function Label({ className, ko, labelProps = {}, labelText }) {
  let labelClassName = "form__label";
  if (ko) {
    labelClassName += " form__label--ko ";
  }
  return (
    <label className={`${labelClassName} ${className}`} {...labelProps}>
      {labelText}
    </label>
  );
}
