import React from "react";

// Components
import { Link } from "react-router-dom";
import { Icon, TableRow, TD, Text, Tooltip } from "../../../components";

// Utilities
import routes from "../../../utils/paths/routes";

export default function ProjectsListElement({ dashboard, projectDataEntry }) {
  return (
    <>
      <TableRow key={projectDataEntry.id}>
        <TD>
          <div className="u-flex u-flex-justify-center">
            <Link
              to={`${routes.apps.cvmApp.subtitlePath}/${projectDataEntry.id}`}>
              <Text
                number={1}
                className={
                  "hyperlink u-hover-underline cm-dash__maps-list-table__text"
                }>
                {projectDataEntry.title}
              </Text>
            </Link>
          </div>
        </TD>
        <TD>
          <Text number={1} className={"cvm__projects-list-table__text"}>
            {projectDataEntry.date_created.split("T")[0]}
          </Text>
        </TD>
        <TD>
          <Tooltip body="Delete Project">
            <div
              onClick={() => {
                dashboard.setState({
                  ...dashboard.state,
                  activeModal: "deleteProjectModal",
                  activeModalData: projectDataEntry,
                });
              }}>
              <Icon icon="trash" />
            </div>
          </Tooltip>
        </TD>
      </TableRow>
    </>
  );
}
