import React from "react";
import { Table, TableBody, TableHead, TableRow, TH, TD } from "../Table";

export default function SGPropsTable({ bodyContent }) {
  const head = ["Name", "Type", "Example", "Required", "Description"];
  const tableHeadContents = (
      <TableHead>
        <TableRow>
          {head.map((rowElement, index) => {
            return (
              <TH className="sg-components__props-table" key={index}>
                {rowElement}
              </TH>
            );
          })}
        </TableRow>
      </TableHead>
    ),
    tableBodyContents = (
      <TableBody>
        {bodyContent.map((row, index) => {
          const rowContent = row.map((rowElement, index) => {
            let columnClassName;
            switch (index) {
              case 0:
                columnClassName = "sg-components__props-table--code";
                break;
              case 1:
                columnClassName = "sg-components__props-table--italic";
                break;
              case 2:
                columnClassName = "sg-components__props-table--code";
                break;
              case 3:
                columnClassName = "sg-components__props-table--bold";
                break;
              default:
                columnClassName = "";
            }

            return (
              <TD key={index}>
                <span
                  className={`sg-components__props-table paragraph__1" ${columnClassName}`}>
                  {rowElement}
                </span>
              </TD>
            );
          });
          return <TableRow key={index}>{rowContent}</TableRow>;
        })}
      </TableBody>
    );

  return (
    <Table>
      {tableHeadContents}
      {tableBodyContents}
    </Table>
  );
}
