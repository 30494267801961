import L from "leaflet";
import API from "./API";
import helpers from "./helpers";
import renderFeatures from "./features";
import utils from "../../utils/utils";
import $ from "jquery";
// import features from "./features";

const listeners = app => {

  $(document).on("click", "#changes", function (e) {
    const { changesActive } = app.state
    app.setState({ changesActive: !changesActive })
  })

  // PROCESS EDIT
  app.state.featureGroup.on('pm:update', function (e) {
    if (e.sourceTarget.feature.geometry.type === "Point") {
      return
    }
    let { editTracker } = app.state
    const DBID = e.sourceTarget.feature.id
    const LID = e.sourceTarget.feature.lid
    let feature = app.state.featureGroup._layers[LID].feature
    const latlngs = app.state.featureGroup._layers[LID]._latlngs
    // const layer = feature // USE TBD
    feature = helpers.updateGeojson(feature, latlngs)
    feature = JSON.stringify(feature)
    const req = {
      DBID,
      feature
    }
    if (app.state.isCreator) {
      API.updateFeature(req)
      app.commitChange({ geometry: feature, id: DBID }, "edit", () => API.updateFeature(req))
    } else if (app.state.isEditor) {
      req["latestVersion"] = editTracker[DBID]
      req["mapID"] = app.state.mapID
      API.proposeEdit(req)
        .then(res => {
          editTracker[DBID] = feature
          app.setState({ editTracker })
        })
    }
    // app.stageChange(layer, DBID)
  })

  app.state.featureGroup.on('pm:edit', function (e) {
    let feature = e.sourceTarget.feature
    const DBID = e.sourceTarget.feature.id
    // app.toggleModal("metadata")
    if (feature.type === "Point" || feature.geometry.type === "Point") {
      const latlngs = e.sourceTarget._latlng
      feature = helpers.updateGeojson(feature, latlngs)
      feature = JSON.stringify(feature)
      const req = {
        DBID,
        feature
      }
      if (app.state.isCreator) {
        API.updateFeature(req)
          .then(res => {
            app.commitChange({ geometry: feature, id: DBID }, "edit", () => API.updateFeature(req))
          })
      } else if (app.state.isEditor) {
        utils.sendAlert("To edit point data, propose a new point and/or delete the old one")
      }
    } else {
      app.stageChange(feature, DBID)
    }
  })

  // PROCESS NEWLY DRAWN FEATURES
  app.state.map.on("pm:create", function (e) {
    let feature = e.layer.toGeoJSON()
    if (app.state.isEditor) {
      feature.properties["editType"] = "proposeAdd"
    }
    feature = JSON.stringify(feature)
    const mapID = app.state.mapID
    const req = {
      feature,
      mapID
    }

    if (app.state.isCreator) {
      API.addFeature(req)
        .then(res => {
          app.state.features.push(res.data)
          renderFeatures(app)
          app.state.map.removeLayer(e.layer)
          app.commitChange({ geometry: feature }, "create", () => API.addFeature(req))
          feature = JSON.parse(feature)
          feature["properties"]["dataset"] = "New features"
          app.stageChange(feature, res.data.id)
        })
    } else if (app.state.isEditor) {
      req["mapID"] = app.state.mapID
      req["type"] = "add"
      API.proposeFeature(req)
        .then(res => {

        })
    }
  })

  // DELETE FEATURES
  app.state.map.on('pm:remove', function (e) {
    const DBID = e.layer.feature.id
    if (app.state.isCreator) {
      const req = {
        id: DBID
      }
      const query = utils.objToQueryParams(req)
      API.deleteFeature(query)
        .then(res => {
          app.state.map.closePopup()
          app.commitChange({ geometry: e.layer.feature, id: DBID }, "delete", () => API.deleteFeature(query))
          app.stageChange(e.layer.feature, DBID)
        })
    } else if (app.state.isEditor) {
      const feature = e.layer.feature
      const { type } = feature.geometry
      const replacementLayer = L.geoJSON(feature)
      replacementLayer.addTo(app.state.map)
      for (let key in replacementLayer._layers) {
        if (type === "Point") {
          replacementLayer._layers[key]._icon.classList.add("leaf-marker-red")
        } else {
          replacementLayer.setStyle({
            "color": "red"
          })
        }
      }
      const req = { type: "delete", id: DBID }
      API.proposeFeature(req)
        .then(res => {
          app.state.map.closePopup()
        })
    }
  })

  /* USE TBD */
  /* const undoExample = [
    {
      geometry: {},
      // create, delete, edit
      action: "",
      // Just redo the call that saved the change in the first place
      apiCall: () => { }
    }
  ] */

  $(document).on("click", "#undo", function (e) {
    const { undo, latestChange, redo } = app.state
    const feature = undo.pop()
    redo.push(latestChange)
    app.setState({ latestChange: feature, undo, redo })
    API.undoChange({
      geometry: feature.geometry,
      action: feature.action
    })
      .then(res => {
        app.renderFeatures()
      })
  })

  $(document).on("click", "#redo", function (e) {
    const { redo } = app.state
    const feature = redo.pop()
    const { apiCall } = feature
    apiCall()
      .then(res => {
      })
    // API.redoChange({})
  })
}

export default listeners