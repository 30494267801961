import React, { useState } from "react";
import staticLink from "../utils/static";

export default function Loading(props) {
  const initState = {
    imgClass: "loading__img",
  };

  const [state, setState] = useState(initState);

  useState(() => {
    let { imgClass } = state;
    if (props.size === "lg") {
      imgClass += " loading__img--lg";
    } else if (props.size === "sm") {
      imgClass += " loading__img--sm";
    } else {
      imgClass += " loading__img--md";
    }

    setState({ ...state, imgClass });
  });
  // Internet Explorer 6-11
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;
  const isEdge = !isIE && !!window.StyleMedia;
  return (
    <span className="loading">
      {isEdge ? (
        <img
          src={`${staticLink}icon/Rolling-1s-200px.svg`}
          alt="Loading"
          className={`${state.imgClass} loading__spin`}
        />
      ) : (
        <img
          src={`${staticLink}icon/Rolling-1s-200px.svg`}
          alt="Loading"
          className={state.imgClass}
        />
      )}
    </span>
  );
}
