import React from "react";
import { Heading, Icon } from "../../components";

export default function NavbarDropdownButton({ category, navbar }) {
  const selectedClassName =
    navbar.state.dropdownMenuSource === category
      ? "nav-dropdown-button--selected"
      : "";

  return (
    <div
      className={`nav-dropdown-button ${selectedClassName}`}
      onClick={() => {
        if (navbar.state.dropdownMenuSource !== category) {
          navbar.setState({
            ...navbar.state,
            dropdownMenuSource: category,
          });
        }
      }}>
      <Heading bold={false} ko={true} number={5}>
        <span className="u-mgn-sm">{category}</span>
        {navbar.state.dropdownMenuSource === category && (
          <Icon ko={true} icon="caret-up" size="sm" />
        )}
        {navbar.state.dropdownMenuSource !== category && (
          <Icon ko={true} icon="caret-down" size="sm" />
        )}
      </Heading>
    </div>
  );
}
