import { Button, Heading } from "../../components";
import React from "react";

export default function CardSmProfile({
  buttonText,
  headerText,
  href,
  imgProps = {},
  /* linkPath, */
  linkTopTo,
  newTab,
  onClick = () => {},
}) {
  const imgElement = imgProps.src ? (
    <img className="card__sm__img" src={imgProps.src} alt={imgProps.alt} />
  ) : (
    <div className="card__sm__img-cir card__sm__img-cir--no-img"></div>
  );
  /* let externalLink = false;
  if (linkPath) {
    linkPath.startsWith("http")
      ? (externalLink = true)
      : (externalLink = false);
  } */
  return (
    <div className="card__sm">
      <div className="u-mgn-btm-sm">{imgElement}</div>
      <div className="card__sm__header-container u-mgn-btm-sm">
        <Heading number={5}>{headerText}</Heading>
      </div>
      <div className="card__sm__button-container">
        <Button
          buttontype="text"
          linkTopTo={linkTopTo}
          href={href}
          newTab={newTab}>
          <span onClick={onClick}>{buttonText}</span>
        </Button>
      </div>
    </div>
  );
}
