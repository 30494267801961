import React from "react";
import { Link } from "react-router-dom";
import utils from "../utils/utils";

export default function LinkTop({ className = "", to, style = {}, ...props }) {
  const handleViewReset = () => {
    utils.scrollToTop();
  };
  return (
    <Link to={to} className={className} onClick={handleViewReset} style={style}>
      {props.children}
    </Link>
  );
}
