import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { LinkTop } from ".";
import React from "react";

export default function Button({
  buttonProps = {},
  buttontype,
  children,
  className = "",
  disabled = false,
  hashLinkTo,
  href,
  ko,
  linkTo,
  linkTopTo,
  newTab = false,
  size,
}) {
  // Apply main button style.
  let classes = "button";
  if (buttontype) {
    classes += ` button__${buttontype}`;
    if (size) {
      classes += ` button__${buttontype}--${size}`;
    }
  }

  // Apply 'knock-out' or 'ko' button style.
  if (ko) {
    let secondaryClass = classes.split(" ");
    secondaryClass = secondaryClass[1];
    let modifierClass = secondaryClass + "--ko";
    modifierClass = " " + modifierClass;
    classes += modifierClass;
  }

  // Apply 'disabled' button style.
  if (disabled) {
    classes += " button--disabled";
  }

  const buttonCore = (
    <button
      {...buttonProps}
      className={`${classes} ${className}`}
      disabled={disabled}>
      {children}
    </button>
  );

  // Security setting if linking to 3rd party websites.
  const [target, rel] = newTab ? ["_blank", "noopener noreferrer"] : ["", ""];

  return (
    <>
      {hashLinkTo && <HashLink to={hashLinkTo}>{buttonCore}</HashLink>}
      {href && (
        <a href={href} target={target} rel={rel}>
          {buttonCore}
        </a>
      )}
      {linkTo && <Link to={linkTo}>{buttonCore}</Link>}
      {linkTopTo && <LinkTop to={linkTopTo}>{buttonCore}</LinkTop>}
      {!hashLinkTo && !href && !linkTopTo && !linkTo && buttonCore}
    </>
  );
}
