import React, { useEffect, useState } from 'react'
import $ from 'jquery'

// Components
import AboutSection from './aboutSection/AboutSection'
import DataSection from './dataSection/DataSection'
import { CollapsibleH, List } from '../../../../components'

export default function ProjectConfigMenu({
  dataLayers,
  mapHandler,
  projectHandler,
}) {
  const projectConfigMenuStateInit = {
      activeModal: '',
      activeModalData: null,
      isMenuOpen: true,
      openSection: '',
    },
    [projectConfigMenuState, setProjectConfigMenuState] = useState(
      projectConfigMenuStateInit,
    ),
    pcm = {
      stateInit: projectConfigMenuStateInit,
      state: projectConfigMenuState,
      setState: setProjectConfigMenuState,
    }
  // console.log('projectConfigMenuState: ', projectConfigMenuState)

  useEffect(() => {
    const scaleBarElement = $('.leaflet-control-scale')
    if (!pcm.state.isMenuOpen) {
      scaleBarElement
        .removeClass('g__map__scale-control--with-open-config-menu')
        .addClass('g__map__scale-control--without-open-config-menu')
    } else {
      scaleBarElement
        .removeClass('g__map__scale-control--without-open-config-menu')
        .addClass('g__map__scale-control--with-open-config-menu')
    }
  }, [pcm.state.isMenuOpen])

  return (
    <div
      className={`g__map__config-menu g__map__config-menu${
        pcm.state.isMenuOpen ? `--open` : `--closed`
      }`}>
      <CollapsibleH
        isOpen={pcm.state.isMenuOpen}
        arrow={true}
        altColors={true}
        title='Project Configuration'
        ko={true}
        fullHeight={true}
        onToggle={() =>
          pcm.setState({
            ...pcm.state,
            isMenuOpen: !pcm.state.isMenuOpen,
          })
        }>
        <List>
          <AboutSection pcm={pcm} projectHandler={projectHandler} />
          <DataSection
            dataLayers={dataLayers}
            mapHandler={mapHandler}
            pcm={pcm}
          />
        </List>
      </CollapsibleH>
    </div>
  )
}
