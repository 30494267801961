import React from "react";

// Components
import { Button } from "../../../../../components";
import DeleteProjectModal from "./DeleteProjectModal";

export default function DeleteProjectButton({ pcm, projectHandler }) {
  return (
    <>
      <Button
        buttonProps={{
          onClick: () =>
            pcm.setState({
              ...pcm.state,
              activeModal: "deleteProjectModal",
              activeModalData: projectHandler.data,
            }),
        }}
        buttontype="primary"
        ko={true}>
        Delete Project
      </Button>
      <DeleteProjectModal pcm={pcm} />
    </>
  );
}
