import { InputText, Label } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGLabel() {
  const content = {
    name: "Label",
    variantName: "",
    description: `A customized version of the <label> element.`,
    implementation:
      "Same as a <label> element with additional props listed below.",
    propsTable: [
      [
        "className",
        "String",
        `u-mgn-sm`,
        "no",
        "Add utility, custom, etc. class name.",
      ],
      [
        "ko",
        "Boolean",
        `true, false`,
        "no",
        "Pass in any of the values displayed below the desired icon above.",
      ],
      [
        "labelText",
        "String",
        "I am a label",
        "yes",
        "Adds text to populate the label.",
      ],
      [
        "labelProps",
        "JSX input attributes",
        `Any valid attribute that is applicable to a html <label> element`,
        "no",
        `Adds props as would normally be applied to a <label> element.`,
      ],
    ],
    links: [
      {
        "MDN Label":
          "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label",
      },
    ],
    codeExample: `
    import { Input, Label } from "/components";

    function handleChange(e) {
      setState({...state, [e.target.name]: e.target.value})
    }

    <Label labelText="label" ko={true} labelProps={{for: "Input1"}}/>
    <InputText inputProps={{onChange:handleChange, placeholder:"placeholder text", type:"email", id: "Input1"}}/>
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-mgn-btm-sm">
            <Label labelText="label" />
            <InputText inputProps={{ placeholder: "placeholder text" }} />
          </div>
          <div className="u-background-blue u-pad-sm">
            <Label labelText="label" ko={true} />
            <InputText
              small={true}
              inputProps={{ placeholder: "placeholder text" }}
              ko={true}
            />
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      links={content.links}
      codeExample={content.codeExample}
    />
  );
}
