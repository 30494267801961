import React from "react";

/* Components */
import { ListItem, LinkTop, Text } from "../../../../../../components";

export default function UserDataListItem({ user, buttonsElement = <></> }) {
  return (
    <>
      <ListItem
        key={user.id}
        thumbnail={
          user.photo === null || user.photo.includes("default_prof") // This replaces legacy default profile image ref
            ? "media/prof_pics/profile_default.svg"
            : user.photo
        }>
        <div className="u-flex u-full-width u-flex-justify-between">
          <div className="u-flex u-flex-column u-flex-justify-center u-mgn-left-md">
            <Text className="u-mgn-right-sm u-text-bold" ko={true} number={1}>
              {user.first_name} {user.last_name}
            </Text>
            {user.organization && (
              <Text className="u-mgn-right-sm" ko={true} number={1}>
                {user.organization}
              </Text>
            )}
            <Text
              className="u-mgn-right-sm u-hover-color-yellow u-hover-underline"
              ko={true}
              number={1}>
              <LinkTop to={`/profile/${user.username}`}>
                {user.username}
              </LinkTop>
            </Text>
          </div>
          {buttonsElement}
        </div>
      </ListItem>
    </>
  );
}
