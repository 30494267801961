import React, { useEffect, useState } from 'react'

// Components
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Button, Heading, Icon, Loading, Text } from '../../../components'

// Utilities
import APIreqs from '../helpers/API'
import { useParams } from 'react-router-dom'
import utils from '../../../utils/utils'
import RasterDataset from '../classes/rasterDataset'
import ValRstDataset from '../classes/valRstDataset'

function AppDataLoadingModal({
  dataLayers,
  mapHandler,
  modalHandler,
  projectHandler,
}) {
  const loadInit = {
      projectDataStatus: 'loading',
      mapDataStatus: undefined,
      dataLayersDataStatus: undefined,
      isLoadingImgsComplete: false,
    },
    [load, setLoad] = useState(loadInit)
  // console.log('load: ', load)

  const { projectID } = useParams()

  // Get and set all app data.
  useEffect(() => {
    // Get and set project data.
    if (load.projectDataStatus === 'loading') {
      async function getProjectData() {
        try {
          const getProjectRes = await APIreqs.projects.getByID(projectID)
          return getProjectRes.data
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'get CVM project on app init' error.",
            'error',
          )
        }
      }

      async function setProjectData() {
        try {
          const projectData = await getProjectData()
          projectHandler.setData(projectData)
          setLoad({
            ...load,
            projectDataStatus: 'success',
            mapDataStatus: 'loading',
          })
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'set CVM project on app init' error.",
            'error',
          )
          setLoad({ ...load, projectDataStatus: 'error' })
        }
      }

      setProjectData()
    }

    // Get and set map data.
    else if (
      load.projectDataStatus === 'success' &&
      load.mapDataStatus === 'loading'
    ) {
      async function getMapData() {
        try {
          const getMapRes = await APIreqs.maps.getByID(projectID)
          return getMapRes.data
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'get CVM map on app init' error.",
            'error',
          )
        }
      }

      async function setMapData() {
        try {
          const mapData = await getMapData(),
            { base_layer, id, last_center_coords, last_zoom } = mapData
          mapHandler.setData({
            base_layer,
            id,
            last_center_coords: JSON.parse(last_center_coords),
            last_zoom: Number(last_zoom),
          })
          setLoad({
            ...load,
            mapDataStatus: 'success',
            dataLayersDataStatus: 'loading',
          })
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'set CVM map data on app init' error.",
            'error',
          )
          setLoad({ ...load, mapDataStatus: 'error' })
        }
      }

      setMapData()
    }

    // Get and set data layers data.
    else if (
      load.mapDataStatus === 'success' &&
      load.dataLayersDataStatus === 'loading'
    ) {
      async function getDataLayersData() {
        try {
          const dataLayersDataRes = await APIreqs.dataLayers.getAll(projectID)
          return dataLayersDataRes.data
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'get CVM data layers on app init' error.",
            'error',
          )
        }
      }

      async function getRastersData() {
        try {
          const rastersDataRes = await APIreqs.rasters.getAll(projectID)
          return rastersDataRes.data
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'get CVM rasters on app init' error.",
            'error',
          )
        }
      }

      async function getValRstsData() {
        try {
          const valRstsDataRes = await APIreqs.valRasters.getAll(projectID)
          return valRstsDataRes.data
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'get CVM val rsts on app init' error.",
            'error',
          )
        }
      }

      function mapRastersToDataLayers(
        dataLayersResData,
        rastersResData,
        valRstsResData,
      ) {
        const mapped = dataLayersResData.map((dataLayerResDataInstance) => {
          const matchingRasterDatasetInstance = rastersResData
            .map((rasterResDataInstance) => {
              if (
                rasterResDataInstance.data_layer === dataLayerResDataInstance.id
              ) {
                const rasterDataset = new RasterDataset()
                rasterDataset.setResData(rasterResDataInstance)
                rasterDataset.setData()
                return rasterDataset
              }
            })
            .filter((element) => element !== undefined)[0]
          dataLayerResDataInstance['raster'] = matchingRasterDatasetInstance

          const matchingValRstDatasetInstance = valRstsResData
            .map((valRstsResDataInstance) => {
              if (
                valRstsResDataInstance.data_layer ===
                dataLayerResDataInstance.id
              ) {
                const valRstDataset = new ValRstDataset()
                valRstDataset.setResData(valRstsResDataInstance)
                valRstDataset.setData()
                return valRstDataset
              }
            })
            .filter((element) => element !== undefined)[0]
          dataLayerResDataInstance['valRstDataset'] =
            matchingValRstDatasetInstance

          return dataLayerResDataInstance
        })

        return mapped
      }

      async function setMappedDataLayersData() {
        const dataLayersData = await getDataLayersData()
        const rastersData = await getRastersData()
        const valRstsData = await getValRstsData()
        const mappedData = mapRastersToDataLayers(
          dataLayersData,
          rastersData,
          valRstsData,
        )
        dataLayers.setData(mappedData)
        setLoad({
          ...load,
          dataLayersDataStatus: 'success',
          isLoadingImgsComplete: 'loading',
        })
        try {
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'set CVM data layers and rasters data on app init' error.",
            'error',
          )
          setLoad({ ...load, dataLayersDataStatus: 'error' })
        }
      }

      setMappedDataLayersData()
    }
  }, [load])

  // Update isLoadingImgsComplete state var.
  useEffect(() => {
    if (
      load.dataLayersDataStatus === 'success' &&
      load.isLoadingImgsComplete === 'loading'
    ) {
      let isLoadingImgsComplete

      if (dataLayers.data.length === 0) {
        isLoadingImgsComplete = 'success'
      } else {
        let isAllImgsLoaded = true

        isAllImgsLoaded = !dataLayers.data
          .map((dataLayer) => {
            return dataLayer.valRstDataset
              ? dataLayer.valRstDataset.data.isImgLoaded.value ||
                  !dataLayer.is_visible
              : dataLayer.raster.data.isImgLoaded.value || !dataLayer.is_visible
          })
          .includes(false)

        if (isAllImgsLoaded === true) {
          isLoadingImgsComplete = 'success'
        }
      }

      if (isLoadingImgsComplete === 'success') {
        setLoad({ ...load, isLoadingImgsComplete })
      }
    }
  }, [dataLayers.data, load.dataLayersDataStatus])

  // JSX
  const loadingIcon = (
      <Loading key='loading' size='sm' style={{ cursor: 'auto' }} />
    ),
    checkIcon = (
      <Icon key='check' icon='check' size='sm' style={{ cursor: 'auto' }} />
    ),
    errorIcon = (
      <Icon key='error' icon='warning' size='sm' style={{ cursor: 'auto' }} />
    )

  function getIcon(status) {
    let icon
    if (status === 'loading' || status === false) {
      icon = loadingIcon
    } else if (status === 'success' || status === true) {
      icon = checkIcon
    } else if (status === 'error') {
      icon = errorIcon
    }
    return icon
  }

  return (
    <Modal
      centered={true}
      size='lg'
      isOpen={modalHandler.state.activeModal === 'AppDataLoadingModal'}>
      <ModalHeader>Application Data</ModalHeader>
      <ModalBody className='u-pad-md'>
        <Heading number={3} color='secondary'>
          Loading Data
        </Heading>
        <div className='u-mgn-top-md'>
          <div className='u-mgn-sm'>
            <Text className='u-pad-right-sm' number={1}>
              1. Project data...
            </Text>
            {load.projectDataStatus && getIcon(load.projectDataStatus)}
          </div>
          <div className='u-mgn-sm'>
            <Text className='u-pad-right-sm' number={1}>
              2. Map data...
            </Text>
            {load.mapDataStatus && getIcon(load.mapDataStatus)}
          </div>
          <div className='u-mgn-sm'>
            <Text className='u-pad-right-sm' number={1}>
              3. Data layers data...
            </Text>
            {load.dataLayersDataStatus && getIcon(load.dataLayersDataStatus)}
          </div>
          <div className='u-mgn-sm'>
            <Text className='u-pad-right-sm' number={1}>
              4. Image data...
            </Text>
            {load.isLoadingImgsComplete && getIcon(load.isLoadingImgsComplete)}
          </div>
        </div>
        <div className='u-mgn-top-lg u-flex u-flex-justify-end'>
          <Button
            buttontype='primary'
            buttonProps={{
              onClick: () => {
                modalHandler.setState({
                  ...modalHandler.state,
                  activeModal: '',
                })
              },
            }}
            disabled={load.isLoadingImgsComplete !== 'success'}>
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default AppDataLoadingModal
