import React from "react";

export default function InputComment ({ko, onChange, onSubmit, children, commentProps={}}) {
    let className = "input__comment"
    if (ko) {
        className += " input__comment--ko "
    }
    return (
        <div className="u-pos-relative" >
            <div className={className} {...commentProps} contentEditable onInput={onChange}>
                {children}
            </div>
            <button onClick={onSubmit} className="input__comment-submit">
                POST
            </button >
        </div>
    )
}