import React, { useRef } from 'react'

// Components
import MapControls from './MapControls'

// Map Resources
import { ImageOverlay, Map, LayersControl, ScaleControl } from 'react-leaflet'
import baseLayers from '../../../../utils/leaflet/baseLayers'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'

// Utilities
import APIreqs from '../../helpers/API'
import utils from '../../../../utils/utils'

function MapContainer({ dataLayersHandler, mapHandler }) {
  // State and data handlers.
  async function handleBaseLayerChange(e) {
    let base_layer = ''
    const layerID = e.layer.options.id

    if (layerID.includes('dark')) {
      base_layer = 'Dark'
    } else if (layerID.includes('light')) {
      base_layer = 'Light'
    } else if (layerID.includes('outdoors')) {
      base_layer = 'Outdoors'
    } else if (layerID.includes('satellite')) {
      base_layer = 'Satellite'
    }

    try {
      await APIreqs.maps.put(mapHandler.data.id, [{ base_layer }])
      mapHandler.setData({ ...mapHandler.data, base_layer })
    } catch {
      utils.sendAlert(
        "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'put CVM project base layer' error.",
        'error',
      )
    }
  }

  async function handleMoveOrZoomEnd(e) {
    const center = e.target.getCenter(),
      centerCoords = [Number(center.lat), Number(center.lng)],
      zoom = e.target.getZoom()

    try {
      await APIreqs.maps.put(mapHandler.data.id, [
        { last_center_coords: centerCoords },
        { last_zoom: zoom },
      ])
      mapHandler.setData({
        ...mapHandler.data,
        last_center_coords: centerCoords,
        last_zoom: zoom,
      })
    } catch {
      utils.sendAlert(
        "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'put CVM map zoom and center' error.",
        'error',
      )
    }
  }

  function handleOnLoad(currentDataLayer) {
    setTimeout(() => {
      dataLayersHandler.setData(
        dataLayersHandler.data.map((dataLayer) => {
          if (currentDataLayer.id === dataLayer.id) {
            const key = dataLayer.valRstDataset ? 'valRstDataset' : 'raster'
            dataLayer[key].data.isImgLoaded.value = true
          }
          return dataLayer
        }),
      )
    }, 1000) // `setTimeout()` needed to set dataLayers data in desired order.
  }

  // Map vars, helpers, and elements.
  const { BaseLayer } = LayersControl,
    mapRef = useRef(null)

  const baseLayerOptions = ['Outdoors', 'Satellite', 'Light', 'Dark'],
    baseLayersElements = baseLayerOptions.map((option) => (
      <BaseLayer
        checked={mapHandler.data.base_layer === option}
        key={`baselayer${option}`}
        name={option}>
        {baseLayers[option]}
      </BaseLayer>
    ))

  const rstImgOverlays =
    dataLayersHandler.data.length > 0 &&
    dataLayersHandler.data.map((dataLayer) => {
      let overlay = (
        <div
          key={`${dataLayer.id}${dataLayer.img_background_color}${dataLayer.is_visible}`}></div>
      )
      if (dataLayer.is_visible && dataLayer.raster.data.bounds) {
        const rasterImgPath = dataLayer.valRstDataset
          ? `/${dataLayer.valRstDataset.data.imgFilePath.value}`
          : `/${dataLayer.raster.data.imgFilePath.value}`

        overlay = (
          <ImageOverlay
            bounds={dataLayer.raster.data.bounds.value}
            className={`u-background-${dataLayer.img_background_color}-30`}
            // `key` prop requires select dataLayer attrs to ensure ImageOverlay DOM element is updated on state change.
            key={`${dataLayer.id}${dataLayer.img_background_color}${dataLayer.is_visible}`}
            onload={() => {
              handleOnLoad(dataLayer)
            }}
            opacity={1}
            url={rasterImgPath}
            zIndex={10}
          />
        )
      }
      return overlay
    })

  return (
    <>
      <Map
        center={mapHandler.data.last_center_coords}
        className='cvm__map'
        doubleClickZoom={true}
        editable={true}
        onbaselayerchange={(e) => {
          handleBaseLayerChange(e)
        }}
        onmoveend={(e) => {
          handleMoveOrZoomEnd(e)
        }}
        onzoomend={(e) => {
          handleMoveOrZoomEnd(e)
        }}
        ref={mapRef}
        zoom={mapHandler.data.last_zoom}
        zoomControl={true}>
        <ScaleControl />
        <LayersControl collapsed={false} position='topright'>
          {baseLayersElements}
        </LayersControl>
        {rstImgOverlays}
      </Map>
      <MapControls mapRef={mapRef} />
    </>
  )
}

export default MapContainer
