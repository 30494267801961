import React, { useState } from "react";

export default function Icon({
  className = "",
  disabled = false,
  error,
  icon,
  inherit,
  ko,
  size,
  ...props
}) {
  const initState = {
    iconColor: "icon",
    iconDisabled: "",
    iconShape: "icon-icon__",
    iconSize: "icon--md",
  };

  const [state, setState] = useState(initState);

  useState(() => {
    let { iconColor, iconDisabled, iconShape, iconSize } = state;

    if (size === "sm") {
      iconSize = "icon--sm";
    } else if (size === "lg") {
      iconSize = "icon--lg";
    }

    if (
      icon === "caret-down" ||
      icon === "caret-up" ||
      icon === "close" ||
      icon === "check"
    ) {
      iconShape = "icon2-icon2__";
    }
    iconShape += icon;

    if (ko && !error) {
      iconColor += "--ko";
    } else if (ko && error) {
      iconColor += "--error-color--ko";
    } else if (error && !ko) {
      iconColor += "--error-color";
    }

    if (inherit) {
      iconColor += "--inherit";
    }

    if (disabled) {
      iconDisabled = "icon--disabled";
    }

    setState({ ...state, iconDisabled, iconColor, iconShape, iconSize });
  });

  return (
    <span
      className={`${className} ${state.iconColor} ${state.iconDisabled} ${state.iconShape} ${state.iconSize}`}
      {...props}></span>
  );
}
