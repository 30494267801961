import React from "react";

/* Components */
import { Heading, Text, TextEditField } from "../../../../../../components";

/* Methods */
import API from "../../../../API";

export default function MapSummaryEditable({ app }) {
  function updateMapAttr(obj) {
    const { /* inputValue, */ updatedValue, elementName } = obj;
    API.updateMapAttr(elementName, updatedValue, app.state.mapID).then(() => {
      app.setState({ [elementName]: updatedValue });
    });
  }

  return (
    <>
      <Heading className="u-mgn-btm-sm" ko={true} number={5}>
        Summary
      </Heading>
      <TextEditField
        inputValue={app.state.summary}
        ko={true}
        maxLength="400"
        name="summary"
        updateValueCB={updateMapAttr}>
        <Text ko={true} number={1}>
          {app.state.summary}
        </Text>
      </TextEditField>
    </>
  );
}
