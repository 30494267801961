import React from "react";

/* Components */
import { Button, Heading, Text } from "../../components";
import { Footer } from "..";

/* Methods */
import routes from "../../utils/paths/routes";
import staticLink from "../../utils/static";

export default function Home() {
  return (
    <div className="home">
      {/* HERO IMAGE */}
      <section
        className="home__hero"
        style={{
          backgroundImage: `linear-gradient(to right , rgba(40,1,42,.75), rgba(0,0,0,0)), url(${staticLink}hero/CM-Hero.png)`,
        }}>
        <Heading number={2} ko={true} className="u-mgn-btm-md">
          Collaborative Mapper
        </Heading>
        <Heading number={4} bold={false} ko={true} className="u-mgn-btm-lg">
          Interactive team mapping made easy.
        </Heading>
        <Button
          buttontype="secondary"
          ko={true}
          linkTopTo={routes.apps.collabDash.path}>
          Open App
        </Button>
      </section>

      {/* APP DESCRIPTIONS */}
      <section className="home__content">
        <div className="u-align-center u-mgn-btm-lg u-textbox-lg u-mgn-auto">
          <Heading number={3}>Mapping Together</Heading>
          <div className="u-mgn-top-sm">
            <Heading number={5} bold={false} className="u-color-black">
              Collaborative Mapper lets multiple users interactively create and
              edit geospatial data. No more sending multiple files back and
              forth. Start a map, invite collaborators, and seamlessly edit the
              data together.
            </Heading>
          </div>
        </div>
        <div>
          <div className="u-flex u-mgn-btm-lg">
            <img
              src={`${staticLink}demo-shots/cm-demo1.png`}
              alt="Collaborative Mapper screenshot"
              className="home__demoimg"
            />
            <div className="u-mgn-left-lg u-flex u-flex-column u-flex-justify-center">
              <div className="u-textbox-lg">
                <Heading number={4}>Collaborate</Heading>
                <Text number={2}>
                  Share maps with colleagues and invite collaborators without
                  creating multiple copies of geospatial data. Collaborative
                  Mapper lets you invite other users to contribute to your map,
                  tracking changes and comments in one place.
                </Text>
              </div>
              <div className="u-textbox-lg">
                <div className="u-mgn-top-md">
                  <Heading number={4}>Manage Edits </Heading>
                  <Text number={2}>
                    Collaborative Mapper offers a rich set of features to
                    create, document, and accept edits to geospatial data. Users
                    can propose additions, deletions, and edits to your
                    geospatial data along with explanations for the changes.
                    Collaborative Mapper notifies and highlights these for you
                    so you can easily see what changes were proposed and
                    understand why. As a map owner, you can ultimately accept or
                    reject these changes.
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div className="u-flex">
            <div className="u-mgn-right-lg u-flex u-flex-column u-flex-justify-center">
              <div className="u-textbox-lg">
                <Heading number={4}>Upload And Import Data </Heading>
                <Text number={2} className="u-pad-btm-sm">
                  No matter where you’re starting from, you can start building
                  an editable map in Collaborative Mapper. Either upload and
                  save your own data or create a fresh map and digitize from
                  scratch.
                </Text>
                <Text number={2}>
                  If you need to find data as a starting point, Collaborative
                  Mapper has built in connections to import publicly accessible
                  ecological data. For example, species range data can be
                  imported into Collaborative Mapper projects from the{" "}
                  <a
                    className="hyperlink u-hover-underline"
                    href="https://ecos.fws.gov/ecp/"
                    rel="noopener noreferrer"
                    target="_blank">
                    Environmental Conservation Online System (ECOS)
                  </a>{" "}
                  maintained by the US Fish and Wildlife Service, as well as
                  from the{" "}
                  <a
                    className="hyperlink u-hover-underline"
                    href="https://ecos.fws.gov/ecp/"
                    rel="noopener noreferrer"
                    target="_blank">
                    Global Biodiversity Information Facility (GBIF)
                  </a>{" "}
                  API.
                </Text>
              </div>
              <div className="u-textbox-lg">
                <div className="u-mgn-top-md">
                  <Heading number={4}>Publish Your Work </Heading>
                  <Text number={2}>
                    Think other conservationists will be interested in your map?
                    Share it! You can make any map in your workspace public,
                    enabling colleagues to view and comment on it.
                  </Text>
                </div>
              </div>
            </div>
            <img
              src={`${staticLink}demo-shots/cm-demo2.png`}
              alt="Collaborative Mapper screenshot"
              className="home__demoimg"
            />
          </div>
        </div>
      </section>

      {/* BETA TESTING */}
      <section className="home__testing">
        <div className="u-flex u-flex-column u-mgn-right-md">
          <div className="u-mgn-btm-sm">
            <Heading number={3}>
              Interested in participating in beta testing?
            </Heading>
          </div>
          <Heading number={5} bold={false}>
            Help us improve Collaborative Mapper so it can be more helpful to
            you!
          </Heading>
          <div className="u-mgn-top-md">
            <Text number={1}>
              As a beta tester, you will be able to help us shape the future of
              our apps. Sign up and choose your level of participation to start
              helping us push the platform forward. You will also have the
              option to receive regular email updates about this app and other
              new features we plan to add in the future.
            </Text>
          </div>
          <div className="u-flex u-flex-justify-end u-mgn-top-lg">
            <div className="u-mgn-right-sm">
              <Button
                buttontype="primary"
                hashLinkTo={routes.betaTesting.path.learnMore}
                size="sm">
                Learn More
              </Button>
            </div>
            <Button
              buttontype="primary"
              hashLinkTo={routes.betaTesting.path.signUp}
              size="sm">
              Sign Up
            </Button>
          </div>
        </div>
        <img
          src={`${staticLink}graphics/CM-Graphic.png`}
          alt=""
          className="home__demoimg"
        />
      </section>

      {/* COMING SOON */}
      <section
        className="home__soon"
        style={{
          backgroundImage: `linear-gradient(#61286586, #61286586), url("${staticLink}section/CM-Section.png")`,
        }}>
        <div className="home__soon--left u-mgn-right-lg">
          <Heading number={4} ko={true}>
            Coming Soon
          </Heading>
          <div className="u-pad-vert-sm">
            <img
              src={`${staticLink}demo-shots/cm-ui-update.png`}
              alt="CM undo feature"
              className="home__soon--img"
            />
          </div>
          <div className="u-mgn-top-sm u-mgn-btm-sm">
            <Heading number={5} ko={true}>
              Collaborative Mapper is getting an upgrade!
            </Heading>
            <Text number={2} ko={true}>
              June 30th, 2021
            </Text>
          </div>

          <div className="u-mgn-top-sm">
            <Text number={2} ko={true} className="u-pad-btm-sm">
              Over the next few months, the team at Conservationist.io will be
              developing and testing features that will upgrade the existing
              functionality of the app and streamline the user experience.
            </Text>
            <Text number={2} ko={true}>
              We look forward to beta testing with our mapping community during
              this next stage of technology development.
            </Text>
          </div>
        </div>

        <div>
          <div className="home__timeline--mgn-btm">
            <Heading number={5} ko={true}>
              Timeline
            </Heading>
          </div>
          {/* <div className="home__box u-mgn-btm-sm">
            <Heading number={5}>TBD: Project integration</Heading>
            <Text number={2}>
              TBD: As Conservationist.io continues to grow and add more apps, we
              will eventually add integration between apps. For example, the
              geometries you create in Collaborative Mapper might be exported
              for an analysis in another app--or conversely, polygons generated
              from other apps can be added to Collaborative Mapper.
            </Text>
          </div> */}
          <div className="home__box u-mgn-btm-sm">
            <div className="u-pad-btm-sm">
              <Heading number={5}>Collaborative Mapper Update</Heading>
              <Text number={1}>September, 2021</Text>
            </div>
            <Text number={2}>
              Over the summer of 2021, CM will be updated to improve the user
              interface and the user experience. These updates include improved
              layer visualizations, streamlined map-making tools, and additional
              map and geometry layer data management features.
            </Text>
          </div>
          <div className="home__box u-mgn-btm-sm">
            <div className="u-pad-btm-sm">
              <Heading number={5}>Collaborative Mapper Beta Launch</Heading>
              <Text number={1}>May, 2021</Text>
            </div>
            <Text number={2}>
              With the launch of our updated Conservationist.io platform, we
              have released the Beta version Collaborative Mapper, or CM. CM
              uses the core mapping technology of Refined Range Mapper and
              expands upon features that are useful for the broader community of
              users interested in conservation mapping.
            </Text>
          </div>
          <div className="home__box u-mgn-btm-sm">
            <div className="u-pad-btm-sm">
              <Heading number={5}>Refining Range Maps</Heading>
              <Text number={1}>2018 - 2021</Text>
            </div>
            <Text number={2}>
              We have been working with USFWS to improve their processes related
              to updating species ranges, and supporting these updates with the
              best available science. The Technology Team at CCI has built an
              app, called Refined Range Mapper, to provide a dynamic,
              map-building web interface with designated <i>owner</i> and{" "}
              <i>collaborator</i> user permissions to facilitate these
              processes.
            </Text>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
