import React from "react";
import Leaflet from "leaflet";

/* Components */
import {
  Autocomplete,
  Button,
  Heading,
  ModalClose,
  Text,
} from "../../../../../../components";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

/* Data */
import species from "../../../../../../utils/data/species_range_cn_and_sn";
import srCnToSn from "../../../../../../utils/data/species_range_cn_to_sn";

/* Methods */
import API from "../../../../API";
import utils from "../../../../../../utils/utils";

export default function AddGBIFLayerModal({ app }) {
  const addGBIFLayer = () => {
    if (app.state.gbifLayer) {
      app.state.map.removeLayer(app.state.gbifLayer);
    }
    let searchVal = document.querySelector("#gbifSearch").value;
    if (srCnToSn[searchVal]) {
      searchVal = srCnToSn[searchVal];
    }
    API.GBIF(searchVal)
      .then((res) => {
        const key = res.data.results[0].key;
        const gbifLayer = Leaflet.tileLayer(
          `https://api.gbif.org/v2/map/occurrence/density/{z}/{x}/{y}@1x.png?taxonKey=${key}&bin=hex&hexPerTile=30&style=green.poly`,
          {},
        );
        const gbifName = searchVal;
        app.setState({ gbifLayer, gbifName }, () =>
          app.state.gbifLayer.addTo(app.state.map),
        );
      })
      .catch((err) => {
        if (err) {
          utils.sendAlert(
            `An error occured. Try searching a different species, or make sure your spelling is correct.`,
            "error",
          );
        }
      });
  };

  return (
    <Modal
      centered={true}
      isOpen={app.state.modals[`addReferenceLayerGBIF`]}
      toggle={() => {
        app.toggleModal(`addReferenceLayerGBIF`);
      }}>
      <ModalHeader>
        <ModalClose
          cb={() => {
            app.toggleModal(`addReferenceLayerGBIF`);
          }}
        />
        Reference Layers
      </ModalHeader>
      <ModalBody>
        <div className="u-pad-md">
          <Heading className="u-pad-btm-md" number={3} color="secondary">
            Import Species Occurrence Data
          </Heading>
          <Heading number={5}>
            Search for a species occurrence dataset from{" "}
            <a
              className={`hyperlink u-hover-underline`}
              href="https://www.gbif.org/"
              target="_blank"
              rel="noopener noreferrer">
              <span className="heading--5">GBIF</span>
            </a>{" "}
            by scientific name:
          </Heading>
          <div className="autocomplete__form u-mgn-top-sm u-full-width">
            <Autocomplete
              data={species}
              this={app}
              icon={true}
              placeholder="Scientific Name Of Species"
              htmlID="gbifSearch"
              otherclasses="u-full-width"
            />
          </div>
          <div className="u-pad-top-sm">
            <Text number={1}>
              Importing a species occurrence dataset will add green, hexagonal
              markers at species occurrence locations to the map.
            </Text>
          </div>
          <div className="u-pad-top-md u-flex u-flex-justify-end">
            <div className="u-mgn-right-sm map__modal-button-container">
              <Button
                buttontype="secondary"
                buttonProps={{
                  onClick: () => {
                    app.toggleModal(`addReferenceLayerGBIF`);
                  },
                }}
                size="inherit">
                Cancel
              </Button>
            </div>
            <div className="map__modal-button-container">
              <Button
                buttontype="primary"
                buttonProps={{
                  onClick: () => {
                    addGBIFLayer();
                    app.toggleModal(`addReferenceLayerGBIF`);
                  },
                }}
                size="inherit">
                Import
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
