import React, { useEffect, useState } from "react";
import $ from "jquery";

/* Components */
import AboutSection from "./aboutSection/AboutSection";
import CommentsSection from "./commentsSection/CommentsSection";
import LayersSection from "./layersSection/LayersSection";
import { CollapsibleH, List } from "../../../../../components";

export default function MapConfigMenu({ app }) {
  const mapConfigMenuInit = { openSection: "" },
    [mapConfigMenuState, setMapConfigMenuState] = useState({
      mapConfigMenuInit,
    }),
    mcm = {
      stateInit: mapConfigMenuInit,
      state: mapConfigMenuState,
      setState: setMapConfigMenuState,
    };

  useEffect(() => {
    const scaleBarElement = $(".leaflet-control-scale");
    if (!app.state.sideNavOpen) {
      scaleBarElement
        .removeClass("g__map__scale-control--with-open-config-menu")
        .addClass("g__map__scale-control--without-open-config-menu");
    } else {
      scaleBarElement
        .removeClass("g__map__scale-control--without-open-config-menu")
        .addClass("g__map__scale-control--with-open-config-menu");
    }
  }, [app.state.sideNavOpen]);

  return (
    <div
      className="g__map__config-menu"
      style={app.state.sideNavOpen ? { width: "30%" } : { width: 0 }}>
      <CollapsibleH
        isOpen={app.state.sideNavOpen}
        arrow={true}
        altColors={true}
        title=""
        ko={true}
        fullHeight={true}
        onToggle={() => app.setState({ sideNavOpen: !app.state.sideNavOpen })}>
        <List>
          <AboutSection app={app} parent={mcm} />
          <LayersSection app={app} parent={mcm} />
          <CommentsSection app={app} parent={mcm} />
        </List>
      </CollapsibleH>
    </div>
  );
}
