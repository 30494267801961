import React, { Component } from 'react'
import {
  Map,
  TileLayer,
  LayersControl,
  ScaleControl,
  GeoJSON,
  FeatureGroup,
} from 'react-leaflet'
import L from 'leaflet'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import styleLeafletControls from '../../utils/leaflet/styleControls'
import {
  Autocomplete,
  CollapsibleH,
  Form,
  Button,
  FileUpload,
  InputSliderH,
  Icon,
  Text,
  Label,
  Span,
  Heading,
  Loading,
} from '../../components'
import listeners from './listeners'
import helpers from './helpers'
import utils from '../../utils/utils'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { css } from '@emotion/react'
import { PulseLoader } from 'react-spinners'
import Slider from './Slider'
// import navInit from "../../auth/navInit";
import authInit from '../../auth/init'
// import species from "../../utils/endangeredSpecies";
import chSpecies from '../../utils/data/critical_habitat_cn_and_sn'
import chCnToSn from '../../utils/data/critical_habitat_cn_to_sn'
import srSpecies from '../../utils/data/species_range_cn_and_sn'
import srCnToSn from '../../utils/data/species_range_cn_to_sn'
import API from './API'
// import Geojson from "../../utils/leaflet/geojson";
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
// import { type } from "jquery";
import geojson from '../../utils/leaflet/geojson'
// import { exact } from "prop-types";
import mapControls from './mapControls'
import $ from 'jquery'
import baseLayers from '../../utils/leaflet/baseLayers'

const { BaseLayer } = LayersControl

toast.configure({
  autoClose: 180000,
})

let zoom
let today = new Date()
let ddToday = String(today.getDate()).padStart(2, '0')
let mmToday = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
let yyyyToday = today.getFullYear()
// today = mmToday + '/' + ddToday + '/' + yyyyToday;
today = yyyyToday + '-' + mmToday + '-' + ddToday
class HabitatPatrol extends Component {
  state = {
    afterImg: '',
    afterOpacity: 10,
    beforeImg: '',
    beforeOpacity: 10,
    changeImg: '',
    changeOpacity: 10,
    coords: '',
    featureCollection: false,
    currentTileOpacity: '',
    endDate: '2019-12-31',
    isAuthenticated: false,
    layer: false,
    mapControls: false,
    landCover: 'scrub',
    loading: false,
    mapCenter: [41.256, -95.9345],
    mapZoom: 5,
    queryMade: false,
    startDate: '2019-01-01',
    tileScale: 6,
    tutorialShow: true,
    uploadModalShow: false,
    userIsReady: false,
    sideNavOpen: true,
    speciesImg: false,
    activeSpecies: false,
    speciesSciName: false,
    speciesComName: false,
    displayBeforeDate: '',
    displayAfterDate: '',
  }

  componentDidMount = () => {
    // MAKE THE MAP ACCESSIBLE THROUGH STATE
    authInit(this)
    this.setState(
      {
        map: this.map.leafletElement,
      },
      async () => {
        // ADD DRAW CONTROLS
        await this.state.map.pm.addControls({
          position: 'topright',
          drawMarker: false,
          drawRectangle: true,
          drawPolyline: false,
          drawCircleMarker: false,
          drawCircle: false,
          dragMode: false,
          cutPolygon: false,
          editMode: false,
          removalMode: true,
          drawPolygon: false,
          beforeOpacity: 10,
        })
        styleLeafletControls()
        listeners(this.state.map, this)
        $('.leaflet-control').children().addClass('leaflet-control-custom')
        zoom = $('.leaflet-control-zoom')
        zoom.addClass('changedetection__zoom')
        $('.leaflet-top.leaflet-right').append(zoom)
        $('.leaflet-top.leaflet-right').addClass('changedetection__controls')
      },
    )

    // if (!this.state.mapControls) {
    //   mapControls(this);
    //   this.setState({
    //     mapControls: true,
    //   });
    // }
  }

  componentDidCatch(error, info) {
    console.log('ERROR', error)
    console.log('INFO', info)
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value })

  getSpeciesInfo = (species) => {
    API.getAOI(species, 'species_range')
      .then((res) => {
        const { speciesID } = res.data
        API.getSpeciesThumb(speciesID).then((res) => {
          try {
            const speciesImg = res.data.data[0][9].url
            const activeSpecies = true
            const speciesSciName = species
            const speciesComName = res.data.data[0][0]
            this.setState({
              speciesImg,
              activeSpecies,
              speciesSciName,
              speciesComName,
            })
          } catch {
            utils.sendAlert(`No information found for that species.`, 'error')
          }
        })
      })
      .catch((err) => {
        console.log("Couldn't get species info")
      })
  }

  addSpeciesRange = (e) => {
    e.preventDefault()
    this.setState({
      featureCollection: false,
      userIsReady: false,
      loading: true,
      uploadModalShow: false,
    })
    let species = document.querySelector('#ecosSearch').value
    if (srCnToSn[species]) {
      species = srCnToSn[species]
    }
    API.getAOI(species, 'species_range')
      .then((res) => {
        const { data } = res
        const { geometries } = data
        if (geometries.length < 1) {
          utils.sendAlert(`No range found for that species.`, 'error')
          this.setState({ loading: false })
          return
        }
        const speciesRange = []
        let area = 0
        for (let i = 0; i < geometries.length; i++) {
          const dataset = geometries[i]
          for (let j = 0; j < dataset.length; j++) {
            L.geoJson(dataset[j], {
              onEachFeature: function (feature, layer) {
                const editor = new L.Edit.Poly(layer)
                let standardized = new L.polygon(editor._defaultShape())
                standardized = standardized.toGeoJSON()

                const latlngs = layer.getLatLngs()
                let currArea = L.GeometryUtil.length(latlngs[0])
                area += currArea
                speciesRange.push(standardized)
              },
            })
          }
        }
        const featureCollection = {
          type: 'FeatureCollection',
          features: speciesRange,
        }
        const bounds = L.geoJSON(featureCollection).getBounds().getCenter()
        const { lat, lng } = bounds
        this.state.map.flyTo([lat, lng], 5)
        if (this.state.layer) {
          this.state.map.removeLayer(this.state.layer)
        }
        const coords = featureCollection
        this.setState({
          userIsReady: true,
          featureCollection,
          coords,
          loading: false,
        })
        this.getSpeciesInfo(species)
      })
      .catch((err) => {
        if (err) {
          utils.sendAlert(
            `Something went wrong when we tried to retreive that species' data. Please try again.`,
            'error',
          )
          this.setState({ loading: false })
        }
      })
  }

  addSpeciesCriticalHabitat = (e) => {
    e.preventDefault()
    this.setState({
      featureCollection: false,
      userIsReady: false,
      loading: true,
      uploadModalShow: false,
    })
    let species = document.querySelector('#criticalHabitatSearch').value
    if (chCnToSn[species]) {
      species = chCnToSn[species]
    }
    API.getAOI(species, 'critical_habitat').then((res) => {
      const { data } = res
      if (data.length < 1) {
        utils.sendAlert(`No range found for that species.`, 'error')
        this.setState({ loading: false })
        return
      }
      const bounds = L.geoJSON(data).getBounds().getCenter()
      const { lat, lng } = bounds
      this.state.map.flyTo([lat, lng], 5)
      if (this.state.layer) {
        this.state.map.removeLayer(this.state.layer)
      }
      const coords = data
      this.setState({
        userIsReady: true,
        featureCollection: data,
        coords,
        loading: false,
      })
      this.getSpeciesInfo(species)
    })
  }

  uploadRange = (formData) => {
    this.setState({
      featureCollection: false,
      userIsReady: false,
      uploadModalShow: false,
      speciesImg: false,
      activeSpecies: false,
      speciesSciName: false,
      speciesComName: false,
    })
    API.uploadRange(formData).then((res) => {
      const featureCollection = geojson.standardizeFeatures(res.data)
      // CONDITION FOR NO GEOMETRY
      // const featureCollection = []
      // for (let i = 0; i < res.data.length; i++) {
      //   L.geoJson(res.data[i], {
      //     onEachFeature: function (feature, layer) {
      //       const editor = new L.Edit.Poly(layer)
      //       let standardized = new L.polygon(editor._defaultShape())
      //       standardized = standardized.toGeoJSON()
      //       featureCollection.push(standardized)
      //     }
      //   })
      // }
      // const coords = res.data
      this.setState({
        userIsReady: true,
        featureCollection,
        coords: featureCollection,
      })
    })
  }

  runChangeDetection = (e) => {
    e.preventDefault()

    if (this.state.loading) {
      utils.sendAlert(
        'Cannot run an analysis while on is already in progress',
        'error',
      )
      return
    }

    if (!this.state.userIsReady) {
      utils.sendAlert(
        'Please select dates and an area of interest before running an analysis',
        'error',
      )
      return
    }
    let { coords, startDate, endDate } = this.state
    const displayBeforeDate = this.formatDate(startDate)
    const displayAfterDate = this.formatDate(endDate)
    const startArray = startDate.split('-')
    const startYear = parseInt(startArray[0])
    const endArray = endDate.split('-')
    const endYear = parseInt(endArray[0])
    const endMonth = parseInt(endArray[1])
    const endDay = parseInt(endArray[1])
    if (startYear < 2015) {
      utils.sendAlert(
        'Satellite data from before 2015 is not available, please pick a later date.',
        'error',
      )
      return
    }

    const formattedEndDate = new Date(endDate)
    const formattedToday = new Date(today)
    if (formattedEndDate > formattedToday) {
      utils.sendAlert(
        'Please select an end date that is not in the future.',
        'error',
      )
      return
    }

    let compareStart = new Date(startDate)
    let compareEnd = new Date(endDate)
    compareStart = compareStart.getTime()
    compareEnd = compareEnd.getTime()
    if (compareStart > compareEnd) {
      utils.sendAlert(
        'Please make sure your end date occurs after your starting date.',
        'error',
      )
      return
    }
    if (coords.length) {
      coords = { type: 'FeatureCollection', features: coords }
    }
    // this.setState({featureCollection: false})
    // const largestPolygon = helpers.getLargestGeometry(coords)
    // console.log("LP", largestPolygon)
    // const leafLP = L.polygon(largestPolygon.geometry.coordinates)
    // console.log("leafLP", leafLP)
    // leafLP.addTo(this.state.map)
    // this.setState({
    //   featureCollection: false
    // }, () => this.setState({
    //   featureCollection: [largestPolygon]
    // }))

    // coords = largestPolygon
    // this.state.map.addLayer(L.polygon(largestPolygon))
    coords = JSON.stringify(coords)
    const tries = 1
    let req = {
      coords,
      startDate,
      endDate,
      tries,
    }
    this.setState({
      loading: true,
      userIsReady: false,
      displayBeforeDate,
      displayAfterDate,
    })
    // return
    // HELPER FUNCTIONS WILL RETRY THE ANALYSIS IF IT FAILS
    helpers.runAnalysis(req, this.state.map, this)
  }

  setOpacityVal = (e) => {
    let tile = this.state.currentTileOpacity.split('Opacity')[0]
    tile += 'Img'
    const chosenTile = this.state[tile]
    const opacityVal = e / 10
    this.setState(
      {
        [this.state.currentTileOpacity]: e,
      },
      () => {
        if (chosenTile) {
          chosenTile.setOpacity(opacityVal)
        }
      },
    )
  }

  setOpacityName = (name) => {
    // We need this method because the onChange event only tracks value. This method lets us know which slider we're adjusting
    this.setState({
      currentTileOpacity: name,
    })
  }

  reset = () => {
    const { map, beforeImg, afterImg, changeImg } = this.state
    map.removeLayer(beforeImg)
    map.removeLayer(afterImg)
    map.removeLayer(changeImg)
    document
      .querySelector('.leaflet-buttons-control-button')
      .classList.remove('u-inactive')
    this.setState({
      queryMade: false,
      userIsReady: false,
      beforeImg: '',
      afterImg: '',
      changeImg: '',
      beforeOpacity: 10,
      afterOpacity: 10,
      changeOpacity: 10,
      currentTileOpacity: '',
    })
  }
  // ALLOWS US TO DISMISS THE TUTORIAL MODAL
  toggleTutorial = () => {
    this.setState({
      tutorialShow: !this.state.tutorialShow,
    })
  }

  // ALLOWS US TO DISMISS THE ADD AOIs MODAL
  toggleUpload = () => {
    this.setState({
      uploadModalShow: !this.state.uploadModalShow,
    })
  }

  formatDate(date) {
    date = date.split('-')
    const day = date[1]
    const month = date[2]
    const year = date[0]
    let newDate = []
    newDate.push(day)
    newDate.push(month)
    newDate.push(year)
    newDate = newDate.join('/')
    return newDate
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div className='changedetection__loading'>
            <Loading size='lg' />
          </div>
        ) : null}
        {/* {navInit(this, "navbar--clear")} */}

        <div className='u-full-screen'>
          <div
            className='changedetection__config'
            style={this.state.sideNavOpen ? { width: '30%' } : { width: 0 }}>
            <CollapsibleH
              isOpen={this.state.sideNavOpen}
              arrow={true}
              altColors={true}
              title=''
              ko={true}
              fullHeight={false}
              onToggle={() =>
                this.setState({ sideNavOpen: !this.state.sideNavOpen })
              }>
              <div className='u-pad-horiz-md'>
                {!this.state.queryMade ? (
                  <div className='u-pad-sm u-mgn-vert-sm'>
                    <div className='u-mgn-btm-md u-align-center'>
                      <Heading number={5} ko={true} color='secondary'>
                        Compare images between
                      </Heading>
                    </div>

                    <div className='u-flex u-flex-column u-mgn-btm-md'>
                      <div className='u-mgn-btm-md'>
                        <Label ko={true} labelText='Start date' />
                        <input
                          value={this.state.startDate}
                          onChange={this.handleChange}
                          required
                          type='date'
                          name='startDate'
                          className='paramsInput changedetection__input u-full-width'
                        />
                      </div>

                      <div className='u-flex u-flex-column'>
                        <Label ko={true} labelText='End date' />
                        <input
                          value={this.state.endDate}
                          onChange={this.handleChange}
                          required
                          type='date'
                          name='endDate'
                          className='paramsInput changedetection__input'
                        />
                      </div>
                    </div>

                    <div className='u-flex u-flex-justify-center'>
                      <Button
                        buttontype='primary'
                        ko={true}
                        disabled={this.state.userIsReady ? false : true}
                        buttonProps={{ onClick: this.runChangeDetection }}>
                        Run
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className='changedetection__interface changedetection__after'>
                    <Label labelText='Before-Image Opacity' ko={true} />
                    <Text number={3} ko={true}>
                      {this.state.displayBeforeDate}
                    </Text>
                    <Slider
                      min={0}
                      max={10}
                      label='Before-Image Opacity'
                      defaultValue={this.state.beforeOpacity}
                      val={this.state.beforeOpacity}
                      onChange={this.setOpacityVal}
                      onBeforeChange={() =>
                        this.setOpacityName('beforeOpacity')
                      }
                    />

                    <Label labelText='After-Image Opacity' ko={true} />
                    <Text number={3} ko={true}>
                      {this.state.displayAfterDate}
                    </Text>
                    <Slider
                      min={0}
                      max={10}
                      defaultValue={this.state.afterOpacity}
                      val={this.state.afterOpacity}
                      onChange={this.setOpacityVal}
                      onBeforeChange={() => this.setOpacityName('afterOpacity')}
                    />
                    <Label labelText='Change-Image Opacity' ko={true} />

                    <Slider
                      min={0}
                      max={10}
                      val={this.state.changeOpacity}
                      defaultValue={this.state.changeOpacity}
                      onChange={this.setOpacityVal}
                      onBeforeChange={() =>
                        this.setOpacityName('changeOpacity')
                      }
                    />
                    <div className='u-center-flex u-mgn-top-md'>
                      <Button
                        buttontype='primary'
                        ko={true}
                        buttonProps={{ onClick: this.reset }}>
                        Reset
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </CollapsibleH>
          </div>
          {/* <div className="changedetection__upload" onClick={this.toggleUpload}> 
            <i className="fas fa-paw"></i>
          </div> */}
          <Map
            zoom={this.state.mapZoom}
            center={this.state.mapCenter}
            editable={true}
            ref={(c) => {
              this.map = c
            }}
            animate={true}
            boxZoom={true}
            doubleClickZoom={true}>
            <LayersControl collapsed={false} position='topright'>
              <BaseLayer checked name='Satellite'>
                {baseLayers.Satellite}
              </BaseLayer>
              <BaseLayer name='Outdoors'>{baseLayers.Outdoors}</BaseLayer>
            </LayersControl>

            {this.state.featureCollection ? (
              <FeatureGroup>
                <GeoJSON data={this.state.featureCollection} />
              </FeatureGroup>
            ) : null}

            <ScaleControl></ScaleControl>
          </Map>
          {this.state.activeSpecies ? (
            <div className='changedetection__species'>
              <img
                className='changedetection__species--img'
                src={this.state.speciesImg}
                alt=''
              />
              <div className='changedetection__species--text'>
                <h5 className='changedetection__species--com'>
                  {this.state.speciesComName}
                </h5>
                <h5 className='changedetection__species--sci'>
                  {this.state.speciesSciName}
                </h5>
              </div>
            </div>
          ) : null}
        </div>

        <Modal isOpen={this.state.tutorialShow} toggle={this.toggleTutorial}>
          <ModalHeader>Habitat Patrol</ModalHeader>
          <ModalBody>
            <div className='u-pad-sm'>
              <Text number={1}>
                <Span>ABOUT:</Span> The Habitat Patrol app allows you to detect
                and visualize land cover changes over a period of time and area
                of your choice. Draw a polygon with the{' '}
                <Icon icon='polygon' size='sm' /> tool on the left hand side of
                the screen, and then choose two dates to run the analysis. You
                will be shown any changes to landcover occurring between those
                dates, as well as satellite imagery from the beginning and end
                of the time period.
              </Text>
              <div className='u-mgn-top-sm'>
                <Text number={1}>
                  IMPORTANT: The algorithm powering this app can take quite a
                  while to run. For the best results, polygons should be kept
                  relatively small. Press Shift + Z on any area of the map to
                  zoom in at the appropriate scale. Polygons drawn at this or a
                  smaller scale will yield the best results.
                </Text>
              </div>
              <div className='u-mgn-top-sm'>
                <Text number={1}>
                  This tool was designed and tested in the continental United
                  States. At this time, results may be less reliable in other
                  geographic regions and environments.
                </Text>
              </div>
            </div>
            <div className='u-flex u-flex-justify-end'>
              <Button
                buttontype='primary'
                buttonProps={{ onClick: this.toggleTutorial }}>
                Close
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.uploadModalShow} toggle={this.toggleUpload}>
          <ModalBody className='d-flex justify-content-around align-items-center'>
            <div className='container changedetection__tutorial'>
              <h1 className='heading-tertiary u-mgn-btm-sm u-white'>
                Add an Area Of Interest (AOI)
              </h1>
              <h4>Add a species range</h4>
              <form
                onSubmit={this.addSpeciesRange}
                className='autocomplete__form u-mgn-btm-sm'>
                <div className='u-black u-full-width'>
                  <Autocomplete
                    data={srSpecies}
                    app={this}
                    icon={true}
                    placeholder='Scientific Name Of Species'
                    htmlID='ecosSearch'
                  />
                </div>
              </form>

              <h4>Add a species critical habitat</h4>
              <form
                onSubmit={this.addSpeciesCriticalHabitat}
                className='autocomplete__form u-mgn-btm-sm'>
                <div className='u-black u-full-width'>
                  <Autocomplete
                    data={chSpecies}
                    app={this}
                    icon={true}
                    placeholder='Scientific Name Of Species'
                    htmlID='criticalHabitatSearch'
                  />
                </div>
              </form>

              <h4>Upload an AOI</h4>
              <FileUpload
                fileType='vector'
                heading='Upload Vector Data File'
                cb={this.uploadRange}
              />
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
export default HabitatPatrol
