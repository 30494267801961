import $ from "jquery";

const styleLeafletControls = () => {
  $(".leaflet-control-attribution").css({
    position: "fixed",
    bottom: "0",
    left: "0",
    width: "22rem",
  });

  $(".leaflet-control-zoom").addClass("changedetection__zoom");
  // css({
  //   "position": "fixed",
  //   "top": "270px",
  //   "right": "-167.5px",
  //   "width": "22rem"
  // })
  $(".leaflet-control").children().addClass("leaflet-control-custom");
  $(".leaflet-buttons-control-button").css({
    "background-color": "#005596",
    height: "4rem",
    width: "4rem",
  });
  $(".leaflet-buttons-control-button div").each(function (i, elem) {
    if (i === 0) {
      // $(this).parent().append("<i class='fas fa-draw-polygon leaflet-pm--icon' style='font-size: 24px'></i>")
      $(this)
        .parent()
        .append(
          "<span class='icon-icon__rectangle icon--ko icon--md u-position-absolute-center'></span>",
        );

      $(this).css({
        "background-image": "none",
      });
    } else if (i === 1) {
      // $(this).parent().append("<i class='fas fa-trash-alt leaflet-pm--icon' style='font-size: 24px'></i>")
      $(this)
        .parent()
        .append(
          "<span class='icon-icon__trash icon--ko icon--md u-position-absolute-center'></span>",
        );
      $(this).css({
        "background-image": "none",
      });
    }
  });
};

export default styleLeafletControls;
