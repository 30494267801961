import React from "react";

/* Components */
import {
  Heading,
  Icon,
  InputEditField,
  Text,
  Tooltip,
} from "../../../../../../components";
import DeleteVectorLayerModal from "./DeleteVectorLayerModal";
import ImportSpeciesRangeModal from "./ImportSpeciesRangeModal";
import UploadVectorDataFileModal from "./UploadVectorDataFileModal";

/* Methods */
import API from "../../../../API";

export default function VectorLayers({ app }) {
  return (
    <>
      <div className="u-flex u-flex-justify-between">
        <Heading number={5} ko={true}>
          Vector Layers
        </Heading>
        {(app.state.isCreator || app.state.isEditor) && (
          <div className="u-flex u-flex-evenly">
            <div
              className="u-mgn-right-sm"
              onClick={() => app.toggleModal("uploadLayer")}>
              <Tooltip body="Upload Vector Data" ko={true}>
                <Icon icon="upload" ko={true} size="md" />
              </Tooltip>
            </div>
            <UploadVectorDataFileModal app={app} />
            <div
              className="u-mgn-right-sm"
              onClick={() => app.toggleModal("addSpecies")}>
              <Tooltip body="Import Species Range Vector Data" ko={true}>
                <Icon icon="search" ko={true} size="md" />
              </Tooltip>
            </div>
            <ImportSpeciesRangeModal app={app} />
            <div
              onClick={() => {
                API.updateMapAttr(
                  "is_vector_drawing_controls_enabled",
                  !app.state.isVectorDrawingControlsEnabled,
                  app.state.mapID,
                ).then(() => {
                  app.setState({
                    isVectorDrawingControlsEnabled:
                      !app.state.isVectorDrawingControlsEnabled,
                  });
                });
              }}>
              {app.state.isVectorDrawingControlsEnabled === false ? (
                <Tooltip body="Enable Vector Drawing Controls" ko={true}>
                  <Icon icon="plus" ko={true} size="md" />
                </Tooltip>
              ) : (
                <div>
                  <Tooltip body="Disable Vector Drawing Controls" ko={true}>
                    <Icon icon="minus" ko={true} size="md" />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {Object.keys(app.state.datasets).map((key) => {
        let { dataset, isVisible, id } = app.state.datasets[key];
        if (isVisible === undefined) {
          isVisible = true;
        }
        const layerVisibilityElement = isVisible ? (
          <span onClick={() => app.hideLayerGroup(dataset)}>
            <Tooltip body="Hide Layer" ko={true}>
              <Icon icon="visible" ko={true} size="md" />
            </Tooltip>
          </span>
        ) : (
          <span onClick={() => app.hideLayerGroup(dataset)}>
            <Tooltip body="Show Layer" ko={true}>
              <span className="icon--ko icon--md icon-icon__invisible"></span>
            </Tooltip>
          </span>
        );

        return (
          <div className="rrm__layer-group__container u-mgn-vert-sm" key={key}>
            {app.state.isCreator ? (
              <>
                {/* Layer Visibility Icon */}
                <div className="u-pad-sm">
                  <span className="icon--sm">{layerVisibilityElement}</span>
                </div>
                {/* Layer Name Element */}
                <div className="rrm__layer-group__text-element">
                  <InputEditField
                    inputValue={dataset}
                    ko={true}
                    updateValueCB={app.updateLayerName}
                    name={`featureGroup${id}`}>
                    <span className="rrm__layer-group--left">
                      <span className="rrm__layer-group-name">
                        <Text ko={true} number={2}>
                          {dataset}
                        </Text>
                      </span>
                    </span>
                  </InputEditField>
                </div>
                {/* Delete Layer Icon */}
                <div className="u-pad-sm">
                  <Tooltip body="Delete Layer" ko={true}>
                    <span
                      className="icon--ko icon--md icon-icon__trash"
                      onClick={() => {
                        app.toggleModal(`deleteVectorLayer${dataset}`);
                      }}></span>
                  </Tooltip>
                  <DeleteVectorLayerModal app={app} dataset={dataset} />
                </div>
              </>
            ) : (
              <>
                <span className="rrm__layer-group--left">
                  <span className="u-mgn-right-sm rrm__layer-group-name">
                    <Text ko={true} number={2}>
                      {dataset}
                    </Text>
                  </span>
                  <div className="u-pad-sm">
                    <div className="icon--sm">{layerVisibilityElement}</div>
                  </div>
                </span>
              </>
            )}
          </div>
        );
      })}
    </>
  );
}
