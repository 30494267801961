import { InputPassword, Label } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGInputPassword() {
  const content = {
    name: "",
    variantName: "InputPassword",
    description: `A customized version of the html Input element.`,
    implementation:
      "In cases where an <Input type='password' /> element is appropriate.",
    propsTable: [
      [
        "className",
        "string",
        `u-mgn-sm`,
        "no",
        "Add utility, custom, etc. class name.",
      ],
      [
        "inputProps",
        "hash",
        `{maxLength: 32}`,
        "no",
        "Provide props for an html <Input type='password' /> element.",
      ],
      [
        "ko",
        "Boolean",
        `true, false`,
        "no",
        "Set to 'true' if placed on a dark background.",
      ],
      [
        "small",
        "Boolean",
        "true, false",
        "no",
        "Reduces the size of the component when set to true.",
      ],
    ],
    links: [
      {
        "MDN Input type='password'":
          "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/password",
      },
    ],
    codeExample: `import { InputPassword, Label } from "/components";
import React from "react';

export default function InputPasswordContainer(){
  function handleChange(e) {
    setState({...state, [e.target.name]: e.target.value})
  }

  return(
    <div className="u-mgn-btm-sm">
        <Label labelText="Password" />
        <InputPassword
          className=""
          inputProps={{ placeholder: "placeholder text" }}
          ko={false}
          small={false}
        />
    </div>
  )
}`,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-mgn-btm-sm">
            <Label labelText="Password" />
            <InputPassword
              className=""
              inputProps={{ placeholder: "placeholder text" }}
              ko={false}
              small={false}
            />
          </div>
        </div>
      }
      links={content.links}
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
