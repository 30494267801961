import React from "react";

/* Components */
import { Heading, RadioButton, Text } from "../../../../../../components";

/* Methods */
import API from "../../../../API";

export default function MapPrivacy({ app }) {
  const setMapPrivacy = (status) => {
    const { mapID, mapData } = app.state;
    API.setMapStatus(mapID, status).then(() => {
      if (status === "public") {
        mapData.is_published = true;
      } else {
        mapData.is_published = false;
      }
      app.setState({ mapData: mapData });
    });
  };

  return (
    <div className="u-mgn-top-md">
      <Heading className="u-pad-btm-sm" ko={true} number={5}>
        Privacy
      </Heading>
      <Text ko={true} number={2}>
        Map privacy settings.
      </Text>
      <form className="u-flex u-flex-justify-start u-mgn-top-sm">
        <div className="u-flex u-flex-align-center u-mgn-right-md">
          <RadioButton
            ko={true}
            name="Visible"
            onClick={() => setMapPrivacy("public")}
            defaultChecked={app.state.mapData.is_published ? true : false}
          />
          <div className="u-mgn-left-sm">
            <p className="paragraph__2 paragraph__2--ko">Public</p>
          </div>
        </div>
        <div className="u-flex u-flex-align-center">
          <RadioButton
            ko={true}
            name="Private"
            onClick={() => setMapPrivacy("private")}
            defaultChecked={!app.state.mapData.is_published ? true : false}
          />
          <div className="u-mgn-left-sm">
            <p className="paragraph__2 paragraph__2--ko">Private</p>
          </div>
        </div>
      </form>
      <div className="u-mgn-vert-sm">
        <Text className="u-text-italic" ko={true} number={2}>
          {app.state.mapData.is_published
            ? "This map can be viewed and downloaded by everyone."
            : "This map can be viewed and downloaded by the map owner and map collaborators."}
        </Text>
      </div>
    </div>
  );
}
