import React, { useEffect, useState } from 'react'

// Components
import {
  Button,
  Heading,
  Icon,
  Loading,
  Text,
} from '../../../../../../components'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

// Utilities
import APIreqs from '../../../../helpers/API'
import { getZoomFromBoundingBox } from '../../../../helpers/map'
import utils from '../../../../../../utils/utils'

function ProcessRasterModal({ dataLayers, mapHandler, pcm }) {
  const processInit = {
      metadataStatus: 'loading', // Step 1
      CRSStatus: undefined, // Step 2
      geospatialStatus: undefined, // Step 3
      postImgStatus: undefined, // Step 4
      rasterDataStatus: undefined, // Step 5
      getImgStatus: undefined, // Step 6
    },
    [process, setProcess] = useState(processInit)
  // console.log("process: ", process);

  // Perform all steps to process an uploaded raster.
  useEffect(() => {
    if (
      dataLayers.data.length > 0 &&
      dataLayers.data[dataLayers.data.length - 1].raster &&
      pcm.state.activeModal === 'processRasterModal'
    ) {
      const rasterID = dataLayers.data[dataLayers.data.length - 1].raster
        ._resData.ID
        ? dataLayers.data[dataLayers.data.length - 1].raster._resData.ID
        : dataLayers.data[dataLayers.data.length - 1].raster.data.ID.value

      // Step 1: Extract raster data file metadata.
      if (process.metadataStatus === 'loading') {
        async function putRasterMetadata() {
          try {
            const putRasterRes = await APIreqs.rasters.put(rasterID, 'metadata')
            return putRasterRes.data
          } catch {
            utils.sendAlert(
              "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'put CVM raster metadata' error.",
              'error',
            )
          }
        }

        async function processMetadata() {
          try {
            const putRasterResData = await putRasterMetadata() // Can equal "success" or "error".
            if (putRasterResData === 'success') {
              setProcess({
                ...process,
                metadataStatus: putRasterResData,
                CRSStatus: 'loading',
              })
            } else {
              setProcess({
                ...process,
                metadataStatus: putRasterResData,
              })
            }
          } catch {
            utils.sendAlert(
              "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'process CVM raster metadata' error.",
              'error',
            )
          }
        }

        processMetadata()
      }

      // Step 2: Perform coordinate reference system reprojection, if necessary.
      if (
        process.metadataStatus === 'success' &&
        process.CRSStatus === 'loading'
      ) {
        async function putRasterCRS() {
          try {
            const putRasterCRSRes = await APIreqs.rasters.put(rasterID, 'crs')
            return putRasterCRSRes
          } catch {
            utils.sendAlert(
              "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'put CVM raster crs' error.",
              'error',
            )
          }
        }

        async function processCRS() {
          try {
            await putRasterCRS()
            setProcess({
              ...process,
              CRSStatus: 'success',
              geospatialStatus: 'loading',
            })
          } catch {
            utils.sendAlert(
              "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'process CVM raster crs' error.",
              'error',
            )
          }
        }

        processCRS()
      }

      // Step 3: Calculate and extract data geospatial attributes.
      if (
        process.CRSStatus === 'success' &&
        process.geospatialStatus === 'loading'
      ) {
        async function putGeospatialAttrs() {
          try {
            const putGeospatialAttrsRes = await APIreqs.rasters.put(
              rasterID,
              'geospatial',
            )
            return putGeospatialAttrsRes
          } catch {
            utils.sendAlert(
              "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'put CVM raster spatial' error.",
              'error',
            )
          }
        }

        async function processGeospatialAttrs() {
          try {
            await putGeospatialAttrs()
            setProcess({
              ...process,
              geospatialStatus: 'success',
              postImgStatus: 'loading',
            })
          } catch {
            utils.sendAlert(
              "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'process CVM raster geospatial attributes' error.",
              'error',
            )
          }
        }

        processGeospatialAttrs()
      }

      // Step 4: Generate image from raster dataset.
      if (
        process.geospatialStatus === 'success' &&
        process.postImgStatus === 'loading'
      ) {
        async function putImg() {
          try {
            const putImgRes = await APIreqs.rasters.put(rasterID, 'img')
            return putImgRes
          } catch {
            utils.sendAlert(
              "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'put CVM raster img' error.",
              'error',
            )
          }
        }

        async function processImg() {
          try {
            await putImg()
            setProcess({
              ...process,
              postImgStatus: 'success',
              rasterDataStatus: 'loading',
            })
          } catch {
            utils.sendAlert(
              "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'process CVM raster image' error.",
              'error',
            )
          }
        }

        processImg()
      }

      // Step 5: Update raster data state with generated raster data.
      if (
        process.postImgStatus === 'success' &&
        process.rasterDataStatus === 'loading'
      ) {
        async function getRaster() {
          try {
            const getRasterRes = await APIreqs.rasters.getByID(rasterID)
            return getRasterRes.data
          } catch {
            utils.sendAlert(
              "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'get CVM raster' error.",
              'error',
            )
          }
        }

        async function updateRasterData() {
          try {
            let rasterDataset
            const rasterData = await getRaster(),
              mappedData = dataLayers.data.map((dataLayer) => {
                const rasterID = dataLayer.raster.data.ID
                  ? dataLayer.raster.data.ID.value
                  : dataLayer.raster._resData.ID // This sorts the available raster ID value for newly created/downloaded files compared to existing rasters that have been fully processed.
                if (rasterData.id === rasterID) {
                  rasterDataset = dataLayer.raster
                  rasterDataset.setResData(rasterData)
                  rasterDataset.setData()
                  dataLayer.raster = rasterDataset
                }
                return dataLayer
              })
            dataLayers.setData(mappedData)

            // Set map center and zoom to view img.
            const zoom = getZoomFromBoundingBox(
              rasterDataset.data.boundingBox.value,
            )
            mapHandler.setData({
              ...mapHandler.data,
              last_center_coords: rasterDataset.data.centerCoords.value,
              last_zoom: zoom,
            })

            setProcess({
              ...process,
              rasterDataStatus: 'success',
              getImgStatus: 'loading',
            })
          } catch {
            utils.sendAlert(
              "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'process CVM raster data' error.",
              'error',
            )
          }
        }

        updateRasterData()
      }

      // Step 6: Load image to map.
      if (
        process.rasterDataStatus === 'success' &&
        process.getImgStatus === 'loading' &&
        (dataLayers.data[dataLayers.data.length - 1].raster.data.isImgLoaded
          .value ||
          !dataLayers.data[dataLayers.data.length - 1].is_visible)
      ) {
        function processGetImg() {
          setProcess({
            ...process,
            getImgStatus: 'success',
          })
        }

        processGetImg()
      }
    }
  }, [pcm.state.activeModal, process, dataLayers.data])

  function resetModal() {
    pcm.setState({
      ...pcm.state,
      activeModal: '',
    })
    setTimeout(() => {
      setProcess(processInit)
    }, 200)
  }

  // JSX
  const loadingIcon = <Loading size='sm' style={{ cursor: 'auto' }} />,
    checkIcon = <Icon icon='check' size='sm' style={{ cursor: 'auto' }} />,
    errorIcon = <Icon icon='warning' size='sm' style={{ cursor: 'auto' }} />

  function getIcon(status) {
    let icon
    if (status === 'loading') {
      icon = loadingIcon
    } else if (status === 'success') {
      icon = checkIcon
    } else if (status === 'error') {
      icon = errorIcon
    } else {
      icon = <></>
    }
    return icon
  }

  return (
    <Modal
      centered={true}
      size='lg'
      isOpen={pcm.state.activeModal === 'processRasterModal'}>
      <ModalHeader>Data Layers</ModalHeader>
      <ModalBody>
        <div className='u-pad-md'>
          <Heading number={3} color='secondary'>
            Processing Raster
          </Heading>
          <div className='u-mgn-top-md'>
            <div className='u-mgn-sm'>
              <Text number={1} className='u-pad-right-sm'>
                1. Extracting metadata...
              </Text>
              {process.metadataStatus && getIcon(process.metadataStatus)}
            </div>
            {process.metadataStatus === 'error' && (
              <div className='u-mgn-sm'>
                <Text number={2}>
                  ERROR: The upload raster file does not contain required
                  metadata attributes. Please use the Conservationist.io contact
                  form for support on this issue citing 'CVM error when
                  extracting metadata'.
                </Text>
              </div>
            )}
            <div className='u-mgn-sm'>
              <Text number={1} className='u-pad-right-sm'>
                2. Processing coordinate reference systems...
              </Text>
              {process.CRSStatus && getIcon(process.CRSStatus)}
            </div>
            <div className='u-mgn-sm'>
              <Text number={1} className='u-pad-right-sm'>
                3. Calculating geospatial attributes...
              </Text>
              {process.geospatialStatus && getIcon(process.geospatialStatus)}
            </div>
            <div className='u-mgn-sm'>
              <Text number={1} className='u-pad-right-sm'>
                4. Generating raster image...
              </Text>
              {process.postImgStatus && getIcon(process.postImgStatus)}
            </div>
            <div className='u-mgn-sm'>
              <Text number={1} className='u-pad-right-sm'>
                5. Loading raster data...
              </Text>
              {process.rasterDataStatus && getIcon(process.rasterDataStatus)}
            </div>
            <div className='u-mgn-sm'>
              <Text number={1} className='u-pad-right-sm'>
                6. Fetching raster image to web map...
              </Text>
              {process.getImgStatus && getIcon(process.getImgStatus)}
            </div>
          </div>
          <div className='u-mgn-top-lg u-flex u-flex-justify-end'>
            <Button
              buttontype='primary'
              buttonProps={{
                onClick: () => {
                  resetModal()
                },
              }}
              disabled={
                process.getImgStatus !== 'success' &&
                process.metadataStatus !== 'error'
              }>
              Close
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ProcessRasterModal
