import React, { useEffect } from "react";

// Components
import { LinkTop } from "../../../../components";

// Utilities
import renderControls from "./renderControls";
import routes from "../../../../utils/paths/routes";

export default function MapControls({ mapRef }) {
  useEffect(() => {
    // `mapElement` pattern implemented from example: https://github.com/geoman-io/leaflet-geoman/discussions/860#discussioncomment-612068
    let mapElement = mapRef.current.leafletElement;
    if (mapRef.current) {
      mapElement.pm.addControls({
        cutPolygon: false,
        dragMode: false,
        drawCircle: false,
        drawCircleMarker: false,
        drawMarker: false, // true for vector management
        drawPolygon: false, // true for vector management
        drawPolyline: false, // true for vector management
        drawRectangle: false, // true for vector management
        editMode: false, // true for vector management
        removalMode: false, // true for vector management
      });
      renderControls();
    }
  }, []);

  return (
    <>
      <div className="leaflet-buttons__exit-button">
        <LinkTop to={routes.apps.cvmDash.path}>
          <div className="rrm__exit--text">Exit</div>
        </LinkTop>
      </div>
    </>
  );
}
