import React from "react";

/* Components */
import {
  Button,
  Heading,
  ModalClose,
  Text,
} from "../../../../../../components";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

/* Methods */
import API from "../../../../API";
import utils from "../../../../../../utils/utils";

export default function DeleteMapModal({ app }) {
  const deleteMap = () => {
    const { mapID } = app.state;
    API.deleteMap(mapID).then(() => {
      app.toggleModal("deleteMap");
      utils.sendAlert("Map deleted.", "success");
      setTimeout(() => {
        window.location.href = "/apps/collaborativemapper/dashboard";
      }, 1500);
    });
  };

  return (
    <Modal
      centered={true}
      isOpen={app.state.modals.deleteMap}
      toggle={() => app.toggleModal("deleteMap")}>
      <ModalHeader>
        <ModalClose cb={() => app.toggleModal("deleteMap")} />
        Map Actions
      </ModalHeader>
      <ModalBody>
        <div className="u-pad-md">
          <div className="u-mgn-btm-md">
            <Heading color="secondary" number={3}>
              Delete Map
            </Heading>
          </div>
          <div className="u-mgn-btm-md">
            <Heading className="u-mgn-btm-sm" number={5}>
              Deleting a map is permanent.
            </Heading>
            <Text number={1}>
              This action will erase the map and associated layers, data, and
              files.
            </Text>
          </div>
          <div className="u-flex u-flex-row u-flex-justify-end u-pad-top-md">
            <div className="map__modal-button-container">
              <Button
                buttontype="secondary"
                buttonProps={{
                  onClick: () => app.toggleModal("deleteMap"),
                }}>
                Cancel
              </Button>
            </div>
            <div className="map__modal-button-container">
              <Button buttontype="primary" buttonProps={{ onClick: deleteMap }}>
                Delete
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
