import React, { useState, useEffect } from "react";
import Icon from "./Icon";

export default function CollapsibleH(props) {
  // Dismisses the dropdown when another element is clicked
  // const container = React.createRef();
  const initState = {
    active: props.isOpen,
    style: {},
  };

  const [state, setState] = useState(initState);

  useEffect(() => {
    if (props.fullHeight) {
      const style = { height: "100vh" };
      setState((state) => {
        return { ...state, style };
      });
    }
  }, [props]);

  function open(e) {
    setState({
      ...state,
      active: !state.active,
    });
    if (props.onToggle) {
      props.onToggle();
    }
  }

  return (
    <div
      className={state.active ? "sidenav sidenav__active" : "sidenav"}
      /* style={state.style} */
    >
      <button
        className={props.ko ? "sidenav__btn--ko" : "sidenav__btn"}
        onClick={open}>
        <div className={"sidenav__btn--title u-font-bold"}>
          {state.active && props.title}
        </div>
        <div
          className={
            state.active
              ? "sidenav__btn--icon--left"
              : "sidenav__btn--icon--right"
          }>
          {state.active ? (
            <Icon icon="caret-up" size="sm" ko={props.ko ? false : true} />
          ) : (
            <Icon icon="caret-up" size="sm" ko={props.ko ? false : true} />
          )}
        </div>
      </button>
      <div className="sidenav__content">
        {state.active ? (
          props.children
        ) : (
          <div className="sidenav__closed"></div>
        )}
      </div>
    </div>
  );
}
