import React, { useEffect, useState } from 'react'

// Components
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Button, FormInputWValidations, ModalClose } from '../../components'

// Utilities
import API from '../../auth/API'
import utils from '../../utils/utils'

export default function NavbarSignUpModal({ navbar }) {
  const navbarSignUpModalStateInit = {
      isConfirmPasswordValid: false,
      isEmailValid: false,
      isFirstNameValid: false,
      isLastNameValid: false,
      isPasswordValid: false,
      isUsernameValid: false,
      valueConfirmPassword: '',
      valueEmail: '',
      valueFirstName: '',
      valueLastName: '',
      valuePassword: '',
      valueUsername: '',
    },
    [navbarSignUpModalState, setNavbarSignUpModalState] = useState(
      navbarSignUpModalStateInit,
    )

  const signUpButtonStateInit = { isFieldsValid: false },
    [signUpButtonState, setSignUpButtonState] = useState(signUpButtonStateInit)

  useEffect(() => {
    const fields = [
      'ConfirmPassword',
      'Email',
      'FirstName',
      'LastName',
      'Password',
      'Username',
    ]
    let isFieldsValid = true
    fields.forEach((field) => {
      if (navbarSignUpModalState[`is${field}Valid`] === false) {
        isFieldsValid = false
      }
    })
    setSignUpButtonState({ isFieldsValid })
  }, [navbarSignUpModalState])

  const signUp = () => {
    if (signUpButtonState.isFieldsValid === false) {
      utils.sendAlert(
        'Please fill out the form to completion and fix all errors.',
        'error',
      )
    } else {
      const data = {
        first_name: navbarSignUpModalState.valueFirstName,
        last_name: navbarSignUpModalState.valueLastName,
        username: navbarSignUpModalState.valueUsername,
        email: navbarSignUpModalState.valueEmail,
        password: navbarSignUpModalState.valuePassword,
        password2: navbarSignUpModalState.valueConfirmPassword,
      }
      API.signUp
        .register(data)
        .then(() => {
          navbar.setState({
            ...navbar.state,
            isSignUpModalOpen: false,
            isSignUpEmailMessageModalOpen: true,
          })
        })
        .catch((err) => {
          utils.sendAlert(
            'Something went wrong.  Please try again or contact support.',
            'error',
          )
        })
    }
  }

  // console.log("navbarSignUpModalState: ", navbarSignUpModalState);
  // console.log("signUpButtonState: ", signUpButtonState);

  return (
    <Modal
      backdrop='static'
      centered={true}
      isOpen={navbar.state.isSignUpModalOpen}
      size='lg'>
      <ModalHeader className='u-no-select'>
        <ModalClose
          cb={() => {
            setNavbarSignUpModalState(navbarSignUpModalStateInit)
            navbar.setState({
              ...navbar.state,
              isSignUpModalOpen: false,
            })
          }}
        />
        Sign Up
      </ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            signUp()
          }}>
          <div className='nav-sign-up-modal__names-container u-pad-top-sm'>
            {/* FIRST NAME */}
            <div className='nav-sign-up-modal__name-container u-pad-sm'>
              <FormInputWValidations
                parentState={navbarSignUpModalState}
                placeholder='Enter first name'
                setParentState={setNavbarSignUpModalState}
                inputSubType='first'
                inputType='name'
                validationKey='isFirstNameValid'
                validationsContext='sign up'
                valueKey='valueFirstName'
                useValidations={true}
              />
            </div>

            {/* LAST NAME */}
            <div className='nav-sign-up-modal__name-container u-pad-sm'>
              <FormInputWValidations
                parentState={navbarSignUpModalState}
                placeholder='Enter last name'
                setParentState={setNavbarSignUpModalState}
                inputSubType='last'
                inputType='name'
                validationKey='isLastNameValid'
                validationsContext='sign up'
                valueKey='valueLastName'
                useValidations={true}
              />
            </div>
          </div>

          {/* EMAIL */}
          <div className='u-pad-sm'>
            <FormInputWValidations
              parentState={navbarSignUpModalState}
              placeholder='Enter email'
              setParentState={setNavbarSignUpModalState}
              inputType='email'
              validationKey='isEmailValid'
              validationsContext='sign up'
              valueKey='valueEmail'
              useValidations={true}
            />
          </div>

          {/* USERNAME */}
          <div className='u-pad-sm'>
            <FormInputWValidations
              parentState={navbarSignUpModalState}
              placeholder='Enter username'
              setParentState={setNavbarSignUpModalState}
              inputType='username'
              validationKey='isUsernameValid'
              validationsContext='sign up'
              valueKey='valueUsername'
              useValidations={true}
            />
          </div>

          {/* PASSWORD */}
          <div className='u-pad-sm'>
            <FormInputWValidations
              parentState={navbarSignUpModalState}
              placeholder='Enter password'
              setParentState={setNavbarSignUpModalState}
              inputType='password'
              validationKey='isPasswordValid'
              validationsContext='sign up'
              valueKey='valuePassword'
              useValidations={true}
            />
          </div>

          {/* CONFIRM PASSWORD */}
          <div className='u-pad-sm'>
            <FormInputWValidations
              parentState={navbarSignUpModalState}
              placeholder='Confirm password'
              setParentState={setNavbarSignUpModalState}
              inputType='confirmpassword'
              validationKey='isConfirmPasswordValid'
              validationsContext='sign up'
              valueKey='valueConfirmPassword'
              useValidations={true}
            />
          </div>

          {/* SIGN UP BUTTON */}
          <div className='u-pad-btm-md u-pad-top-md u-pad-left-sm u-pad-right-sm'>
            <Button
              buttonProps={{
                type: 'submit',
              }}
              buttontype='primary'
              size='inherit'>
              Sign Up
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}
