import React from "react";

/* Components */
import { SectionsPageTemplate } from "..";
import Footer from "../footer/Footer";

/* Data */
import siteMapData from "./siteMapData";

export default function TermsOfUsePage() {
  return (
    <>
      <SectionsPageTemplate contentType="links" sectionsData={siteMapData} />
      <Footer />
    </>
  );
}
