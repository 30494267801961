import { Icon } from "..";
import React from "react";

export default function CardMdContentStripe({
  leftHeaderIconsArray = [],
  rightHeaderIconsArray = [],
  imgProps = {},
}) {
  const leftHeaderIconsStyled = leftHeaderIconsArray.map((icon, index) => {
      return (
        <div key={index} className="card__md__icon-container">
          {icon}
        </div>
      );
    }),
    rightHeaderIconsStyled = rightHeaderIconsArray.map((icon, index) => {
      return (
        <div key={index} className="card__md__icon-container">
          {icon}
        </div>
      );
    });

  const noImgClassName = imgProps.src ? "" : "card__md__img-sq--no-img";

  return (
    <div className="card__md">
      <div className="card__md__stripe-top">
        <div className="card__md__stripe-top--left">
          {leftHeaderIconsStyled}
        </div>
        <div className="card__md__stripe-top--right">
          {rightHeaderIconsStyled}
        </div>
      </div>
      <div className="card__md__img-sq-container">
        <img
          className={`card__md__img-sq ${noImgClassName}`}
          src={imgProps.src}
          alt={imgProps.alt}
        />
        <div className="card__md__img-sq-icon">
          <Icon size="lg" ko={true} icon="public" />
        </div>
      </div>
    </div>
  );
}
