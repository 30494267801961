import { Text } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGText() {
  const content = {
    name: "",
    variantName: "Text",
    description: `A pre-styled p tag.`,
    implementation: `To be used whenever rendering "regular" text.`,
    propsTable: [
      [
        "children",
        "JSX",
        "Any valid JSX",
        "yes",
        "Text is not a self-closing component. Wrap any desire content within the open and close tags.",
      ],
      [
        "className",
        "string",
        "u-pad-sm",
        "no",
        "Add additional classes to this prop.",
      ],
      [
        "ko",
        "Boolean",
        "true, false",
        "no",
        "Set to true if the component will be displayed over a dark background.",
      ],
      [
        "number",
        "integer, string",
        "Accepted values are 1, 2, 3",
        "no",
        `There are 3 text variants, default = 1. "1" Will display the largest available text. "2" is moderately smaller than "1". "3" will render "subtext", the smallest text variant.`,
      ],
    ],
    codeExample: `
    import { Text } from "/components";
            
    return (

        <Text number={1}> paragraph text 1 </Text>
    
        <Text number={2}> paragraph text 2 </Text>
      
        <Text number={3}> subtext </Text>
    
    )
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-pad-sm">
            <div className="u-mgn-vert-sm">
              <Text number={1}>Text 1</Text>
            </div>
            <div className="u-mgn-vert-sm">
              <Text number={2}>Text 2</Text>
            </div>
            <div className="u-mgn-vert-sm">
              <Text number={3}> Subtext</Text>
            </div>
          </div>

          <div className="u-background-blue u-pad-sm">
            <div className="u-mgn-vert-sm">
              <Text ko={true} number={1}>
                Text 1 KO
              </Text>
            </div>
            <div className="u-mgn-vert-sm">
              <Text ko={true} number={2}>
                Text 2 KO
              </Text>
            </div>
            <div className="u-mgn-vert-sm">
              <Text ko={true} number={3}>
                Subtext KO
              </Text>
            </div>
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
