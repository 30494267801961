import externalLinks from "../../utils/paths/externalLinks"
import React from "react"
import routes from "../../utils/paths/routes"

export default [
  {
    label: routes.support.siteMap.label,
    pathname: routes.support.siteMap.path,
    type: "internal link",
  },
  {
    label: routes.support.contact.label,
    pathname: routes.support.contact.path,
    type: "internal link",
  },
  {
    label: externalLinks.defenders.privacyPolicy.label,
    pathname: externalLinks.defenders.privacyPolicy.path,
    type: "external link",
    newTab: true,
  },
  {
    label: externalLinks.defenders.cookiePolicy.label,
    pathname: externalLinks.defenders.cookiePolicy.path,
    type: "external link",
    newTab: true,
  },
  {
    label: <>Creative Commons License (CC BY-NC-SA), 2021 Defenders of Wildlife</>,
    pathname: routes.support.creativeCommons.path,
    type: "internal link",
  },
]