import React, { useState } from "react";
import { Text, TextArea, Tooltip } from "../components";

export default function TextEditField(props) {
  const initState = {
    isEditing: false,
    [props.name]: props.inputValue,
    inputValue: props.inputValue,
    message: "Edit",
    inputName: props.name,
  };

  const [state, setState] = useState(initState);

  let classes = "edit-field";
  let type = "";
  let pFont = "paragraph__2";
  let iconFont = "icon";
  if (props.textType === "primary") {
    classes += " edit-field__primary";
    type = "edit-field__primary";
  } else if (props.textType === "secondary") {
    classes += " edit-field__secondary";
    type = "edit-field__secondary";
  } else if (props.textType === "text") {
    classes += " edit-field__text";
    type = "edit-field__text";
  }

  if (props.ko) {
    let secondaryClass = classes.split(" ");
    secondaryClass = secondaryClass[1];
    let modifierClass = secondaryClass + "--ko";
    modifierClass = " " + modifierClass;
    classes += modifierClass;
    pFont += " paragraph__2--ko";
    iconFont += "--ko";
  }

  if (props.small) {
    const smallClass = " " + type + "--sm";
    classes += smallClass;
  }

  function changeEditStatus(arg) {
    const { isEditing } = state;
    if (isEditing) {
      setState({ ...state, isEditing: !isEditing, message: "Edit" });
    } else {
      setState({
        ...state,
        isEditing: !isEditing,
        message: "Save",
        inputValue: state[props.name],
      });
    }
    if (arg === "save") {
      props.updateValueCB({
        inputValue: state.inputValue,
        updatedValue: state[props.name],
        elementName: state.inputName,
      });
    }
  }

  function handleChange(e) {
    if (!state.isEditing) {
      props.updateValueCB(e.target);
    }
    setState({ ...state, [e.target.name]: e.target.value });
  }

  return (
    <div className="edit-field__container">
      {state.isEditing ? (
        <div className="edit-field__content">
          <TextArea
            ko={props.ko ? true : false}
            textAreaProps={{
              maxLength: props.maxLength,
              name: props.name,
              onChange: handleChange,
              value: state[props.name],
            }}
            small={true}
          />
        </div>
      ) : (
        <Text className="edit-field__content" ko={props.ko}>
          {props.children}
        </Text>
      )}
      {/* <Tooltip body={state.message} ko={props.ko}> */}
      {state.isEditing ? (
        <p
          className={`${pFont} ${
            props.ko ? "u-hover-color-yellow" : "u-hover-color-darkblue"
          }`}
          onClick={() => changeEditStatus("save")}>
          DONE
        </p>
      ) : (
        <div className="edit-field__icon-container">
          <Tooltip body="Edit Text" ko={props.ko}>
            <span
              className={`${iconFont} icon-icon__edit icon--md`}
              onClick={() => changeEditStatus("change")}></span>
          </Tooltip>
        </div>
      )}
      {/* </Tooltip> */}
    </div>
  );
}
