import React from 'react'

/* Components */
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import {
  Button,
  Heading,
  ModalClose,
  Text,
} from '../../../../../../../../components'

/* Methods */
import APIreqs from '../../../../../../helpers/API'
import utils from '../../../../../../../../utils/utils'

function DeleteDataLayerModal({ dataLayers, i, pcm }) {
  function resetModal() {
    pcm.setState({
      ...pcm.state,
      activeModal: '',
      activeModalData: null,
    })
  }

  function setDeletedDataLayer(targetID) {
    const filteredDataLayersData = dataLayers.data.filter((dataLayerObj) => {
      return dataLayerObj.id !== targetID
    })
    dataLayers.setData(filteredDataLayersData)
  }

  async function deleteDataLayer() {
    try {
      const dataLayerID = dataLayers.data[pcm.state.activeModalData.i].id
      await APIreqs.dataLayers.delete(dataLayerID)
      setDeletedDataLayer(dataLayerID)
      utils.sendAlert(`Data layer deleted.`, 'success')
    } catch {
      utils.sendAlert(
        "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'delete CVM data layer' error.",
        'error',
      )
    }
  }

  return (
    <Modal
      centered={true}
      size='lg'
      isOpen={pcm.state.activeModal === `deleteDataLayerModal${i}`}
      toggle={() => {}}>
      <ModalHeader>
        <ModalClose cb={resetModal} />
        Data Layers
      </ModalHeader>
      <ModalBody>
        <div className='u-pad-md'>
          <Heading className='' number={3} color='secondary'>
            Delete Data Layer
          </Heading>
          <Heading className='u-pad-top-md u-pad-btm-sm' number={5}>
            This action is permanent.
          </Heading>
          <div className='u-pad-top-md'>
            <Text number={1}>
              Delete data layer{' '}
              <b>
                {dataLayers.data[pcm.state.activeModalData.i] &&
                  dataLayers.data[pcm.state.activeModalData.i].label}
              </b>
              ?
            </Text>
          </div>
          <div className='u-pad-vert-md'>
            <Text number={1}>
              This action will delete all data and files associated with this
              data layer.
            </Text>
          </div>
          <div className='u-mgn-top-md u-flex u-flex-justify-end'>
            <div className='u-mgn-right-sm map__modal-button-container'>
              <Button
                buttontype='secondary'
                buttonProps={{
                  onClick: resetModal,
                }}
                size='inherit'>
                Cancel
              </Button>
            </div>
            <div className='map__modal-button-container'>
              <Button
                buttontype='primary'
                buttonProps={{
                  onClick: () => {
                    deleteDataLayer()
                    resetModal()
                  },
                }}
                size='inherit'>
                Delete
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DeleteDataLayerModal
