import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";

let alertCounter = 0;

export default {
  formatDateTime: (str) => {
    let dateTime = str.split("T");
    let date = dateTime[0];
    date = date.split("-");
    date = date[1] + "/" + date[2] + "/" + date[0];
    let time = dateTime[1].split(":");
    let hour = parseInt(time[0]);
    let timeOfDay = "";
    if (hour - 12 > 0) {
      timeOfDay = "PM";
      hour = hour - 12;
    } else {
      timeOfDay = "AM";
    }
    const minutes = time[1];
    time = hour + ":" + minutes;
    dateTime = date + "@" + time + timeOfDay;
    return dateTime;
  },
  baseURL: window.location.href.includes("http://localhost:3000/") ? "http://localhost:3000/" : "https://conservationist.io/",
  sendAlert: (msg, type) => {
    const typeClassName = type ? `alert-toast__${type}` : "";
    const alertNumber = `alertNumber${alertCounter}`;
    toast(msg, {
      position: toast.POSITION.TOP_CENTER,
      className: `alert-toast ${typeClassName} ${alertNumber}`,
      autoClose: true,
    });

    const htmlElem = (msg, type) => {
      const firstUpper = type.charAt(0).toUpperCase() + type.slice(1),
        htmlParams = {
          error: {
            headingKo: "heading--5--ko",
            iconName: "icon2-icon2__close",
            iconKo: "icon-ko",
            msgKo: "paragraph__1--ko"
          },
          message: {
            headingKo: "",
            iconName: "icon-icon__chat",
            iconKo: "",
            msgKo: ""
          },
          success: {
            headingKo: "heading--5--ko",
            iconName: "icon2-icon2__check",
            iconKo: "icon-ko",
            msgKo: "paragraph__1--ko"
          },
          warning: {
            headingKo: "heading--5--ko",
            iconName: "icon-icon__warning",
            iconKo: "icon-ko",
            msgKo: "paragraph__1"
          },
        }
      return (`
        <div class=" u-flex u-flex-align-center">
          <div class="alert-toast__icon">
            <div class="alert-toast__icon--${type}">
              <span class="${htmlParams[type].iconName} ${htmlParams[type].iconKo} icon--lg"></span>
            </div>
          </div>
          <div class="u-flex u-flex-column u-mgn-left-sm">
            <h5 class="heading--5 ${htmlParams[type].headingKo} heading--bold u-mgn-btm-sm">${firstUpper}</h5>
            <p class="paragraph__1 ${htmlParams[type].msgKo} alert-toast__msg alert-toast__text">${msg}</p>
          </div>
        </div>
      `)
    }

    const checkExist = setInterval(function () {
      if ($(`.${alertNumber}`).find("div")[0]) {
        let jqElem = $(`.${alertNumber}`).children()[0]
        jqElem = $(jqElem)
        try {
          jqElem.html(htmlElem(msg, type))
        } catch (err) {
          clearInterval(checkExist);
        }
        clearInterval(checkExist);
      }
    }, 100); // check every 100ms
    alertCounter++;
  },
  objToQueryParams: (obj) => {
    let query = "?";
    const params = Object.keys(obj);
    for (let i = 0; i < params.length; i++) {
      const key = params[i];
      query += key;
      query += "=";
      query += obj[key];
      if (i !== params.length - 1) {
        query += "&";
      }
    }
    return query;
  },
  queryParamsToObj: (url) => {
    // URL should be `window.location.search.substring(1)`
    return JSON.parse(
      '{"' +
      decodeURI(url)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}',
    );
  },
  scrollToTop: () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  },
};
