import React from 'react'

// Components
import CycleBackgroundColorBtn from './CycleBackgroundColorBtn'
import CycleVisibilityBtn from './CycleVisibilityBtn'
import DeleteBtn from './delete/DeleteBtn'
import FindImgBtn from './FindImgBtn'
import MetadataBtn from './metadata/MetadataBtn'
import WarningIcon from './WarningIcon'

function BtnsElement({ dataLayers, i, mapHandler, pcm }) {
  return (
    <div className='u-flex u-flex-justify-between cvm__data-layer__large-icon__container'>
      <div className='u-flex u-flex-align-center'>
        <div className='u-flex u-flex-align-center cvm__data-layer__large-icon__container cvm__data-layer__large-icon__container--left-end'>
          <MetadataBtn dataLayers={dataLayers} i={i} pcm={pcm} />
        </div>
      </div>

      <div className='u-flex u-flex-align-center u-flex-justify-end'>
        <div
          className={`u-background-${dataLayers.data[i].img_background_color} u-flex u-flex-align-center cvm__data-layer__large-icon__container cvm__data-layer__large-icon__container--center`}>
          <CycleBackgroundColorBtn dataLayers={dataLayers} i={i} />
        </div>
        <div className='u-flex u-flex-align-center cvm__data-layer__large-icon__container cvm__data-layer__large-icon__container--center'>
          <CycleVisibilityBtn dataLayers={dataLayers} i={i} />
        </div>
        <div className='u-flex u-flex-align-center cvm__data-layer__large-icon__container cvm__data-layer__large-icon__container--center'>
          <FindImgBtn dataLayers={dataLayers} i={i} mapHandler={mapHandler} />
        </div>
        <div className='u-flex u-flex-align-center cvm__data-layer__large-icon__container cvm__data-layer__large-icon__container--right-end'>
          <DeleteBtn dataLayers={dataLayers} i={i} pcm={pcm} />
        </div>
        <WarningIcon dataLayers={dataLayers} i={i} />
      </div>
    </div>
  )
}

export default BtnsElement
