import React from 'react'

// Components
import { Icon, Tooltip } from '../../../../../../../../components'
import DeleteDataLayerModal from './DeleteDataLayerModal'

function DeleteBtn({ dataLayers, i, pcm }) {
  return (
    <div
      key={dataLayers.data[i].id}
      onClick={() => {
        pcm.setState({
          ...pcm.state,
          activeModal: `deleteDataLayerModal${i}`,
          activeModalData: { i },
        })
      }}>
      <Tooltip body={`Delete data layer.`}>
        <Icon icon='trash' ko={true} size='md' />
      </Tooltip>
      {pcm.state.activeModal === `deleteDataLayerModal${i}` && (
        <DeleteDataLayerModal dataLayers={dataLayers} i={i} pcm={pcm} />
      )}
    </div>
  )
}

export default DeleteBtn
