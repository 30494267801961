import { Button, Heading, Text } from '../components'
import React, {
  useEffect,
  useState,
  // useCallback
} from 'react'
import staticLink from '../utils/static'
// import { useDropzone } from "react-dropzone";

export default function FileUpload({
  heading,
  customInstructions,
  fileType,
  maxFileSizeB = 1e6,
  modalToggle,
  uploadCB,
  continueCB = false,
  uploadPercentage = 0,
}) {
  const initState = {
      file: null,
      isDisabled: false,
      isExtensionValid: false,
      isFileValid: false,
      isSizeValid: false,
    },
    [state, setState] = useState(initState),
    maxFileSizeMB = maxFileSizeB / 1e6,
    validExtensions = {
      raster: ['.tif', '.tiff'],
      vector: ['.json', '.kml', '.kmz', '.zip'],
    }
  // console.log("file upload state: ", state);

  const predefinedInstructions = {
      raster: (
        <div className='u-pad-btm-sm'>
          <Heading number={5}>Raster Data File Requirements</Heading>
          <div>
            <Text number={1}>
              1. File type: <b>TIFF / GeoTIFF (.tif, .tiff)</b>.
            </Text>
          </div>
          <div>
            <Text number={1}>
              2. File size: <b>Less than {maxFileSizeMB} MB</b>.
            </Text>
          </div>
        </div>
      ),
      vector: (
        <div className='u-pad-btm-sm'>
          <Heading number={5}>Vector data file requirements</Heading>
          <div>
            <Text number={1}>
              1. File type: <b>Compressed shapefile (.zip)</b>,{' '}
              <b>KML/KMZ file (.kml/.kmz)</b>, or <b>GeoJSON file (.json)</b>.
            </Text>
          </div>
          <div>
            <Text number={1}>
              2. File size: <b>Less than {maxFileSizeMB} MB</b>.
            </Text>
          </div>
        </div>
      ),
    },
    instructions = customInstructions
      ? customInstructions
      : predefinedInstructions[fileType]

  function targetFile(e) {
    setState({
      ...state,
      file: e.target.files[0],
    })
  }

  // Validate target file.
  useEffect(() => {
    if (state.file) {
      let isExtensionValid = false
      for (let i = 0; i < validExtensions[fileType].length; i++) {
        const extension = validExtensions[fileType][i]
        if (
          state.file.name.slice(-4) === extension ||
          state.file.name.slice(-5) === extension
        ) {
          isExtensionValid = true
          break
        }
      }

      let isSizeValid = false
      if (state.file.size <= maxFileSizeB) {
        isSizeValid = true
      }

      let isFileValid = false
      if (isExtensionValid && isSizeValid) {
        isFileValid = true
      }

      setState({ ...state, isExtensionValid, isFileValid, isSizeValid })
    }
  }, [state.file])

  async function submit(e) {
    e.preventDefault()
    setState({ ...state, isDisabled: true }) // Update buttons.

    let formData = new FormData()
    formData.append('file', state.file, state.file.name)
    await uploadCB(formData, state.file.name) // Execute upload callback function.
    if (continueCB) {
      continueCB() // Execute continue callback function if defined.
    }
    setState(initState)
  }

  /* const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        let formData = new FormData();
        formData.append("file", file, file.name);
        setState({ ...state, formData, name: file.name });
      });
    },
    [state],
  ); */

  /* const {
    // acceptedFiles, 
    getRootProps, 
    // getInputProps,
  } = useDropzone({ onDrop }); */

  // console.log("FileUpload state: ", state);

  const uploadButton = (
    <Button
      buttontype='primary'
      buttonProps={{
        onClick: submit,
      }}
      className={!state.isFileValid ? 'file-upload__button--disabled' : ''}
      disabled={!state.isFileValid || state.isDisabled}>
      Upload
    </Button>
  )

  // Button with file data percentage upload status, if provided by parent.
  function getPercentageUploadButton() {
    if (uploadPercentage === 0) {
      return uploadButton
    } else if (uploadPercentage < 100) {
      return (
        <div className='file-upload__button--min-width'>
          <Button
            buttontype='primary'
            className='file-upload__button--disabled '
            disabled={true}
            size='inherit'>
            {`${uploadPercentage}%`}
          </Button>
        </div>
      )
    }
  }

  return (
    <div className='file-upload'>
      <div className='u-pad-btm-md'>
        <Heading number={3} color='secondary'>
          {heading}
        </Heading>
      </div>
      <div className='u-pad-btm-sm'>
        <div>{instructions}</div>
      </div>

      {/* Target file for upload component. */}
      <div id='fileDrag' /* {...getRootProps()} */>
        <div className='file-upload__drag' onChange={targetFile}>
          <img
            src={`${staticLink}icon/graphic__browse.svg`}
            alt='Browse Files'
            className='file-upload__doc-icon'
          />
          <form
            className='file-upload__form'
            encType='multipart/form-data'
            onSubmit={submit}>
            <input
              type='file'
              name='map_layer'
              id='map_layer'
              className='form__file'
              single='true'
              onChange={targetFile}
              disabled={state.isDisabled}
            />
            <label
              htmlFor='map_layer'
              className={`button__primary ${
                state.isDisabled ? 'button--disabled' : ''
              } js-labelFile u-mgn-top-md`}
              id='layerUploadLabel'>
              Browse
            </label>
          </form>
          {/* <div className="u-mgn-top-sm">
            <Heading number={5}>or drag a file here</Heading>
          </div> */}
        </div>
      </div>
      <div className='u-mgn-top-md'>
        <Heading number={5}>Selected file:</Heading>
      </div>
      {state.file ? (
        <div className='u-background-blue u-pad-sm u-mgn-btm-sm'>
          <div>
            <Text number={1} ko={true}>
              Name: <i>{state.file.name}</i>
            </Text>
          </div>
          <div>
            <Text number={1} ko={true}>
              Size: {(state.file.size / 1000000).toFixed(3)} MB
            </Text>
          </div>
        </div>
      ) : (
        <div className='u-mgn-btm-sm u-mgn-top-sm'>
          <Text number={2}>
            <i>No file selected.</i>
          </Text>
        </div>
      )}

      {/* File validation error messages. */}
      {state.file ? (
        state.file.size > maxFileSizeB && (
          <div>
            <Text number={2} className='file-upload__error-text'>
              Error: File size cannot be larger than {`${maxFileSizeMB}`} MB.
            </Text>
          </div>
        )
      ) : (
        <></>
      )}
      {!state.isExtensionValid && state.file && (
        <div>
          <Text number={2} className='file-upload__error-text'>
            Error:{' '}
            {`File must must be of type ${validExtensions[fileType].join(
              ', ',
            )}.`}
          </Text>
        </div>
      )}

      {/* Action buttons. */}
      <div className='file-upload__submit u-mgn-top-md'>
        {((continueCB && !uploadPercentage) || !continueCB) && (
          <div className='u-mgn-right-sm'>
            <Button
              buttontype='secondary'
              buttonProps={{
                onClick: () => {
                  modalToggle()
                  setState(initState)
                },
              }}>
              Cancel
            </Button>
          </div>
        )}
        {continueCB ? getPercentageUploadButton() : uploadButton}
      </div>
    </div>
  )
}
