import React from 'react'

// Components
import { Icon, Tooltip } from '../../../../../../components'
import UploadRasterModal from './UploadRasterModal'
import ProcessRasterModal from './ProcessRasterModal'

export default function UploadRasterButton({ dataLayers, mapHandler, pcm }) {
  return (
    <>
      <div
        onClick={() => {
          pcm.setState({
            ...pcm.state,
            activeModal: 'uploadRasterModal',
          })
        }}>
        <Tooltip body='Add data layer by uploading data file.'>
          <Icon icon='upload' ko={true} size='md' />
        </Tooltip>
      </div>
      <UploadRasterModal dataLayers={dataLayers} pcm={pcm} />
      <ProcessRasterModal
        dataLayers={dataLayers}
        mapHandler={mapHandler}
        pcm={pcm}
      />
    </>
  )
}
