import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

// Authentication
import Activate from './auth/Activate.jsx'
import PasswordReset from './auth/PasswordReset.jsx'

// Components
import {
  BetaTestingPage,
  ContactPage,
  CreativeCommons,
  LandingPage,
  Navbar,
  ProfilePage,
  SiteMapPage,
  StyleGuide,
  UtilityPage404,
} from './platform'

// Utilities
import routes from './utils/paths/routes'

// Style
import './App.css'

// Conservation Apps
import HabitatPatrol from './apps/habitatpatrol/HabitatPatrol'
import HPHome from './platform/apps/HPHomePage'
import CMDashboard from './apps/collaborativeMapper/components/dashboard/CMDashboard.jsx'
import CMApp from './apps/collaborativeMapper/components/app/CMApp'
import CMHome from './platform/apps/CMHomePage'
import CVMEntryPoint from './apps/conservationValueMapper/CVMEntryPoint.jsx'

export default function App() {
  const authStateInit = {
      isAppTester: false,
      isAuthenticated: false,
      isTabVisible: 'visible',
    },
    [authState, setAuthState] = useState(authStateInit),
    auth = {
      setState: setAuthState,
      state: authState,
      stateInit: authStateInit,
    }

  const accountStateInit = {
      bio: '',
      email: '',
      email_subscribed: false,
      first_name: '',
      id: null,
      last_name: '',
      organization: '',
      photo: '',
      username: '',
    },
    [accountState, setAccountState] = useState(accountStateInit),
    account = {
      setState: setAccountState,
      state: accountState,
      stateInit: accountStateInit,
    }

  const isLogInModalOpenInit = false,
    navbarStateInit = {
      dropdownMenuSource: '',
      isAppPage: false,
      isLogInModalOpen: isLogInModalOpenInit,
      isResetPasswordModalOpen: false,
      isRetrieveUsernameModalOpen: false,
      isSignUpModalOpen: false,
      isSignUpEmailMessageModalOpen: false,
      subtitle: '',
    },
    [navbarState, setNavbarState] = useState(navbarStateInit),
    navbar = {
      state: navbarState,
      setState: setNavbarState,
      init: navbarStateInit,
      isLogInModalOpenInit: isLogInModalOpenInit,
    }

  // Add eventlistener to update auth.state on tab visibility change
  useEffect(() => {
    function checkIsVisible() {
      auth.setState({
        ...auth.state,
        isTabVisible: document.visibilityState,
      })
    }
    document.addEventListener('visibilitychange', checkIsVisible)
    return () => {
      document.removeEventListener('visibilitychange', checkIsVisible)
    }
  })

  // console.log("auth state:", auth.state);
  // console.log("account state:", account.state);

  return (
    <>
      <ToastContainer autoClose={8000} />
      <BrowserRouter>
        {/* Navbar */}
        <div className='app__navbar-placeholder'>DO NOT REMOVE THIS DIV</div>
        <Route
          path={routes.root.path}
          render={({ location }) => {
            return (
              <Navbar
                account={account}
                auth={auth}
                location={location}
                navbar={navbar}
              />
            )
          }}
        />

        <Switch>
          {/* Landing Page */}
          <Route exact path={routes.landing.path}>
            <LandingPage />
          </Route>

          {/* User Profile Page */}
          <Route
            exact
            sensitive
            path={routes.profile.username.path}
            render={({ location }) => {
              return (
                <ProfilePage
                  account={account}
                  auth={auth}
                  location={location}
                />
              )
            }}
          />

          {/* Collaborative Mapper */}
          <Redirect
            exact
            from={routes.apps.collab.path}
            to={routes.apps.collabDash.path}
          />
          <Route exact path={routes.apps.collabHome.path}>
            <CMHome account={account} auth={auth} />
          </Route>
          <Route exact path={routes.apps.collabDash.path}>
            <CMDashboard account={account} auth={auth} />
          </Route>
          <Route path={routes.apps.collabMap.path}>
            <CMApp />
          </Route>

          {/* Conservation Value Mapper */}
          <Redirect
            exact
            from={routes.apps.cvm.path}
            to={routes.apps.cvmDash.path}
          />
          <Route path={routes.apps.cvm.path}>
            <CVMEntryPoint account={account} auth={auth} />
          </Route>

          {/* Habitat Patrol */}
          <Route exact path={routes.apps.habPatHome.path}>
            <HPHome />
          </Route>
          <Route exact path={routes.apps.habPatApp.path}>
            <HabitatPatrol />
          </Route>

          {/* Resources */}
          <Route exact path={routes.betaTesting.path.home}>
            <BetaTestingPage account={account} auth={auth} navbar={navbar} />
          </Route>
          <Route exact path={routes.support.contact.path}>
            <ContactPage />
          </Route>
          <Route exact path={routes.support.siteMap.path}>
            <SiteMapPage />
          </Route>
          <Route exact path={routes.support.creativeCommons.path}>
            <CreativeCommons />
          </Route>

          {/* Style Guide */}
          <Route exact path={routes.style.path}>
            <StyleGuide />
          </Route>

          {/* Authentication Pages */}
          <Route exact path={routes.auth.activate.path}>
            <Activate />
          </Route>
          <Route exact path={routes.auth.passwordReset.path}>
            <PasswordReset account={account} auth={auth} />
          </Route>

          {/* If not authenticated but auth is checked once, redirect to <LandingPage />  */}
          <Redirect exact from={routes.root.path} to={routes.landing.path} />

          <Route path={routes.utility[404].path}>
            <UtilityPage404 account={account} auth={auth} />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  )
}
