import React from "react";
import { Heading, TableHead, TableRow, TH } from "../../../components";

export default function ProjectsListColumnTitles() {
  return (
    <TableHead>
      <TableRow className="u-mgn-top-md">
        <TH className="u-align-center">
          <Heading number={4}>Project Title</Heading>
        </TH>
        <TH className="u-align-center">
          <Heading number={4}>Date</Heading>
        </TH>
        <TH className="u-align-center">
          <Heading number={4}>Actions</Heading>
        </TH>
      </TableRow>
    </TableHead>
  );
}
