import { FormInputWValidations, Text } from "..";
import React, { useState } from "react";
import { SGTemplate } from ".";

export default function SGFormInputWValidations() {
  const stateInit = {
      isFirstNameValid: false,
      isMessageValid: false,
      isPasswordValid: false,
      isUsernameValid: false,
      valueFirstName: "",
      valueMessage: "",
      valuePassword: "",
      valueUsername: "",
    },
    [state, setState] = useState(stateInit);

  const content = {
    name: "",
    variantName: "FormInputWValidations",
    description: `<FormInputWValidations /> is a composite component that integrates the functionality of <FormInput /> with additional validation logic that checks the format of the input value using validation functions.  Error-specific messages are shown on the UI, according to the type of identified error, to provide feedback to the user.  These validations are specific to the specified type of input, e.g. email, name, password, etc.  Furthermore, <FormInputWValidations /> passes the form input value and form input validation state to the state variable of its parent component, such as a form or modal component.`,
    implementation:
      "Instantiate as a closed JSX component with props assigned.",
    propsTable: [
      [
        "inputClassName",
        "string",
        `u-pad-sm`,
        "no",
        "Add additional style classes to the input element.",
      ],
      [
        "inputType",
        "string",
        "password",
        "yes",
        "`inputType` attribute defines the label, input formatting, and input validation scheme for the component.  \n\nOptions include: 'confirmpassword', 'email', 'message', 'name', 'password', 'username'.",
      ],
      [
        "inputSubType",
        "string",
        "first",
        "maybe",
        "`inputSubType` is required if `inputType = 'name'`.  \n\nOptions for `inputSubType` attribute include: 'first', 'last'.",
      ],
      [
        "ko",
        "boolean",
        `true`,
        "no",
        "Set `ko` to `true` when placing element on a dark background.",
      ],
      [
        "labelClassName",
        "string",
        `u-mgn-sm`,
        "no",
        "Add additional style classes to the label element.",
      ],
      [
        "parentState",
        "state object",
        `parentStateObj`,
        "yes",
        "Assign the state of the parent component to `parentState` to pass the state object to this component for updating.",
      ],
      [
        "placeholder",
        "string",
        `Enter email`,
        "no",
        "Add `placeholder` text to show in the input area, for example to prompt the user to enter an email.",
      ],
      [
        "useValidations",
        "boolean",
        "true",
        "no",
        "To enable validation methods on input value, set `useValidations = {true}` and assign arguments to `validationKey`, and `validationsContext`.  \n\nSee `frontend/src/components/Form/FormInputWValidations.jsx` and `frontend/src/utils/forms/InputsValidations.js` for more information about validation functions.",
      ],
      [
        "validationKey",
        "string",
        "isEmailValid",
        "maybe",
        "`validationKey` assignment is required if `useValidations = {true}`.  \n\nThe validation key is the name of the attribute in the parent state that stores the boolean value representing if the input is valid or not.  \n\nFor example, if the parent component is a form that uses this component to define an email input, then `<FormInputWValidations validationKey='isEmailValid'/> will enable this component to update the ['isEmailValid'] parent state attribute to `false` or `true` after validation functions are triggered.",
      ],
      [
        "validationsContext",
        "string",
        "sign up",
        "maybe",
        "`validationsContext` assignment required if `useValidations = {true}`.  \n\n`validationsContext` attribute options include: 'sign up', 'contact'.",
      ],
      [
        "valueKey",
        "string",
        "emailValue",
        "yes",
        "The value key is the name of the attribute in the parent state that stores the string value representing the input text.  \n\nFor example, if the parent component is a form that uses this component to define an email input, then `<FormInputWValidations valueKey='emailValue'/> will enable this component to update the ['emailValue'] parent state attribute to a string that is the current input value such as 'email@gmail.com'.",
      ],
      [
        "setParentState",
        "state object update function",
        "setParentStateObj",
        "yes",
        "Assign the setState function of the parent component to `setParentState` to pass the setState function to this component to update the parent state object.",
      ],
    ],
    codeExample: `import { FormInputWValidations } from "..";
import React from "react";

export default function Form(){
  const stateInit = {
    isFirstNameValid: false,
    isPasswordValid: false,
    isUsernameValid: false,
    valueFirstName: "",
    valuePassword: "",
    valueUsername: "",
  },
  [state, setState] = useState(stateInit);

  return(
    <div className="u-pad-sm" style={{ width: "500px" }}>
      <div className="u-pad-sm">
        <FormInputWValidations
          parentState={state}
          placeholder="Enter first name"
          setParentState={setState}
          inputSubType="first"
          inputType="name"
          validationKey="isFirstNameValid"
          validationsContext="sign up"
          valueKey="valueFirstName"
          useValidations={true}
        />
      </div>
      <div className="u-pad-sm">
        <FormInputWValidations
          parentState={state}
          placeholder="Enter username"
          setParentState={setState}
          inputType="username"
          validationKey="isUsernameValid"
          validationsContext="sign up"
          valueKey="valueUsername"
          useValidations={true}
        />
      </div>
      <div className="u-pad-sm">
        <FormInputWValidations
          parentState={state}
          placeholder="Enter password"
          setParentState={setState}
          inputType="password"
          validationKey="isPasswordValid"
          validationsContext="sign up"
          valueKey="valuePassword"
          useValidations={true}
        />
      </div>
    </div>
  )
}`,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div>
          <div>
            <Text number={1}>
              Try applying valid and invalid inputs to see if the validation
              methods catch errors. The error message is shown when the input
              element loses focus (on tab, clicking somewhere else, etc.). These
              examples use validation functions defined for the "sign up"
              context.
            </Text>
          </div>
          <div className="u-pad-sm" style={{ width: "500px" }}>
            <div className="u-pad-sm">
              <FormInputWValidations
                parentState={state}
                placeholder="Enter first name"
                setParentState={setState}
                inputSubType="first"
                inputType="name"
                validationKey="isFirstNameValid"
                validationsContext="sign up"
                valueKey="valueFirstName"
                useValidations={true}
              />
            </div>
            <div className="u-pad-sm">
              <FormInputWValidations
                parentState={state}
                placeholder="Enter username"
                setParentState={setState}
                inputType="username"
                validationKey="isUsernameValid"
                validationsContext="sign up"
                valueKey="valueUsername"
                useValidations={true}
              />
            </div>
            <div className="u-pad-sm">
              <FormInputWValidations
                parentState={state}
                placeholder="Enter password"
                setParentState={setState}
                inputType="password"
                validationKey="isPasswordValid"
                validationsContext="sign up"
                valueKey="valuePassword"
                useValidations={true}
              />
            </div>
            <div className="u-pad-sm">
              <FormInputWValidations
                parentState={state}
                placeholder="Enter message"
                setParentState={setState}
                inputType="message"
                validationKey="isMessageValid"
                validationsContext="contact"
                valueKey="valueMessage"
                useValidations={false}
              />
            </div>
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
