import React from "react";

export default function Text({ className, number = 1, ...props }) {
  let textClassName = "paragraph__";

  textClassName += number;

  if (number === 3) {
    textClassName = "subtext";
  }

  if (props.ko) {
    const koClass = textClassName + "--ko";
    textClassName += " ";
    textClassName += koClass;
  }

  return (
    <>
      {(() => {
        if (number === 1) {
          return (
            <span className={`${textClassName} ${className}`}>
              {props.children}
            </span>
          );
        } else if (number === 2) {
          return (
            <span className={`${textClassName} ${className}`}>
              {props.children}
            </span>
          );
        } else if (number === 3) {
          return (
            <span className={`${textClassName} ${className}`}>
              {props.children}
            </span>
          );
        }
      })()}
    </>
  );
}
