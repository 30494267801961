import React from 'react'

// Components
import LabelElement from './labelElement/LabelElement'
import BtnsElement from './btnsElement/BtnsElement'
import ValuationElement from './valuationElement/ValuationElement'

export default function DataLayerElement({ dataLayers, i, mapHandler, pcm }) {
  return (
    <div
      className='cvm__data-layer__container u-mgn-vert-md u-full-width'
      key={dataLayers.data[i].id}>
      <LabelElement dataLayers={dataLayers} i={i} />
      <ValuationElement dataLayers={dataLayers} i={i} pcm={pcm} />
      <BtnsElement
        dataLayers={dataLayers}
        i={i}
        mapHandler={mapHandler}
        pcm={pcm}
      />
    </div>
  )
}
