import { TileLayer } from "react-leaflet";

// Extra key: pk.eyJ1Ijoic3BhdHNlbCIsImEiOiJjanRrYXp5ZHkxNHQ0M3lxbXF1N25oODJhIn0.EUKeJrihgiJzxo5FAwlUIw

const attribution = '© <a href="https://www.mapbox.com/about/maps/" target="_blank" rel="noreferrer"><u>Mapbox</u></a> © <a href="© <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noreferrer"><u>OpenStreetMap</u></a>&nbsp;<a href="https://apps.mapbox.com/feedback/" target="_blank" rel="noreferrer"><u>Improve This Map</u></a>',
    url = "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
    baseLayers = {
        Dark: <TileLayer
            url={url}
            accessToken="pk.eyJ1IjoibWF1YmFpbmNjaSIsImEiOiJja2I1YXA4dHoxMnJpMnFtZHlyaGRsbDFuIn0.PBCNxXVT4V8KwnnR6TZEBg"
            id="mapbox/dark-v10"
            attribution={attribution}
        />,
        Light: <TileLayer
            url={url}
            accessToken="pk.eyJ1IjoibWF1YmFpbmNjaSIsImEiOiJja2I1YXA4dHoxMnJpMnFtZHlyaGRsbDFuIn0.PBCNxXVT4V8KwnnR6TZEBg"
            id="mapbox/light-v10"
            attribution={attribution}
        />,
        Outdoors: <TileLayer
            url={url}
            id="mapbox/outdoors-v11"
            accessToken="pk.eyJ1IjoibWF1YmFpbmNjaSIsImEiOiJja2I1YXA4dHoxMnJpMnFtZHlyaGRsbDFuIn0.PBCNxXVT4V8KwnnR6TZEBg"
            attribution={attribution}
        />,
        Satellite: <TileLayer
            url={url}
            accessToken="pk.eyJ1IjoibWF1YmFpbmNjaSIsImEiOiJja2I1YXA4dHoxMnJpMnFtZHlyaGRsbDFuIn0.PBCNxXVT4V8KwnnR6TZEBg"
            id="mapbox/satellite-streets-v11"
            attribution={attribution}
        />,
    }

export default baseLayers