import { Heading, Text } from "../../components";
import React from "react";
import staticLink from "../../utils/static";

export default function ProfilePagePublicSection({ profile }) {
  return (
    <>
      <img
        src={`${staticLink}hero/Cio-Hero.png`}
        alt="banner"
        className="profile__banner"
      />
      <div className="profile__public">
        <div className="profile__roundtop">
          <img
            src={`/${profile.state.profileFields.photo}`}
            alt="Profile picture"
            className="profile__roundtop--thumb"
          />
        </div>
        <div className="profile__public--info">
          <div className="u-mgn-top-sm u-align-center">
            <Heading number={4}>{profile.state.profileFields.username}</Heading>
          </div>
          <div className="u-mgn-top-sm u-align-center">
            <Text number={2}> {profile.state.profileFields.organization} </Text>
          </div>
          <div className="u-mgn-top-sm">
            {profile.state.profileFields.bio ? (
              <Text number={2}>{profile.state.profileFields.bio}</Text>
            ) : (
              <Text number={2}>No description added yet</Text>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
