import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// Components
import { UtilityPage401, UtilityPage404 } from '../../../platform'
import AppUI from './AppUI'

// Utilities
import APIreqs from '../helpers/API'
import utils from '../../../utils/utils'

function PageHandler({ account, auth }) {
  const pageComponentInit = <></>,
    [pageComponent, setPageComponent] = useState(pageComponentInit)
  const { projectID } = useParams()

  useEffect(() => {
    // Set app page when user arrives at CVM project URL.
    async function setPage() {
      // Use projectID from URL, authentication state, and account state to get appropriate page.
      async function getPageKey() {
        // Return 404 page if projectID param from URL is invalid (not an integer).
        if (!Number.isInteger(Number(projectID))) {
          return '404'
        }

        // Set 401 page if projectID is valid but user is not authenticated.
        else if (!auth.state.isAuthenticated) {
          return '401'
        }

        // Set CVM App UI page if projectID is valid, user is authenticated, and user is project analyst.
        else if (account.state.id !== null) {
          async function getProjects() {
            try {
              const res = await APIreqs.projects.getAll(account.state.id)
              const projects = res.data
              if (projects.length > 0) {
                for (let project of projects) {
                  if (project.id === Number(projectID)) {
                    return 'appUI'
                  }
                }
              }
            } catch {
              utils.sendAlert(
                "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'get CVM projects' error.",
                'error',
              )
              return '401'
            }
          }
          return await getProjects()
        }

        // Set 401 page if none of the above conditions are valid.
        return '401'
      }
      const pages = {
          401: <UtilityPage401 account={account} auth={auth} />,
          404: <UtilityPage404 account={account} auth={auth} />,
          appUI: <AppUI account={account} auth={auth} />,
        },
        pageKey = await getPageKey()
      setPageComponent(pages[pageKey])
    }
    setPage()
  }, [account.state.id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <>{pageComponent}</>
}

export default PageHandler
