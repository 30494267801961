import React from "react";

/* Components */
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  Autocomplete,
  Button,
  Heading,
  ModalClose,
  Text,
} from "../../../../../../components";

/* Data */
import species from "../../../../../../utils/data/species_range_cn_and_sn";
import srCnToSn from "../../../../../../utils/data/species_range_cn_to_sn";

/* Methods */
import API from "../../../../API";
import featuresHelperImport from "../../../../features";
import Leaflet from "leaflet";
import utils from "../../../../../../utils/utils";

export default function ImportSpeciesRangeModal({ app }) {
  const addSpeciesRange = () => {
    app.toggleModal("addSpecies");
    const { mapID, isCreator, isEditor } = app.state;
    let species = document.querySelector("#ecosSearch").value;
    if (srCnToSn[species]) {
      species = srCnToSn[species];
    }
    API.getSpeciesRange(species)
      .then((res) => {
        const { data } = res;
        if (data.length < 1) {
          utils.sendAlert(`No range found for that species.`, "error");
          return;
        }
        const feats = [];
        for (let i = 0; i < data.length; i++) {
          const dataset = data[i];
          for (let j = 0; j < dataset.length; j++) {
            Leaflet.geoJson(dataset[j], {
              onEachFeature: function (feature, layer) {
                const editor = new Leaflet.Edit.Poly(layer);
                let standardized = new Leaflet.polygon(editor._defaultShape());
                standardized = standardized.toGeoJSON();
                standardized["dataset"] = dataset[j].dataset;
                if (!isEditor && !isCreator) {
                  standardized = JSON.stringify(standardized);
                }
                feats.push(standardized);
              },
            });
          }
        }
        const req = { feats };
        if (!isEditor && !isCreator) {
          let features = app.state.features;
          features = features.concat(feats);
          utils.sendAlert(
            "To add species ranges to the map, please sign up to make an account.",
            "error",
          );
          // app.setState({ features }, () => featuresHelperImport(app))
        } else {
          API.standardizeFeatures(req, mapID).then((res2) => {
            let features = app.state.features;
            features = features.concat(res2.data);
            app.setState({ features }, () => featuresHelperImport(app));
          });
        }
      })
      .catch((err) => {
        utils.sendAlert(
          "There was an issue adding that species to this map.",
          "error",
        );
      });
  };

  return (
    <Modal
      centered={true}
      isOpen={app.state.modals.addSpecies}
      toggle={() => app.toggleModal("addSpecies")}>
      <ModalHeader>
        <ModalClose cb={() => app.toggleModal("addSpecies")} />
        Vector Layers
      </ModalHeader>
      <ModalBody>
        <div className="u-pad-md">
          <Heading className="u-pad-btm-md" color="secondary" number={3}>
            Import Species Range
          </Heading>
          <div className="">
            <Heading number={5} className="">
              Search for a species range from{" "}
              <a
                className={`hyperlink u-hover-underline`}
                href="https://ecos.fws.gov/ecp/"
                target="_blank"
                rel="noopener noreferrer">
                <span className="heading--5">ECOS</span>
              </a>{" "}
              by scientific name:
            </Heading>
            <div className="u-mgn-top-sm">
              <div className="u-full-width">
                <Autocomplete
                  data={species}
                  app={app}
                  icon={true}
                  placeholder="Scientific Name Of Species"
                  htmlID="ecosSearch"
                />
              </div>
              <div className="u-pad-vert-md">
                <Text number={1}>
                  Importing a species range will add the species range vector
                  data to the map.
                </Text>
              </div>
              <div className="u-mgn-top-md u-flex u-flex-justify-end">
                <Button
                  buttontype="secondary"
                  buttonProps={{
                    onClick: () => app.toggleModal("addSpecies"),
                  }}
                  className="u-mgn-right-sm">
                  Cancel
                </Button>
                <Button
                  buttontype="primary"
                  buttonProps={{
                    onClick: () => {
                      addSpeciesRange();
                    },
                  }}>
                  Import
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
