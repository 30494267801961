import React from "react";
import { Button } from "..";

export default function CardLgProfileButton({
  linkPath,
  buttonText,
  bodyText,
  headerText,
  imgProps = {},
}) {
  const imgElement = imgProps.src ? (
    <img className="card__lg__img-cir" src={imgProps.src} alt={imgProps.alt} />
  ) : (
    <div className="card__lg__img-cir card__lg__img-cir--no-img"></div>
  );

  return (
    <div className="card__lg">
      {imgElement}
      <div className="card__lg__header-container heading__3">{headerText}</div>
      <div className="card__lg__body-container">
        <div className="card__lg__body heading__5">{bodyText}</div>
      </div>
      <div className="card__lg__button-container">
        <Button buttontype="primary" href={linkPath}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
}
