import { CardMdProfileStripe, Icon, Tooltip } from "..";
import React from "react";
import { SGTemplate } from ".";
import staticLink from "../../utils/static";

export default function SGCardMdProfileStripe() {
  const content = {
    name: "",
    variantName: "CardMdProfileStripe",
    description:
      "A medium sized Card component that allows for the display of a circular image, header text, and a footer with button text on the left and icons on the right. An action or link can be optionally executed onClick of the button text or the icons.",
    implementation:
      "Instantiate as a self-closing component.  To avoid distortions in the displayed image, the target image file should be cropped to have a 1:1 aspect ratio.",
    propsTable: [
      [
        "headerText",
        "string",
        "'Header text'",
        "no",
        "Text for header element.",
      ],
      [
        "imgProps",
        "hash",
        "{src: 'path/to/img.png', alt: 'img-desc'}",
        "no",
        "Src and Alt props for html <img> tag.",
      ],
      [
        "leftFooterButtonText",
        "string",
        "'btn text'",
        "no",
        "Text for button element along left side of footer.  This is automatically capitalized.",
      ],
      [
        "leftFooterButtonLinkPath",
        "string",
        "'https://www.google.com'",
        "no",
        "URL for html <a> tag for onClick action.\n\nDo not use `leftFooterButtonLinkPath` with the `leftFooterButtonOnClick` prop.",
      ],
      [
        "leftFooterButtonOnClick",
        "function",
        "() => { setState({...state, var: newVarVal}) }",
        "no",
        "Function to execute onClick.\n\nDo not use `leftFooterButtonOnClick` with the `leftFooterButtonLinkPath` prop.",
      ],
      [
        "rightFooterIconsArray",
        "array of html elements",
        "[<a href=`url`><Icon ko={true} size='md' icon='help' /></a>,]",
        "no",
        "Array of functionally and visually defined icons.  Do not apply container <div>s with padding/margin -- this will be applied automatically.  Icons will order from left to right in order of array index.",
      ],
    ],
    links: [
      {
        "Zeplin: cards":
          "https://app.zeplin.io/project/5f45822488016a3407dea78c/screen/5fb43c5f4d41e574a25ceab0",
      },
    ],
    codeExample: `import {CardMdProfileStripe} from "frontend/src/components";

export default function CardContainer(){

  function download() {
    return console.log("Download");
  }
  
  return(
    <CardMdProfileStripe
      imgProps={{ src: "./img/legacy/tortoise.jpg", alt: "profile" }}
      headerText="Header text"
      leftFooterButtonText="Btn text"
      leftFooterButtonLinkPath="https://www.google.com"
      rightFooterIconsArray={[
        <div
          onClick={() => {
            download();
          }}>
          <Icon ko={true} size="md" icon="download" />
        </div>,
        <Tooltip body="Help is here!">
          <Icon ko={true} size="md" icon="help" />
        </Tooltip>,
      ]}
    />
  )
}`,
  };

  function download() {
    return console.log("Download");
  }

  return (
    <>
      <SGTemplate
        name={content.name}
        variantName={content.variantName}
        description={content.description}
        implementation={content.implementation}
        componentExamples={
          <div style={{ display: "flex" }} className="u-mgn-vert-sm">
            <div className="u-pad-sm">
              <CardMdProfileStripe
                headerText="Header text"
                leftFooterButtonText="Btn text"
              />
            </div>
            <div className="u-pad-sm">
              <CardMdProfileStripe
                imgProps={{
                  src: `${staticLink}legacy/tortoise.jpg`,
                  alt: "profile",
                }}
                rightFooterIconsArray={[
                  <Icon ko={true} size="md" icon="network" />,
                  <div
                    onClick={() => {
                      download();
                    }}>
                    <Icon ko={true} size="md" icon="download" />
                  </div>,
                  <Tooltip body="Help is here!">
                    <Icon ko={true} size="md" icon="help" />
                  </Tooltip>,
                ]}
              />
            </div>
            <div className="u-pad-sm">
              <CardMdProfileStripe
                imgProps={{
                  src: `${staticLink}legacy/tortoise.jpg`,
                  alt: "profile",
                }}
                headerText="Header text"
                leftFooterButtonText="Btn text"
                leftFooterButtonLinkPath="https://www.google.com"
                rightFooterIconsArray={[
                  <div
                    onClick={() => {
                      download();
                    }}>
                    <Icon ko={true} size="md" icon="download" />
                  </div>,
                  <Tooltip body="Help is here!">
                    <Icon ko={true} size="md" icon="help" />
                  </Tooltip>,
                ]}
              />
            </div>
          </div>
        }
        propsTableContents={content.propsTable}
        links={content.links}
        codeExample={content.codeExample}
      />
    </>
  );
}
