import { FileUpload } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGFileUpload() {
  /* const initState = {
    sidenavOpen: true
  };

  const [state, setState] = useState(initState);

  function toggleNav() {
    setState({ ...state, navShowing: !state.navShowing });
  } */

  const content = {
    name: "FileUpload",
    variantName: "",
    description: `Handles file uploads. Users can click the provided button or drag a
    file over this component to prepare it for upload. This component
    also triggers an event that allows the developer to handle the
    provided file however they so choose.`,
    implementation:
      "Use this component when you need to process a file uploaded by a user.",
    propsTable: [
      [
        "heading",
        "string",
        "Upload Raster Data File",
        "no",
        "Text to be rendered at the top of the element in the <Heading> style.",
      ],
      [
        "instructions",
        "string or jsx",
        "<div>This is the modal sub heading...</div>",
        "no",
        "Text to be rendered above the file upload field. Use this text to give the user instructions about how/what they should upload.",
      ],
      [
        "fileType",
        "string",
        "'raster' or 'vector'",
        "yes",
        "The `fileType` prop determines the file extension validation options.  ['.json', '.kml', '.kmz', '.zip'] for 'vector' file types.",
      ],
      [
        "maxFileSizeB",
        "number",
        "1000",
        "no",
        "The `maxFileSizeB` prop uses units of bytes.  If no value is specified, the default `maxFileSizeB` = 1e6 bytes, or 1 MB.",
      ],
      [
        "modalToggle",
        "function",
        "Modal toggle function",
        "no",
        `If passed it will be assumed this component is rendered on a modal. If provided, the component will render a "close" button which allows the user to exit the modal. The function should close/toggle whichever Modal the FileUpload component is in.`,
      ],
      [
        "uploadCB",
        "function",
        "Event",
        "yes",
        "An arrow function to be performed to execute the an action (such as upload) on the target file.",
      ],
    ],
    codeExample: `
    import { FileUpload } from "/components";

    function handleFileUpload(formData) {
      // Send the formdata to your server, handle error, etc...
    }

    <FileUpload 
      uploadCB={handleFileUpload} 
      customInstructions={<Text>This is where you leave instructions about the file(s) you want users to upload</Text} 
    />
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-pad-sm">
            <FileUpload
              uploadCB={() =>
                console.log("this happens when you upload a file")
              }
              instructions="This is where you leave instructions about the file(s) you want users to upload"
            />
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
