import React from "react";
import { GeoJSON, Popup, FeatureGroup } from "react-leaflet";
import API from "./API";
import utils from "../../utils/utils";
import ChatBox from "./ChatBox";
import { Heading, Text, InputComment } from "../../components"; 

export default app => {
  let counter = 1
  function featurePopup(feature) {
    if (!feature.comments) {
      feature["comments"] = []
    }
    if (!feature.properties) {
      feature["properties"] = {}
    }

    return (
      <Popup>
        <div className="rrm__feature-comments">
          <div className="u-background-blue u-align-center u-pad-sm u-mgn-btm-sm">
            <Heading number={5} ko={true}>Comments</Heading>
          </div>
          <div className="u-pad-sm">

          <ChatBox acceptCB={app.decideOnAdd} deleteCB={app.decideOnDelete} isCreator={app.state.isCreator} deleteCommentCB={app.deleteFeatureComment} account={app.state.account} id={feature.id} name={`featureComment${feature.id}`} comments={feature.comments} onSubmit={() => app.featureComment(feature.id)} onChange={app.handleChange}/>
          </div>
        </div>

      </Popup>
    )
  }
  // const datasets = []
  const datasets = {}
  app.state.features.map(feature => {
    const layer = JSON.parse(feature.layer)
    // Delete empty features that break map functionality
    let coordLength
    if (layer.coordinates) {
      coordLength = layer.coordinates.length
    } else {
      coordLength = layer.geometry.coordinates.length
    }
    if (coordLength < 1) {
      const req = {
        id: feature.id
      }
      const query = utils.objToQueryParams(req)
      API.deleteFeature(query)
    } else {
      let featureStyle = {}
      if (feature.propose_delete) {
        console.log("propose_delete", feature)
        featureStyle = { "color": "red" }
      } else if (!feature.resolved) {
        featureStyle = { "color": "green" }
      } else {
        featureStyle = { "color": "#3388ff" }
      }
      const geojson = JSON.parse(feature.layer)
      geojson["id"] = feature.id
      // After initialization
      const numDatasets = Object.keys(datasets).length
      if (numDatasets > 0) {
        let datasetExists = false
        let datasetIndex = 0
        for (let key in datasets) {
          if (datasets[key].dataset === feature.dataset) {
            datasetExists = true
            datasetIndex = key
          }
        }
        if (datasetExists) {
          if (!geojson.properties) {
            geojson["properties"] = {}
          }
          if (!feature.comments) {
            feature["comments"] = []
          }
          geojson.properties["numComments"] = feature.comments.length
          geojson.properties["dataset"] = feature.dataset
          const reactJSON = (
            <FeatureGroup key={feature.id}>
              {app.state.isCreator || app.state.isEditor ?
                featurePopup(feature)
                : null}
              {geojson ?
                <GeoJSON
                  data={geojson}
                  onEachFeature={app.onEachFeature}
                  key={feature.id}
                  style={featureStyle}
                /> : null}
            </FeatureGroup>
          )
          datasets[datasetIndex].features.push(reactJSON)
        } else {
          if (!geojson.properties) {
            geojson["properties"] = {}
          }
          if (!feature.comments) {
            feature["comments"] = []
          }
          let commentLength = feature.comments.length
          if (!commentLength) {
            commentLength = 0
          }
          geojson.properties["numComments"] = commentLength
          geojson.properties["dataset"] = feature.dataset
          const dataset = {
            dataset: feature.dataset,
            id: counter,
            features: [(
              <FeatureGroup key={feature.id}>
                {app.state.isCreator || app.state.isEditor ?
                  featurePopup(feature)
                  : null}
                {geojson ?
                  <GeoJSON
                    data={geojson}
                    onEachFeature={app.onEachFeature}
                    key={feature.id}
                    style={featureStyle}
                  /> : null}
              </FeatureGroup>
            )]
          }
          datasets[dataset.dataset] = dataset
          counter++
        }
      } else {
        if (!geojson.properties) {
          geojson["properties"] = {}
        }
        if (!feature.comments) {
          feature["comments"] = []
        }
        let commentLength = feature.comments.length
        if (!commentLength) {
          commentLength = 0
        }
        geojson.properties["numComments"] = commentLength
        geojson.properties["dataset"] = feature.dataset
        const dataset = {
          dataset: feature.dataset,
          id: counter,
          features: [(
            <FeatureGroup key={feature.id}>
              {app.state.isCreator || app.state.isEditor ?
                featurePopup(feature)
                : null}
              {geojson ?
                <GeoJSON
                  data={geojson}
                  onEachFeature={app.onEachFeature}
                  key={feature.id}
                  style={featureStyle}
                /> : null}
            </FeatureGroup>
          )]
        }
        datasets[dataset.dataset] = dataset
        counter++
      }
    }

  })
  app.setState({ datasets })
}

