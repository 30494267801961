import React from "react";

// Components
import { CollapsibleV, Heading } from "../../../../../components";
import DeleteProjectButton from "./DeleteProjectButton";
import ProjectSummaryEditable from "./ProjectSummaryEditable";
import ProjectTitleEditable from "./ProjectTitleEditable";

export default function AboutSection({ pcm, projectHandler }) {
  return (
    <CollapsibleV parent={pcm} title="About">
      <div className="g__map__config-menu__section">
        <ProjectTitleEditable projectHandler={projectHandler} />
        <ProjectSummaryEditable projectHandler={projectHandler} />
        <div className="u-mgn-top-md">
          <Heading className="u-pad-btm-sm" ko={true} number={5}>
            Actions
          </Heading>
          <DeleteProjectButton pcm={pcm} projectHandler={projectHandler} />
        </div>
      </div>
    </CollapsibleV>
  );
}
