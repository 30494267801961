import React from 'react'

// Components
import { Heading, InputEditField } from '../../../../../../../components'

// Utilities
import APIreqs from '../../../../../helpers/API'
import utils from '../../../../../../../utils/utils'

function LabelElement({ dataLayers, i }) {
  function setDataLayerAttr(obj) {
    const { updatedValue, elementName } = obj,
      updatedDataLayersData = [...dataLayers.data].map((obj, j) => {
        return i === j
          ? { ...dataLayers.data[i], [elementName]: updatedValue }
          : obj
      })
    dataLayers.setData(updatedDataLayersData)
  }

  async function putDataLayerAttrFromInput(obj) {
    try {
      const { updatedValue, elementName } = obj
      await APIreqs.dataLayers.putAttrs(dataLayers.data[i].id, [
        { [elementName]: updatedValue },
      ])
      setDataLayerAttr(obj)
    } catch {
      utils.sendAlert(
        "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'put CVM data layer label' error.",
        'error',
      )
    }
  }

  return (
    <div className='cvm__data-layer__input-edit'>
      <InputEditField
        inputValue={dataLayers.data[i].label}
        maxLength='50'
        name='label'
        updateValueCB={putDataLayerAttrFromInput}>
        <div className='cvm__data-layer__input-edit-static-container'>
          <Heading className='u-pad-right-sm' number={5}>
            {`${i + 1}.`}
          </Heading>
          <Heading className='cvm__data-layer__input-edit-static' number={5}>
            {`${dataLayers.data[i].label}`}
          </Heading>
        </div>
      </InputEditField>
    </div>
  )
}

export default LabelElement
