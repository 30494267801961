import React from "react";
import { FeatureGroup, ImageOverlay, Popup } from "react-leaflet";

const rasters = app => {
  function featurePopup(feature) {
    if (!feature.comments) {
      feature["comments"] = []
    }
    return (
      <Popup>
        <div>
          {/* DELETE COMMENT */}
          {
            app.state.isCreator ?
              <div className="comments__item--container" style={{ "justifyContent": "center" }} >
                <button className="btn-alt btn-alt--sm" onClick={() => app.deleteRaster(feature.id)}>
                  Click here to delete this image
                </button>
              </div> : null
          }
          {/* DELETE COMMENT */}
          {
            feature.comments.length > 0 ?
              <div className="comments__feed">
                {feature.comments.map(comment => {
                  if (comment.propose_add) {
                    return (
                      <div className="comments__item--container" key={comment.id}>
                        <div className="comments__thumb">
                          <a href={`/account/${comment.account.username}`}>
                            <img src={comment.account.photo} alt="Profile Thumbnail" className="comments__thumb--img" />
                          </a>
                        </div>
                        <div className="comments__item" >
                          <div className="comments__comment">
                            <span className="comments__username">
                              {comment.account.username}
                            </span>
                            <span className="comments__text">
                              {comment.comment}
                            </span>
                          </div>
                          {
                            (() => {
                              if (app.state.isCreator) {
                                return (
                                  <div>
                                    <button className="btn-alt btn-alt--sm" onClick={() => app.decideOnAdd(feature.id, comment.id, "accept")}>
                                      Accept
                                    </button>
                                    <button className="btn-alt btn-alt--sm" onClick={() => app.decideOnAdd(feature.id, comment.id, "reject")}>
                                      Reject
                                    </button>
                                  </div>
                                )
                              } else if (app.state.account.username === comment.account.username) {
                                return (
                                  <div>
                                    <button className="btn-alt btn-alt--sm" onClick={() => app.deleteProposal(feature.id, "feature")}>
                                      Withdraw Edit
                                    </button>
                                  </div>
                                )

                              } else {
                                return (
                                  null
                                )
                              }
                            })()
                          }
                        </div>
                      </div>
                    )
                  } else if (comment.propose_delete) {
                    return (
                      <div className="comments__item--container" key={comment.id}>
                        <div className="comments__thumb">
                          <a href={`/accounts/account/${comment.account.username}`}>
                            <img src={comment.account.photo} alt="Profile Thumbnail" className="comments__thumb--img" />
                          </a>
                        </div>
                        <div className="comments__item" >
                          <div className="comments__comment">
                            <span className="comments__username">
                              {comment.account.username}
                            </span>
                            <span className="comments__text">
                              {comment.comment}
                            </span>
                          </div>
                          {
                            app.state.isCreator ?
                              <div>
                                <button className="btn-alt btn-alt--sm" onClick={() => app.decideOnDelete(feature.id, comment.id, "accept")}>
                                  Accept
                                </button>
                                <button className="btn-alt btn-alt--sm" onClick={() => app.decideOnDelete(feature.id, comment.id, "reject")}>
                                  Reject
                                </button>
                              </div> : null
                          }
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <div className="comments__item--container" key={comment.id}>
                        <div className="comments__thumb">
                          <a href={`/profiles/profile/${comment.account.username}`}>
                            <img src={comment.account.photo} alt="Profile Thumbnail" className="comments__thumb--img" />
                          </a>
                        </div>
                        <div className="comments__item">
                          <div className="comments__comment">
                            <span className="comments__username">{comment.account.username}</span>
                            <span className="comments__text" featid={feature.id}>{comment.comment}</span>
                          </div>
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
              :
              <div className="comments__feed">
                <div className="comments__none">This map does not have any comments yet...</div>
              </div>
          }
          <div className="comments__form--container u-grey-back">
            <div className="comments__thumb">
              <img src={app.state.account.photo} alt="Profile Thumbnail" className="comments__thumb--img" />
            </div>
            <form action="" className="comments__form rasterComment" dbid={feature.id} onSubmit={app.rasterComment}>
              <textarea name={`rasterComment${feature.id}`} type="text" className="comments__input" cols="1" placeholder="Write a comment..." onChange={app.handleChange}>
                {app.state[`rasterComment${feature.id}`]}
              </textarea>
              <button className="comments__submit" type="submit">
                <i className="fas fa-paper-plane comments__submit--icon"></i>
              </button>
            </form>
          </div>
        </div>
      </Popup>
    )
  }
  return (
    <FeatureGroup>
      {app.state.rasters.map(layer => {
        let url;
        if (layer.map_layer.endsWith("tiff")) {
          url = layer.map_layer.substr(0, layer.map_layer.length - 5)
          url += ".png"
        } else {
          url = layer.map_layer.substr(0, layer.map_layer.length - 4)
          url += ".png"
        }
        const imageBounds = [[], []]
        let coordinates = layer.coordinates
        if (!coordinates) {
          return false
        }
        coordinates = coordinates.split(" ")
        imageBounds[0].push(coordinates[1])
        imageBounds[0].push(coordinates[0])
        imageBounds[1].push(coordinates[3])
        imageBounds[1].push(coordinates[2])
        return (
          <div key={layer.id}>
            {featurePopup(layer)}
            {url ?
              <ImageOverlay
                url={url}
                bounds={imageBounds}
                interactive={true}
                key={layer.id}
              /> : null}
          </div>
        )
      })}
    </FeatureGroup>
  )
}

export default rasters