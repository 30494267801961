import React from 'react'

/* Components */
import { Heading, Text } from '../../components'
import {
  BetaTestingSection,
  Footer,
  LandingApplicationCard,
  LandingPartnerCard,
  LandingTechnicalServiceCard,
} from '..'

/* Data */
import data from './LandingPageData'

/* Methods */
import staticLink from '../../utils/static'

export default function Landing() {
  const appsCards = data.applications.cards.map((application, index) => {
    return (
      <LandingApplicationCard
        key={index}
        imgPath={application.imgPath}
        textMain={application.text.main}
        textSub={application.text.sub}
        buttonPath={application.buttonPath}
      />
    )
  })

  const technicalServiceCards = data.technicalService.cards.map(
    (service, index) => {
      return (
        <LandingTechnicalServiceCard
          key={index}
          textMain={service.main}
          textSub={service.sub}
        />
      )
    },
  )

  const catchPhrases = data.conservationService.catchPhrases.map(
    (phrase, index) => {
      return (
        <div key={index}>
          <Text number={1} ko={true}>
            <span className='landing__conservation-service__left__catch-phrases--main'>
              {phrase.main}
            </span>{' '}
            <span className='landing__conservation-service__left__catch-phrases--sub'>
              {phrase.sub}
            </span>
          </Text>
        </div>
      )
    },
  )

  const partnersCards = data.conservationService.cards.map((partner, index) => {
    return (
      <LandingPartnerCard
        externalLinkPathname={partner.externalLinkPathname}
        key={index}
        textMain={partner.main}
        textSub={partner.sub}
      />
    )
  })

  return (
    <div className='page__container'>
      <div
        className='landing__banner'
        style={{
          backgroundImage: `linear-gradient(
        to right,
        rgba(1, 0, 39, 0.651),
        rgba(255, 255, 255, 0)
      ), url("${staticLink}hero/Cio-Hero.png")`,
        }}>
        <div className='landing__banner__text--container'>
          <div className='landing__banner__text--main '>
            <Heading ko={true} number={2}>
              {data.banner.text.main}
            </Heading>
          </div>
          <div className='landing__banner__text--sub'>
            <Heading ko={true} number={5} bold={false}>
              {data.banner.text.sub}
            </Heading>
          </div>
        </div>
      </div>
      <div className='landing__applications'>
        <div className='landing__applications__text--container'>
          <div className='landing__applications__text--main '>
            <Heading number={3}>{data.applications.text.main}</Heading>
          </div>
          <div className='landing__applications__text--sub'>
            <Heading number={5} bold={false}>
              {data.applications.text.sub}
            </Heading>
          </div>
        </div>
        <div className='landing__applications__app-cards--container'>
          {appsCards}
        </div>
      </div>
      <div className='landing__technical-service'>
        <div className='landing__technical-service__left'>
          <div className='landing__technical-service__left--container u-textbox-lg'>
            <div>
              <Heading
                number={2}
                className='landing__technical-service__left--heading'>
                {data.technicalService.text.main}
              </Heading>
              <Heading number={5} bold={false} className='u-color-darkgray'>
                {data.technicalService.text.sub}
              </Heading>
            </div>
            <div className='landing__technical-service__left--cards'>
              {technicalServiceCards}
            </div>
          </div>
        </div>
        <div className='landing__technical-service__right'>
          <img
            src={`${staticLink}graphics/Cio-Graphic.png`}
            alt='waterfall-and-technology'
            className='landing__technical-service__right__img'
          />
        </div>
      </div>
      <BetaTestingSection />
      <div
        className='landing__conservation-service'
        style={{
          backgroundImage: `linear-gradient(
        #00396588,
        #00396588
      ), url("${staticLink}section/Cio-Section.png")`,
        }}>
        <div className='landing__conservation-service__left'>
          <div className='landing__conservation-service__left--container'>
            <div className='landing__conservation-service__left__text--container'>
              <div>
                <div className='landing__conservation-service__left__text--main'>
                  <Heading ko={true} number={2}>
                    {data.conservationService.text.main}
                  </Heading>
                </div>
                <div className='landing__conservation-service__left__text--sub'>
                  <Heading bold={false} ko={true} number={5}>
                    {data.conservationService.text.sub}
                  </Heading>
                </div>
              </div>
            </div>
            <div className='landing__conservation-service__left__catch-phrases--container'>
              {catchPhrases}
            </div>
          </div>
        </div>
        <div className='landing__conservation-service__right'>
          <div className='landing__conservation-service__right--container'>
            {partnersCards}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
