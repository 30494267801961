import React, { useState } from "react";

// Components
import { Heading } from "../../../components";
import CreateProjectButton from "./CreateProjectButton";
import DashboardHeading from "./DashboardHeading";
import DeleteProjectModal from "./DeleteProjectModal";
import { Footer } from "../../../platform";
import ProjectsListSelector from "./ProjectsListSelector";
import ProjectsListTable from "./ProjectsListTable";

// Utilities
import APIreqs from "../helpers/API";
import staticLink from "../../../utils/static";
import utils from "../../../utils/utils";

function Dashboard({ account, auth }) {
  const initDashboardState = {
      activeModal: "",
      activeModalData: null,
      isProjectsDataChecked: false,
      projectsDataArray: [],
    },
    [dashboardState, setDashboardState] = useState(initDashboardState);

  async function getAndSetCvmProjectsData() {
    async function getProjects() {
      try {
        const acccountID = account.state.id,
          getProjectsRes = await APIreqs.projects.getAll(acccountID);
        return getProjectsRes.data;
      } catch {
        utils.sendAlert(
          "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'get CVM projects' error.",
          "error",
        );
      }
    }

    function setProjects(projectsData) {
      if (projectsData.length > 0) {
        dashboard.setState({
          ...dashboard.state,
          projectsDataArray: projectsData,
          isProjectsDataChecked: true,
          activeModal: "",
          activeModalData: null,
        });
      } else {
        dashboard.setState({
          ...dashboard.state,
          projectsDataArray: [],
          isProjectsDataChecked: true,
          activeModal: "",
          activeModalData: null,
        });
      }
    }

    const projectsData = await getProjects();
    setProjects(projectsData);
  }

  const dashboard = {
    initState: initDashboardState,
    methods: { getAndSetCvmProjectsData },
    setState: setDashboardState,
    state: dashboardState,
  };

  // console.log("CVM Dashboard State: ", dashboard.state);

  return (
    <>
      <div className="g-dashboard__container">
        <img
          src={`${staticLink}banner/CVM-Banner.png`}
          alt="Conservation Value Banner"
          className="u-full-width"
        />
        <div className="g-dashboard">
          {/* LEFT SPACER */}
          <ProjectsListSelector auth={auth} dashboard={dashboard} />

          {/* RIGHT DASHBOARD ELEMENT */}
          <div className="u-full-width">
            <DashboardHeading dashboard={dashboard} />
            <CreateProjectButton
              account={account}
              auth={auth}
              dashboard={dashboard}
            />
            {auth.state.isAuthenticated ? (
              <ProjectsListTable account={account} dashboard={dashboard} />
            ) : (
              <Heading className="u-pad-vert-lg" number={5}>
                Please log in to view projects.
              </Heading>
            )}
          </div>
        </div>
      </div>
      <Footer />
      <DeleteProjectModal dashboard={dashboard} />
    </>
  );
}

export default Dashboard;
