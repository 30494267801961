import { CardLgProfile } from "..";
import React from "react";
import { SGTemplate } from ".";
import staticLink from "../../utils/static";

export default function SGCardLgProfile() {
  const content = {
    name: "",
    variantName: "CardLgProfile",
    description:
      "A larger sized Card component that allows for the display of a circular image, header text, body text, and button text. An action or link can be optionally executed onClick of the button text.",
    implementation:
      "Instantiate as a self-closing component.  To avoid distortions in the displayed image, the target image file should be cropped to have a 1:1 aspect ratio.",
    propsTable: [
      [
        "buttonText",
        "string",
        "'btn text'",
        "yes",
        "Text for the button element.  This is automatically capitalized.",
      ],
      ["bodyText", "string", "'Body text'", "no", "Text for the body element."],
      [
        "headerText",
        "string",
        "'Header text'",
        "no",
        "Text for the header element.",
      ],
      [
        "imgProps",
        "hash",
        "{src: 'path/to/img.png', alt: 'img-desc'}",
        "no",
        "Src and Alt props for html <img> tag.",
      ],
      [
        "linkPath",
        "string",
        "'https://www.google.com'",
        "no",
        "URL for html <a> tag for onClick action.\n\nDo not use `linkPath` with the `onClick` prop.",
      ],
      [
        "onClick",
        "function",
        "() => { setState({...state, var: newVarVal}) }",
        "no",
        "Function to execute onClick.\n\nDo not use `onClick` with the `linkPath` prop.",
      ],
    ],
    links: [
      {
        "Zeplin: cards":
          "https://app.zeplin.io/project/5f45822488016a3407dea78c/screen/5fb43c5f4d41e574a25ceab0",
      },
    ],
    codeExample: `import {CardLgProfile} from "frontend/src/components";

export default function CardContainer(){
  return(
    <div style={{ display: "flex" }} className="u-mgn-vert-sm">
      <div className="u-pad-sm">
        <CardLgProfile buttonText="btntext" />
      </div>
      <div className="u-pad-sm">
        <CardLgProfile
          buttonText="btntext"
          headerText="Header text"
          bodyText="Body text"
          imgProps={{ src: "./img/legacy/tortoise.jpg", alt: "profile" }}
          linkPath="https://www.google.com"
        />
      </div>
    </div>
  )
}`,
  };

  return (
    <>
      <SGTemplate
        name={content.name}
        variantName={content.variantName}
        description={content.description}
        implementation={content.implementation}
        componentExamples={
          <div style={{ display: "flex" }} className="u-mgn-vert-sm">
            <div className="u-pad-sm">
              <CardLgProfile buttonText="btntext" />
            </div>
            <div className="u-pad-sm">
              <CardLgProfile
                buttonText="btntext"
                headerText="Header text"
                bodyText="Body text"
                imgProps={{
                  src: `${staticLink}legacy/tortoise.jpg`,
                  alt: "profile",
                }}
                linkPath="https://www.google.com"
              />
            </div>
          </div>
        }
        propsTableContents={content.propsTable}
        links={content.links}
        codeExample={content.codeExample}
      />
    </>
  );
}
