import React from "react";
import { Icon, Text, Tooltip } from "..";
import { SGTemplate } from ".";

export default function SGTooltip() {
  const templateContent = {
    name: "Tooltip",
    variantName: "",
    description:
      "An element that shows a popup message onHover.  A tooltop message has a body but no header.",
    implementation:
      "Instantiate as a component wrapped around the html element or component that will host the onHover effect.  The body content can be populated by manually formatted html objects, or a string.",
    propsTable: [
      [
        "...props.children",
        "enclosed html object",
        "<Icon />",
        "yes",
        "The Tooltip component wraps around the html object that hosts the onHover effect.  See Implementation and Code Examples.",
      ],
      [
        "body",
        "enclosed html object OR string",
        "<><p>Body paragraph</><div>Body div</div></>  OR 'Body text.'",
        "no",
        "The body can be populated with html objects that must be manually styled, such as divs, imgs, graphs, etc.\n\nOR\n\nThe body can be populated with text that will be automatically styled.",
      ],
      [
        "id",
        "string",
        "'Tooltip-element-1'",
        "no",
        "The Tooltip element can be optionally assigned a specific html id, for example for testing purposes.",
      ],
      [
        "...props",
        "n/a",
        "n/a",
        "no",
        "This Tooltip component is built using the Reactstrap library and can utilize any props that are allowed by the Reactstrap Tooltip component.  See link below for documentation.",
      ],
    ],
    links: [
      {
        "Zeplin: popovers (tooltips have analogous style)":
          "https://app.zeplin.io/project/5f45822488016a3407dea78c/screen/5faf435478cfcd694aba49bd",
      },
      {
        "Reactstrap: tooltip documentation":
          "https://reactstrap.github.io/components/tooltips/",
      },
    ],
    codeExample: `import { Icon, Tooltip } from "frontend/src/components";

export default function TooltipContainer(){
  const tooltipContent = {
    headerText: "This is a header",
    body: (
      <>
        <p>This is a body p.</p>
        <div>This is a body div.</div>
        <div>
          This type of input needs to come with its own style applied
          beforehand.
        </div>
      </>
    ),
    bodyText:
      "This is the body text, which is automatically formatted by the component.",
  };

  return(
    <>
      // Example using body
      <Tooltip
        body={tooltipContent.body}
        headerText={tooltipContent.headerText}>
        <Icon size="md" icon="help" />
      </Tooltip>

      // Example using bodyText
      <Tooltip
        bodyText={tooltipContent.bodyText}
        headerText={tooltipContent.headerText}>
        <Icon size="md" icon="help" />
      </Tooltip>
    </>
  )
}`,
  };

  const tooltipContent = {
    body: (
      <>
        <p>This is a body p.</p>
        <div>This is a body div.</div>
        <div>
          This type of input needs to come with its own style applied
          beforehand.
        </div>
      </>
    ),
    bodyText:
      "This is the body text. 'bodyText' is most appropriate for a single sentence message.",
  };
  return (
    <SGTemplate
      name={templateContent.name}
      variantName={templateContent.variantName}
      description={templateContent.description}
      implementation={templateContent.implementation}
      componentExamples={
        <div>
          <Text number={1}>Hover on the help icons below.</Text>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100px" }}>
              <div className="u-mgn-md">
                <Tooltip body={tooltipContent.body}>
                  <Icon size="md" icon="help" />
                </Tooltip>
              </div>
            </div>
            <div style={{ width: "100px" }}>
              <div className="u-mgn-md">
                <Tooltip body={tooltipContent.bodyText}>
                  <Icon size="md" icon="help" />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      }
      propsTableContents={templateContent.propsTable}
      links={templateContent.links}
      codeExample={templateContent.codeExample}
    />
  );
}
