import { CardMdContentStripe, Icon } from "..";
import React from "react";
import { SGTemplate } from ".";
import staticLink from "../../utils/static";

export default function SGCardMdContentStripe() {
  const content = {
    name: "",
    variantName: "CardMdContentStripe",
    description:
      "A medium sized Card component that allows for the display of a square image, and a header with groups of icons on the left and  right. An action or link can be optionally executed onClick of the icons.",
    implementation:
      "Instantiate as a self-closing component.  The target image file should be pre-cropped and have a 1.65:2 (height:width) aspect ratio to avoid having the displayed image not being centered or cropped as desired.",
    propsTable: [
      [
        "leftHeaderIconsArray",
        "array of html elements",
        "[<a href=`url`><Icon ko={true} size='md' icon='help' /></a>, [...], [...]]",
        "no",
        "Array of functionally and visually defined icons.  Do not apply container <div>s with padding/margin -- this will be applied automatically.  Icons will order from left to right in order of array index.",
      ],
      [
        "rightHeaderIconsArray",
        "array of html elements",
        "[<a href=`url`><Icon ko={true} size='md' icon='help' /></a>]",
        "no",
        "Array of functionally and visually defined icons.  Do not apply container <div>s with padding/margin -- this will be applied automatically.  Icons will order from left to right in order of array index.",
      ],
      [
        "imgProps",
        "hash",
        "{src: 'path/to/img.png', alt: 'img-desc'}",
        "no",
        "Src and Alt props for html <img> tag.\n\nDo not use with `nonImgObjects`.",
      ],
    ],
    links: [
      {
        "Zeplin: cards":
          "https://app.zeplin.io/project/5f45822488016a3407dea78c/screen/5fb43c5f4d41e574a25ceab0",
      },
    ],
    codeExample: `import {CardMdContentStripe} from "frontend/src/components";

export default function CardContainer(){
  function download() {
    return console.log("Download");
  }

  return(
    <div className="u-pad-sm">
      <CardMdContentStripe
        imgProps={{ src: "./img/legacy/tortoise.jpg", alt: "profile" }}
        leftHeaderIconsArray={[
          <div
            onClick={() => {
              download();
            }}>
            <Icon ko={true} size="md" icon="download" />
          </div>,
          <Icon ko={true} size="md" icon="edit" />,
          <Icon ko={true} size="md" icon="move" />,
        ]}
        rightHeaderIconsArray={[
          <Icon ko={true} size="md" icon="trash" />,
        ]}
      />
    </div>
  )
}`,
  };

  function download() {
    return console.log("Download");
  }

  return (
    <>
      <SGTemplate
        name={content.name}
        variantName={content.variantName}
        description={content.description}
        implementation={content.implementation}
        componentExamples={
          <div style={{ display: "flex" }} className="u-mgn-vert-sm">
            <div className="u-pad-sm">
              <CardMdContentStripe />
            </div>
            <div className="u-pad-sm">
              <CardMdContentStripe
                imgProps={{
                  src: `${staticLink}legacy/tortoise.jpg`,
                  alt: "profile",
                }}
                leftHeaderIconsArray={[
                  <div
                    onClick={() => {
                      download();
                    }}>
                    <Icon ko={true} size="md" icon="download" />
                  </div>,
                  <Icon ko={true} size="md" icon="edit" />,
                  <Icon ko={true} size="md" icon="move" />,
                ]}
                rightHeaderIconsArray={[
                  <Icon ko={true} size="md" icon="trash" />,
                ]}
              />
            </div>
          </div>
        }
        propsTableContents={content.propsTable}
        links={content.links}
        codeExample={content.codeExample}
      />
    </>
  );
}
