import React, { useEffect, useState } from 'react'

// Utilities
import Plot from 'react-plotly.js'
import {
  generateColorsArr,
  generatePlotRangeArr,
} from '../../../../../../helpers/plot'

function ValRstPlot({ coeffs, colorPalette, dataLayers, i, valuationFnType }) {
  // Handles plot series values, range values, and color palette.
  const plotInit = {
      colorsArr: [],
      rangeArr: [],
      seriesArr: [],
      seriesMaxVal: undefined,
      seriesMinVal: undefined,
    },
    [plot, setPlot] = useState(plotInit)
  // console.log('plot: ', plot)

  // Generate plot series values.
  useEffect(() => {
    const seriesMaxVal = dataLayers.data[i].raster.data.datasetMaxVal.value
    const seriesMinVal = dataLayers.data[i].raster.data.datasetMinVal.value
    const seriesIntervalCount = 20
    const seriesInterval = (seriesMaxVal - seriesMinVal) / seriesIntervalCount
    const seriesArr = Array.from(
      { length: seriesIntervalCount + 1 },
      (_, index) => seriesMinVal + index * seriesInterval,
    )

    setPlot({ ...plot, seriesArr, seriesMaxVal, seriesMinVal })
  }, [dataLayers, i])

  // Updates plot arguments with props update.
  useEffect(() => {
    // Valuation function coefficients.
    const A = coeffs.A.value
    const B = coeffs.B.value
    const C = coeffs.C.value
    const fnType = valuationFnType

    let rangeArr, colorsArr
    if (plot.seriesArr.length > 0) {
      // Generate plot range values.
      rangeArr = generatePlotRangeArr(
        fnType,
        plot.seriesArr,
        A,
        B,
        C,
        plot.seriesMinVal,
        plot.seriesMaxVal,
      )

      // Generate colors.
      colorsArr = generateColorsArr(rangeArr, colorPalette)

      setPlot({ ...plot, colorsArr, rangeArr })
    }
  }, [
    coeffs.A.value,
    coeffs.B.value,
    coeffs.C.value,
    colorPalette,
    plot.seriesArr,
    valuationFnType,
  ])

  return (
    <Plot
      layout={{
        title: {
          font: { family: 'Arial', size: 20 },
          text: 'Conservation Valuation Curve',
        },
        font: { size: 12 },
        height: 440,
        margin: { b: 60, l: 60, r: 40, t: 40 },
        width: 540,
        xaxis: {
          title: {
            font: { family: 'Arial', size: 16 },
            text: 'Original Data Values [A.U.]',
          },
        },
        yaxis: {
          dtick: 0.5,
          range: [-1.25, 1.25],
          title: {
            font: { family: 'Arial', size: 16 },
            text: 'Conservation Values [A.U.]',
          },
        },
      }}
      data={[
        {
          x: plot.seriesArr,
          y: plot.rangeArr,
          type: 'scatter',
          mode: 'markers',
          marker: {
            color: plot.colorsArr,
            line: {
              color: 'black',
              width: 1,
            },
            size: 20,
          },
        },
      ]}
    />
  )
}

export default ValRstPlot
