import { Text } from "../../components";
import React from "react";

export default function LandingPartnerCard({
  externalLinkPathname,
  textMain,
  textSub,
}) {
  return (
    <div className="landing__conservation-service__right__partner__container">
      <div className="landing__conservation-service__right__partner__link__container">
        <a
          className="hyperlink landing__conservation-service__right__partner__link u-hover-underline"
          href={externalLinkPathname}
          rel="noopener noreferrer"
          target="_blank">
          {textMain}
        </a>
      </div>
      <Text number={2}>{textSub}</Text>
    </div>
  );
}
