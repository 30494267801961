import React from "react";

/* Components */
import {
  CollapsibleV,
  Heading,
  LinkTop,
  List,
  Span,
  Text,
  Tooltip,
} from "../../../../../../components";

/* Methods */
import utils from "../../../../../../utils/utils";

export default function CommentsSection({ app, parent }) {
  return (
    <CollapsibleV parent={parent} title="Comments">
      <div className="g__map__config-menu__section">
        {/* Comments */}
        <div className="u-flex u-flex-justify-between u-mgn-btm-sm">
          <Heading number={5} ko={true}>
            Comments Log
          </Heading>
          {app.state.isAuthenticated ? (
            <div>
              <Tooltip body="Add Comment" ko={true}>
                <p
                  className="paragraph__2 paragraph__2--ko"
                  onClick={() => app.toggleModal("comment")}>
                  <span className="icon--ko icon--md icon-icon__comment"></span>
                </p>
              </Tooltip>
            </div>
          ) : (
            <p className="paragraph__2 paragraph__2--ko">
              Sign up or log in to comment
            </p>
          )}
        </div>
        <div className="comments__feed">
          {app.state.comments < 1 ? (
            // <p className="paragraph__2 paragraph__2--ko">
            <Text number={2} ko={true} className="u-text-italic">
              Start a conversation with map collaborators...
            </Text>
          ) : // </p>
          null}
          <List>
            {app.state.comments.map((c) => {
              return (
                <div className="chatbox__comment u-mgn-btm-sm" key={c.id}>
                  <div className="u-flex u-flex-justify-between u-flex-align-center u-mgn-btm-sm">
                    <LinkTop to={`/profile/${c.account.username}`}>
                      <Text number={2} ko={true}>
                        <Span className="u-text-underline">
                          {c.account.first_name} {c.account.last_name}
                        </Span>
                      </Text>
                    </LinkTop>
                    <Text number={3} ko={true}>
                      {utils.formatDateTime(c.date_created)}
                    </Text>
                  </div>
                  <Text number={2} ko={true}>
                    {c.comment}
                  </Text>
                  {c.account.id === app.state.account.id ? (
                    <div className="u-mgn-top-sm u-flex-justify-end">
                      <Tooltip body="Delete Comment" ko={true}>
                        <span
                          className="icon--ko icon--sm icon-icon__trash"
                          onClick={() => app.deleteMapComment(c.id)}></span>
                      </Tooltip>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </List>
        </div>
      </div>
    </CollapsibleV>
  );
}
