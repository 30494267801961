import Auth from "./API";

const init = async (app) => {
  await Auth.getAuth()
    .then(res => {
      let isAuthenticated = true
      if (res.data.anonymous) {
        isAuthenticated = false
      }
      app.setState({
        isAuthenticated,
        thumbnail: res.data.photo,
        account: res.data
      })
    })
    .catch(err => {
      console.log("ERROR", err)
    })
}

export default init