import React from "react";

/* Components */
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FileUpload, ModalClose } from "../../../../../../components";

/* Methods */
import API from "../../../../API";
import Leaflet from "leaflet";
import utils from "../../../../../../utils/utils";

export default function UploadVectorDataFileModal({ app }) {
  const uploadFile = (formData) => {
    const { mapID, rasters, IDToImage, modals } = app.state;
    app.setState({ loading: true });
    API.uploadFile(formData, app.state.mapData.id)
      .then((res) => {
        modals.uploadLayer = false;
        app.setState({ loading: false, modals });
        const feats = [];
        if (res.data.map_layer) {
          rasters.push(res.data);
          let { id, map_layer } = res.data;
          map_layer = map_layer.split(".");
          map_layer = map_layer[0] + ".png";
          IDToImage[id] = map_layer;
          app.setState({ rasters, IDToImage });
        } else {
          for (let i = 0; i < res.data.feature_list.length; i++) {
            Leaflet.geoJson(res.data.feature_list[i], {
              onEachFeature: function (feature, layer) {
                const editor = new Leaflet.Edit.Poly(layer);
                let standardized = new Leaflet.polygon(editor._defaultShape());
                standardized = standardized.toGeoJSON();
                standardized["dataset"] = res.data.feature_list[i].dataset;
                feats.push(standardized);
              },
            });
          }
          const req = { feats };
          API.standardizeFeatures(req, mapID).then((res2) => {
            let features = app.state.features;
            features = features.concat(res2.data);
            app.setState({ features }, () => app.renderFeatures(app));
          });
        }
        utils.sendAlert(
          `File uploaded.\n\nDepending on the file size, it may take a few moments to render the vector features on the map and show the layer metadata in the Vector Layers section.`,
          "success",
        );
      })
      .catch((err) => {
        if (err) {
          app.setState({ loading: false });
          utils.sendAlert(
            `The selected vector file could not be processed.\n\nPlease verify the integrity and format of the file and try again.\n\nIf the upload continues to fail, please use the contact form to get in touch with technical support.`,
            "error",
          );
        }
      });
  };

  return (
    <Modal
      isOpen={app.state.modals.uploadLayer}
      toggle={() => app.toggleModal("uploadLayer")}>
      <ModalHeader>
        <ModalClose cb={() => app.toggleModal("uploadLayer")} />
        Vector Layers
      </ModalHeader>
      <ModalBody>
        <FileUpload
          fileType="vector"
          heading="Upload Vector Data File"
          uploadCB={uploadFile}
          maxFileSizeB={1e7}
          modalToggle={() => app.toggleModal("uploadLayer")}
        />
      </ModalBody>
    </Modal>
  );
}
