import { CardSmProfile, Heading } from "../../components";
import React from "react";
import routes from "../../utils/paths/routes";
import staticLink from "../../utils/static";

export default function ProfilePageAppsSection({ auth }) {
  return (
    <div className="profile__apps-section">
      <div className="profile__header u-mgn-btm-md">
        <Heading number={4} ko={true}>
          Apps
        </Heading>
      </div>
      <div className="profile__apps-section--cards">
        <div className="u-mgn-right-md">
          <CardSmProfile
            buttonText="open app"
            headerText="Collaborative Mapper"
            imgProps={{
              src: `${staticLink}logo/CM-Logo.png`,
              alt: "collaborative mapper logo",
            }}
            linkTopTo={routes.apps.collabDash.path}
            newTab={true}
          />
        </div>
        <div className="u-mgn-right-md">
          <CardSmProfile
            buttonText="open app"
            headerText="Habitat Patrol"
            imgProps={{
              src: `${staticLink}logo/HP-Logo.png`,
              alt: "habitat patrol logo",
            }}
            linkTopTo={routes.apps.habPatApp.path}
            newTab={true}
          />
        </div>
        {auth.state.isAppTester && (
          <div className="u-mgn-right-md">
            <CardSmProfile
              buttonText="open app"
              headerText="Conservation Value Mapper"
              imgProps={{
                src: `${staticLink}logo/CVM-Logo.png`,
                alt: "conservation value mapper logo",
              }}
              linkTopTo={routes.apps.cvmDash.path}
              newTab={true}
            />
          </div>
        )}
      </div>
    </div>
  );
}
