import { FooterCard, Footnote } from "..";
import { Heading } from "../../components";
import footnoteData from "./footnoteData";
import footerData from "./footerData";
import React from "react";
import staticLink from "../../utils/static";

export default function Footer({}) {
  const footerCards = Object.keys(footerData).map((key, index) => {
    return <FooterCard key={index} heading={key} data={footerData[key]} />;
  });

  return (
    <>
      <div className="footer">
        <div className="footer__left">
          <div className="footer__left--container">
            <div className="footer__left--main u-pad-btm-sm">
              <Heading number={3}>Conservationist.io</Heading>
            </div>
            <div className="u-pad-btm-sm">
              <Heading number={4} bold={false} color="secondary">
                By Defenders of Wildlife
              </Heading>
            </div>
            <div>
              <img
                src={`${staticLink}logo/DOW_LOGO_black_transparent.png`}
                alt="defenders-logo"
                className="footer__left__logo"></img>
            </div>
          </div>
        </div>
        <div className="footer__right">{footerCards}</div>
      </div>
      <div className="footer__footnote">
        <Footnote links={footnoteData} />
      </div>
    </>
  );
}
