import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

/* Components */
import {
  Button,
  FormInputWValidations,
  Heading,
  Label,
  Select,
} from "../../components";
import { Footer } from "..";

/* Methods */
import API from "../../utils/API";
import routes from "../../utils/paths/routes";
import utils from "../../utils/utils";

export default function ContactPage() {
  const location = useLocation(),
    valueMessageInit = "",
    valueSubjectInit = "Reaching out",
    contactPageStateInit = {
      isEmailValid: false,
      isFirstNameValid: false,
      isLastNameValid: false,
      isMessageValid: false,
      valueEmail: "",
      valueFirstName: "",
      valueLastName: "",
      valueMessage: valueMessageInit,
      valueSubject: valueSubjectInit,
    },
    [contactPageState, setContactPageState] = useState(contactPageStateInit);

  useEffect(() => {
    setContactPageState({
      ...contactPageState,
      valueMessage: location.valueMessage || valueMessageInit,
      valueSubject: location.valueSubject || valueSubjectInit,
    });
  }, [location]);

  const subjectOptions = {
      "Reaching out": 0,
      // "Sign up for beta testing": 1,
      "Providing feedback": 1,
      "Reporting a bug": 2,
      Other: 3,
    },
    subjectObjects = Object.keys(subjectOptions).map((optionText) => {
      return {
        [optionText]: () => {
          setContactPageState({
            ...contactPageState,
            valueSubject: optionText,
          });
        },
      };
    });

  const history = useHistory(),
    contact = () => {
      if (
        contactPageState.isEmailValid &&
        contactPageState.isFirstNameValid &&
        contactPageState.isLastNameValid &&
        contactPageState.isMessageValid
      ) {
        const data = {
          email: contactPageState.valueEmail,
          firstName: contactPageState.valueFirstName,
          lastName: contactPageState.valueLastName,
          message: contactPageState.valueMessage,
          subject: contactPageState.valueSubject,
        };
        API.submitContactForm(data)
          .then(() => {
            utils.sendAlert(
              "Message sent. Please look for a response from us within 7 days.",
              "success",
            );
            history.push(routes.root.path);
          })
          .catch(() => {
            utils.sendAlert(
              "Something went wrong. Please try again or email maubain@defenders.org for technical support.",
              "error",
            );
          });
      } else {
        utils.sendAlert("Please fill out the entire form.", "error");
      }
    };

  // console.log("contactPageState: ", contactPageState);
  // console.log("location: ", location);

  return (
    <>
      {/* <div className="sections-page-template__banner"></div> */}
      <form
        className="contact__form--container"
        onSubmit={(e) => {
          e.preventDefault();
          contact();
        }}>
        <div className="contact__form">
          <div className="u-pad-vert-sm">
            <Heading number={2}>Contact</Heading>
          </div>
          <div className="u-pad-vert-sm">
            <Heading number={4} color="secondary" bold={false}>
              How can we help you?
            </Heading>
          </div>
          <div className="u-pad-vert-sm">
            <Heading number={5} bold={false}>
              Let us know about any questions, comments, or concerns you may
              have:
            </Heading>
          </div>
          <div className="contact__form__names--container">
            <div className="contact__form__name--container u-pad-vert-sm u-pad-right-sm">
              <FormInputWValidations
                parentState={contactPageState}
                placeholder="Enter first name"
                setParentState={setContactPageState}
                inputSubType="first"
                inputType="name"
                validationKey="isFirstNameValid"
                validationsContext="contact"
                valueKey="valueFirstName"
                useValidations={true}
              />
            </div>
            <div className="contact__form__name--container u-pad-vert-sm u-pad-left-sm">
              <FormInputWValidations
                parentState={contactPageState}
                placeholder="Enter last name"
                setParentState={setContactPageState}
                inputSubType="last"
                inputType="name"
                validationKey="isLastNameValid"
                validationsContext="contact"
                valueKey="valueLastName"
                useValidations={true}
              />
            </div>
          </div>
          <div className="u-pad-vert-sm">
            <FormInputWValidations
              parentState={contactPageState}
              placeholder="Enter email"
              setParentState={setContactPageState}
              inputType="email"
              validationKey="isEmailValid"
              validationsContext="contact"
              valueKey="valueEmail"
              useValidations={true}
            />
          </div>
          <div className="u-pad-vert-sm">
            <div>
              <Label labelText="Subject" />
            </div>
            <div className="contact__form__subject-selector--container">
              <Select
                hashObjects={subjectObjects}
                defaultIndex={subjectOptions[contactPageState.valueSubject]}
              />
            </div>
          </div>

          <div className="u-pad-vert-sm">
            <FormInputWValidations
              parentState={contactPageState}
              placeholder="Enter message"
              setParentState={setContactPageState}
              inputType="message"
              validationKey="isMessageValid"
              validationsContext="contact"
              valueKey="valueMessage"
              useValidations={true}
            />
          </div>
          <div className="u-pad-btm-md u-pad-top-md">
            <Button
              buttonProps={{
                type: "submit",
              }}
              buttontype="primary"
              size="inherit">
              Send Message
            </Button>
          </div>
        </div>
      </form>
      <Footer />
    </>
  );
}
