import React from "react";
import { SGTemplate } from "../SGcomponents";

export default function SGCard() {
  const content = {
    name: "Card",
    description:
      "Card components are fixed dimension elements that concisely convey the identity, data, and functionality about an app, a personal profile, or other type of complex platform object.  Cards are contrasted from Lists in that Cards are almost always interactable through button elements that contain weblinks or arrow functions.",
  };

  return <SGTemplate name={content.name} description={content.description} />;
}
