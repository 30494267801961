export function getZoomFromBoundingBox(boundingBox) {
  // Bounding box has format ([0] west lng., [1] south lat., [2] east lng., [3] north lat.) 

  const [latDiff, lngDiff] = [Math.abs(
    boundingBox[3] - boundingBox[1]
  ),
  Math.abs(
    boundingBox[2] - boundingBox[0]
  )],
    larger = (latDiff > lngDiff) ? latDiff : lngDiff;

  let zoom;
  if (larger > 3.5) {
    zoom = 7;
  } else if (larger > 1.5) {
    zoom = 8;
  } else if (larger > 0.8) {
    zoom = 9;
  } else if (larger > 0.1) {
    zoom = 11;
  }
  return zoom;
}