import API from "../../auth/API";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  Button,
  FormInputWValidations,
  ModalClose,
  Text,
} from "../../components";
import React, { useState } from "react";
import utils from "../../utils/utils";

export default function NavbarRetrieveUsernameModal({ navbar }) {
  const navbarRetrieveUsernameModalStateInit = {
      valueEmail: "",
    },
    [
      navbarRetrieveUsernameModalState,
      setNavbarRetrieveUsernameModalState,
    ] = useState(navbarRetrieveUsernameModalStateInit);

  function retrieveUsername(e) {
    e.preventDefault();
    const email = navbarRetrieveUsernameModalState.valueEmail;

    API.retrieveUsername(email).then((res) => {
      console.log("RES", res.data);
      if (res.data.success) {
        utils.sendAlert(res.data.message, "success");
        navbar.setState({
          ...navbar.state,
          isRetrieveUsernameModalOpen: false,
        });
      } else {
        utils.sendAlert(res.data.message, "error");
      }
    });
  }

  return (
    <Modal
      isOpen={navbar.state.isRetrieveUsernameModalOpen}
      centered={true}
      backdrop="static"
      size="lg">
      <ModalHeader>
        <ModalClose
          cb={() => {
            setNavbarRetrieveUsernameModalState(
              navbarRetrieveUsernameModalStateInit,
            );
            navbar.setState({
              ...navbar.state,
              isRetrieveUsernameModalOpen: false,
            });
          }}
        />
        Retrieve Username
      </ModalHeader>
      <ModalBody>
        <div className="u-pad-sm">
          <Text number={1}>
            Please enter the email address associated with your account and the
            account username will be sent to your inbox.
          </Text>
        </div>
        <form onSubmit={retrieveUsername}>
          <div className="u-pad-sm u-pad-top-sm">
            <FormInputWValidations
              parentState={navbarRetrieveUsernameModalState}
              placeholder="Enter email"
              setParentState={setNavbarRetrieveUsernameModalState}
              inputType="email"
              validationsContext="sign up"
              valueKey="valueEmail"
              useValidations={false}
            />
            {/* <InputText
            inputProps={{onChange: handleChange}}
            /> */}
          </div>
          <div className="u-pad-btm-md u-pad-top-md u-pad-left-sm u-pad-right-sm">
            <Button
              buttonProps={{ type: "submit" }}
              buttontype="primary"
              size="inherit">
              Submit
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}
