import React from "react";

export default function Input({
  className,
  inputProps = {},
  ko,
  small,
  value,
}) {
  let inputClassName = "input__text";
  if (ko) {
    inputClassName += " input__text--ko ";
  }
  if (small) {
    inputClassName += " input__text--sm ";
  }
  return (
    <input
      autoComplete="off"
      className={`${inputClassName} ${className}`}
      spellCheck={false}
      type="text"
      value={value}
      {...inputProps}
    />
  );
}
