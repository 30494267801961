export default [
  "Acanthorutilus handlirschi",
  "Accipiter fasciatus natalis",
  "Accipiter francesii pusillus",
  "Accipiter gentilis laingi",
  "Accipiter striatus venator",
  "Achatinella spp.",
  "Acinonyx jubatus",
  "Acipenser brevirostrum",
  "Acipenser medirostris",
  "Acipenser mikadoi",
  "Acipenser naccarii",
  "Acipenser oxyrinchus (=oxyrhynchus) desotoi",
  "Acipenser oxyrinchus oxyrinchus",
  "Acipenser sinensis",
  "Acipenser sturio",
  "Acipenser transmontanus",
  "Acrocephalus familiaris kingi",
  "Acrocephalus luscinia",
  "Acrocephalus percernis aquilonis",
  "Acropora cervicornis",
  "Acropora globiceps",
  "Acropora jacquelinae",
  "Acropora lokani",
  "Acropora palmata",
  "Acropora pharaonis",
  "Acropora retusa",
  "Acropora rudis",
  "Acropora speciosa",
  "Acropora tenella",
  "Addax nasomaculatus",
  "Adelocosa anops",
  "Aepyceros melampus petersi",
  "Aerodramus vanikorensis bartschi",
  "Agelaius xanthomus",
  "Agelastes meleagrides",
  "Ailuropoda melanoleuca",
  "Aipysurus fuscus",
  "Akialoa stejnegeri",
  "Alasmidonta atropurpurea",
  "Alasmidonta heterodon",
  "Alasmidonta raveneliana",
  "Alauda razae",
  "Alcelaphus buselaphus swaynei",
  "Alcelaphus buselaphus tora",
  "Alethe choloensis",
  "Alligator mississippiensis",
  "Alligator sinensis",
  "Alouatta palliata",
  "Alouatta pigra",
  "Amazilia luciae",
  "Amazona arausiaca",
  "Amazona brasiliensis",
  "Amazona collaria",
  "Amazona guildingii",
  "Amazona imperialis",
  "Amazona leucocephala",
  "Amazona pretrei pretrei",
  "Amazona rhodocorytha",
  "Amazona versicolor",
  "Amazona vinacea",
  "Amazona vittata",
  "Amblema neislerii",
  "Amblyopsis rosae",
  "Ambrysus amargosus",
  "Ambystoma bishopi",
  "Ambystoma californiense",
  "Ambystoma cingulatum",
  "Ambystoma macrodactylum croceum",
  "Ambystoma tigrinum stebbinsi",
  "Ameiva polops",
  "Ammodorcas clarkei",
  "Ammodramus maritimus mirabilis",
  "Ammodramus savannarum floridanus",
  "Amphispiza belli clementeae",
  "Amytornis goyderi",
  "Anacropora spinosa",
  "Anaea troglodyta floridalis",
  "Anairetes alpinus",
  "Anas aucklandica nesiotis",
  "Anas laysanensis",
  "Anas wyvilliana",
  "Anaxyrus californicus",
  "Anaxyrus canorus",
  "Andrias davidianus",
  "Andrias japonicus",
  "Anguispira picta",
  "Anodorhynchus glaucus",
  "Anodorhynchus hyacinthinus",
  "Anodorhynchus leari",
  "Anolis roosevelti",
  "Anoxypristis cuspidata",
  "Antechinomys laniger",
  "Antechinus apicalis",
  "Antilocapra americana peninsularis",
  "Antilocapra americana sonoriensis",
  "Antilocapra americana sonoriensis",
  "Antrobia culveri",
  "Antrolana lira",
  "Aonyx congicus microdon",
  "Aphelocoma coerulescens",
  "Aplodontia rufa nigra",
  "Aplonis pelzelni",
  "Apodemia mormo langei",
  "Aproteles bulmerae",
  "Aquila heliaca adalberti",
  "Ara ambiguus",
  "Ara glaucogularis",
  "Ara macao ssp. cyanopterus",
  "Ara macao ssp. macao",
  "Ara militaris",
  "Aratinga guarouba",
  "Arcanator orostruthus",
  "Arctocephalus townsendi",
  "Ardeotis nigriceps",
  "Arkansia wheeleri",
  "Assiminea pecos",
  "Ateles geoffroyi frontatus",
  "Ateles geoffroyl panamensis",
  "Atelopus varius zeteki",
  "Athearnia anthonyi",
  "Atrichornis clamosus",
  "Avahi laniger",
  "Axis porcinus annamiticus",
  "Axis porcinus calamianensis",
  "Axis porcinus kuhli",
  "Aythya innotata",
  "Babyrousa babyrussa",
  "Balaena mysticetus",
  "Balaenoptera borealis",
  "Balaenoptera musculus",
  "Balaenoptera physalus",
  "Batagur baska",
  "Batrachoseps aridus",
  "Batrisodes texanus",
  "Batrisodes venyivi",
  "Bebrornis rodericanus",
  "Bebrornis sechellensis",
  "Bettongia gaimardi",
  "Bettongia lesueur",
  "Bettongia penicillata",
  "Bettongia tropica",
  "Bison bison athabascae",
  "Blastocerus dichotomus",
  "Boloria acrocnema",
  "Bolyeria multocarinata",
  "Bombus affinis",
  "Bos gaurus",
  "Bos javanicus",
  "Bos mutus",
  "Bos sauveli",
  "Brachylagus idahoensis",
  "Brachylophus fasciatus",
  "Brachylophus vitiensis",
  "Brachyramphus marmoratus",
  "Brachyteles arachnoides",
  "Bradypus torquatus",
  "Branchinecta conservatio",
  "Branchinecta longiantenna",
  "Branchinecta lynchi",
  "Branchinecta sandiegonensis",
  "Branta sandvicensis",
  "Brychius hungerfordi",
  "Bubalus depressicornis",
  "Bubalus mindorensis",
  "Bubalus quarlesi",
  "Buceros vigil",
  "Bufo hemiophrys baxteri",
  "Bufo houstonensis",
  "Bufo periglenes",
  "Bufo superciliaris",
  "Burramys parvus",
  "Buteo galapagoensis",
  "Buteo platypterus brunnescens",
  "Buteo solitarius",
  "Cacajao spp.",
  "Cacatua alba",
  "Cacatua haematuropygia",
  "Cacatua moluccensis",
  "Cacatua sulphurea",
  "Caiman crocodilus apaporiensis",
  "Caiman crocodilus crocodilus",
  "Caiman crocodilus fuscus",
  "Caiman latirostris",
  "Caiman yacare",
  "Cairina scutulata",
  "Calidris canutus rufa",
  "Callaeas cinerea",
  "Callimico goeldii",
  "Callithrix aurita",
  "Callithrix flaviceps",
  "Callophrys mossii bayensis",
  "Caloprymnus campestris",
  "Camarhynchus pauper",
  "Cambarus aculabrum",
  "Cambarus callainus",
  "Cambarus veteranus",
  "Cambarus zophonastes",
  "Camelus bactrianus",
  "Campeloma decampi",
  "Campephilus imperialis",
  "Campephilus principalis",
  "Canis lupus",
  "Canis lupus baileyi",
  "Canis rufus",
  "Canis simensis",
  "Cantharellus noumeae",
  "Capra falconeri chiltanensis",
  "Capra falconeri megaceros",
  "Capra pyrenaica pyrenaica",
  "Capra walie",
  "Caprimulgus noctitherus",
  "Caprolagus hispidus",
  "Capromys angelcabrerai",
  "Capromys auritus",
  "Capromys nana",
  "Capromys sanfelipensis",
  "Carduelis cucullata",
  "Caretta caretta",
  "Casarea dussumieri",
  "Castor fiber birulai",
  "Catopuma temminckii",
  "Catostomus discobolus yarrowi",
  "Catostomus santaanae",
  "Catostomus warnerensis",
  "Catreus wallichii",
  "Centrocercus minimus",
  "Cephalophus jentinki",
  "Cephalorhynchus hectori hectori",
  "Cephalorhynchus hectori maui",
  "Ceratotherium simum cottoni",
  "Ceratotherium simum ssp. simum",
  "Cercocebus galeritus galeritus",
  "Cercocebus torquatus",
  "Cercopithecus diana",
  "Cercopithecus erythrogaster",
  "Cercopithecus erythrotis",
  "Cercopithecus lhoesti",
  "Cervus alfredi",
  "Cervus duvauceli",
  "Cervus elaphus bactrianus",
  "Cervus elaphus barbarus",
  "Cervus elaphus corsicanus",
  "Cervus elaphus hanglu",
  "Cervus elaphus macneilii",
  "Cervus elaphus wallichi",
  "Cervus elaphus yarkandensis",
  "Cervus eldi",
  "Cervus nippon grassianus",
  "Cervus nippon keramae",
  "Cervus nippon kopschi",
  "Cervus nippon mandarinus",
  "Cervus nippon taiouanus",
  "Chaeropus ecaudatus",
  "Chaetocercus berlepschi",
  "Chaetomys subspinosus",
  "Charadrius melodus",
  "Charadrius nivosus nivosus",
  "Chasiempis ibidis",
  "Chasmistes brevirostris",
  "Chasmistes cujus",
  "Chasmistes liorus",
  "Chelonia mydas",
  "Chinchilla brevicaudata boliviana",
  "Chiropotes albinasus",
  "Chiropotes satanas satanas",
  "Chlamyphorus truncatus",
  "Chondrohierax uncinatus mirus",
  "Chondrohierax uncinatus wilsonii",
  "Chrosomus saylori",
  "Chrysocyon brachyurus",
  "Cichlherminia iherminieri santaeluciae",
  "Cicindela dorsalis dorsalis",
  "Cicindela nevadica lincolniana",
  "Cicindela ohlone",
  "Cicindela puritana",
  "Cicindelidia floridana",
  "Ciconia boyciana",
  "Cicurina baronia",
  "Cicurina madla",
  "Cicurina venii",
  "Cicurina vespera",
  "Cinclocerthia ruficauda gutturalis",
  "Cinclodes aricomae",
  "Clemmys muhlenbergii",
  "Cnemidophorus vanzoi",
  "Coccyzus americanus",
  "Colinus virginianus ridgwayi",
  "Colobus satanas",
  "Columba inornata wetmorei",
  "Columba junoniae",
  "Columba mayeri",
  "Columba palumbus azorica",
  "Conolophus pallidus",
  "Conraua goliath",
  "Copsychus niger cebuensis",
  "Copsychus sechellarum",
  "Coquus newtoni",
  "Coquus typicus",
  "Coracopsis nigra barklyi",
  "Coreobagrus ichikawai",
  "Corvus hawaiiensis",
  "Corvus kubaryi",
  "Corvus leucognaphalus",
  "Corynorhinus townsendii ingens",
  "Corynorhinus townsendii virginianus",
  "Cotinga maculata",
  "Cottus paulus",
  "Cottus specus",
  "Craseonycteris thonglongyai",
  "Crax alberti",
  "Crax blumenbachii",
  "Crenichthys baileyi baileyi",
  "Crenichthys baileyi grandis",
  "Crenichthys nevadae",
  "Crocodylus acutus",
  "Crocodylus acutus",
  "Crocodylus cataphractus",
  "Crocodylus intermedius",
  "Crocodylus niloticus",
  "Crocodylus novaeguineae mindorensis",
  "Crocodylus palustris kimbula",
  "Crocodylus palustris palustris",
  "Crocodylus porosus",
  "Crocodylus rhombifer",
  "Crocodylus siamensis",
  "Crossoptilon crossoptilon",
  "Crossoptilon mantchuricum",
  "Crotalus unicolor",
  "Crotalus willardi obscurus",
  "Cryptobranchus alleganiensis bishopi",
  "Crystallaria cincotta",
  "Cumberlandia monodonta",
  "Cuon alpinus",
  "Cyanopsitta spixii",
  "Cyanoramphus auriceps forbesi",
  "Cyanoramphus cookii",
  "Cyclargus ammon",
  "Cyclargus thomasi bethunebakeri",
  "Cyclura carinata bartschi",
  "Cyclura carinata carinata",
  "Cyclura collei",
  "Cyclura cychlura cychlura",
  "Cyclura cychlura figginsi",
  "Cyclura cychlura inornata",
  "Cyclura lewisi",
  "Cyclura nubila caymanensis",
  "Cyclura nubila nubila",
  "Cyclura pinguis",
  "Cyclura rileyi cristata",
  "Cyclura rileyi nuchalis",
  "Cyclura rileyi rileyi",
  "Cyclura stejnegeri",
  "Cynomys mexicanus",
  "Cynomys parvidens",
  "Cynoscion macdonaldi",
  "Cyprinella caerulea",
  "Cyprinella formosa",
  "Cyprinodon bovinus",
  "Cyprinodon diabolis",
  "Cyprinodon elegans",
  "Cyprinodon macularius",
  "Cyprinodon nevadensis mionectes",
  "Cyprinodon nevadensis pectoralis",
  "Cyprinodon radiosus",
  "Cyprogenia stegaria",
  "Cyprogenia stegaria",
  "Cyrtodactylus serpensinsula",
  "Cyrtonaias tampicoensis tecomatensis",
  "Cyrtonyx montezumae merriami",
  "Damaliscus pygarus dorcas",
  "Dama mesopotamica",
  "Dasyornis broadbenti littoralis",
  "Dasyornis longirostris",
  "Dasyurus viverrinus",
  "Daubentonia madagascariensis",
  "Delphinapterus leucas",
  "Deltistes luxatus",
  "Dendrogyra cylindrus",
  "Dendroica chrysoparia",
  "Dendroica petechia petechia",
  "Dermatemys mawii",
  "Dermochelys coriacea",
  "Desmocerus californicus dimorphus",
  "Dicerorhinus sumatrensis",
  "Diceros bicornis",
  "Dinacoma caseyi",
  "Diomedia amsterdamensis",
  "Dionda diaboli",
  "Dipodomys heermanni morroensis",
  "Dipodomys ingens",
  "Dipodomys merriami parvus",
  "Dipodomys nitratoides exilis",
  "Dipodomys nitratoides nitratoides",
  "Dipodomys stephensi",
  "Discoglossus nigriventer",
  "Discus macclintocki",
  "Drepanis coccinea",
  "Drepanoptila holosericea",
  "Dromus dromas",
  "Dromus dromas",
  "Dromus dromas",
  "Drosophila aglaia",
  "Drosophila differens",
  "Drosophila digressa",
  "Drosophila hemipeza",
  "Drosophila heteroneura",
  "Drosophila montgomeryi",
  "Drosophila mulli",
  "Drosophila musaphilia",
  "Drosophila neoclavisetae",
  "Drosophila obatai",
  "Drosophila ochrobasis",
  "Drosophila sharpi",
  "Drosophila substenoptera",
  "Drosophila tarphytrichia",
  "Drymarchon corais couperi",
  "Dryocopus javensis richardsi",
  "Ducula galeata",
  "Ducula mindorensis",
  "Dugong dugon",
  "Egretta eulophotes",
  "Elaphrus viridis",
  "Elassoma alabamae",
  "Elephas maximus",
  "Eleutherodactylus cooki",
  "Eleutherodactylus jasperi",
  "Eleutherodactylus juanariveroi",
  "Elimia crenatella",
  "Elliptio chipolaensis",
  "Elliptio lanceolata",
  "Elliptio spinosa",
  "Elliptio steinstansana",
  "Elliptoideus sloatianus",
  "Emballonura semicaudata rotensis",
  "Emballonura semicaudata semicaudata",
  "Emoia slevini",
  "Empetrichthys latos",
  "Empidonax euleri johnstonei",
  "Empidonax traillii extimus",
  "Enhydra lutris kenyoni",
  "Enhydra lutris nereis",
  "Epicrates inornatus",
  "Epicrates monensis granti",
  "Epicrates monensis monensis",
  "Epicrates subflavus",
  "Epinephelus striatus",
  "Epioblasma brevidens",
  "Epioblasma brevidens",
  "Epioblasma brevidens",
  "Epioblasma capsaeformis",
  "Epioblasma florentina curtisii",
  "Epioblasma florentina florentina",
  "Epioblasma florentina walkeri",
  "Epioblasma metastriata",
  "Epioblasma obliquata obliquata",
  "Epioblasma obliquata perobliqua",
  "Epioblasma othcaloogensis",
  "Epioblasma penita",
  "Epioblasma torulosa gubernaculum",
  "Epioblasma torulosa rangiana",
  "Epioblasma torulosa torulosa",
  "Epioblasma triquetra",
  "Equus africanus",
  "Equus grevyi",
  "Equus hemionus",
  "Equus przewalskii",
  "Equus zebra hartmannae",
  "Equus zebra ssp. zebra",
  "Equus zebra zebra",
  "Eremichthys acros",
  "Eremophila alpestris strigata",
  "Eretmochelys imbricata",
  "Erignathus barbatus nauticus",
  "Erimonax monachus",
  "Erimystax cahni",
  "Erinna newcombi",
  "Eriocnemis nigrivestis",
  "Eschrichtius robustus",
  "Etheostoma akatulo",
  "Etheostoma boschungi",
  "Etheostoma chermocki",
  "Etheostoma chienense",
  "Etheostoma etowahae",
  "Etheostoma fonticola",
  "Etheostoma moorei",
  "Etheostoma nianguae",
  "Etheostoma nuchale",
  "Etheostoma okaloosae",
  "Etheostoma osburni",
  "Etheostoma percnurum",
  "Etheostoma phytophilum",
  "Etheostoma rubrum",
  "Etheostoma scotti",
  "Etheostoma sellare",
  "Etheostoma spilotum",
  "Etheostoma susanae",
  "Etheostoma trisella",
  "Etheostoma wapiti",
  "Etheostoma wapiti",
  "Eua zebrina",
  "Eubalaena australis",
  "Eubalaena glacialis",
  "Eubalaena japonica",
  "Eucyclogobius newberryi",
  "Eudyptes chrysocome",
  "Eudyptes pachyrhynchus",
  "Eudyptes sclateri",
  "Eudyptula albosignata",
  "Eulidia yarrellii",
  "Eumeces egregius lividus",
  "Eumetopias jubatus",
  "Eumops floridanus",
  "Euphilotes battoides allyni",
  "Euphilotes enoptes smithi",
  "Euphydryas editha bayensis",
  "Euphydryas editha quino",
  "Euphydryas editha taylori",
  "Euphyllia paradivisa",
  "Euproserpinus euterpe",
  "Eurycea chisholmensis",
  "Eurycea nana",
  "Eurycea naufragia",
  "Eurycea sosorum",
  "Eurycea tonkawae",
  "Eurycea waterlooensis",
  "Eutrichomyias rowleyi",
  "Eutriorchis astur",
  "Falco araea",
  "Falco femoralis septentrionalis",
  "Falco peregrinus peregrinus",
  "Falco punctatus",
  "Felis jacobita",
  "Felis margarita scheffeli",
  "Felis nigripes",
  "Felis pardina",
  "Formicivora erythronotos",
  "Foudia flavicans",
  "Foudia rubra",
  "Foudia sechellarum",
  "Francolinus ochropectus",
  "Fregata andrewsi",
  "Fulica americana alai",
  "Fusconaia burkei",
  "Fusconaia cor",
  "Fusconaia cor",
  "Fusconaia cor",
  "Fusconaia cuneolus",
  "Fusconaia escambia",
  "Fusconaia rotulata",
  "Gallicolumba stairi",
  "Gallinula chloropus guami",
  "Gallinula galeata sandvicensis",
  "Gallirallus sylvestris",
  "Gallotia simonyi simonyi",
  "Gambelia silus",
  "Gambusia gaigei",
  "Gambusia georgei",
  "Gambusia heterochir",
  "Gambusia nobilis",
  "Gammarus acherondytes",
  "Gammarus desperatus",
  "Gammarus hyalleloides",
  "Gammarus pecos",
  "Gasterosteus aculeatus williamsoni",
  "Gavialis gangeticus",
  "Gazella cuvieri",
  "Gazella dama",
  "Gazella dama lozanoi",
  "Gazella dama mhorr",
  "Gazella dorcas massaesyla",
  "Gazella dorcas pelzelni",
  "Gazella dorcas saudiya",
  "Gazella gazella",
  "Gazella leptoceros",
  "Gazella subgutturosa marica",
  "Geochelone nigra",
  "Geochelone radiata",
  "Geochelone yniphora",
  "Geoclemys hamiltonii",
  "Geopsittacus occidentalis",
  "Geronticus eremita",
  "Gila bicolor ssp.",
  "Gila bicolor ssp. mohavensis",
  "Gila bicolor ssp. snyderi",
  "Gila boraxobius",
  "Gila cypha",
  "Gila ditaenia",
  "Gila elegans",
  "Gila intermedia",
  "Gila nigrescens",
  "Gila purpurea",
  "Gila robusta jordani",
  "Gila seminuda",
  "Glaucomys sabrinus coloratus",
  "Glaucopsyche lygdamus palosverdesensis",
  "Gopherus agassizii",
  "Gopherus flavomarginatus",
  "Gopherus polyphemus",
  "Gorilla gorilla",
  "Grallaria milleri",
  "Graptemys flavimaculata",
  "Graptemys oculifera",
  "Grus americana",
  "Grus canadensis nesiotes",
  "Grus canadensis pulla",
  "Grus japonensis",
  "Grus leucogeranus",
  "Grus monacha",
  "Grus nigricollis",
  "Grus vipio",
  "Gymnobelideus leadbeateri",
  "Gymnogyps californianus",
  "Gymnomyza samoensis",
  "Haematopus meadewaldoi",
  "Halcyon cinnamomina cinnamomina",
  "Haliaeetus albicilla groenlandicus",
  "Haliaeetus vociferoides",
  "Haliotis cracherodii",
  "Haliotis sorenseni",
  "Hamiota australis",
  "Harpia harpyja",
  "Helminthoglypta walkeriana",
  "Hemiargus ceraunus antibubastus",
  "Hemignathus affinis",
  "Hemignathus hanapepe",
  "Hemignathus wilsoni",
  "Hemiphaga novaeseelandiae chathamensis",
  "Hemistena lata",
  "Hemitragus jayakari",
  "Hemitriccus kaempferi",
  "Heraclides aristodemus ponceanus",
  "Herpailurus yagouaroundi cacomitli",
  "Herpailurus yagouaroundi fossata",
  "Herpailurus yagouaroundi panamensis",
  "Herpailurus yagouaroundi tolteca",
  "Hesperia dacotae",
  "Hesperia leonardus montana",
  "Heterelmis comalensis",
  "Himantopus mexicanus knudseni",
  "Himantopus novaezelandiae",
  "Hippocamelus antisensis",
  "Hippocamelus bisulcus",
  "Hipposideros ridleyi",
  "Hippotragus niger variani",
  "Huso dauricus",
  "Huso huso",
  "Hyaena hyaena barbara",
  "Hybognathus amarus",
  "Hybognathus amarus",
  "Hylaeus anthracinus",
  "Hylaeus assimulans",
  "Hylaeus facilis",
  "Hylaeus hilaris",
  "Hylaeus kuakea",
  "Hylaeus longiceps",
  "Hylaeus mana",
  "Hylobates spp. (including Nomascus)",
  "Hymenophysa curta",
  "Hypolimnas octocula marianensis",
  "Hypomesus transpacificus",
  "Hypsipetes borbonicus olivaceus",
  "Icaricia icarioides fenderi",
  "Icaricia icarioides missionensis",
  "Icaricia (Plebejus) shasta charlestonensis",
  "Ictalurus pricei",
  "Indri indri",
  "Ischnura luta",
  "Isopora crateriformis",
  "Juturnia kosteri",
  "Kachuga tecta tecta",
  "Kinosternon sonoriense longifemorale",
  "Kobus leche",
  "Lagorchestes hirsutus",
  "Lagostrophus fasciatus",
  "Lagothrix flavicauda",
  "Lampsilis abrupta",
  "Lampsilis altilis",
  "Lampsilis higginsii",
  "Lampsilis perovalis",
  "Lampsilis powellii",
  "Lampsilis rafinesqueana",
  "Lampsilis streckeri",
  "Lampsilis subangulata",
  "Lampsilis virescens",
  "Lampsilis virescens",
  "Lanius ludovicianus mearnsi",
  "Lanx sp.",
  "Larus audouinii",
  "Larus relictus",
  "Lasiorhinus krefftii",
  "Lasiurus cinereus semotus",
  "Lasmigona decorata",
  "Laterallus tuerosi",
  "Latimeria chalumnae",
  "Leiolopisma telfairi",
  "Leiopelma hamiltoni",
  "Lemiox rimosus",
  "Lemiox rimosus",
  "Lemiox rimosus",
  "Lemuridae",
  "Leontopithecus spp.",
  "Leopardus pardalis",
  "Leopardus tigrinus",
  "Leopardus wiedii",
  "Lepidochelys kempii",
  "Lepidochelys olivacea",
  "Lepidochelys olivacea",
  "Lepidomeda albivallis",
  "Lepidomeda mollispinis pratensis",
  "Lepidomeda vittata",
  "Lepidurus packardi",
  "Leporillus conditor",
  "Leptailurus serval constantina",
  "Leptasthenura xenothorax",
  "Leptodea leptodon",
  "Leptonycteris nivalis",
  "Leptoptilos dubius",
  "Leptotes cassius theonus",
  "Leptotila rufaxilla wellsi",
  "Leptoxis ampla",
  "Leptoxis foremani",
  "Leptoxis plicata",
  "Leptoxis taeniata",
  "Lepyrium showalteri",
  "Leucopeza semperi",
  "Leucopsar rothschildi",
  "Lichenostomus melanops cassidix",
  "Liophus ornatus",
  "Lioplax cyclostomaformis",
  "Lipotes vexillifer",
  "Lirceus usdagalun",
  "Lontra felina",
  "Lontra longicaudis",
  "Lontra provocax",
  "Lophophorus lhuysii",
  "Lophophorus sclateri",
  "Lophura edwardsi",
  "Lophura imperialis",
  "Lophura swinhoii",
  "Loxioides bailleui",
  "Loxodonta africana",
  "Loxops caeruleirostris",
  "Loxops coccineus",
  "Loxops ochraceus",
  "Lycaeides argyrognomon lotis",
  "Lycaeides melissa samuelis",
  "Lycaon pictus",
  "Lynx canadensis",
  "Lynx rufus escuinapae",
  "Macaca arctoides",
  "Macaca cyclopis",
  "Macaca fuscata",
  "Macaca silenus",
  "Macaca sinica",
  "Macrocephalon maleo",
  "Macropus giganteus tasmaniensis",
  "Macropus parma",
  "Macrotis lagotis",
  "Macrotis leucura",
  "Malaconotus alius",
  "Malimbus ibadanensis",
  "Mandrillus leucophaeus",
  "Mandrillus sphinx",
  "Manduca blackburni",
  "Manis temminckii",
  "Margaritifera hembeli",
  "Margaritifera marrianae",
  "Marmota vancouverensis",
  "Martes flavigula chrysospila",
  "Masticophis lateralis euryxanthus",
  "Meda fulgida",
  "Medionidus acutissimus",
  "Medionidus parvulus",
  "Medionidus penicillatus",
  "Medionidus simpsonianus",
  "Medionidus walkeri",
  "Megadyptes antipodes",
  "Megalagrion leptodemas",
  "Megalagrion nesiotes",
  "Megalagrion nigrohamatum nigrolineatum",
  "Megalagrion oceanicum",
  "Megalagrion pacificum",
  "Megalagrion xanthomelas",
  "Megalonaias nicklineana",
  "Megapodius laperouse",
  "Megaptera novaeangliae",
  "Megaptera novaeangliae",
  "Megaptera novaeangliae",
  "Megaptera novaeangliae",
  "Megaptera novaeangliae",
  "Melamprosops phaeosoma",
  "Melanochelys tricarinata",
  "Melanosuchus niger",
  "Menidia extensa",
  "Mergus octosetaceus",
  "Mesodon clarki nantahala",
  "Microhexura montivaga",
  "Microtus californicus scirpensis",
  "Microtus pennsylvanicus dukecampbelli",
  "Mimizuku gurneyi",
  "Mimus graysoni",
  "Mitu mitu mitu",
  "Moapa coriacea",
  "Moho braccatus",
  "Monachus monachus",
  "Monachus schauinslandi",
  "Montipora australiensis",
  "Morenia ocellata",
  "Moschus spp.",
  "Muntiacus feae",
  "Mustela nigripes",
  "Mustela nigripes",
  "Myadestes lanaiensis rutha",
  "Myadestes myadestinus",
  "Myadestes palmeri",
  "Mycetophyllia ferox",
  "Mycteria americana",
  "Myotis grisescens",
  "Myotis septentrionalis",
  "Myotis sodalis",
  "Myrmecobius fasciatus",
  "Naemorhedus sumatraensis",
  "Naemorhedus goral",
  "Nasalis concolor",
  "Nasalis larvatus",
  "Nectarinia prigoginei",
  "Nectophrynoides spp.",
  "Necturus alabamensis",
  "Nemosia rourei",
  "Neofelis nebulosa",
  "Neoleptoneta microps",
  "Neoleptoneta myopica",
  "Neomorphus geoffroyi dulcis",
  "Neonympha mitchellii francisci",
  "Neonympha mitchellii mitchellii",
  "Neophema chrysogaster",
  "Neoseps reynoldsi",
  "Neotoma floridana smalli",
  "Neotoma fuscipes riparia",
  "Neotragus moschatus moschatus",
  "Nerodia clarkii taeniata",
  "Nerodia erythrogaster neglecta",
  "Nesillas aldabranus",
  "Newcombia cumingi",
  "Nicrophorus americanus",
  "Nipponia nippon",
  "Notomys aquilo",
  "Notropis albizonatus",
  "Notropis buccula",
  "Notropis cahabae",
  "Notropis girardi",
  "Notropis mekistocholas",
  "Notropis oxyrhynchus",
  "Notropis simus pecosensis",
  "Notropis topeka",
  "Notropis topeka",
  "Noturus baileyi",
  "Noturus baileyi",
  "Noturus crypticus",
  "Noturus flavipinnis",
  "Noturus placidus",
  "Noturus stanauli",
  "Noturus trautmani",
  "Numenius borealis",
  "Numenius tenuirostris",
  "Nycticebus pygmaeus",
  "Oarisma poweshiek",
  "Obovaria retusa",
  "Obovaria retusa",
  "Oceanodroma castro",
  "Odocoileus hemionus cerrosensis",
  "Odocoileus virginianus clavium",
  "Odocoileus virginianus leucurus",
  "Odontophorus strophium",
  "Oncorhynchus aguabonita whitei",
  "Oncorhynchus apache",
  "Oncorhynchus clarkii henshawi",
  "Oncorhynchus clarkii seleniris",
  "Oncorhynchus clarkii stomias",
  "Oncorhynchus gilae",
  "Oncorhynchus keta",
  "Oncorhynchus keta",
  "Oncorhynchus kisutch",
  "Oncorhynchus kisutch",
  "Oncorhynchus kisutch",
  "Oncorhynchus kisutch",
  "Oncorhynchus mykiss",
  "Oncorhynchus nerka",
  "Oncorhynchus tshawytscha",
  "Onychogalea fraenata",
  "Onychogalea lunata",
  "Orbicella annularis",
  "Orbicella faveolata",
  "Orbicella franksi",
  "Orcinus orca",
  "Orconectes shoupi",
  "Oreomystis bairdi",
  "Oreomystis mana",
  "Oreophasis derbianus",
  "Orthalicus reses",
  "Oryx dammah",
  "Oryx leucoryx",
  "Oryzomys palustris natator",
  "Osteolaemus tetraspis osborni",
  "Osteolaemus tetraspis tetraspis",
  "Ostodes strigatus",
  "Otus ireneae",
  "Otus magicus (=insularis) insularis",
  "Otus rutilus capnodes",
  "Ovis ammon",
  "Ovis ammon",
  "Ovis canadensis nelsoni",
  "Ovis canadensis sierrae",
  "Ovis musimon ophion",
  "Ovis vignei vignei",
  "Oxyloma haydeni kanabensis",
  "Ozotoceros bezoarticus",
  "Pacifastacus fortis",
  "Palaemonetes cummingi",
  "Palaemonias alabamae",
  "Palaemonias ganteri",
  "Palmeria dolei",
  "Pangasianodon gigas",
  "Pangasius sanitwongsei",
  "Pan paniscus",
  "Panthalops hodgsonii",
  "Panthera leo ssp. leo",
  "Panthera leo ssp. melanochaita",
  "Panthera onca",
  "Panthera pardus",
  "Panthera pardus",
  "Panthera tigris",
  "Pan troglodytes",
  "Papasula abbotti",
  "Papilio chikae",
  "Papilio homerus",
  "Papilio hospiton",
  "Papustyla pulcherrima",
  "Parahyaena brunnea",
  "Pardofelis marmorata",
  "Paroreomyza flammea",
  "Paroreomyza maculata",
  "Partula gibba",
  "Partula langfordi",
  "Partula radiolata",
  "Partulina semicarinata",
  "Partulina variabilis",
  "Pavona diffluens",
  "Pedionomus torquatus",
  "Pegias fabula",
  "Peltophryne lemur",
  "Penelope albipennis",
  "Penelope perspicax",
  "Pentalagus furnessi",
  "Perameles bougainville",
  "Perameles eremiana",
  "Percina antesella",
  "Percina aurolineata",
  "Percina aurora",
  "Percina jenkinsi",
  "Percina pantherina",
  "Percina rex",
  "Percina tanasi",
  "Perognathus longimembris pacificus",
  "Peromyscus gossypinus allapaticola",
  "Peromyscus polionotus allophrys",
  "Peromyscus polionotus ammobates",
  "Peromyscus polionotus niveiventris",
  "Peromyscus polionotus peninsularis",
  "Peromyscus polionotus phasma",
  "Peromyscus polionotus trissyllepsis",
  "Petrogale xanthopus",
  "Petroica multicolor multicolor",
  "Petroica traversi",
  "Pezoporus wallicus",
  "Phaenicophaeus pyrrhocephalus",
  "Phaeognathus hubrichti",
  "Phaethornis malaris margarettae",
  "Pharomachrus mocinno ssp. mocinno",
  "Phascolarctos cinereus",
  "Phelsuma edwardnewtoni",
  "Phelsuma guentheri",
  "Phoca hispida saimensis",
  "Phoca largha",
  "Phoca hispida botnica",
  "Phoca hispida hispida",
  "Phoca hispida ladogensis",
  "Phoca hispida ochotensis",
  "Phocoena sinus",
  "Phoebastria albatrus",
  "Phoenicoparrus andinus",
  "Phoxinus cumberlandensis",
  "Phrynops hogei",
  "Physa natricina",
  "Physeter catodon",
  "Phytotoma raimondii",
  "Picathartes gymnocephalus",
  "Picathartes oreas",
  "Picoides borealis",
  "Pionopsitta pileata",
  "Pipile jacutinga",
  "Pipile pipile pipile",
  "Pipilo crissalis eremophilus",
  "Pithecophaga jefferyi",
  "Pitta gurneyi",
  "Pitta kochi",
  "Pituophis melanoleucus lodingi",
  "Pituophis ruthveni",
  "Plagopterus argentissimus",
  "Planigale ingrami subtilissima",
  "Planigale tenuirostris",
  "Platanista gangetica minor",
  "Platysteira laticincta",
  "Plethobasus cicatricosus",
  "Plethobasus cooperianus",
  "Plethobasus cyphyus",
  "Plethodon neomexicanus",
  "Plethodon nettingi",
  "Plethodon shenandoah",
  "Pleurobema clava",
  "Pleurobema collina",
  "Pleurobema curtum",
  "Pleurobema decisum",
  "Pleurobema furvum",
  "Pleurobema georgianum",
  "Pleurobema gibberum",
  "Pleurobema hanleyianum",
  "Pleurobema marshalli",
  "Pleurobema perovatum",
  "Pleurobema plenum",
  "Pleurobema pyriforme",
  "Pleurobema strodeanum",
  "Pleurobema taitianum",
  "Pleurocera foremani",
  "Pleuronaia dolabelloides",
  "Ploceus golandi",
  "Podarcis pityusensis",
  "Podiceps taczanowskii",
  "Podilymbus gigas",
  "Podocnemis expansa",
  "Podocnemis unifilis",
  "Poeciliopsis occidentalis",
  "Poecilotheria fasciata",
  "Poecilotheria ornata",
  "Poecilotheria pederseni",
  "Poecilotheria smithi",
  "Poecilotheria subfusca",
  "Polioptila californica californica",
  "Polyborus plancus audubonii",
  "Polygyriscus virginianus",
  "Polyphylla barbata",
  "Polyplectron emphanum",
  "Polysticta stelleri",
  "Pomarea nigra",
  "Pongo pygmaeus",
  "Popenaias popeii",
  "Porites napopora",
  "Potamilus capax",
  "Potamilus inflatus",
  "Presbytis potenziani",
  "Presbytis senex",
  "Prietella phreatophila",
  "Priodontes maximus",
  "Prionailurus bengalensis bengalensis",
  "Prionailurus bengalensis iriomotensis",
  "Prionailurus planiceps",
  "Prionodon pardicolor",
  "Pristis clavata",
  "Pristis pectinata",
  "Pristis pristis",
  "Pristis zijsron",
  "Probarbus jullieni",
  "Procaris hawaiana",
  "Procolobus pennantii (=kirki) kirki",
  "Procolobus preussi (=badius p.)",
  "Procolobus rufomitratus (=badius r.)",
  "Propithecus spp.",
  "Psammobates geometricus",
  "Psephotus chrysopterygius",
  "Psephotus pulcherrimus",
  "Pseudemydura umbrina",
  "Pseudemys alabamensis",
  "Pseudemys rubriventris bangsi",
  "Pseudibis gigantea",
  "Pseudobulweria macgillivrayi",
  "Pseudocopaeodes eunus obscurus",
  "Pseudomys fieldi",
  "Pseudomys fumeus",
  "Pseudomys gouldii",
  "Pseudomys novaehollandiae",
  "Pseudomys occidentalis",
  "Pseudomys praeconis",
  "Pseudomys shortridgei",
  "Pseudonestor xanthophrys",
  "Pseudorca crassidens",
  "Pseudotryonia adamantina",
  "Psittacula echo",
  "Psittirostra psittacea",
  "Psophodes nigrogularis",
  "Pterodroma aterrima",
  "Pterodroma axillaris",
  "Pterodroma cahow",
  "Pterodroma madeira",
  "Pterodroma magentae",
  "Pterodroma phaeopygia",
  "Pterodroma sandwichensis",
  "Pteronura brasiliensis",
  "Pteropus mariannus mariannus",
  "Pteropus rodricensis",
  "Pteropus tokudae",
  "Ptychobranchus greenii",
  "Ptychobranchus jonesi",
  "Ptychobranchus subtentum",
  "Ptychocheilus lucius",
  "Ptychocheilus lucius",
  "Pudu pudu",
  "Puffinus auricularis newelli",
  "Puffinus heinrothi",
  "Puma concolor",
  "Puma concolor coryi",
  "Puma concolor costaricensis",
  "Pygathrix nemaeus",
  "Pyrgulopsis bernardina",
  "Pyrgulopsis bruneauensis",
  "Pyrgulopsis chupaderae",
  "Pyrgulopsis pachyta",
  "Pyrgulopsis neomexicana",
  "Pyrgulopsis ogmorhaphe",
  "Pyrgulopsis roswellensis",
  "Pyrgulopsis texana",
  "Pyrgulopsis trivialis",
  "Pyrgus ruralis lagunae",
  "Pyriglena atra",
  "Pyrrhula pyrrhula murina",
  "Pyrrhura cruentata",
  "Python molurus molurus",
  "Quadrula cylindrica cylindrica",
  "Quadrula cylindrica strigillata",
  "Quadrula fragosa",
  "Quadrula fragosa",
  "Quadrula intermedia",
  "Quadrula sparsa",
  "Quadrula stapes",
  "Quisicalus palustris",
  "Rallus longirostris levipes",
  "Rallus longirostris obsoletus",
  "Rallus longirostris yumanensis",
  "Rallus owstoni",
  "Rallus owstoni",
  "Rallus pectoralis muelleri",
  "Ramphocinclus brachyurus",
  "Ramphodon dohrnii",
  "Rana chiricahuensis",
  "Rana draytonii",
  "Rana muscosa",
  "Rana pretiosa",
  "Rana sevosa",
  "Rana sierrae",
  "Rangifer tarandus caribou",
  "Reithrodontomys raviventris",
  "Rhadine exilis",
  "Rhadine infernalis",
  "Rhadine persephone",
  "Rhaphiomidas terminatus abdominalis",
  "Rhea pennata",
  "Rhinichthys osculus lethoporus",
  "Rhinichthys osculus nevadensis",
  "Rhinichthys osculus oligoporus",
  "Rhinichthys osculus ssp.",
  "Rhinichthys osculus thermalis",
  "Rhinoceros sondaicus",
  "Rhinoceros unicornis",
  "Rhinopithecus avunculus",
  "Rhinopithecus bieti",
  "Rhinopithecus brelichi",
  "Rhinopithecus roxellana",
  "Rhinoptilus bitorquatus",
  "Rhodonessa caryophyllacea",
  "Rhynchopsitta pachyrhyncha",
  "Rhynochetos jubatus",
  "Romerolagus diazi",
  "Rostrhamus sociabilis plumbeus",
  "Rukia longirostra",
  "Rupicapra rupicapra ornata",
  "Saguinus bicolor",
  "Saguinus leucopus",
  "Saguinus oedipus",
  "Saiga tatarica mongolica",
  "Saimiri oerstedii",
  "Salmo platycephalus",
  "Salmo salar",
  "Salvelinus confluentus",
  "Salvelinus confluentus",
  "Samoana fragilis",
  "Sauromalus varius",
  "Scaphirhynchus albus",
  "Scaphirhynchus platorynchus",
  "Scaphirhynchus suttkusi",
  "Scleropages formosus",
  "Sebastes paucispinis",
  "Sebastes pinniger",
  "Sebastes ruberrimus",
  "Semnopithecus entellus",
  "Seriatopora aculeata",
  "Setonix brachyurus",
  "Setophaga angelae",
  "Setophaga kirtlandii",
  "Sistrurus catenatus",
  "Sitta ledanti",
  "Sminthopsis longicaudata",
  "Sminthopsis psammophila",
  "Solenodon cubanus",
  "Solenodon paradoxus",
  "Somateria fischeri",
  "Somatochlora hineana",
  "Sorex ornatus relictus",
  "Spelaeorchestia koloana",
  "Speoplatyrhinus poulsoni",
  "Speyeria callippe callippe",
  "Speyeria zerene behrensii",
  "Speyeria zerene hippolyta",
  "Speyeria zerene hippolyta",
  "Speyeria zerene myrtleae",
  "Sphaerodactylus micropithecus",
  "Spheniscus demersus",
  "Spheniscus humboldti",
  "Spheniscus mendiculus",
  "Sphenodon guntheri",
  "Sphenodon punctatus",
  "Sphyrna lewini",
  "Squatina squatina",
  "Sterna antillarum",
  "Sterna antillarum browni",
  "Sterna dougallii dougallii",
  "Sternotherus depressus",
  "Streptocephalus woottoni",
  "Streptopelia picturata rostrata",
  "Strigops habroptilus",
  "Strix occidentalis caurina",
  "Strix occidentalis lucida",
  "Struthio camelus spatzi",
  "Struthio camelus syriacus",
  "Strymon acis bartrami",
  "Stygobromus hayi",
  "Stygobromus pecki",
  "Stygoparnus comalensis",
  "Succinea chittenangoensis",
  "Sus salvanius",
  "Sylvilagus bachmani riparius",
  "Sylvilagus palustris hefneri",
  "Symphalangus syndactylus",
  "Syncaris pacifica",
  "Syrmaticus ellioti",
  "Syrmaticus humaie",
  "Syrmaticus mikado",
  "Tachybaptus rufolavatus",
  "Tamiasciurus hudsonicus grahamensis",
  "Tanakia tanago",
  "Tapirus bairdii",
  "Tapirus indicus",
  "Tapirus pinchaque",
  "Tapirus terrestris",
  "Tarsius syrichta",
  "Tartarocreagris texana",
  "Tauraco bannermani",
  "Taurotragus derbianus derbianus",
  "Taylorconcha serpenticola",
  "Telespyza cantans",
  "Telespyza ultima",
  "Terpsiphone corvina",
  "Terrapene coahuila",
  "Tetrao urogallus cantabricus",
  "Texamaurops reddelli",
  "Texella cokendolpheri",
  "Texella reddelli",
  "Texella reyesi",
  "Thaleichthys pacificus",
  "Thamnophis eques megalops",
  "Thamnophis gigas",
  "Thamnophis rufipunctatus",
  "Thamnophis sirtalis tetrataenia",
  "Thermosphaeroma thermophilus",
  "Theropithecus gelada",
  "Thinornis novaeseelandiae",
  "Thomomys mazama glacialis",
  "Thomomys mazama pugetensis",
  "Thomomys mazama tumuli",
  "Thomomys mazama yelmensis",
  "Thylacinus cynocephalus",
  "Tiaroga cobitis",
  "Tinamus solitarius",
  "Tomistoma schlegelii",
  "Toxolasma cylindrellus",
  "Trachemys scripta callirostris",
  "Trachemys stejnegeri malonei",
  "Trachemys terrapen",
  "Trachypithecus francoisi",
  "Trachypithecus geei",
  "Trachypithecus pileatus",
  "Tragopan blythii",
  "Tragopan caboti",
  "Tragopan melanocephalus",
  "Trichechus inunguis",
  "Trichechus manatus",
  "Trichechus senegalensis",
  "Trichocichla rufa",
  "Trimerotropis infantilis",
  "Tringa guttifer",
  "Triodopsis platysayoides",
  "Trionyx ater",
  "Trionyx gangeticus",
  "Trionyx hurum",
  "Trionyx nigricans",
  "Troglodytes aedon guadeloupensis",
  "Troglodytes aedon mesoleucus",
  "Troides alexandrae",
  "Tryonia alamosae",
  "Tryonia cheatumi",
  "Tryonia circumstriata",
  "Tubastraea floreana",
  "Tulotoma magnifica",
  "Turdus olivaceus helleri",
  "Turnagra capensis",
  "Tympanuchus cupido attwateri",
  "Typhlomolge rathbuni",
  "Tyto soumagnei",
  "Uma inornata",
  "Uncia uncia",
  "Uratelornis chimaera",
  "Urocitellus brunneus",
  "Urocyon littoralis catalinae",
  "Ursus arctos",
  "Ursus arctos arctos",
  "Ursus arctos horribilis",
  "Ursus arctos horribilis",
  "Ursus arctos pruinosus",
  "Ursus maritimus",
  "Ursus thibetanus gedrosianus",
  "Vagrans egistina",
  "Varanus bengalensis",
  "Varanus flavescens",
  "Varanus griseus",
  "Varanus komodoensis",
  "Vermivora bachmanii",
  "Vetericaris chaceorum",
  "Vicugna vicugna",
  "Vicugna vicugna",
  "Villosa choctawensis",
  "Villosa fabalis",
  "Villosa perpurpurea",
  "Villosa trabalis",
  "Vipera latifii",
  "Vireo bellii pusillus",
  "Viverra civettina",
  "Vulpes macrotis mutica",
  "Vulpes velox hebes",
  "Vultur gryphus",
  "Wyulda squamicaudata",
  "Xenicus longipes",
  "Xenopirostris damii",
  "Xenopirostris polleni",
  "Xeromys myoides",
  "Xipholena atropurpurea",
  "Xyrauchen texanus",
  "Zapus hudsonius luteus",
  "Zapus hudsonius preblei",
  "Zosterops albogularis",
  "Zosterops conspicillatus conspicillatus",
  "Zosterops modesta",
  "Zosterops rotensis",
  "Zyzomys pedunculatus"
  ]