import { Heading } from "../../components";
import { NavbarDropdownMenuSectionItemTemplate } from "..";
import React from "react";

export default function NavbarDropdownMenuCategoryTemplate({
  categoryData,
  navbar,
}) {
  const sections = Object.keys(categoryData).map((category, index) => {
    const sectionItems = categoryData[category].map(
      (sectionItemData, index) => {
        return (
          <NavbarDropdownMenuSectionItemTemplate
            key={index}
            navbar={navbar}
            sectionItemData={sectionItemData}
          />
        );
      },
    );
    return (
      <div key={index} className="nav-dropdown-menu__section">
        <Heading
          number={4}
          bold={false}
          color="secondary"
          className="nav-dropdown-menu__section__heading">
          {category}
        </Heading>
        {sectionItems}
      </div>
    );
  });
  return (
    <div className="nav-dropdown-menu__sections-container u-pad-top-sm">
      {sections}
    </div>
  );
}
