import Auth from "../../auth/API";
import {
  Button,
  Heading,
  Text,
  Icon,
  LinkTop,
  Menu,
  Dropdown,
  // Span,
} from "../../components";
import {
  NavbarDropdownButton,
  NavbarDropdownMenu,
  NavbarDropdownMenuCategoryTemplate,
  NavbarLogInModal,
  NavbarResetPasswordModal,
  NavbarRetrieveUsernameModal,
  NavbarSignUpModal,
  NavbarSignUpEmailMessageModal,
} from "..";
import menuData from "./navbarDropdownMenuData";
import React, { useEffect } from "react";
import routes from "../../utils/paths/routes";
import { useHistory } from "react-router-dom";
import utils from "../../utils/utils";

export default function Navbar({ account, auth, navbar }) {
  /* const initNotifications = {
      notifications: [],
      notificationJSX: [],
      unseen: 0,
    },
    [notiState, setNotiState] = useState(initNotifications); */

  const history = useHistory();
  // Updates navbar subtitle and generates dropdown menu
  useEffect(() => {
    let dropdownMenuSource = "",
      isAppPage = false,
      subtitle = "";
    const appsPagesRefs = [
      routes.apps.collabDash,
      routes.apps.collabMap,
      routes.apps.cvmDash,
      routes.apps.cvmApp,
      routes.apps.habPatApp,
    ];
    appsPagesRefs.forEach((appPageRef) => {
      if (
        history.location.pathname.includes(
          appPageRef.subtitlePath || appPageRef.path,
        )
      ) {
        subtitle = appPageRef.label;
        isAppPage = true;
      }
    });
    navbar.setState({
      ...navbar.state,
      dropdownMenuSource,
      isAppPage,
      subtitle,
    });

    // Opens log in modal after account activation
    if (history.location.isLogInModalOpen) {
      navbar.setState({
        ...navbar.state,
        isLogInModalOpen:
          history.location.isLogInModalOpen || navbar.isLogInModalOpenInit,
      });
    }
  }, [history.location.pathname]);

  let menuContent = {};
  Object.keys(menuData).forEach((category) => {
    menuContent[category] = (
      <NavbarDropdownMenuCategoryTemplate
        categoryData={menuData[category]}
        navbar={navbar}
      />
    );
  });

  const navbarDropdownButtons = Object.keys(menuData).map((category, index) => {
    return (
      <NavbarDropdownButton key={index} category={category} navbar={navbar} />
    );
  });

  // Generates and updates notifications
  /* function setNotificationJSX(res) {
    const notificationJSX = [],
      { notifications, unseen } = res;

    for (let i = 0; i < notifications.length; i++) {
      const notification = notifications[i],
        obj = {
          jsx: "",
        };
      const jsx = (
        <div className="u-flex u-flex-column" key={notification.id}>
          <div
            style={{ width: "20rem" }}
            className="u-flex u-flex-justify-between u-mgn-btm-sm">
            <Text
              number={2}
              ko={true}
              className={
                notification.seen
                  ? "u-color-inactive-white u-text-italic"
                  : "u-text-italic"
              }>
              {utils.formatDateTime(notification.date_created)}
            </Text>
          </div>
          <LinkTop to={notification.link}>
            <Text
              number={2}
              ko={true}
              className={
                notification.seen
                  ? "u-color-inactive-white u-hover-color-yellow"
                  : "u-hover-color-yellow"
              }>
              {notification.message}
            </Text>
          </LinkTop>
        </div>
      );
      obj.jsx = jsx;
      // obj.link = notification.link;
      notificationJSX.push(obj);
    }
    setNotiState({ notifications, notificationJSX, unseen });
  } */

  /* function markNotificationsAsRead() {
    API.readNotifications(account.state.id).then((res) => {
      setNotificationJSX(res.data);
    });
  } */

  /* function clearNotifications() {
    API.clearNotifications(account.state.id).then((res) => {
      setNotiState({
        notifications: [],
        notificationJSX: [],
        unseen: 0,
      });
    });
  } */

  /* useEffect(() => {
    if (account.state.id) {
      API.getNotifications(account.state.id).then((res) => {
        setNotificationJSX(res.data);
      });
    }
  }, [history.location.pathname, auth.state.isTabVisible]); */

  // Manages user authentication state
  function logOut() {
    localStorage.removeItem("token");
    auth.setState(auth.stateInit);
    account.setState(account.stateInit);
    utils.scrollToTop();
    utils.sendAlert("Logged out.", "success");
  }

  const appTesterUsernames = ["max-aubain1", "mjevans", "lnunes"];

  useEffect(() => {
    if (auth.state.isAuthenticated) {
      Auth.getAuth()
        .then((res) => {
          let isAppTester = false;
          appTesterUsernames.forEach((username) => {
            if (username === res.data.username) {
              isAppTester = true;
            }
          });
          account.setState(res.data);
          let isAuthenticated = true;
          if (res.data.anonymous) {
            isAuthenticated = false;
          }
          auth.setState({
            ...auth.state,
            isAppTester,
            isAuthenticated,
          });
        })
        .catch(() => {
          utils.sendAlert(
            "Your session has expired and you have been logged out.",
            "message",
          );
          localStorage.removeItem("token");
          history.push(routes.landing.path);
          auth.setState(auth.stateInit);
          account.setState(account.stateInit);
        });
    }
  }, [history.location.pathname, auth.state.isAuthenticated]);

  /* Update auth.isAuthenticated on tab visibility change 
  and GET private account data when authenticated. */
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("token") ? true : false;
    if (auth.state.isTabVisible) {
      auth.setState({
        ...auth.state,
        isAuthenticated,
      });
      if (auth.state.isAuthenticated) {
        Auth.getAuth()
          .then((res) => {
            account.setState(res.data);
          })
          .catch(() => {
            utils.sendAlert(
              "Your session has expired and you have been logged out.",
              "message",
            );
            localStorage.removeItem("token");
            auth.setState(auth.stateInit);
            account.setState(account.stateInit);
            history.push(routes.landing.path);
          });
      }
    }
  }, [auth.state.isTabVisible]);

  // Push to Profile Page from Landing Page if newly authenticated.
  useEffect(() => {
    if (
      history.location.pathname === "/landing" &&
      account.state.username &&
      auth.state.isAuthenticated
    ) {
      history.push(`/profile/${account.state.username}`);
    }
  }, [
    account.state
      .username /* If auth token exists in browser and user is returning to site. */,
    auth.state
      .isAuthenticated /* If user is at site unauthenticated and logs in. */,
  ]);

  // console.log("Account state:", account.state);

  return (
    <>
      <div className="nav u-no-select">
        <div className="nav__left">
          <div className="nav__left__brand">
            <LinkTop
              to={
                auth.state.isAuthenticated
                  ? routes.profile.path + account.state.username
                  : routes.landing.path
              }
              className="nav__left__brand--title">
              <div className="u-mgn-right-sm">
                <Heading number={2} ko={true}>
                  Conservationist.io
                </Heading>
              </div>
            </LinkTop>
            <Heading number={2} ko={true} bold={false} color="secondary">
              {navbar.state.subtitle}
            </Heading>
          </div>
          {navbar.state.isAppPage === false && (
            <div className="nav__left__options">{navbarDropdownButtons}</div>
          )}
        </div>
        <div className="nav__right">
          {navbar.state.isAppPage && (
            <>
              <div className="nav__right__element-container nav__right__element-container--icon">
                <Dropdown
                  title={<Icon icon="apps" size="lg" ko={true} />}
                  justified="right">
                  <div className="nav__apps">
                    <Menu
                      options={[
                        {
                          jsx: (
                            <div className="u-flex u-flex-align-center u-hover-color-yellow">
                              <Heading
                                number={4}
                                ko={true}
                                className="u-mgn-right-sm">
                                CM
                              </Heading>
                              <Text number={1} ko={true}>
                                Collaborative Mapper
                              </Text>
                            </div>
                          ),
                          link: `${utils.baseURL}apps/collaborativemapper/dashboard`,
                        },
                        {
                          jsx: (
                            <div className="u-flex u-flex-align-center u-hover-color-yellow">
                              <Heading
                                number={4}
                                ko={true}
                                className="u-mgn-right-sm">
                                HP
                              </Heading>
                              <Text number={1} ko={true}>
                                Habitat Patrol
                              </Text>
                            </div>
                          ),
                          link: `${utils.baseURL}apps/habitatpatrol`,
                        },
                      ]}
                      dark={true}
                    />
                  </div>
                </Dropdown>
              </div>
              <div className="nav__right__element-container nav__right__element-container--icon u-mgn-right-sm">
                <Dropdown
                  title={<Icon icon="help" ko={true} size="lg" />}
                  justified="right">
                  <div className="nav__help">
                    <Menu
                      options={[
                        {
                          jsx: (
                            <div className="u-flex u-flex-align-center u-hover-color-yellow">
                              <Text number={1} ko={true}>
                                Report a bug
                              </Text>
                            </div>
                          ),
                          link: `/support/contact`,
                          valueSubject: "Reporting a bug",
                        },
                      ]}
                      dark={true}
                    />
                  </div>
                </Dropdown>
              </div>
            </>
          )}
          {auth.state.isAuthenticated ? (
            <>
              {/* <NavbarNotificationsWIP /> */}
              {/* Account dropdown */}
              <div className="nav__right__element-container nav__right__element-container--icon">
                <Dropdown
                  title={<Icon icon="profile" size="lg" ko={true} />}
                  justified="right">
                  <div className="nav__profile">
                    <div className="u-flex u-flex-justify-start u-flex-align-center u-pad-sm">
                      <div className="nav__profile--img">
                        <img
                          src={`/${account.state.photo}`}
                          alt="Profile picture"
                          className="u-thumb-md"
                        />
                      </div>
                      <div>
                        <Heading number={5} color="secondary">
                          {account.state.username}
                        </Heading>
                        <div className="nav__profile--email">
                          <Text number={1}>{account.state.email}</Text>
                        </div>
                        <Text number={1}>
                          <LinkTop
                            className="hyperlink u-hover-underline"
                            to={`/profile/${account.state.username}`}>
                            Visit Profile
                          </LinkTop>
                        </Text>
                      </div>
                    </div>
                    <div className="nav__log-out">
                      <Button
                        buttontype="text"
                        buttonProps={{ onClick: logOut }}>
                        Log Out
                      </Button>
                    </div>
                  </div>
                </Dropdown>
              </div>
            </>
          ) : (
            <>
              <div className="nav__right__element-container">
                <Button
                  buttonProps={{
                    onClick: () => {
                      navbar.setState({
                        ...navbar.state,
                        isLogInModalOpen: true,
                      });
                    },
                  }}
                  buttontype="text"
                  className="nav__right__button"
                  ko={true}>
                  Log In
                </Button>
              </div>
              <div className="nav__right__element-container">
                <Button
                  buttonProps={{
                    onClick: () => {
                      navbar.setState({
                        ...navbar.state,
                        isSignUpModalOpen: true,
                      });
                    },
                  }}
                  buttontype="text"
                  className="nav__right__button"
                  ko={true}>
                  Sign Up
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      <NavbarDropdownMenu menuContent={menuContent} navbar={navbar} />

      {/* AUTHENTICATION MODALS */}
      <NavbarLogInModal auth={auth} navbar={navbar} account={account} />
      <NavbarResetPasswordModal navbar={navbar} />
      <NavbarRetrieveUsernameModal navbar={navbar} />
      <NavbarSignUpModal navbar={navbar} />
      <NavbarSignUpEmailMessageModal navbar={navbar} />
    </>
  );
}
