import { Heading, LinkTop, Text } from "../components";
import React from "react";

export default function SectionsPageTemplate({ contentType, sectionsData }) {
  const tableOfContents = sectionsData.sections.map((section, index) => {
    return (
      <a
        key={index}
        href={`#${section.heading.main.replace(/\s+/g, "")}`}
        style={{ textDecoration: "none" }}>
        <div className="sections-page-template__table-of-contents--element--container">
          <Heading number={5} ko={true}>
            {section.heading.main}
          </Heading>
        </div>
      </a>
    );
  });

  let sections;
  if (contentType === "text") {
    sections = sectionsData.sections.map((section, index) => {
      const content = section.content.map((paragraph, index) => {
        return (
          <div key={index} className="u-pad-vert-sm">
            <Text number={2}>{paragraph}</Text>
          </div>
        );
      });

      return (
        <div
          key={index}
          id={section.heading.main.replace(/\s+/g, "")}
          className="sections-page-template__section-top">
          <div className="u-pad-top-md">
            <Heading number={3} color="secondary" bold={false}>
              {section.heading.main}
            </Heading>
          </div>
          <Heading number={5}>{section.heading.sub}</Heading>
          <div>{content}</div>
        </div>
      );
    });
  }

  if (contentType === "links") {
    sections = sectionsData.sections.map((section, index) => {
      const subSections = section.subSections.map((subSection, index) => {
        const links = subSection.links.map((link, index) => {
          let actionElement;
          switch (link.actionType) {
            case "external link":
              actionElement = (
                <a
                  className="hyperlink u-hover-underline"
                  href={link.linkPath}
                  rel="noopener noreferrer"
                  target="_blank">
                  {link.label}
                </a>
              );
              break;
            case "internal link":
              actionElement = (
                <LinkTop className="hyperlink u-hover-underline" to={link.to}>
                  {link.label}
                </LinkTop>
              );
              break;
            case "none":
              actionElement = <div>{link.label}</div>;
              break;
            default:
              actionElement = <></>;
          }
          return (
            <div key={index} className="u-pad-vert-sm">
              {actionElement}
            </div>
          );
        });
        return (
          <div
            key={index}
            className="sections-page-template__links--subsection--container u-pad-vert-sm">
            <div className="sections-page-template__links--subsection--heading">
              <Heading number={5}>{subSection.subHeading}</Heading>
            </div>
            {links}
          </div>
        );
      });

      return (
        <div
          key={index}
          id={section.heading.main.replace(/\s+/g, "")}
          className="sections-page-template__section-top">
          <div className="u-pad-top-md">
            <Heading number={3} color="secondary" bold={false}>
              {section.heading.main}
            </Heading>
          </div>
          <div className="sections-page-template__links--subsections--container">
            {subSections}
          </div>
        </div>
      );
    });
  }

  return (
    <>
      {/* <div className="sections-page-template__banner"></div> */}
      <div className="sections-page-template__page--container">
        <div className="sections-page-template__table-of-contents">
          {tableOfContents}
        </div>
        <div className="sections-page-template__page--left"></div>
        <div className="sections-page-template__page--right">
          <div className="u-pad-btm-sm">
            <Heading number={2}>{sectionsData.title}</Heading>
          </div>
          <div className="u-pad-vert-sm">
            <Heading number={5}>
              Last Updated: {sectionsData.lastUpdated}
            </Heading>
          </div>
          {sections}
        </div>
      </div>
    </>
  );
}
