import { TextEditField } from "..";
import React, { useState } from "react";
import { SGTemplate } from ".";

export default function SGTextEditField() {
  const initState = {
    editFieldName1: "Default value",
    editFieldName2: "Default value",
  };
  const [state, setState] = useState(initState);
  function updateInputEditField(e) {
    setState({ ...state, [e.elementName]: e.updatedValue });
  }

  const content = {
    name: "",
    variantName: "TextEditField",
    description: `When inactive, this component displays JSX. When a user clicks the pencil icon to edit, they can manually change the value displayed in the inactive state.`,
    implementation:
      "Used for text area values you want the user to be able to edit.",
    propsTable: [
      [
        "inputValue",
        "string",
        `any string`,
        "yes",
        "Will populate the input field when in editing mode.",
      ],
      [
        "name",
        "string",
        "any string",
        "yes",
        "To be used as an ID. If using multiple edit fields, it is key that they have different names so that values can be properly updated.",
      ],
      [
        "updateValueCB",
        "function",
        `Function will be invoked like an event`,
        "yes",
        `The provided function should take a single argument when defined. The argument will be an object containing the original value, the changed value and the input name. This function is invoked when the user clicks "Done".`,
      ],
      [
        "ko",
        "Boolean",
        `true, false`,
        "no",
        `Set to true when the component is used over a dark background. Defaults to false.`,
      ],
      [
        "children",
        "JSX input attributes",
        `Any valid JSX`,
        "yes",
        `InputEditField is not a self-closing component. Wrap any desired content within the open and close tags. This content will be rendered when the input is not in "edit mode".`,
      ],
    ],
    codeExample: `
    import { TextEditField } from "/components";
    state = {
      editFieldName: "Some name"
    }
    function updateValueCB(e) {
      const { inputValue, updatedValue, elementName }
      setState({...state, editFieldName: updatedValue})
    }
     
    <TextEditField 
      inputValue={state.editFieldName} 
      ko={false} 
      updateValueCB={updateInputEditField} 
      name="editFieldName">
      <p>
        {state.editFieldName}
      </p>
    </TextEditField>
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-pad-sm">
            <div style={{ width: "30rem" }}>
              <TextEditField
                inputValue={state.editFieldName1}
                ko={false}
                updateValueCB={updateInputEditField}
                name="editFieldName1">
                <p className="paragraph__1">{state.editFieldName1}</p>
              </TextEditField>
            </div>
          </div>

          <div className="u-pad-sm u-background-darkblue">
            <div style={{ width: "30rem" }}>
              <TextEditField
                inputValue={state.editFieldName2}
                ko={true}
                updateValueCB={updateInputEditField}
                name="editFieldName2">
                <p className="paragraph__1 paragraph__1--ko">
                  {state.editFieldName2}
                </p>
              </TextEditField>
            </div>
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
