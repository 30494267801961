import React, { useEffect, useState } from "react";

/* Components */
import { Pagination, Heading, Table, TableBody } from "../../../components";
import ProjectsListColumnTitles from "./ProjectsListColumnTitles";
import ProjectsListElement from "./ProjectsListElement";
import LoadingGraphic from "./LoadingGraphic";

export default function ProjectsListTable({ account, dashboard }) {
  const projectsPerPage = 5,
    projectsListTableStateInit = {
      currentPage: 1,
      projectsDataArraySlice: [],
    },
    [projectsListTableState, setProjectsListTableState] = useState(
      projectsListTableStateInit,
    );

  /* Loads the initial map data entries to be shown on the table. */
  useEffect(() => {
    setProjectsListTableState({
      ...projectsListTableState,
      currentPage: 1,
      projectsDataArraySlice: dashboard.state.projectsDataArray.slice(
        0,
        projectsPerPage,
      ),
    });
  }, [dashboard.state.projectsDataArray]);

  function setDisplayProjects(pageNumber) {
    const indexOfLastProject = pageNumber * projectsPerPage,
      indexOfFirstProject = indexOfLastProject - projectsPerPage;
    setProjectsListTableState({
      ...projectsListTableState,
      currentPage: pageNumber,
      projectsDataArraySlice: dashboard.state.projectsDataArray.slice(
        indexOfFirstProject,
        indexOfLastProject,
      ),
    });
  }

  useEffect(() => {
    if (dashboard.state.projectsDataArray.length === 0 && account.state.id) {
      dashboard.methods.getAndSetCvmProjectsData();
    }
  }, [account.state.id]);

  // console.log("projectsListTableState: ", projectsListTableState);

  return (
    <>
      {dashboard.state.projectsDataArray.length === 0 &&
        dashboard.state.isProjectsDataChecked && (
          <Heading className="u-pad-vert-lg" number={5}>
            There are no projects to display.
          </Heading>
        )}
      {/* Loading graphic */}
      {dashboard.state.projectsDataArray.length === 0 &&
        !dashboard.state.isProjectsDataChecked && (
          <div className="cm-dash__maps-list-table__container">
            <LoadingGraphic />
          </div>
        )}
      {/* Display of Public Maps data formatted as table rows. */}
      {dashboard.state.projectsDataArray.length > 0 && (
        <div className="cvm__projects-list-table__container">
          <Table>
            <ProjectsListColumnTitles />
            <TableBody>
              {projectsListTableState.projectsDataArraySlice.map(
                (projectDataEntry) => (
                  <ProjectsListElement
                    dashboard={dashboard}
                    key={projectDataEntry.id}
                    projectDataEntry={projectDataEntry}
                  />
                ),
              )}
            </TableBody>
          </Table>
        </div>
      )}
      {dashboard.state.projectsArrayLength !== 0 && (
        <div className="u-mgn-top-sm u-flex u-flex-justify-end">
          <Pagination
            postsPerPage={projectsPerPage}
            totalPosts={dashboard.state.projectsDataArray.length}
            paginate={setDisplayProjects} // Change "page" being displayed
            activeNumber={projectsListTableState.currentPage}
          />
        </div>
      )}
    </>
  );
}
