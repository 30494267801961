import { Button, Heading, Icon, Text } from "../../components";
import React from "react";
import routes from "../../utils/paths/routes";

export default function BetaTestingSection() {
  return (
    <div className="beta-testing-section" id="betatesting">
      <div className="beta-testing-section__container">
        <div className="beta-testing-section__left--container u-textbox-lg">
          <div>
            <Heading number={2}>Interested?</Heading>
            <Heading number={2}>Become a beta tester.</Heading>
            <br />
            <Heading bold={false} number={5} className="u-color-darkgray">
              Choose how your feedback shapes the platform through participation
              tailored to you and your interests.
            </Heading>
          </div>
          <div>
            <Heading number={4} bold={false}>
              Early access.
            </Heading>
            <br />
            <Heading number={4} bold={false}>
              Shape our apps.
            </Heading>
            <br />
            <Heading number={4} bold={false}>
              Make an impact.
            </Heading>
          </div>
        </div>
        <div className="beta-testing-section__right--container">
          <div className="beta-testing-section__right__cards--container">
            <div className="beta-testing-section__right__cards__element">
              <div className="beta-testing-section__right__cards__element--heading">
                <Heading ko={true} number={4}>
                  Testing Opportunities
                </Heading>
              </div>
              <div className="beta-testing-section__right__cards__element--description">
                <Heading className="u-pad-vert-sm" number={5}>
                  You can choose what to improve.
                </Heading>
                <Text number={1}>
                  We offer three types of beta-testing-section opportunities:
                </Text>
                <div className="u-pad-top-sm">
                  <div className="u-flex u-pad-vert-sm">
                    <Icon icon="success" />
                    <Text className="u-pad-horiz-sm" number={2}>
                      Complete user surveys and questionnaires.
                    </Text>
                  </div>
                  <div className="u-flex u-pad-vert-sm">
                    <Icon icon="success" />
                    <Text className="u-pad-horiz-sm" number={2}>
                      Participate in live interviews with our UX/UI Design Team.
                    </Text>
                  </div>
                  <div className="u-flex u-pad-vert-sm">
                    <Icon icon="success" />
                    <Text className="u-pad-horiz-sm" number={2}>
                      Test pre-release platform or apps features.
                    </Text>
                  </div>
                </div>
                <Text className="u-pad-vert-md" number={1}>
                  Please sign up to register and select what interests you.
                </Text>
              </div>
            </div>
          </div>
          <div className="beta-testing-section__right--button-container">
            <div className="beta-testing-section__right--button">
              <Button
                buttontype="primary"
                hashLinkTo={routes.betaTesting.path.learnMore}
                size="inherit">
                Learn More
              </Button>
            </div>
            <div className="beta-testing-section__right--button">
              <Button
                buttontype="primary"
                hashLinkTo={routes.betaTesting.path.signUp}
                size="inherit">
                Sign Up
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
