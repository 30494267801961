import React from "react";
import { useHistory } from "react-router-dom";

// Components
import { Button, Icon, Text, Tooltip } from "../../../components";

// Utilities
import APIreqs from "../helpers/API";
import routes from "../../../utils/paths/routes";
import utils from "../../../utils/utils";

function CreateMapButton({ account, auth }) {
  const history = useHistory();

  async function createNewProject() {
    async function postProject() {
      try {
        const acccountID = account.state.id,
          postProjectRes = await APIreqs.projects.post(acccountID),
          projectID = postProjectRes.data;
        return projectID;
      } catch {
        utils.sendAlert(
          "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'post CVM project' error.",
          "error",
        );
      }
    }

    async function postMap(projectID) {
      try {
        const res = await APIreqs.maps.post(projectID);
        return res;
      } catch {
        utils.sendAlert(
          "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'post CVM map' error.",
          "error",
        );
      }
    }

    const projectID = await postProject(),
      postMapRes = await postMap(projectID);

    if (postMapRes.status === 200) {
      utils.sendAlert("Project created.", "success");
      history.push(`${routes.apps.cvmApp.subtitlePath}/${projectID}`);
    }
  }

  // JSX
  const buttonContent = (
    <div className="u-flex u-flex-column u-flex-justify-center u-flex-align-center cm-dash__create">
      <Icon icon="plus" ko={true} size="lg" />
      <Text className="u-pad-top-sm" ko={true} number={1}>
        New Project
      </Text>
    </div>
  );

  return (
    <div className="u-mgn-vert-md">
      {auth.state.isAuthenticated ? (
        <Button
          buttontype="primary"
          buttonProps={{
            onClick: () => {
              createNewProject();
            },
          }}
          className={"u-hover-color-yellow"}>
          {buttonContent}
        </Button>
      ) : (
        <Tooltip
          body={
            <Text number={1} ko={true}>
              Please log in to create a project.
            </Text>
          }>
          <Button
            buttontype="primary"
            className="cm-dash__create-map-button-icon-disable"
            disabled={true}>
            {buttonContent}
          </Button>
        </Tooltip>
      )}
    </div>
  );
}

export default CreateMapButton;
