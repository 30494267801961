import React, { useState } from 'react'

// Components
import {
  Button,
  Heading,
  InputSliderH,
  ModalClose,
  Select,
  Text,
  Tooltip,
} from '../../../../../../../../components'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import ValRstPlot from './ValRstPlot'

// Utilities
import APIreqs from '../../../../../../helpers/API'
import utils from '../../../../../../../../utils/utils'

function ValRstPlotModal({ dataLayers, pcm }) {
  const { i } = pcm.state.activeModalData
  const valRstDataset = dataLayers.data[i].valRstDataset

  // Handles valuation function type.
  const valuationFnInit = valRstDataset
    ? valRstDataset.data.valFnType.value
    : 'lin'
  const [valuationFnType, setValuationFn] = useState(valuationFnInit)
  const valuationFnSelectorOptions = [
    {
      Linear: () => {
        setValuationFn('lin')
        setCoeffs(coeffsTypes.lin)
      },
    },
    {
      Logarithmic: () => {
        setValuationFn('log')
        setCoeffs(coeffsTypes.log)
      },
    },
    {
      Exponential: () => {
        setValuationFn('exp')
        setCoeffs(coeffsTypes.exp)
      },
    },
    {
      Step: () => {
        setValuationFn('step')
        setCoeffs(coeffsTypes.step)
      },
    },
  ]
  const valuationFnSelectorInit = {
    lin: 0,
    log: 1,
    exp: 2,
    step: 3,
  }

  // Handles valuation function coefficients based on function type.
  const [valFnCoeffA, valFnCoeffB, valFnCoeffC] = valRstDataset
    ? [
        Number(valRstDataset.data.valFnCoeffA.value),
        Number(valRstDataset.data.valFnCoeffB.value),
        Number(valRstDataset.data.valFnCoeffC.value),
      ]
    : [undefined, undefined, undefined]
  const [minVal, maxVal] = [
    dataLayers.data[i].raster.data.datasetMinVal.value,
    dataLayers.data[i].raster.data.datasetMaxVal.value,
  ]
  const coeffsTypes = {
    exp: {
      A: {
        min: -2,
        max: 2,
        step: 0.1,
        value: valFnCoeffA || 0.5,
      },
      B: {
        min: 0.1,
        max: 10,
        step: 0.1,
        value: valFnCoeffB || 5,
      },
      C: {
        min: -1,
        max: 1,
        step: 0.1,
        value: valFnCoeffC || 0,
      },
    },
    lin: {
      A: {
        min: -2,
        max: 2,
        step: 0.1,
        value: valFnCoeffA || 0.5,
      },
      B: { min: 0, max: 0, step: 0, value: valFnCoeffB || 0 },
      C: {
        min: -1,
        max: 1,
        step: 0.1,
        value: valFnCoeffC || 0,
      },
    },
    log: {
      A: { min: -2, max: 2, step: 0.1, value: valFnCoeffA || 0.5 },
      B: { min: 0.1, max: 10, step: 0.1, value: valFnCoeffB || 5 },
      C: { min: -1, max: 1, step: 0.1, value: valFnCoeffC || 0 },
    },
    step: {
      A: { min: -2, max: 2, step: 0.1, value: valFnCoeffA || 0.5 },
      B: {
        min: minVal,
        max: maxVal,
        step: 1,
        value: valFnCoeffB || minVal + Math.floor((maxVal - minVal) / 3),
      },
      C: { min: -1, max: 1, step: 0.1, value: valFnCoeffC || 0 },
    },
  }
  const [coeffs, setCoeffs] = useState(coeffsTypes[valuationFnInit])
  // console.log('coeffs: ', coeffs)

  // Handles valuation function color palette selections.
  const colorInit = valRstDataset
    ? {
        min: valRstDataset.data.colorPaletteMin.value,
        zero: valRstDataset.data.colorPaletteZero.value,
        max: valRstDataset.data.colorPaletteMax.value,
      }
    : { min: 'black', zero: 'gray', max: 'white' }
  const [colorPalette, setColorPalette] = useState(colorInit)
  const colorSelectorOptions = [
    {
      'Black / White': () => {
        setColorPalette({ ...colorPalette, min: 'black', max: 'white' })
      },
    },
    {
      'Blue / Orange': () => {
        setColorPalette({ ...colorPalette, min: 'blue', max: 'orange' })
      },
    },
    {
      'Purple / Yellow': () => {
        setColorPalette({ ...colorPalette, min: 'purple', max: 'yellow' })
      },
    },
    {
      'Green / Red': () => {
        setColorPalette({ ...colorPalette, min: 'green', max: 'red' })
      },
    },
  ]
  const colorSelectorInit = {
    black: 0,
    blue: 1,
    purple: 2,
    green: 3,
  }
  // console.log('colorsPalette: ', colorPalette)

  // Methods
  function openProcessValRstModal() {
    const valRstDatasetID = valRstDataset
      ? valRstDataset.data.ID.value
      : undefined

    pcm.setState({
      ...pcm.state,
      activeModal: `processValRstModal${i}`,
      activeModalData: {
        colorPalette,
        dataLayerID: dataLayers.data[i].id,
        fnCoeffs: {
          A: coeffs.A.value,
          B: coeffs.B.value,
          C: coeffs.C.value,
        },
        fnType: valuationFnType,
        i,
        valRstDatasetID,
      },
    })
  }

  function resetModal() {
    pcm.setState({
      ...pcm.state,
      activeModal: '',
      activeModalData: null,
    })
  }

  async function deleteValRstDataset() {
    try {
      const res = await APIreqs.valRasters.delete(
        dataLayers.data[i].valRstDataset.data.ID.value,
      )
      return res
    } catch {
      utils.sendAlert(
        "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'delete CVM valued raster for Remove button onClick' error.",
        'error',
      )
    }
  }

  async function processDeleteValRstDataset() {
    try {
      await deleteValRstDataset()
      const dataLayersData = dataLayers.data.map((dataLayer) => {
        if (
          dataLayer.valRstDataset &&
          dataLayer.valRstDataset.data.ID.value ===
            dataLayers.data[i].valRstDataset.data.ID.value
        ) {
          delete dataLayer.valRstDataset
        }
        return dataLayer
      })
      dataLayers.setData(dataLayersData)
    } catch {
      utils.sendAlert(
        "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'process delete CVM valued raster for Remove button onClick' error.",
        'error',
      )
    }
  }

  // JSX
  const coeffSlidersElements = Object.entries(coeffs).map(([key, value]) => {
    const expCondition = false
    const linCondition = valuationFnType === 'lin' && key === 'B'
    const logCondition = false
    const stepCondition = false
    const allConditions =
      linCondition || logCondition || expCondition || stepCondition
    const descriptor = {
      A: 'Amplitude',
      B: 'Curvature',
      C: 'Offset',
    }

    return (
      <div className='u-mgn-btm-sm' key={key}>
        <div>
          <Text number={1}>
            <b>{descriptor[key]}</b>
          </Text>
        </div>
        <div className='u-flex u-flex-align-center'>
          <div className='cvm__valuation-fn__attrs__coeff-label'>
            <Text number={1}>
              {key}: {coeffs[key].value}
            </Text>
          </div>
          <InputSliderH
            disabled={allConditions}
            min={value.min}
            max={value.max}
            step={value.step}
            value={value.value}
            onChange={(e) => {
              setCoeffs({
                ...coeffs,
                [key]: {
                  ...coeffs[key],
                  value: Number(e.target.value),
                },
              })
            }}
          />
        </div>
      </div>
    )
  })

  return (
    <Modal
      centered={true}
      isOpen={pcm.state.activeModal === `valRstPlotModal${i}`}
      size='xl'
      toggle={() => {}}>
      <ModalHeader>
        Generate Conservation Value Dataset
        <ModalClose cb={resetModal} />
      </ModalHeader>
      <ModalBody>
        <div className='u-pad-md'>
          {/* Modal description */}
          <Heading className='u-mgn-btm-md' number={3} color='secondary'>
            Convert Your Uploaded Data Into A Raster Of Conservation ‘Values’
          </Heading>
          <div className='u-mgn-btm-md'>
            <Heading number={5}>
              Please choose a function and coefficients that represent how you
              think this variable should be prioritized.
            </Heading>
            <Text number={1}>
              The graph displays how values of the original data on the x-axis
              will be converted to conservation value on the y-axis. The output
              valued dataset can be further analyzed and combined with other
              valued datasets to produce a comprehensive conservation value
              analysis.
            </Text>
          </div>

          {/* Valuation Function Color Selectors */}
          <div className='u-pad-btm-md'>
            <Heading className='u-mgn-btm-sm' color='secondary' number={4}>
              Define Your Conservation Valuation Curve
            </Heading>
          </div>

          {/* Valuation Function Coefficient Selectors and Graph */}
          <div className='u-flex'>
            <div className='u-flex u-flex-column u-flex-justify-center cvm__valuation-fn__attrs__container'>
              <div className='cvm__valuation-fn__attrs__selectors__container'>
                <div className='u-flex u-full-width u-flex-all-center'>
                  <Heading number={5}>Type</Heading>
                </div>
                <Select
                  defaultIndex={valuationFnSelectorInit[valuationFnInit]}
                  hashObjects={valuationFnSelectorOptions}
                />
                <div className='u-mgn-vert-md'>
                  <div className='u-flex u-full-width u-flex-all-center'>
                    <Heading number={5}>Coefficients</Heading>
                  </div>
                  {coeffSlidersElements}
                </div>
              </div>
              <div className='u-flex u-flex-column cvm__valuation-fn__attrs__selectors__container'>
                <div className='u-flex u-full-width u-flex-all-center'>
                  <Heading number={5}>Color Palette</Heading>
                </div>
                <Select
                  hashObjects={colorSelectorOptions}
                  defaultIndex={colorSelectorInit[colorInit.min]}
                />
              </div>
            </div>
            <div className='u-flex u-flex-all-center cvm__valuation-fn__graph__container'>
              <ValRstPlot
                coeffs={coeffs}
                colorPalette={colorPalette}
                dataLayers={dataLayers}
                i={i}
                valuationFnType={valuationFnType}
              />
            </div>
          </div>

          {/* Modal Buttons */}
          <div className='u-mgn-top-lg u-flex u-flex-justify-end'>
            <div className='map__modal-button-container'>
              <Button
                buttontype='secondary'
                buttonProps={{
                  onClick: resetModal,
                }}
                size='inherit'>
                Cancel
              </Button>
            </div>
            <Tooltip body='Remove current valuation function to revert to original dataset.'>
              <div className='map__modal-button-container u-mgn-left-sm'>
                <Button
                  buttontype='primary'
                  buttonProps={{
                    onClick: async () => {
                      await processDeleteValRstDataset()
                      resetModal()
                    },
                  }}
                  disabled={!dataLayers.data[i].valRstDataset}
                  size='inherit'>
                  Remove
                </Button>
              </div>
            </Tooltip>
            <Tooltip body='Apply valuation function to generate conservation values.'>
              <div className='map__modal-button-container u-mgn-left-sm'>
                <Button
                  buttontype='primary'
                  buttonProps={{
                    onClick: openProcessValRstModal,
                  }}
                  size='inherit'>
                  Apply
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ValRstPlotModal
