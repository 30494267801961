import React from 'react'

// Components
import {
  Button,
  Heading,
  ModalClose,
  Text,
} from '../../../../../../../../components'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import AttributesTable from '../../../../../../components/AttributesTable'

function MetadataModal({ dataLayers, i, pcm }) {
  function resetModal() {
    pcm.setState({
      ...pcm.state,
      activeModal: '',
      activeModalData: null,
    })
  }

  const rasterMetadataTableObj = {
    attrsArr: [
      'dataType',
      'dataCRS',
      'numOfBands',
      'noDataVal',
      'bounds',
      'centerCoords',
      'height',
      'width',
      'pixelCount',
      'pixelResolutionEPSG4326',
      'pixelResolutionEPSG3857',
    ],
    title: 'Raster Dataset Metadata Table',
    subTitle:
      'These values describe the raster dataset associated with this data layer.',
  }

  const filesMetadataTableObj = {
    attrsArr: [
      'dataFileName',
      'dataFileSize',
      'originalDataCRS',
      'imgFileName',
      'imgFileSize',
    ],
    title: 'Files Metadata Table',
    subTitle: 'These values describe files associated with this data layer.',
  }

  return (
    <Modal
      key={dataLayers.data[pcm.state.activeModalData.i].label}
      centered={true}
      size='xl'
      isOpen={pcm.state.activeModal === `metadataModal${i}`}
      toggle={() => {}}>
      <ModalHeader>
        Data Layers
        <ModalClose cb={resetModal} />
      </ModalHeader>
      <ModalBody>
        <div className='u-pad-md'>
          <Heading className='' number={3} color='secondary'>
            Metadata
          </Heading>
          <div className='u-pad-top-md'>
            <Text className='u-pad-top-md u-pad-btm-sm' number={1}>
              The following tables contain the metadata associated with the{' '}
              <b>{dataLayers.data[pcm.state.activeModalData.i].label}</b> data
              layer.
            </Text>
          </div>
          <AttributesTable
            attrs={rasterMetadataTableObj.attrsArr}
            data={dataLayers.data[pcm.state.activeModalData.i].raster.data}
            subTitle={rasterMetadataTableObj.subTitle}
            title={rasterMetadataTableObj.title}
          />
          <AttributesTable
            attrs={filesMetadataTableObj.attrsArr}
            data={dataLayers.data[pcm.state.activeModalData.i].raster.data}
            subTitle={filesMetadataTableObj.subTitle}
            title={filesMetadataTableObj.title}
          />
          <div className='u-mgn-top-lg u-flex u-flex-justify-end'>
            <div className='map__modal-button-container'>
              <Button
                buttontype='primary'
                buttonProps={{
                  onClick: () => {
                    resetModal()
                  },
                }}
                size='inherit'>
                Close
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default MetadataModal
