import React, { useEffect, useState } from "react";

/* Components */
import {
  Pagination,
  Heading,
  Table,
  TableBody,
} from "../../../../../components";
import MapsListColumnTitles from "./MapsListColumnTitles";
import MapsListElement from "./MapsListElement";
import MapsListLoadingGraphic from "./MapsListLoadingGraphic";

/* Methods */
import API from "../../../API";
import utils from "../../../../../utils/utils";

export default function MapsListTable({ account, cm }) {
  /* Defines the parameters that show the map data in the table, 
  and stores the `slice` of data entries from the entire data array
  that is shown on the table at any given time.  */
  const mapsPerPage = 5,
    mapsListTableStateInit = {
      currentPage: 1,
      myMapsDataArraySlice: [],
      publicMapsDataArraySlice: [],
    },
    [mapsListTableState, setMapsListTableState] = useState(
      mapsListTableStateInit,
    );

  /* Loads the initial map data entries to be shown on the table. */
  useEffect(() => {
    setMapsListTableState({
      ...mapsListTableState,
      myMapsDataArraySlice: cm.state.myMapsDataArray.slice(0, mapsPerPage),
      publicMapsDataArraySlice: cm.state.publicMapsDataArray.slice(
        0,
        mapsPerPage,
      ),
    });
  }, [cm.state.myMapsDataArray, cm.state.publicMapsDataArray]);

  /* Updates `mapsListTableState` with current slice of data entries
  to be shown on the table. */
  function setDisplayMaps(pageNumber) {
    const indexOfLastMap = pageNumber * mapsPerPage,
      indexOfFirstMap = indexOfLastMap - mapsPerPage;
    if (cm.state.mapsListSelection === "My Maps") {
      setMapsListTableState({
        ...mapsListTableState,
        currentPage: pageNumber,
        myMapsDataArraySlice: cm.state.myMapsDataArray.slice(
          indexOfFirstMap,
          indexOfLastMap,
        ),
      });
    }
  }

  /* Retrieves the Public Maps data from the db and stores in an array
  in `cm.state`. */
  useEffect(() => {
    if (
      cm.state.publicMapsDataArray.length === 0 &&
      cm.state.mapsListSelection === "Public Maps"
    ) {
      API.getDashMaps("public_maps", "")
        .then((res) => {
          if (res.data.length > 0) {
            cm.setState({
              ...cm.state,
              publicMapsDataArray: res.data,
              selectedDataArrayLength: res.data.length,
            });
          } else {
            cm.setState({
              ...cm.state,
              isPublicMapsDataChecked: true,
            });
          }
        })
        .catch(() => {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'get CM public maps' error.",
            "error",
          );
        });
    }
  }, [cm.state.publicMapsDataArray]);

  /* Retrieves the My Maps data from the db and stores in an array
  in `cm.state`. */
  useEffect(() => {
    if (
      cm.state.myMapsDataArray.length === 0 &&
      cm.state.mapsListSelection === "My Maps" &&
      account.state.id !== null
    ) {
      API.getDashMaps("my_maps", account.state.id)
        .then((res) => {
          if (res.data.length > 0) {
            cm.setState({
              ...cm.state,
              myMapsDataArray: res.data,
              selectedDataArrayLength: res.data.length,
            });
          } else {
            cm.setState({
              ...cm.state,
              isMyMapsDataChecked: true,
            });
          }
        })
        .catch(() => {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'get CM my maps' error.",
            "error",
          );
        });
    }
  }, [cm.state.myMapsDataArray]);

  // console.log("mapsListTableState: ", mapsListTableState);

  return (
    <>
      {/* Message if no Public Maps data exists. */}
      {cm.state.publicMapsDataArray.length === 0 &&
        cm.state.mapsListSelection === "Public Maps" &&
        cm.state.isPublicMapsDataChecked && (
          <Heading className="u-pad-vert-lg" number={5}>
            There are no public maps to display.
          </Heading>
        )}
      {/* Message if no My Maps data exists. */}
      {cm.state.myMapsDataArray.length === 0 &&
        cm.state.mapsListSelection === "My Maps" &&
        cm.state.isMyMapsDataChecked && (
          <Heading className="u-pad-vert-lg" number={5}>
            There are no my maps to display.
          </Heading>
        )}

      {/* Loading graphic while Public Maps data is retrieved. */}
      {cm.state.publicMapsDataArray.length === 0 &&
        cm.state.mapsListSelection === "Public Maps" &&
        !cm.state.isPublicMapsDataChecked && (
          <div className="cm-dash__maps-list-table__container">
            <MapsListLoadingGraphic />
          </div>
        )}
      {/* Display of Public Maps data formatted as table rows. */}
      {cm.state.publicMapsDataArray.length > 0 &&
        cm.state.mapsListSelection === "Public Maps" && (
          <div className="cm-dash__maps-list-table__container">
            <Table>
              <MapsListColumnTitles />
              <TableBody>
                {mapsListTableState.publicMapsDataArraySlice.map(
                  (mapDataEntry, index) => (
                    <MapsListElement key={index} mapDataEntry={mapDataEntry} />
                  ),
                )}
              </TableBody>
            </Table>
          </div>
        )}
      {/* Loading graphic while My Maps data is retrieved. */}
      {cm.state.myMapsDataArray.length === 0 &&
        cm.state.mapsListSelection === "My Maps" &&
        !cm.state.isMyMapsDataChecked && (
          <div className="cm-dash__maps-list-table__container">
            <MapsListLoadingGraphic />
          </div>
        )}
      {/* Display of My Maps data formatted as table rows. */}
      {cm.state.myMapsDataArray.length > 0 &&
        cm.state.mapsListSelection === "My Maps" && (
          <div className="cm-dash__maps-list-table__container">
            <Table>
              <MapsListColumnTitles />
              <TableBody>
                {mapsListTableState.myMapsDataArraySlice.map(
                  (mapDataEntry, index) => (
                    <MapsListElement key={index} mapDataEntry={mapDataEntry} />
                  ),
                )}
              </TableBody>
            </Table>
          </div>
        )}

      {cm.state.selectedDataArrayLength !== 0 && (
        <div className="u-mgn-top-sm u-flex u-flex-justify-end">
          <Pagination
            postsPerPage={mapsPerPage}
            totalPosts={cm.state.selectedDataArrayLength}
            paginate={setDisplayMaps} // Change "page" being displayed
            activeNumber={mapsListTableState.currentPage}
          />
        </div>
      )}
    </>
  );
}
