import API from "./API";
import { Heading, Loading } from "../components";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import routes from "../utils/paths/routes";
import staticLink from "../utils/static";
import utils from "../utils/utils";

export default function Activate() {
  const activateStateInit = {
      activating: true,
      error: false,
      username: "",
    },
    [activateState, setActivateState] = useState(activateStateInit);

  useEffect(() => {
    const queryObj = utils.queryParamsToObj(
        window.location.search.substring(1),
      ),
      { uid, token } = queryObj,
      req = { uid, token };

    API.activate(req)
      .then((res) => {
        if (res.data.error) {
          setActivateState({
            ...activateState,
            activating: false,
            error: true,
          });
        } else {
          setActivateState({
            ...activateState,
            username: res.data.username,
            activating: false,
          });
        }
      })
      .catch(() => {
        utils.sendAlert(
          "Something went wrong.  Please use the contact form to report a bug.",
          "error",
        );
      });
  }, []);

  const history = useHistory();
  if (activateState.error) {
    setTimeout(() => {
      history.push({ pathname: routes.landing.path });
    }, 10000);
  }
  if (!activateState.activating && !activateState.error) {
    setTimeout(() => {
      history.push({ pathname: routes.landing.path, isLogInModalOpen: true });
    }, 10000);
  }

  // console.log("Activate state: ", activateState);

  return (
    <>
      {activateState.activating && (
        <div className="u-flex-all-center u-flex-column u-mgn-top-lg">
          <Heading number={2} className="u-mgn-btm-md">
            Activating account...
          </Heading>
          <Loading size="lg" />
        </div>
      )}
      {!activateState.activating && activateState.error && (
        <div className="password-reset">
          <img
            src={`${staticLink}banner/Email-Banner.png`}
            alt=""
            className="password-reset__banner"
          />
          <div className="password-reset__content">
            <Heading number={2}>Error</Heading>
            <div className="u-mgn-top-sm u-mgn-btm-md">
              <Heading number={4} color="secondary">
                This account activation link has expired. Redirecting to the
                landing page.
              </Heading>
            </div>
          </div>
        </div>
      )}
      {!activateState.activating && !activateState.error && (
        <div className="password-reset">
          <img
            src={`${staticLink}banner/Email-Banner.png`}
            alt=""
            className="password-reset__banner"
          />
          <div className="password-reset__content">
            <Heading number={2}>Welcome, {activateState.username}.</Heading>
            <div className="u-mgn-top-sm u-mgn-btm-md">
              <Heading number={4} color="secondary">
                Your account has been activated. Redirecting to the landing page
                where you can log in.
              </Heading>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
