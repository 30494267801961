import {
  Popover as PopoverReactstrap,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import React, { useEffect, useRef, useState } from "react";
import { Text } from "../components";
import _uniqueId from "lodash/uniqueId";

export default function Popover({
  bodyHtmlObjects,
  bodyText = "",
  headerText = "",
  id = _uniqueId("popover-"),
  ...props
}) {
  const popoverStateInit = { id: id, isOpen: false },
    [popoverState, setPopoverState] = useState(popoverStateInit);

  const toggle = () => {
    setPopoverState({ ...popoverState, isOpen: !popoverState.isOpen });
  };

  const body = bodyHtmlObjects ? (
    bodyHtmlObjects
  ) : (
    <Text ko={true} number={2}>
      {bodyText}
    </Text>
  );

  const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        setPopoverState((state) => {
          return { ...state, isOpen: false };
        });
      }
    }

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      <div ref={ref} className="c-popover__children" id={popoverState.id}>
        {props.children}
      </div>
      <PopoverReactstrap
        {...props}
        isOpen={popoverState.isOpen}
        target={popoverState.id}
        toggle={toggle}>
        <PopoverHeader>
          <div className="u-pad-sm heading__5 heading__5--ko u-color-white">
            {headerText}
          </div>
        </PopoverHeader>
        <PopoverBody>
          <div className="u-pad-sm">{body}</div>
        </PopoverBody>
      </PopoverReactstrap>
    </>
  );
}
