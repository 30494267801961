import { Button, Heading, Text } from "../../components";
import React from "react";

export default function CardApplication({
  buttonPath,
  imgPath,
  textMain,
  textSub,
}) {
  return (
    <div className="landing__application-card__container">
      <div className="landing__application-card__img__container">
        <img
          alt="application-logo"
          className="landing__application-card__img"
          src={imgPath}></img>
      </div>
      <div className="landing__application-card__text-main-container">
        <Heading number={5} color="primary">
          {textMain}
        </Heading>
      </div>
      <div className="u-pad-left-sm landing__application-card__text-sub-container">
        <Text number={2}>{textSub}</Text>
      </div>
      <div className="landing__application-card__button-container">
        <Button buttontype="text" linkTopTo={buttonPath} size={"lg"}>
          Learn More
        </Button>
      </div>
    </div>
  );
}
