import { LinkTop } from "../../components";
import React from "react";

export default function Footnote({ ko = false, links }) {
  const [linkClassName, spanClassName] = ko
    ? ["hyperlink--ko", "paragraph__2 paragraph__2--ko"]
    : ["hyperlink", "paragraph__2"];

  const linksElement = links.map((linkHash, index, array) => {
    const label = linkHash.label,
      [target, rel] = linkHash.newTab
        ? ["_blank", "noopener noreferrer"]
        : ["", ""];

    let linkElement;
    switch (linkHash.type) {
      case "external link":
        linkElement = (
          <a
            className={`${linkClassName} u-hover-underline`}
            href={linkHash.pathname}
            target={target}
            rel={rel} // prevents 'tabnabbing' attack.
          >
            {label}
          </a>
        );
        break;
      case "internal link":
        linkElement = (
          <LinkTop className={`${linkClassName} u-hover-underline`} to={linkHash.pathname}>
            {label}
          </LinkTop>
        );
        break;
      default:
        linkElement = <span className="paragraph__subtext">{label}</span>;
    }

    const spanElement =
      index !== array.length - 1 ? (
        <>{linkElement}&nbsp;&nbsp;/&nbsp;&nbsp;</>
      ) : (
        <>{linkElement}</>
      );

    return (
      <span key={index} className={spanClassName}>
        {spanElement}
      </span>
    );
  });

  return <>{linksElement}</>;
}
