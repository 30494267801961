import React from 'react'

// Components
import { Icon, Tooltip } from '../../../../../../../components'

// Utilities
import { getZoomFromBoundingBox } from '../../../../../helpers/map'

function FindImgBtn({ dataLayers, i, mapHandler }) {
  function moveAndZoomToImg() {
    mapHandler.setData({
      ...mapHandler.data,
      last_center_coords: dataLayers.data[i].raster.data.centerCoords.value,
      last_zoom: getZoomFromBoundingBox(
        dataLayers.data[i].raster.data.boundingBox.value,
      ),
    })
  }

  return (
    <div
      onClick={() => {
        moveAndZoomToImg()
      }}>
      <Tooltip body={`Find image on map.`}>
        <Icon icon='crosshairs' ko={true} size='md' />
      </Tooltip>
    </div>
  )
}

export default FindImgBtn
