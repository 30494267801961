import React from "react";
import { LinkTop } from ".";

export default function Menu({ options, dark, sm }) {
  let optionClasses = "menu__option";
  let menuClasses = "menu";
  if (dark) {
    menuClasses += " menu--dark";
    optionClasses += " menu__option--dark";
  }

  if (sm) {
    menuClasses += " menu--sm";
    optionClasses += " menu__option--sm";
  }

  return (
    <div className={menuClasses}>
      {options.map((option, i) => (
        <div
          className={`${optionClasses} ${
            option.active ? "menu__option--dark" : ""
          }`}
          key={i}
          onClick={option.onClick ? option.onClick : () => {}}>
          {option.link ? (
            option.link.startsWith("/") ? (
              <LinkTop
                style={{ textDecoration: "none" }}
                to={
                  option.valueSubject
                    ? {
                        pathname: option.link,
                        valueSubject: option.valueSubject,
                      }
                    : { pathname: option.link }
                }>
                {option.jsx}
              </LinkTop>
            ) : (
              <a
                target="_blank"
                href={option.link}
                style={{ textDecoration: "none" }}>
                {option.jsx}
              </a>
            )
          ) : (
            <div>{option.jsx}</div>
          )}
        </div>
      ))}
    </div>
  );
}
