import React from "react";

export default function ListItem({
  thumbnail,
  thumbnailRounded,
  ko,
  interactive,
  children,
  sm,
  lg,
}) {
  let imageStyle;
  if (thumbnailRounded) {
    imageStyle = "round";
  } else {
    imageStyle = "square";
  }

  let elementStyle = "list__item";
  if (ko) {
    elementStyle += " list__item--ko";
  }
  if (interactive) {
    elementStyle += " list__item--interactive";
  }

  let thumbnailClass = "list__item-thumbnail";
  if (sm) {
    elementStyle += " list__item--sm";
    imageStyle += " list__item-img--sm";
    thumbnailClass += " list__item-thumbnail--sm";
  }
  if (lg) {
    elementStyle += " list__item--lg";
    imageStyle += " list__item-img--lg";
    thumbnailClass += " list__item-thumbnail--lg";
  }

  let defaultImgClass = "";
  if (thumbnail) {
    if (thumbnail.includes("default")) {
      defaultImgClass = "list__item-img--default";
    }
  }

  return (
    <li className={elementStyle}>
      {thumbnail && (
        <div className={thumbnailClass}>
          <img
            alt="list img thumbnail"
            src={thumbnail.includes("media") ? `/${thumbnail}` : `${thumbnail}`}
            className={`list__item-img ${imageStyle} ${defaultImgClass}`}
          />
        </div>
      )}
      <div className="list__item--body">{children}</div>
    </li>
  );
}
