import React from "react";

export default function InputSliderH({ ko = false, ...props }) {
  const baseStyle = "input__slider-h";
  let koStyle = ko ? `${baseStyle}--ko` : "",
    disabledStyle = props.disabled && !ko ? `${baseStyle}--disabled` : "",
    disabledKoStyle = ko && props.disabled ? `${baseStyle}--ko--disabled` : "",
    className = `${baseStyle} ${koStyle} ${disabledStyle} ${disabledKoStyle}`;

  return <input {...props} className={className} type="range"></input>;
}
