import axios from "axios";

let config;
if (localStorage.getItem('token')) {
  config = {
    headers: {
      Authorization: `JWT ${localStorage.getItem('token')}`
    }
  }
} else {
  config = {}
}

const uploadHeaders = {
  headers: {
    Authorization: `JWT ${localStorage.getItem('token')}`,
    'content-type': 'multipart/form-data'
  }
}

const getCsrfToken = cname => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


const CsrfToken = getCsrfToken("csrftoken")
if (CsrfToken && !config.headers) {
  config = {
    headers: {
      'X-CSRFToken': CsrfToken
    }
  }
}
// Organize into sub-objects to match class-based views on the backend?
const API = {
  addCollaborator: data => axios.post(`/api/rrm/map_users`, data, config),
  addFeature: data => axios.post(`/api/rrm/features`, data, config),
  changeMapPic: (file, mapID, type) => axios.post(`/api/rrm/map_requests?id=${mapID}&type=${type}`, file, uploadHeaders),
  createMap: (type, data) => axios.post(`/api/rrm/map_requests?type=${type}`, data, config),
  decideOnEdit: data => axios.put(`/api/rrm/manage`, data, config),
  decideOnAdd: data => axios.post(`/api/rrm/manage`, data, config),
  decideOnDelete: (featureID, commentID, decision) => axios.delete(`/api/rrm/manage?featureID=${featureID}&commentID=${commentID}&decision=${decision}`, config),
  deleteCollaborator: data => {
    const allData = config
    allData["data"] = data
    return axios.delete(`/api/rrm/map_users`, allData)
  },
  deleteFeature: q => axios.delete(`/api/rrm/features${q}`, config),
  deleteMap: id => axios.delete(`/api/rrm/map_requests?id=${id}`, config),
  deleteMapComment: id => axios.delete(`/api/rrm/comments?id=${id}`, config),
  deleteFeatureComment: id => axios.delete(`/api/rrm/featurecomment?id=${id}`, config),
  deleteProposal: (DBID, type) => axios.delete(`/api/rrm/proposals?id=${DBID}&type=${type}`, config),
  deleteRaster: id => axios.delete(`/api/rrm/layers?id=${id}`, config),
  featureComment: data => axios.post(`/api/rrm/featurecomment`, data, config),
  forkMap: (mapID, acctID) => axios.post(`/api/rrm/fork?id=${mapID}&acctID=${acctID}`, config),
  GBIF: q => axios.get(`/api/rrm/gbif?q=${q}`),
  getDashMaps: (type, accountID) => axios.get(`/api/rrm/maps_requests?type=${type}&id=${accountID}`, config),
  getMapUsers: (id, type, query_string = "") => axios.get(`/api/rrm/map_users?id=${id}&type=${type}&query_string=${query_string}`, config),
  getMap: id => axios.get(`/api/rrm/map_requests?id=${id}`, config),
  getMapComments: id => axios.get(`/api/rrm/comments?id=${id}`, config),
  getMapFeatures: id => axios.get(`/api/rrm/features?id=${id}`, config),
  getMapHistory: id => axios.get(`/api/rrm/history?id=${id}`, config),
  getMapLayers: id => axios.get(`/api/rrm/layers?id=${id}`, config),
  getProposals: id => axios.get(`/api/rrm/proposals?id=${id}`, config),
  getSpeciesRange: species => axios.get(`/api/rrm/species_range?species=${species}`, config),
  postComment: data => axios.post(`/api/rrm/comments`, data, config),
  proposeEdit: data => axios.put(`/api/rrm/proposals`, data, config),
  proposeFeature: data => axios.post(`/api/rrm/proposals`, data, config),
  rasterComment: data => axios.post(`/api/rrm/layers`, data, config),
  setMapStatus: (id, status) => axios.put(`/api/rrm/status?id=${id}&new_status=${status}`, config),
  redoChange: data => axios.put(`/api/rrm/undoredo`, data, config),
  undoChange: data => axios.post(`/api/rrm/undoredo`, data, config),
  submitJustification: data => axios.post(`/api/rrm/metadata`, data, config),
  standardizeFeatures: (data, id) => axios.post(`/api/rrm/standardize?id=${id}`, data, config),
  updateFeature: data => axios.put(`/api/rrm/features`, data, config),
  updateThumbnail: (data, mapID) => axios.put(`/api/rrm/upload?mapID=${mapID}`, data, config),
  uploadFile: (data, mapID) => axios.post(`/api/rrm/upload?id=${mapID}`, data, uploadHeaders),
  updateLayerName: (old_dataset, new_dataset, mapID, userID) => axios.put(`/api/rrm/layers?old_dataset=${old_dataset}&new_dataset=${new_dataset}&id=${mapID}&userID=${userID}`),
  updateMapAttr: (attr, val, mapID) => axios.put(`/api/rrm/map_requests?attr=${attr}&val=${val}&id=${mapID}`, config)
}

export default API