import React from "react";
import { Heading, Loading } from "../../../components";

export default function LoadingGraphic() {
  return (
    <div className="u-full-width u-flex u-flex-justify-center u-flex-align-center u-space-around u-pad-vert-lg">
      <Loading />
      <Heading className="u-pad-left-md" color="secondary" number={2}>
        Loading, please wait.
      </Heading>
    </div>
  );
}
