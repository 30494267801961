import React from "react";
import { SGTemplate } from ".";

export default function SGInput() {
  const content = {
    name: "Input",
    description:
      "Input components rely on functionality of the <Input> html tag, or provide custom functionality in the same category of 'inputting' data from the UI into a component.",
  };
  return <SGTemplate name={content.name} description={content.description} />;
}
