// Utilities
import chroma from 'chroma-js'

export function generatePlotRangeArr(
    fnType,
    seriesArr,
    A,
    B,
    C,
    seriesMinVal,
    seriesMaxVal,
) {
    const fns = {
        exp: (seriesArr, A, B, C, seriesMinVal, seriesMaxVal) => {
            const c1 = seriesMaxVal - seriesMinVal
            const c2 = -(seriesMinVal /* + dataValuesOffset */) / c1
            return seriesArr.map(
                (seriesVal) =>
                    (A * (Math.exp(B * (seriesVal / c1 + c2)) - 1)) /
                    (Math.exp(B) - 1) +
                    C,
            )
        },
        lin: (seriesArr, A, B, C, seriesMinVal, seriesMaxVal) => {
            const c1 = seriesMaxVal - seriesMinVal
            const c2 = (-A * seriesMinVal) /* + dataValuesOffset */ / c1
            return seriesArr.map((seriesVal) => A * (seriesVal / c1) + c2 + C)
        },
        log: (seriesArr, A, B, C, seriesMinVal, seriesMaxVal) => {
            const c1 = seriesMaxVal - seriesMinVal
            const c2 = -(seriesMinVal /* + dataValuesOffset */) / c1
            return seriesArr.map(
                (seriesVal) =>
                    (A * Math.log(B * (seriesVal / c1 + c2) + 1)) / Math.log(B + 1) +
                    C,
            )
        },
        step: (seriesArr, A, B, C, seriesMinVal, seriesMaxVal) =>
            seriesArr.map((seriesVal) => (seriesVal > B ? A + C : C)),
    }
    return fns[fnType](seriesArr, A, B, C, seriesMinVal, seriesMaxVal)
}

export function generateColorsArr(rangeArr, colorPalette) {
    const selectedColorsArr = Object.keys(colorPalette).map((key) => {
        return colorPalette[key]
    })
    const colorScale = chroma.scale(selectedColorsArr)
    const rangeMinVal = -1
    const rangeMaxVal = 1
    return rangeArr.map((val) =>
        colorScale((val - rangeMinVal) / (rangeMaxVal - rangeMinVal)).hex(),
    )
}