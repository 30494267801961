import React from "react";

/* Components */
import { CollapsibleV } from "../../../../../../components";
import BaseLayers from "./BaseLayers";
import ReferenceLayers from "./ReferenceLayers";
import VectorLayers from "./VectorLayers";

export default function LayersSection({ app, parent }) {
  return (
    <>
      <CollapsibleV parent={parent} title="Layers">
        <div className="g__map__config-menu__section">
          <BaseLayers app={app} />
          <VectorLayers app={app} />
          <ReferenceLayers app={app} />
        </div>
      </CollapsibleV>
    </>
  );
}
