import React from "react";

/* Components */
import UserDataListItem from "./UserDataListItem";
import { List, Heading } from "../../../../../../components";

export default function Owner({ app }) {
  return (
    <>
      <Heading number={5} ko={true}>
        Owner
      </Heading>
      {app.state.creators.length > 0 && (
        <List>
          {app.state.creators.map((creator, index) => (
            <UserDataListItem key={index} user={creator} />
          ))}
        </List>
      )}
    </>
  );
}
