import React from "react";
import { Link } from "react-router-dom";
import {
  LinkTop,
  Icon,
  TableRow,
  TD,
  Text,
  Tooltip,
} from "../../../../../components";

export default function MapsListElement({ mapDataEntry }) {
  return (
    <TableRow key={mapDataEntry.id}>
      <TD>
        <div className="u-flex u-flex-align-center">
          <img
            className="cm-dash__thumb"
            src={mapDataEntry.thumbnail}
            alt="Map"
          />
          <div className="u-mgn-left-sm">
            <Link
              to={`/apps/collaborativemapper/map?id=${mapDataEntry.id}`}
              key={mapDataEntry.id}>
              <Text
                number={1}
                className={
                  "hyperlink u-hover-underline cm-dash__maps-list-table__text"
                }>
                {mapDataEntry.name}
              </Text>
            </Link>
          </div>
        </div>
      </TD>
      <TD>
        {mapDataEntry.creator.map((c) => (
          <LinkTop key={c.id} to={`/profile/${c.username}`}>
            <Text
              number={1}
              key={c.id}
              className={
                "hyperlink u-hover-underline cm-dash__maps-list-table__text"
              }>
              {c.username}
            </Text>
          </LinkTop>
        ))}
      </TD>
      {/* <TD>
        {mapDataEntry.editors.map((e) => (
          <LinkTop key={e.id} to={`/profile/${e.username}`}>
            <Text number={1} key={e.id} className={"hyperlink"}>
              {e.username},
            </Text>
          </LinkTop>
        ))}
      </TD> */}
      <TD>
        <Text number={1} className={"cm-dash__maps-list-table__text"}>
          {mapDataEntry.date_created.split("T")[0]}
        </Text>
      </TD>
      {mapDataEntry.dataset && (
        <TD>
          {/* TO BE ADDED when `fetch()` method is built to get data files */}
          {/* <Tooltip
            body={
              <Text number={1} ko={true}>
                Download map vector layers data in .JSON file format.
              </Text>
            }>
            <a
              href={mapDataEntry.dataset.split("/backend")[1]}
              download
              className="u-full-width u-flex u-flex-justify-center u-reset-text-decoration">
              <Icon icon="download" size="lg" />
            </a>
          </Tooltip> */}
        </TD>
      )}
    </TableRow>
  );
}
