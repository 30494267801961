import { CollapsibleH } from "..";
import React, { useState } from "react";
import { SGTemplate } from ".";

export default function SGCollapsibleH() {
  const initState = {
    sidenavOpen: true,
  };

  const [state, setState] = useState(initState);

  /* function toggleNav() {
    setState({ ...state, navShowing: !state.navShowing });
  } */

  const content = {
    name: "CollapsibleH",
    variantName: "",
    description: `Acts as a "side menu".`,
    implementation:
      "Used when you want to provide the user with content that they can dismiss at their own discretion.",
    propsTable: [
      [
        "isOpen",
        "Boolean",
        `true, false`,
        "yes",
        "Determines whether the content of the collapisble menu is visible or not. A value of true will make the inner content of the collapisble menu visible.",
      ],
      [
        "arrow",
        "Boolean",
        "true, false",
        "no",
        "Determines whether an arrow is visible on the collapisble menu. The arrow will reflect the collapisble menu's current open status. Defaults to true",
      ],
      [
        "ko",
        "Boolean",
        "true, false",
        "no",
        "If set to true, the button to open the collapisble menu will turn white. Set to true when the collapisble menu is rendered on a dark background. If left blank, it will default to false.",
      ],
      [
        "title",
        "string",
        `any string`,
        "no",
        "The text to be displayed on the button that toogles the open status of the collapisble menu.",
      ],
      [
        "fullHeight",
        "Boolean",
        `true, false`,
        "no",
        "Determines whether or not the collapisble menu will occupy the full height of the screen. If left blank, it will default to false.",
      ],
      [
        "onToggle",
        "function",
        `A function that toggles the open state of the collapisble
        menu.`,
        "yes (if you need the menu to toggle with the user's click)",
        "Pass a function here that toggles the open state of the collapisble menu. Also perform any other desired actions in this function.",
      ],
    ],
    codeExample: `
    import { CollapsibleH } from "/components";

    initState = {
      collapsibleOpen: true
    }

    const [state, setState] = useState(initState)


    return (

      <CollapsibleH
        isOpen={state.collapsibleOpen}
        arrow={true}
        ko={false}
        title="CollapsibleH"
        fullHeight={false}
        onToggle={() =>
          setState({ ...state, collapsibleOpen: !state.collapsibleOpen })
      }>
        <div className="u-pad-lg u-background-darkblue">
          <p className="paragraph__1 paragraph__1--ko">
          Content goes here...
          </p>
        </div>
      </CollapsibleH>
    )
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-pad-sm u-background-blue">
            <CollapsibleH
              isOpen={state.sidenavOpen}
              arrow={true}
              title=""
              ko={true}
              fullHeight={false}
              onToggle={() =>
                setState({ ...state, sidenavOpen: !state.sidenavOpen })
              }>
              <div className="u-pad-lg u-background-darkblue">
                <p className="paragraph__1 paragraph__1--ko">
                  Content goes here...
                </p>
              </div>
            </CollapsibleH>
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
