class ValRstDataset {
  constructor() {
    this._attrsMap = {
      color_palette_max: 'colorPaletteMax',
      color_palette_min: 'colorPaletteMin',
      color_palette_zero: 'colorPaletteZero',
      data_file: 'dataFilePath',
      data_file_name: 'dataFileName',
      data_file_size: 'dataFileSize',
      data_layer: 'dataLayer',
      id: 'ID',
      img_file: 'imgFilePath',
      img_file_name: 'imgFileName',
      img_file_size: 'imgFileSize',
      is_img_loaded: 'isImgLoaded',
      val_fn_coeff_A: 'valFnCoeffA',
      val_fn_coeff_B: 'valFnCoeffB',
      val_fn_coeff_C: 'valFnCoeffC',
      val_fn_type: 'valFnType',
    }
    this._resData = {}
    this.data = {}
  }

  /**
   * @param {{ }} resDataObj - Object containing response data from a CVMOrigRstDatasetsCtrl GET req.
   */

  setResData(resDataObj) {
    for (const key in resDataObj) {
      const newKey = this._attrsMap[key] ? this._attrsMap[key] : key
      this._resData[newKey] = resDataObj[key]
    }
  }

  setData() {
    for (const key in this._resData) {
      this.data[key] = {}
      const value = this._resData[key]
      this.data[key]['value'] = value
    }
  }
}

export default ValRstDataset