import React from "react";
import { SGTemplate } from ".";

export default function SGForm() {
  const content = {
    name: "Form",
    description: `Form components are designed for use contained in an html <form> tag, or are components mimic <form> elements but can be used independently.`,
  };
  return <SGTemplate name={content.name} description={content.description} />;
}
