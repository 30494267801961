import React from "react";
import { Heading } from "../../../../components";

export default function DashboardHeading({ cm }) {
  /* THE PERFORMANCE OF THIS SEARCH FUNCTION IS BUGGY */
  /* function searchMaps(e) {
    const { value } = e.target;
    const { isMyMapsActive } = cm.state;
    cm.setState({ ...cm.state, searchVal: value });
    if (isMyMapsActive) {
      const query = [
        ["name__icontains", value],
        ["creator__id__exact", props.account.state.id],
      ];
      const editMapsQuery = [
        ["name__icontains", value],
        ["editors__id__exact", props.account.state.id],
      ];
      API.getDashMaps(query).then((res) => {
        API.getDashMaps(editMapsQuery).then((res2) => {
          const myMaps = res.data.concat(res2.data);
          const currentMapsLength = res.data.length + res2.data.length;
          cm.setState({ ...cm.state, currentMapsLength, searchVal: value });
          setMapsListDataState({ ...cm.state, myMaps });
        });
      });
    } else {
      const query = [
        ["name__icontains", value],
        ["is_published", true],
      ];
      API.getDashMaps(query).then((res) => {
        cm.setState({
          ...cm.state,
          currentMapsLength: res.data.length,
          searchVal: value,
        });
        setMapsListDataState({ ...cm.state, publicMaps: res.data });
      });
    }
  } */

  return (
    <>
      <div className="u-mgn-btm-md">
        <Heading number={2}>Collaborative Mapper Dashboard</Heading>
      </div>
      <div className="g-dashboard__heading">
        <div className="u-mgn-left-sm">
          <Heading ko={true} number={4}>
            {cm.state.mapsListSelection}
          </Heading>
        </div>

        {/* SEARCH BAR TOOL */}
        {/* <div className="cm-dash__input">
              <InputText
                inputProps={{
                  placeholder: "Search for maps by name...",
                  onChange: searchMaps,
                  value: cm.state.searchVal,
                }}
              />
              <div className="cm-dash__search">
                <Icon icon="search" size="sm" />
              </div>
            </div> */}
      </div>
    </>
  );
}
