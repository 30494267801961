import React from 'react'

// Components
import { Icon, Tooltip } from '../../../../../../components'

export default function UploadRasterButton() {
  return (
    <div>
      <Tooltip body='Max Data Layers Limit Reached' ko={true}>
        <Icon className='' disabled={true} icon='upload' ko={true} size='md' />
      </Tooltip>
    </div>
  )
}
