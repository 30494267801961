import React from 'react'

// Components
import { Tooltip } from '../../../../../../../components'

// Utilities
import APIreqs from '../../../../../helpers/API'
import utils from '../../../../../../../utils/utils'

function CycleBackgroundColorBtn({ dataLayers, i }) {
  async function cycleDataLayerBackgroundColor() {
    async function putDataLayerBackgroundColor() {
      try {
        const putDataLayerBackgroundColorRes =
          await APIreqs.dataLayers.putColor(dataLayers.data[i].id)
        return putDataLayerBackgroundColorRes.data
      } catch {
        utils.sendAlert(
          "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'put CVM data layer color' error.",
          'error',
        )
      }
    }

    function setDataLayerColor(backgroundColor) {
      dataLayers.setData(
        dataLayers.data.map((dataLayer, j) => {
          if (i === j) {
            dataLayers.data[i].img_background_color = backgroundColor
          }
          return dataLayer
        }),
      )
    }

    const backgroundColor = await putDataLayerBackgroundColor()
    if (backgroundColor) {
      setDataLayerColor(backgroundColor)
    }
  }

  return (
    <Tooltip body='Change image background color.'>
      <div
        className='cvm__data-layer__colors-icon u-cursor-pointer'
        onClick={() => {
          cycleDataLayerBackgroundColor()
        }}>
        <div className='u-flex'>
          <div className='u-background-lightblue cvm__data-layer__colors-icon__panel'></div>
          <div className='u-background-orange cvm__data-layer__colors-icon__panel'></div>
        </div>
        <div className='u-flex'>
          <div className='u-background-green cvm__data-layer__colors-icon__panel'></div>
          <div className='u-background-purple cvm__data-layer__colors-icon__panel'></div>
        </div>
      </div>
    </Tooltip>
  )
}

export default CycleBackgroundColorBtn
