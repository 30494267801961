import { Icon } from "..";
import React from "react";
import { SGTemplate } from ".";

const iconsList = [
    "account",
    "add",
    "adduser",
    "apps",
    "attach",
    "bell",
    "bookmark",
    "calendar",
    "camera",
    "caret-down",
    "caret-up",
    "chat",
    "close",
    "check",
    "comment",
    "crosshairs",
    "cursor",
    "document",
    "download",
    "duplicate",
    "edit",
    "error",
    "externallink",
    "folder",
    "github",
    "grab",
    "group",
    "help",
    "image",
    "invisible",
    "line",
    "link",
    "locked",
    "marker",
    "menu",
    "merge",
    "minus",
    "move",
    "network",
    "new",
    "people",
    "personalinfo",
    "plus",
    "polygon",
    "print",
    "private",
    "profile",
    "public",
    "rectangle",
    "redo",
    "remove",
    "reply",
    "save",
    "search",
    "send",
    "share",
    "star",
    "success",
    "trash",
    "undo",
    "unlocked",
    "upload",
    "video",
    "visible",
    "warning",
    "works",
  ],
  icons = iconsList.map((iconName, index) => {
    return (
      <div
        key={index}
        className="u-pad-sm u-flex u-flex-column u-flex-align-center">
        <Icon ko={true} icon={iconName} />
        <p className="paragraph__2 paragraph__2--ko">{iconName}</p>
      </div>
    );
  });

export default function SGIcon() {
  const content = {
    name: "Icon",
    variantName: "",
    description: `Small graphics to be placed on a page at the developer's
    convenience.`,
    implementation: "Misc.",
    propsTable: [
      [
        "className",
        "string",
        `icon-custom`,
        "no",
        "Add custom class names to icon element.",
      ],
      [
        "icon",
        "string",
        `any of the reserved strings listed below the desired icon above`,
        "yes",
        "Pass in any of the values displayed below the desired icon above.",
      ],
      [
        "size",
        "string",
        "sm, md, lg",
        "no",
        "Determines the size of the icon. If left blank, the icon will default to medium (md).",
      ],
      [
        "ko",
        "Boolean",
        "true, false",
        "no",
        "When set to true, the component is used over a dark background. Defaults to false.",
      ],
      [
        "inherit",
        "Boolean",
        "true, false",
        "no",
        "When set to true, the Icon will assume the color of its parent element.",
      ],
      [
        "...props",
        "JSX div attributes",
        `Any valid attribute that is applicable to a default JSX "div"
        element`,
        "no",
        `Provide JSX attributes that could be applied to a div element. For example: data-thing="value" or onClick={someFunction}`,
      ],
    ],
    codeExample: `
    import { Icon } from "/components";
             
    <Icon ko={true} icon="works" size="sm" />
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-pad-sm u-background-blue u-flex u-flex-wrap">
            {icons}
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
