import { Heading } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGHeading() {
  const content = {
    name: "",
    variantName: "Heading",
    description: `A pre-styled heading.`,
    implementation: `To be used as an alternative to an h1, h2, etc... tag.`,
    propsTable: [
      [
        "bold",
        "Boolean",
        "true, false",
        "no",
        "Set to false for an un-bolded heading. True by default.",
      ],
      [
        "children",
        "JSX",
        "Any valid JSX",
        "yes",
        "Heading is not a self-closing component. Wrap any desire content within the open and close tags.",
      ],
      [
        "color",
        "string",
        "primary, secondary",
        "no",
        "Primary color set by default.  White for ko, blue for non-ko.  Secondary color is orange and yellow respectively.",
      ],
      [
        "className",
        "string",
        "u-pad-sm",
        "no",
        "Add additional class names to component.",
      ],
      [
        "ko",
        "Boolean",
        "true, false",
        "no",
        "Set to true if the component will be displayed over a dark background.",
      ],
      [
        "number",
        "integer, string",
        "Accepted values are 1, 2, 3, 4 and 5",
        "yes",
        "The number passed here correspons to the default JSX heading tags. For example, passing a 1 will render an h1.",
      ],
    ],
    codeExample: `
    import { Heading } from "/components";
            
    return (

        <Heading number={1}> Heading 1 </Heading>
      
        <Heading number={2} color="secondary"> Heading 2 </Heading>
      
        <Heading number={3}> Heading 3 </Heading>
      
        <Heading number={4}> Heading 4 </Heading>
      
        <Heading number={5}> Heading 5 </Heading>
    )
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-pad-sm">
            <div className="u-mgn-vert-sm">
              <Heading number={1}>Heading 1</Heading>
            </div>
            <div className="u-mgn-vert-sm">
              <Heading number={2} color="secondary">
                Heading 2
              </Heading>
            </div>
            <div className="u-mgn-vert-sm">
              <Heading number={3}>Heading 3</Heading>
            </div>
            <div className="u-mgn-vert-sm">
              <Heading number={4}>Heading 4</Heading>
            </div>
            <div className="u-mgn-vert-sm">
              <Heading number={5}>Heading 5</Heading>
            </div>
          </div>

          <div className="u-background-blue u-pad-sm">
            <div className="u-mgn-vert-sm">
              <Heading ko={true} number={1}>
                Heading 1 KO
              </Heading>
            </div>
            <div className="u-mgn-vert-sm">
              <Heading ko={true} number={2} color="secondary">
                Heading 2 KO
              </Heading>
            </div>
            <div className="u-mgn-vert-sm">
              <Heading ko={true} number={3}>
                Heading 3 KO
              </Heading>
            </div>
            <div className="u-mgn-vert-sm">
              <Heading ko={true} number={4}>
                Heading 4 KO
              </Heading>
            </div>
            <div className="u-mgn-vert-sm">
              <Heading ko={true} number={5}>
                Heading 5 KO
              </Heading>
            </div>
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
