import { RadioButton } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGRadioButton() {
  const content = {
    name: "RadioButton",
    variantName: "",
    description:`A standardized "menu". Elements will be renderd together in a contiguous list. Devs can choose whether a given element contains a link or triggers a callback.`,
    implementation: `A contiguous list`,
    propsTable: [
      [
        "name",
        "string",
        `any string`,
        "yes",
        "Give the radio button a name so it can be identified on click and to separate it from other radio buttons in the same form.",
      ],
      [
        "ko",
        "Boolean",
        "true, false",
        "no",
        "Set to true if the component will be displayed over a dark background.",
      ],
      [
        "onClick",
        "function",
        "callback function triggered by a click on the radio button",
        "no",
        "The function passed here will be invoked when the radio button is clicked.",
      ],
      [
        "defaultChecked",
        "boolean",
        "true, false",
        "no",
        "Determines whether or not a radio button is selected by default.",
      ],
    ],
    codeExample: `
    import { RadioButton } from "/components";

    <form action="" className="u-flex">
      <div className="u-mgn-right-md u-flex u-flex-column u-flex-align-center">
        <RadioButton name="radio1" onClick={() => console.log("onClick CB")} defaultChecked={true} />
        <p className="paragraph__2 u-mgn-top-sm">Option 1</p>
      </div>
      <div className="u-flex u-flex-column u-flex-align-center">

        <RadioButton name="radio2" onClick={() => console.log("onClick CB")} defaultChecked={false} />
        <p className="paragraph__2 u-mgn-top-sm">Option 2</p>
      </div>

    </form>
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <div className="u-pad-sm">
            <form action="" className="u-flex">
              <div className="u-mgn-right-md u-flex u-flex-column u-flex-align-center">
                <RadioButton
                  name="radio1"
                  onClick={() => console.log("onClick CB")}
                  defaultChecked={true}
                />
                <p className="paragraph__2 u-mgn-top-sm">Option 1</p>
              </div>
              <div className="u-flex u-flex-column u-flex-align-center">
                <RadioButton
                  name="radio2"
                  onClick={() => console.log("onClick CB")}
                  defaultChecked={false}
                />
                <p className="paragraph__2 u-mgn-top-sm">Option 2</p>
              </div>
            </form>
          </div>

          <div className="u-pad-sm u-background-darkblue">
            <form action="" className="u-flex">
              <div className="u-mgn-right-md u-flex u-flex-column u-flex-align-center">
                <RadioButton
                  name="radioko1"
                  onClick={() => console.log("onClick CB")}
                  ko={true}
                  defaultChecked={true}
                />
                <p className="paragraph__2 paragraph__2--ko u-mgn-top-sm">
                  Option 1
                </p>
              </div>
              <div className="u-flex u-flex-column u-flex-align-center">
                <RadioButton
                  name="radioko2"
                  onClick={() => console.log("onClick CB")}
                  ko={true}
                  defaultChecked={false}
                />
                <p className="paragraph__2 paragraph__2--ko u-mgn-top-sm">
                  Option 2
                </p>
              </div>
            </form>
          </div>
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
