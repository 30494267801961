import { Loading } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGLoading() {
  const content = {
    name: "Loading",
    variantName: "",
    description:
      "A graphic to display to let a user know they should wait during loading time.",
    implementation: `Display this graphic when waiting for a response from the server or conducting another operation that doesnt immediately execute.`,
    propsTable: [
      [
        "size",
        "string",
        "lg, md, sm",
        "no",
        "Pass one of three strings to determine the size of this component when rendered. If no value or an invalid value is passed, it will default to medium (md) size.",
      ],
    ],
    codeExample: `
    import { Loading } from "/components";

    state = {
      isLoading: false
    }

    {state.isLoading ?
      <Loading />
      : null
    }
    `,
  };
  return (
    <SGTemplate
      name={content.name}
      variantName={content.variantName}
      description={content.description}
      implementation={content.implementation}
      componentExamples={
        <div className="u-flex u-flex-column u-full-width">
          <Loading />
        </div>
      }
      propsTableContents={content.propsTable}
      codeExample={content.codeExample}
    />
  );
}
