import React, { Component } from 'react'
import authInit from '../../../../auth/init'
import { Map, TileLayer, FeatureGroup, ScaleControl } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet-draw'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import API from '../../API'
import renderControls from './rightControls/renderControls'
import listeners from '../../listeners'
import renderFeatures from '../../features'
import processRasters from '../../rasters'
import renderProposals from '../../proposals'
import utils from '../../../../utils/utils'
// import { PulseLoader } from "react-spinners";
// import { css } from "@emotion/react";
import domtoimage from 'dom-to-image'
import MapConfigMenu from './leftMenu/MapConfigMenu'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Button, TextArea, ModalClose } from '../../../../components'
import $ from 'jquery'
import MapControls from './rightControls/MapControls'
import baseLayers from '../../../../utils/leaflet/baseLayers'

let that, zoom

/* const loadingCSS = css`
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1000000;
  transform: translate(-50%, -50%);
  border-color: #ef7334;
`; */

class CMApp extends Component {
  state = {
    account: {},
    availableCollaborators: [],
    baseLayer: '',
    certifications: [],
    comments: [],
    confirmationCB: () => {},
    confirmationMessage: '',
    creators: [],
    datasets: {},
    deleteModalShow: false,
    editors: [],
    editTracker: {},
    endorsements: [],
    featureGroup: '',
    features: [],
    guestUser: false,
    history: [],
    isAuthenticated: false,
    isAuthorized: true,
    isControlsRendered: false,
    isCreator: false,
    isEditor: false,
    isVectorDrawingControlsEnabled: null,
    layers: {},
    loading: false,
    mapCenter: [43.256, -110.9345],
    mapData: {},
    mapID: null,
    mapZoom: 4,
    modals: {},
    name: '',
    proposals: [],
    rasters: [],
    sideNavOpen: true,
    species: null,
    species_desc: null,
    species_sci: null,
    species_tax: null,
    suggestions: [],
    summary: 'Add map summary here.',
    thumbnail: '',
    value: '',

    // Changes
    unstagedChanges: {},
    changesActive: false,
    totalChanges: 0,

    // undo/redo
    undo: [],
    redo: [],
    lastChange: {},
  }

  componentDidMount = async () => {
    await authInit(this)
    this.setState(
      {
        map: this.map.leafletElement,
        featureGroup: L.featureGroup(),
      },
      () => {},
    )
    that = this
    const href = window.location.href
    let mapID = href
    mapID = mapID.split('id=')
    mapID = mapID[1]
    mapID = mapID.split('&')
    mapID = mapID[0]

    if (href.includes('token=')) {
      let token = href.split('token=')
      token = token[1]
      await this.setState({ token })
      await localStorage.setItem(`maptoken${mapID}`, token)
    }
    await API.getMap(mapID)
      .then((res) => {
        const isCreator = res.data.status === 'creator' ? true : false
        let isEditor = res.data.status === 'editor' ? true : false
        const isPublic = res.data.is_published
        let isAuthorized = true
        if (!isCreator && !isEditor && !isPublic) {
          isAuthorized = false
        }
        const mapToken = localStorage.getItem(`maptoken${mapID}`)
        if (mapToken === res.data.token && mapToken) {
          isAuthorized = true
          isEditor = true
        }
        const {
          base_layer,
          center,
          is_vector_drawing_controls_enabled,
          name,
          species,
          species_desc,
          species_sci,
          species_tax,
          summary,
          zoom,
        } = res.data
        this.setState(
          {
            baseLayer: base_layer,
            isCreator,
            isEditor,
            isAuthorized,
            isVectorDrawingControlsEnabled: is_vector_drawing_controls_enabled,
            mapCenter: JSON.parse(center),
            mapData: res.data,
            mapID,
            mapZoom: Number(zoom),
            name,
            species,
            species_desc,
            species_sci,
            species_tax,
            summary,
          },
          this.renderFeatures,
        )

        if (isCreator || isEditor) {
          this.state.map.pm.addControls({
            position: 'topright',

            // These controls are initially disabled.
            drawMarker: false,
            drawPolygon: false,
            drawPolyline: false,
            drawRectangle: false,
            editMode: false,
            removalMode: false,

            // These controls are excluded from the app workflow.
            drawCircleMarker: false,
            drawCircle: false,
            dragMode: false,
            cutPolygon: false,
          })
          listeners(this)
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
    API.getMapLayers(mapID).then((res) => {
      // Let's us keep track of images by mapping their urls to DBID. Image URLs can be accessed through lealfet layers.
      const IDToImage = {}
      for (let i = 0; i < res.data.length; i++) {
        let { id, map_layer } = res.data[i]
        map_layer = map_layer.split('.')
        map_layer = map_layer[0] + '.png'
        IDToImage[id] = map_layer
      }
      this.setState({
        rasters: res.data,
        IDToImage,
      })
    })

    if (!this.state.isControlsRendered) {
      renderControls(this)
      this.setState({
        isControlsRendered: true,
      })
    }
    API.getMapUsers(mapID, 'owner').then((res) => {
      this.setState({ creators: res.data.owner })
    })

    API.getMapUsers(mapID, 'collaborators').then((res) => {
      this.setState({ editors: res.data.collaborators })
    })

    API.getMapComments(mapID).then((res) => {
      this.setState({ comments: res.data })
    })

    API.getMapHistory(mapID).then((res) => {
      this.setState({ history: res.data })
    })

    //  CAPTURE SCREENSHOT FOR MAP THUMBNAIL
    if (this.state.isEditor || this.state.isCreator) {
      setTimeout(() => {
        var node = document.getElementById('screenshot')
        domtoimage.toPng(node).then(function (dataUrl, err) {
          if (err) {
            console.log('ImgUploadErr', err)
          }
          let formData = new FormData()
          formData.append('image_data', dataUrl)
          API.updateThumbnail(formData, that.state.mapID).then((res) => {})
        })
      }, 2500)
    }
    zoom = $('.leaflet-control-zoom')
    zoom.addClass('changedetection__zoom')
    // this.addSpeciesRange()
  }

  toggleChangeView() {
    const { changesActive } = this.state
    this.setState({ changesActive: !changesActive })
  }

  stageChange(layer, id) {
    const { unstagedChanges } = this.state
    const { dataset } = layer.properties
    if (!unstagedChanges[id]) {
      unstagedChanges[id] = {
        layers: [layer],
        dataset,
      }
    } else {
      unstagedChanges[id].layers.push(layer)
    }
    let totalChanges = 0
    for (let key in unstagedChanges) {
      const numChanges = unstagedChanges[key].layers.length
      totalChanges += numChanges
    }
    this.setState({ unstagedChanges, totalChanges })
  }

  removeJustifiedChange(id) {
    const { unstagedChanges } = that.state
    delete unstagedChanges[id]
    that.setState({ unstagedChanges })
  }

  decideOnEdit = (id, decision) => {
    const req = { id, decision }
    API.decideOnEdit(req).then((res) => {
      console.log('proposedchange', res)
      // const feat = JSON.parse(res.data.feature);
      // that.stageChange("feat", feat);
      this.setState({ features: [], datasets: [] }, this.renderFeatures)

      API.getProposals(this.state.mapID).then((res) => {
        this.setState({
          proposals: res.data,
        })
      })
    })
  }

  decideOnAdd = (featureID, commentID, decision) => {
    const req = { featureID, commentID, decision }
    const addedLayer = this.state.layers[featureID]
    API.decideOnAdd(req).then((res) => {
      this.renderFeatures()
      if (decision === 'accept') {
        if (addedLayer.feature.geometry.type === 'Point') {
          addedLayer._icon.classList.remove('leaf-marker-green')
        } else {
          addedLayer.setStyle({
            color: '#3388ff',
          })
        }
      }
    })
  }

  decideOnDelete = (featureID, commentID, decision) => {
    const addedLayer = this.state.layers[featureID]
    API.decideOnDelete(featureID, commentID, decision).then((res) => {
      this.renderFeatures()
      if (decision === 'reject') {
        if (addedLayer.feature.geometry.type === 'Point') {
          addedLayer._icon.classList.remove('leaf-marker-red')
        } else {
          this.state.layers[featureID].setStyle({
            color: '#3388ff',
          })
          this.state.layers[featureID].closeTooltip()
        }
      }
    })
  }

  deleteProposal = (DBID, type) => {
    API.deleteProposal(DBID, type).then((res) => {
      if (type === 'feature') {
        this.state.map.closePopup()
        this.state.map.removeLayer(this.state.layers[DBID])
        this.renderFeatures()
      } else if (type === 'edit') {
        this.renderFeatures()
      }
    })
  }

  handleChange = (e) => {
    let name = e.target.name
    let value = e.target.value
    if (!e.target.name) {
      name = e.target.getAttribute('name')
      value = e.target.textContent
    }
    this.setState({ [name]: value })
  }

  featureComment = (featureID) => {
    const comment = this.state[`featureComment${featureID}`]
    const req = { featureID, comment }
    API.featureComment(req).then((res) => {
      this.setState({ [`featureComment${featureID}`]: '' })
      this.renderFeatures()
    })
  }

  rasterComment = (e) => {
    e.preventDefault()
    const rasterID = e.target.getAttribute('dbid')
    const comment = this.state[`rasterComment${rasterID}`]
    const req = { rasterID, comment }
    API.rasterComment(req).then((res) => {
      this.setState({ [`rasterComment${rasterID}`]: '' })
      API.getMapLayers(this.state.mapID).then((res) => {
        this.setState({
          rasters: res.data,
        })
      })
    })
  }

  submitMapComment = (e) => {
    e.preventDefault()
    const { mapID, mapComment, account, comments } = this.state
    const req = { mapID, mapComment }
    if (!mapComment) {
      utils.sendAlert('Please type a comment before submitting', 'error')
      return
    }
    API.postComment(req).then((res) => {
      const new_comment = res.data
      new_comment.account = account
      comments.push(new_comment)
      this.setState({ comments, mapComment: '' })
      this.toggleModal('comment')
    })
  }

  deleteMapComment = (id) => {
    const { mapID } = this.state
    API.deleteMapComment(id).then((res1) => {
      API.getMapComments(mapID).then((res) => {
        this.setState({ comments: res.data })
      })
    })
  }

  deleteFeatureComment = (commentID, featureID) => {
    const { layers } = this.state
    API.deleteFeatureComment(commentID).then((res) => {
      layers[featureID].closeTooltip()
      this.renderFeatures()
    })
  }

  deleteRaster = (id) => {
    const { map, IDToImage } = this.state
    API.deleteRaster(id).then((res) => {
      const layers = map._layers
      const url = IDToImage[id]
      for (let key in layers) {
        if (layers[key].options.url === url) {
          map.closePopup()
          map.removeLayer(layers[key])
        }
      }
    })
  }

  hideLayerGroup = (layerName) => {
    const { datasets } = this.state
    const dataLayer = datasets[layerName]
    if (dataLayer.isVisible === undefined) {
      dataLayer.isVisible = false
    } else {
      dataLayer.isVisible = !dataLayer.isVisible
    }
    datasets[layerName] = dataLayer
    this.setState({ datasets })
  }

  selectFile = (e) => {
    const formData = new FormData()
    try {
      formData.append('file', e.target.files[0], e.target.files[0].name)
      const { mapID } = this.state
      API.changeMapPic(formData, mapID, 'species_img').then((res) => {
        const { mapData } = this.state
        mapData.species_img = res.data.species_img
        this.setState({ mapData })
      })
    } catch {
      utils.sendAlert('Image failed to upload', 'error')
    }
  }

  // Not currently implemented.
  /* fork = (e) => {
    const { mapID } = this.state;
    const acctID = this.state.account.id;
    API.forkMap(mapID, acctID).then((res) => {
      const { newMapID } = res.data;
      window.location.href = "/rrm/map?id=" + newMapID;
    });
  }; */

  renderFeatures = () => {
    API.getMapFeatures(this.state.mapID).then((res) => {
      const features = []
      const { editTracker } = this.state
      for (let i = 0; i < res.data.length; i++) {
        features.push(res.data[i])
        editTracker[res.data[i].id] = res.data[i].layer
      }
      API.getProposals(this.state.mapID).then((res) => {
        this.setState(
          {
            proposals: res.data,
            features,
            editTracker,
          },
          async () => {
            await renderFeatures(this)
            await renderProposals(this)
          },
        )
      })
    })
  }

  toggleModal = (modal) => {
    const { modals } = this.state
    modals[modal] = !modals[modal]
    this.setState({ modals })
  }

  async onEachFeature(feature, layer) {
    const DBID = feature.id
    // Seem to get async issues without this console.log
    await console.log('layer', feature)
    let { numComments } = feature.properties
    numComments += ''
    if (numComments > 0 && that.state.isCreator) {
      layer
        .bindTooltip(numComments, {
          permanent: true,
          className: 'leaf-tooltip',
        })
        .openTooltip()
    }
    if (layer.feature.geometry.type === 'Point') {
      if (layer.feature.properties.editType === 'proposeAdd') {
        layer._icon.classList.add('leaf-marker-green')
      } else if (layer.feature.properties.editType === 'proposeDelete') {
        layer._icon.classList.add('leaf-marker-red')
      }
    }
    const LID = layer._leaflet_id
    layer.feature['lid'] = LID
    layer.feature['id'] = DBID
    try {
      await that.state.featureGroup.addLayer(layer)
    } catch {
      console.log('error')
    }
    that.state.layers[DBID] = layer
    layer.on({
      click: that.clickToFeature,
    })
    try {
      layer.bringToBack()
    } catch {
      console.log('Error')
    }
  }

  /* While being empty, `clickToFeature()` enables Leaflet functionality to click on vector 
  features and trigger effects, such as leave a comment and delete features. */
  clickToFeature(e) {}

  async onEachProposal(feature, layer) {
    await layer.bringToFront()
  }

  changeBaseLayer(baseLayer) {
    const defaultLayer = {
      url: 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      attribution: '',
      id: 'mapbox/outdoors-v11',
      accessToken:
        'pk.eyJ1Ijoic3BhdHNlbCIsImEiOiJjanRrYXp5ZHkxNHQ0M3lxbXF1N25oODJhIn0.EUKeJrihgiJzxo5FAwlUIw',
    }

    const satelliteLayer = {
      url: 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      accessToken:
        'pk.eyJ1IjoibWF1YmFpbmNjaSIsImEiOiJja2I1YXA4dHoxMnJpMnFtZHlyaGRsbDFuIn0.PBCNxXVT4V8KwnnR6TZEBg',
      id: 'mapbox/satellite-streets-v11',
      attribution:
        '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong>',
    }

    this.setState({ baseLayer })
  }

  updateLayerName(obj) {
    const { inputValue, updatedValue, elementName } = obj
    API.updateLayerName(
      inputValue,
      updatedValue,
      that.state.mapID,
      that.state.account.id,
    ).then((res) => {
      if (inputValue !== updatedValue) {
        that.renderFeatures()
      }
    })
  }

  commitChange(geometry, action, apiCall) {
    let { undo, lastChange } = this.state
    const nextUndo = lastChange
    undo.push(nextUndo)
    lastChange = { geometry, action, apiCall }
    this.setState({ undo, lastChange })
  }

  saveMapAttrs(e) {
    const center = e.target.getCenter(),
      centerCoords = [Number(center.lat), Number(center.lng)],
      zoom = e.target.getZoom()
    API.updateMapAttr(
      'center',
      JSON.stringify(centerCoords),
      this.state.mapID,
    ).then(() => {
      API.updateMapAttr('zoom', zoom, this.state.mapID)
    })
  }

  render() {
    // console.log("CMApp state: ", this.state);
    if (!this.state.isAuthorized) {
      window.location.href = '/'
    }
    return (
      <div style={{ width: '100vw' }}>
        <div className='u-full-screen'>
          <MapConfigMenu app={this} />
          <MapControls app={this} />
          <div id='screenshot'>
            <Map
              animate={true}
              boxZoom={true}
              center={this.state.mapCenter}
              doubleClickZoom={true}
              editable={true}
              onmoveend={(e) => {
                this.saveMapAttrs(e)
              }}
              onzoomend={(e) => {
                this.saveMapAttrs(e)
              }}
              ref={(c) => {
                this.map = c
              }}
              zoom={this.state.mapZoom}
              zoomControl={true}>
              {this.state.baseLayer === 'default' && baseLayers.Outdoors}
              {this.state.baseLayer === 'satellite' && baseLayers.Satellite}
              {this.state.rasters.length > 0
                ? // <Overlay name="Rasters" checked={true}>
                  processRasters(this)
                : // </Overlay>
                  null}

              {/* FEATURES/GEOMETRIES RENDERED HERE */}
              {Object.keys(this.state.datasets).map((key) => {
                if (this.state.datasets[key].isVisible === false) {
                  return null
                } else {
                  return (
                    <FeatureGroup key={key}>
                      {this.state.datasets[key].features.map(
                        (feature) => feature,
                      )}
                    </FeatureGroup>
                  )
                }
              })}

              {/* Scale Bar */}
              <ScaleControl></ScaleControl>
            </Map>
          </div>
        </div>

        {/* MODALS */}
        {/* Comment */}
        <Modal
          isOpen={this.state.modals.comment}
          toggle={() => this.toggleModal('comment')}>
          <ModalHeader>
            <ModalClose cb={() => this.toggleModal('comment')} />
            New Comment
          </ModalHeader>
          <ModalBody>
            <div className='u-pad-md'>
              <form onSubmit={this.submitMapComment}>
                <TextArea
                  textAreaProps={{
                    onChange: this.handleChange,
                    name: 'mapComment',
                  }}>
                  {this.state.mapComment}
                </TextArea>
                <div className='u-mgn-top-md u-flex u-flex-justify-end'>
                  <div className='u-mgn-right-sm'>
                    <Button
                      buttontype='secondary'
                      size='sm'
                      buttonProps={{
                        onClick: () => this.toggleModal('comment'),
                        type: 'text',
                      }}>
                      Cancel
                    </Button>
                  </div>
                  <Button
                    buttontype='primary'
                    size='sm'
                    buttonProps={{
                      type: 'submit',
                      onClick: this.submitMapComment,
                    }}>
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default CMApp
