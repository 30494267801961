import API from '../../auth/API'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Button, FormInputWValidations, ModalClose } from '../../components'
import React, { useState } from 'react'
import utils from '../../utils/utils'

export default function NavbarLogInModal({ auth, navbar, account }) {
  const navbarLogInModalStateInit = {
      valuePassword: '',
      valueUsername: '',
    },
    [navbarLogInModalState, setNavbarLogInModalState] = useState(
      navbarLogInModalStateInit,
    )

  const logIn = () => {
    const req = {
      username: navbarLogInModalState.valueUsername,
      password: navbarLogInModalState.valuePassword,
    }

    API.logIn(req)
      .then((res) => {
        localStorage.setItem('token', res.data.token)
        API.getAuth()
          .then((res2) => {
            // Log in and proceed if user exists.
            if (res2.data.username) {
              account.setState(res2.data)
              auth.setState({ ...auth.state, isAuthenticated: true })
              setNavbarLogInModalState(navbarLogInModalStateInit)
              utils.sendAlert('Logged in.', 'success')
              navbar.setState({ ...navbar.state, isLogInModalOpen: false })
            }

            // If authentication successful but user does not yet exist, then handle account not activated.
            if (res2.data.anonymous) {
              setNavbarLogInModalState(navbarLogInModalStateInit)
              localStorage.removeItem('token')
              utils.sendAlert(
                'This account has not been activated.\n\nPlease check your inbox for an email from Conservationist.io containing an activation link and follow the enclosed instructions.',
                'warning',
              )
              navbar.setState({ ...navbar.state, isLogInModalOpen: false })
            }
          })
          .catch(() => {
            utils.sendAlert(
              'Something went wrong.  Please use the contact form to report a bug.',
              'error',
            )
          })
      })
      .catch(() => {
        utils.sendAlert('The credentials provided are not valid.', 'error')
      })
  }

  // console.log("navbar log in modal state: ", navbarLogInModalState);

  return (
    <Modal
      isOpen={navbar.state.isLogInModalOpen}
      centered
      backdrop='static'
      size='lg'>
      <ModalHeader>
        <ModalClose
          cb={() => {
            setNavbarLogInModalState(navbarLogInModalStateInit)
            navbar.setState({ ...navbar.state, isLogInModalOpen: false })
          }}
        />
        Log In
      </ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            logIn()
          }}>
          <div className='u-pad-sm u-pad-top-sm'>
            <FormInputWValidations
              parentState={navbarLogInModalState}
              placeholder='Enter username'
              setParentState={setNavbarLogInModalState}
              inputType='username'
              validationsContext='sign up'
              valueKey='valueUsername'
              useValidations={false}
            />
            <Button
              buttonProps={{
                onClick: () => {
                  setNavbarLogInModalState(navbarLogInModalStateInit)
                  navbar.setState({
                    ...navbar.state,
                    isLogInModalOpen: false,
                    isRetrieveUsernameModalOpen: true,
                  })
                },
                type: 'button',
              }}
              buttontype='text'
              className='nav-log-in-modal__button'
              size='sm'>
              Forgot username?
            </Button>
          </div>
          <div className='u-pad-sm u-pad-top-sm'>
            <FormInputWValidations
              parentState={navbarLogInModalState}
              placeholder='Enter password'
              setParentState={setNavbarLogInModalState}
              inputType='password'
              validationsContext='sign up'
              valueKey='valuePassword'
              useValidations={false}
            />
            <Button
              buttonProps={{
                onClick: () => {
                  setNavbarLogInModalState(navbarLogInModalStateInit)
                  navbar.setState({
                    ...navbar.state,
                    isLogInModalOpen: false,
                    isResetPasswordModalOpen: true,
                  })
                },
                type: 'button',
              }}
              buttontype='text'
              className='nav-log-in-modal__button'
              size='sm'>
              Forgot password?
            </Button>
          </div>
          <div className='u-pad-btm-md u-pad-top-md u-pad-left-sm u-pad-right-sm'>
            <Button
              buttonProps={{ type: 'submit' }}
              buttontype='primary'
              size='inherit'>
              Log In
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}
