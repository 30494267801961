import React from "react";

export default function Heading({
  bold = true,
  color = "primary",
  ko = false,
  number = 1,
  colorOverride,
  className = "",
  children,
}) {
  const classNameBase = "heading--",
    classNameBold = bold ? `${classNameBase}bold` : "",
    classNameKO = ko ? `${classNameBase}ko` : `${classNameBase}not-ko`,
    classNameColor = `${classNameKO}--${color}`,
    classNameNumber = `${classNameBase}${number}`;
  const classNameFinal = `${classNameNumber} ${classNameBold} ${classNameColor} ${className}`;

  return (
    <>
      {(() => {
        if (number === 1) {
          return (
            <h1
              className={classNameFinal}
              style={colorOverride ? { color: colorOverride } : {}}>
              {children}
            </h1>
          );
        } else if (number === 2) {
          return (
            <h2
              className={classNameFinal}
              style={colorOverride ? { color: colorOverride } : {}}>
              {children}
            </h2>
          );
        } else if (number === 3) {
          return (
            <h3
              className={classNameFinal}
              style={colorOverride ? { color: colorOverride } : {}}>
              {children}
            </h3>
          );
        } else if (number === 4) {
          return (
            <h4
              className={classNameFinal}
              style={colorOverride ? { color: colorOverride } : {}}>
              {children}
            </h4>
          );
        } else if (number === 5) {
          return (
            <h5
              className={classNameFinal}
              style={colorOverride ? { color: colorOverride } : {}}>
              {children}
            </h5>
          );
        }
      })()}
    </>
  );
}
