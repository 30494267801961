import externalLinks from "../../utils/paths/externalLinks"
import routes from "../../utils/paths/routes"

export default {
  "Apps": {
    "Available Now": [
      {
        "heading": routes.apps.collabDash.label,
        "description": "Edit geospatial data with colleagues.",
        "actionType": "internal link",
        "to": { "pathname": routes.apps.collabHome.path },
      },
      {
        "heading": routes.apps.habPatApp.label,
        "description": "Track land cover changes.",
        "actionType": "internal link",
        "to": { "pathname": routes.apps.habPatHome.path },
      },
    ],
    /* "Coming Soon": [
      {
        "heading": routes.apps.earthenticateApp.label,
        "description": "Help train AI models.",
        "actionType": "internal link",
        "to": { "pathname": routes.apps.earthenticateHome.path },
      },
      {
        "heading": "Conservation Value Mapper",
        "description": "Determine landscape conservation value.",
        "actionType": "none",
        "url": "",
      },
      {
        "heading": "ESA Document Search",
        "description": "Search Endangered Species Act documents.",
        "actionType": "none",
        "to": "",
      },
    ], */
  },
  "Discover": {
    "Conservationist.io": [
      {
        "heading": routes.landing.label,
        "description": "Conservationist.io apps and services.",
        "actionType": "internal link",
        "to": { "pathname": routes.landing.path }
      },
      /* {
        "heading": "About",
        "description": "Learn more about Conservationist.io.",
        "actionType": "internal link",
        "to": "",
      }, */
      {
        "heading": "Beta Testing",
        "description": "Help us improve conservation apps.",
        "actionType": "internal link",
        "to": { "pathname": routes.betaTesting.path.home },
      },
      /* {
        "heading": "Featured Projects",
        "description": "Featured projects desc.",
        "actionType": "",
        "": "",
      }, */
      /* {
        "heading": "Partnerships",
        "description": "See who else is using our tools.",
        "actionType": "internal link",
        "to": "",
      }, */
    ],
    "Defenders of Wildlife": [
      {
        "heading": "Website",
        "description": "More about Defenders.",
        "actionType": "external link",
        "url": externalLinks.defenders.about.path,
      },
      {
        "heading": "Center for Conservation Innovation",
        "description": "Science, technology, and policy.",
        "actionType": "external link",
        "url": externalLinks.cci.landing.path,
      },
      {
        "heading": externalLinks.defenders.jobs.label,
        "description": "Job and internship opportunities.",
        "actionType": "external link",
        "url": externalLinks.defenders.jobs.path,
      },
      {
        "heading": externalLinks.defenders.donate.label,
        "description": "Support Defenders of Wildlife.",
        "actionType": "external link",
        "url": externalLinks.defenders.donate.path,
      },
    ],
  },
  "Support": {
    /* "Documentation": [
      {
        "heading": "Help Center",
        "description": "desc.",
        "actionType": "internal link",
        "to": "",
      },
      {
        "heading": "Tutorials",
        "description": "desc.",
        "actionType": "internal link",
        "to": "",
      },
    ], */
    "Feedback": [
      {
        "heading": "Contact",
        "description": "Get in touch with us.",
        "actionType": "internal link",
        "to": { "pathname": routes.support.contact.path, "valueSubject": "Reaching out", },
      },
      {
        "heading": "Feedback",
        "description": "Provide ideas for apps and features.",
        "actionType": "internal link",
        "to": { "pathname": routes.support.contact.path, "valueSubject": "Providing feedback", },
      },
      {
        "heading": "Bug Report",
        "description": "Report an issue.",
        "actionType": "internal link",
        "to": { "pathname": routes.support.contact.path, "valueSubject": "Reporting a bug", },
      },
    ]
  },
}