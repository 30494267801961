import React from "react";

/* Components */
import { Heading, InputEditField } from "../../../../../../components";

/* Methods */
import API from "../../../../API";

export default function MaptTitleEditable({ app }) {
  function updateMapAttr(obj) {
    const { /* inputValue, */ updatedValue, elementName } = obj;
    API.updateMapAttr(elementName, updatedValue, app.state.mapID).then(() => {
      app.setState({ [elementName]: updatedValue });
    });
  }

  return (
    <div className="u-mgn-btm-md">
      <Heading className="u-pad-btm-sm" ko={true} number={5}>
        Title
      </Heading>
      <InputEditField
        inputValue={app.state.name}
        ko={true}
        maxLength="50"
        name="name"
        updateValueCB={updateMapAttr}>
        <Heading bold={false} ko={true} number={5}>
          {app.state.name}
        </Heading>
      </InputEditField>
    </div>
  );
}
