import React from 'react'

// Components
import { CollapsibleV, Heading } from '../../../../../components'
import DataLayerElement from './dataLayerElement/DataLayerElement'
import UploadRasterButton from './uploadRaster/UploadRasterButton'
import UploadRasterButtonDisabled from './uploadRaster/UploadRasterButtonDisabled'

function DataSection({ dataLayers, pcm, mapHandler }) {
  return (
    <CollapsibleV parent={pcm} title='Data'>
      <div className='g__map__config-menu__section'>
        <div className='u-flex u-flex-justify-between'>
          <Heading ko={true} number={5}>
            Data Layers
          </Heading>
          <div>
            {dataLayers.data.length < 5 ? (
              <UploadRasterButton
                dataLayers={dataLayers}
                mapHandler={mapHandler}
                pcm={pcm}
              />
            ) : (
              <UploadRasterButtonDisabled />
            )}
          </div>
        </div>
        {dataLayers.data && dataLayers.data.length > 0 ? (
          dataLayers.data.map((obj, i) => {
            return (
              <DataLayerElement
                dataLayers={dataLayers}
                i={i}
                key={obj.id}
                mapHandler={mapHandler}
                pcm={pcm}
              />
            )
          })
        ) : (
          <>
            <div className='u-mgn-top-md'>
              <Heading bold={false} ko={true} number={5}>
                Add a data layer by uploading a data file...
              </Heading>
            </div>
          </>
        )}
      </div>
    </CollapsibleV>
  )
}

export default DataSection
