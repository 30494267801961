import { Icon, Popover, Text } from "..";
import React from "react";
import { SGTemplate } from ".";

export default function SGPopover() {
  /*   bodyHtmlObjects,
  bodyText = "",
  headerText = "",
  id = _uniqueId("popover-"),
  ...props */

  const templateContent = {
    name: "Popover",
    variantName: "",
    description:
      "An element that shows a popup message onClick.  A popover message has a header and a body.",
    implementation:
      "Instantiate as a component wrapped around the html element or component that will have the onClick effect.  The body content can be populated by manually formatted html objects, or a string.",
    propsTable: [
      [
        "...props.children",
        "enclosed html object",
        "<Icon />",
        "yes",
        "The Popover component wraps around the html object that hosts the onClick effect.  See Implementation and Code Examples.",
      ],
      [
        "bodyHtmlObjects",
        "enclosed html object",
        "<><p>Body paragraph</><div>Body div</div></>",
        "no",
        "The body can be populated with html objects that must be manually styled, such as divs, imgs, graphs, etc.\n\nDo not use with `bodyText`.",
      ],
      [
        "bodyText",
        "string",
        "'This is body text.'",
        "no",
        "The body can be populated with text that will be automatically styled.\n\nDo not use with `bodyHtmlObjects`.",
      ],
      [
        "headerText",
        "string",
        "'This is header text.'",
        "no",
        "The header can be populated with text that will be automatically styled.",
      ],
      [
        "id",
        "string",
        "'Popover-element-1'",
        "no",
        "The Popover element can be optionally assigned a specific html id, for example for testing purposes.",
      ],
      [
        "...props",
        "n/a",
        "n/a",
        "no",
        "This Popover component is built using the Reactstrap library and can utilize any props that are allowed by the Reactstrap Popover component.  See link below for documentation.",
      ],
    ],
    links: [
      {
        "Zeplin: popovers":
          "https://app.zeplin.io/project/5f45822488016a3407dea78c/screen/5faf435478cfcd694aba49bd",
      },
      {
        "Reactstrap: popover documentation":
          "https://reactstrap.github.io/components/popovers/",
      },
    ],
    codeExample: `import { Icon, Popover } from "frontend/src/components";

export default function PopoverContainer(){
  const popoverContent = {
    headerText: "This is a header",
    bodyHtmlObjects: (
      <>
        <p>This is a body p.</p>
        <div>This is a body div.</div>
        <div>
          This type of input needs to come with its own style applied
          beforehand.
        </div>
      </>
    ),
    bodyText:
      "This is the body text, which is automatically formatted by the component.",
  };

  return(
    <>
      // Example using bodyHtmlObjects
      <Popover
        bodyHtmlObjects={popoverContent.bodyHtmlObjects}
        headerText={popoverContent.headerText}>
        <Icon size="md" icon="help" />
      </Popover>

      // Example using bodyText
      <Popover
        bodyText={popoverContent.bodyText}
        headerText={popoverContent.headerText}>
        <Icon size="md" icon="help" />
      </Popover>
    </>
  )
}`,
  };

  const popoverContent = {
    headerText: "This is a header",
    bodyHtmlObjects: (
      <>
        <p>This is a body p.</p>
        <div>This is a body div.</div>
        <div>
          This type of input needs to come with its own style applied
          beforehand.
        </div>
      </>
    ),
    bodyText:
      "This is the body text, which is automatically formatted by the component.",
  };
  return (
    <SGTemplate
      name={templateContent.name}
      variantName={templateContent.variantName}
      description={templateContent.description}
      implementation={templateContent.implementation}
      componentExamples={
        <div>
          <Text number={1}>Click on the help icons below.</Text>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100px" }}>
              <div className="u-mgn-md">
                <Popover
                  bodyHtmlObjects={popoverContent.bodyHtmlObjects}
                  headerText={popoverContent.headerText}>
                  <Icon size="md" icon="help" />
                </Popover>
              </div>
            </div>
            <div style={{ width: "100px" }}>
              <div className="u-mgn-md">
                <Popover
                  bodyText={popoverContent.bodyText}
                  headerText={popoverContent.headerText}>
                  <Icon size="md" icon="help" />
                </Popover>
              </div>
            </div>
          </div>
        </div>
      }
      propsTableContents={templateContent.propsTable}
      links={templateContent.links}
      codeExample={templateContent.codeExample}
    />
  );
}
