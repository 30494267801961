import L from "leaflet";
// import features from "../../apps/collaborativeMapper/features";
export default {
  standardizeFeatures: geojson => {
    const featureCollection = []
    for (let i = 0; i < geojson.length; i++) {
      L.geoJson(geojson[i], {
        onEachFeature: function (feature, layer) {
          // console.log("feature", feature)
          // console.log("layer", layer)
          try {
            const editor = new L.Edit.Poly(layer)
            let standardized = new L.polygon(editor._defaultShape())
            standardized = standardized.toGeoJSON()
            featureCollection.push(standardized)
          } catch {
            console.log("fail")
          }
        }
      })
    }

    // for (let i = 0; i < geojson.length; i++) {
    //   L.geoJson(geojson[i], {
    //     onEachFeature: function (feature, layer) {
    //       // console.log("feature", feature)
    //       // console.log("layer", layer)
    //       // const editor = new L.Edit.Poly(layer)
    //       // let standardized = new L.polygon(editor._defaultShape())
    //       // standardized = standardized.toGeoJSON()
    //       // standardized["dataset"] = geojson[i].dataset
    //       featureCollection.push(layer.toGeoJSON())
    //     }
    //   })
    // }
    return featureCollection
  }
}