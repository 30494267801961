import React from "react";

/* Components */
import { Heading } from "../../../../../../components";

const MapSummary = ({ app }) => {
  return (
    <>
      <Heading className="u-pad-btm-sm" ko={true} number={5}>
        Summary
      </Heading>
      <Heading number={5} ko={true} bold={false}>
        {app.state.summary}
      </Heading>
    </>
  );
};

export default MapSummary;
