import React, { useEffect, useState } from 'react'

// Components
import {
  Button,
  Heading,
  Icon,
  Loading,
  Text,
} from '../../../../../../../../components'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

// Utilities
import APIreqs from '../../../../../../helpers/API'
import utils from '../../../../../../../../utils/utils'
import ValRstDataset from '../../../../../../classes/valRstDataset'

function ProcessValRstModal({ dataLayers, pcm }) {
  const processInit = {
      postAttrsStatus: 'loading', // Step 1
      valuedRasterStatus: undefined, // Step 2
      valuedImgStatus: undefined, // Step 3
      loadingStatus: undefined, // Step 4
      getImgStatus: undefined, // Step 5
      valRstDatasetID: undefined,
    },
    [process, setProcess] = useState(processInit)
  // console.log('process: ', process)

  useEffect(() => {
    // Step 1: POST method valRstDataset (with conditional DELETE if previous instance exists).
    // Save valuation function type, coeffs, color palette.
    if (process.postAttrsStatus === 'loading') {
      const { valRstDatasetID } = pcm.state.activeModalData
      async function deleteValRstDataset() {
        try {
          const res = APIreqs.valRasters.delete(valRstDatasetID)
          return res
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'delete CVM valued raster dataset' error.",
            'error',
          )
        }
      }

      async function processDeleteValRstDataset() {
        try {
          await deleteValRstDataset()
          const dataLayersData = dataLayers.data
          const { dataLayerID } = pcm.state.activeModalData
          dataLayersData.map((dataLayer) => {
            if (dataLayer.id === dataLayerID) {
              delete dataLayer.valRstDataset
            }
            return dataLayer
          })
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'process delete CVM valued raster dataset' error.",
            'error',
          )
        }
      }

      async function postValRst() {
        const { colorPalette, dataLayerID, fnCoeffs, fnType } =
          pcm.state.activeModalData
        const colorPaletteStringified = JSON.stringify(colorPalette)
        const fnCoeffsStringified = JSON.stringify(fnCoeffs)

        try {
          // Conditional DELETE req if valRstDataset already exists.
          if (valRstDatasetID) {
            await processDeleteValRstDataset()
          }
          const res = await APIreqs.valRasters.post(
            colorPaletteStringified,
            dataLayerID,
            fnCoeffsStringified,
            fnType,
          )
          return res.data
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'post CVM valued raster attrs' error.",
            'error',
          )
        }
      }

      async function processPostValRst() {
        try {
          const postValRstData = await postValRst()
          setProcess({
            ...process,
            postAttrsStatus: 'success',
            valuedRasterStatus: 'loading',
            valRstDatasetID: postValRstData.valRstDatasetID,
          })
        } catch {
          setProcess({ ...process, postAttrsStatus: 'error' })
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'processing post CVM valued raster attrs' error.",
            'error',
          )
        }
      }

      processPostValRst()
    }

    // Step 2: PUT method valRstDataset.  Generate valued raster data with function and save to file.
    if (
      process.postAttrsStatus === 'success' &&
      process.valuedRasterStatus === 'loading'
    ) {
      async function putValRst() {
        try {
          const res = await APIreqs.valRasters.put(
            process.valRstDatasetID,
            'genValRst',
          )
          return res
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'put CVM valued raster attrs, 'genValRst' error.",
            'error',
          )
        }
      }

      async function processPutValRst() {
        try {
          await putValRst()
          setProcess({
            ...process,
            valuedRasterStatus: 'success',
            valuedImgStatus: 'loading',
          })
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'process CVM valued raster attrs, 'genValRst' error.",
            'error',
          )
        }
      }

      processPutValRst()
    }

    // Step 3: PUT method valRstDataset. Generate valued raster image file using valued raster and color palette.
    if (
      process.valuedRasterStatus === 'success' &&
      process.valuedImgStatus === 'loading'
    ) {
      async function putValImg() {
        try {
          const res = await APIreqs.valRasters.put(
            process.valRstDatasetID,
            'genValImg',
          )
          return res
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'put CVM valued raster image, 'genValRst' error.",
            'error',
          )
        }
      }

      async function processValImg() {
        try {
          await putValImg()
          setProcess({
            ...process,
            valuedImgStatus: 'success',
            loadingStatus: 'loading',
          })
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'process CVM valued raster image, 'genValRst' error.",
            'error',
          )
        }
      }

      processValImg()
    }

    // Step 4: GET method rasterDataset.  Get valued raster dataset data.
    if (
      process.valuedImgStatus === 'success' &&
      process.loadingStatus === 'loading'
    ) {
      async function getValRst() {
        try {
          const res = await APIreqs.valRasters.getByID(process.valRstDatasetID)
          return res
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'get CVM valued raster error.",
            'error',
          )
        }
      }

      async function processGetValRst() {
        try {
          const res = await getValRst()
          const valRstDatasetData = res.data
          const valRstDataset = new ValRstDataset()
          valRstDataset.setResData(valRstDatasetData)
          valRstDataset.setData()

          const dataLayersData = dataLayers.data.map((dataLayer) => {
            if (dataLayer.id === valRstDataset.data.dataLayer.value) {
              dataLayer.raster.data.isImgLoaded.value = false
              dataLayer['valRstDataset'] = valRstDataset
            }
            return dataLayer
          })
          dataLayers.setData(dataLayersData)

          setProcess({
            ...process,
            loadingStatus: 'success',
            getImgStatus: 'loading',
          })
        } catch {
          utils.sendAlert(
            "Something has gone wrong.\n\nPlease use the contact form to submit a bug citing a 'process get CVM valued raster error.",
            'error',
          )
        }
      }

      processGetValRst()
    }

    // Step 5: Watch for isImgLoaded.
    if (
      process.loadingStatus === 'success' &&
      process.getImgStatus === 'loading' &&
      ((dataLayers.data[dataLayers.data.length - 1].valRstDataset &&
        dataLayers.data[dataLayers.data.length - 1].valRstDataset.data
          .isImgLoaded.value) ||
        !dataLayers.data[dataLayers.data.length - 1].is_visible)
    ) {
      setProcess({
        ...process,
        getImgStatus: 'success',
      })
    }
  }, [process, dataLayers.data])

  function resetModal() {
    pcm.setState({
      ...pcm.state,
      activeModal: '',
      activeModalData: null,
    })
  }

  // JSX
  const loadingIcon = <Loading size='sm' style={{ cursor: 'auto' }} />,
    checkIcon = <Icon icon='check' size='sm' style={{ cursor: 'auto' }} />,
    errorIcon = <Icon icon='warning' size='sm' style={{ cursor: 'auto' }} />

  function getIcon(status) {
    let icon
    if (status === 'loading') {
      icon = loadingIcon
    } else if (status === 'success') {
      icon = checkIcon
    } else if (status === 'error') {
      icon = errorIcon
    } else {
      icon = <></>
    }
    return icon
  }

  const { i } = pcm.state.activeModalData

  return (
    <Modal
      centered={true}
      isOpen={pcm.state.activeModal === `processValRstModal${i}`}
      size='xl'
      toggle={() => {}}>
      <ModalHeader>Data Layers</ModalHeader>
      <ModalBody>
        <div className='u-pad-md'>
          <Heading number={3} color='secondary'>
            Processing Valued Raster
          </Heading>
          <div className='u-mgn-top-md'>
            <div className='u-mgn-sm'>
              <Text number={1} className='u-pad-right-sm'>
                1. Storing valuation settings...
              </Text>
              {process.postAttrsStatus && getIcon(process.postAttrsStatus)}
            </div>
            <div className='u-mgn-sm'>
              <Text number={1} className='u-pad-right-sm'>
                2. Building valued raster dataset...
              </Text>
              {process.valuedRasterStatus &&
                getIcon(process.valuedRasterStatus)}
            </div>
            <div className='u-mgn-sm'>
              <Text number={1} className='u-pad-right-sm'>
                3. Generating valued raster image...
              </Text>
              {process.valuedImgStatus && getIcon(process.valuedImgStatus)}
            </div>
            <div className='u-mgn-sm'>
              <Text number={1} className='u-pad-right-sm'>
                3. Loading valued raster data...
              </Text>
              {process.loadingStatus && getIcon(process.loadingStatus)}
            </div>
            <div className='u-mgn-sm'>
              <Text number={1} className='u-pad-right-sm'>
                4. Fetching valued raster image to web map...
              </Text>
              {process.getImgStatus && getIcon(process.getImgStatus)}
            </div>
          </div>

          {/* Modal Buttons */}
          <div className='u-mgn-top-lg u-flex u-flex-justify-end'>
            <div className='map__modal-button-container'>
              <Button
                buttontype='primary'
                buttonProps={{
                  onClick: resetModal,
                }}
                disabled={process.getImgStatus !== 'success'}
                size='inherit'>
                Close
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ProcessValRstModal
