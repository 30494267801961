import { Icon } from ".";
import React, { useEffect, useState, useRef } from "react";

export default function Select({ ko = false, hashObjects, defaultIndex = 0 }) {
  const selectStateInit = {
      isOpen: false,
    },
    [selectState, setSelectState] = useState(selectStateInit),
    [selectedOption, setSelectedOption] = useState({}),
    [selectedIndex, setSelectedIndex] = useState(defaultIndex);

  useEffect(() => {
    setSelectedOption(hashObjects[selectedIndex]);
  }, [hashObjects, selectedIndex]);

  useEffect(() => {
    setSelectedIndex(defaultIndex);
  }, [defaultIndex]);

  const [
      selectorContainerStyleKO,
      selectorStyleKO,
      dropdownContainerStyleKO,
      dropdownElementStyleKO,
    ] = ko
      ? [
          "select__selector-container--ko",
          "select__selector--ko",
          "select__dropdown-container--ko",
          "select__dropdown-element--ko",
        ]
      : ["", "", "", "select__dropdown-element"],
    selectorContainerStyleIsOpen = selectState.isOpen
      ? "select__selector-container--is-open"
      : "",
    selectorContainerStyleIsOpenNotKo =
      selectState.isOpen && !ko
        ? "select__selector-container--is-open--not-ko"
        : "",
    selectorContainerStyleIsOpenKO =
      selectState.isOpen && ko ? "select__selector-container--is-open--ko" : "";

  const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        setSelectState((state) => {
          return { ...state, isOpen: false };
        });
      }
    }

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [ref]);

  const dropdownElements = hashObjects.map((dropdownElement, index) => {
    const dropdownElementName = Object.keys(dropdownElement)[0],
      dropdownElementLastStyle =
        index === hashObjects.length - 1
          ? "select__dropdown-element--last"
          : "";

    return (
      <div
        className={`${dropdownElementStyleKO} ${dropdownElementLastStyle} u-pad-sm paragraph__1`}
        key={index}
        onClick={() => {
          dropdownElement[dropdownElementName](index);
          setSelectedIndex(index);
        }}>
        {dropdownElementName}
      </div>
    );
  });

  return (
    <div
      className={`select__selector-container ${selectorContainerStyleKO} ${selectorContainerStyleIsOpen} ${selectorContainerStyleIsOpenNotKo} ${selectorContainerStyleIsOpenKO}`}
      onClick={() => {
        setSelectState({
          ...selectState,
          isOpen: !selectState.isOpen,
        });
      }}
      ref={ref}>
      <div
        className={`u-pad-sm paragraph__1 select__selector ${selectorStyleKO}`}>
        <span>{Object.keys(selectedOption)[0]}</span>
        <span className="u-pad-left-sm">
          {selectState.isOpen && <Icon ko={!ko} icon="caret-up" size="sm" />}
          {!selectState.isOpen && <Icon ko={!ko} icon="caret-down" size="sm" />}
        </span>
      </div>
      {selectState.isOpen && (
        <div
          className={`select__dropdown-container ${dropdownContainerStyleKO}`}>
          {dropdownElements}
        </div>
      )}
    </div>
  );
}
